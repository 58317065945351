.metro-line {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 25px;
  width: calc(100% - 60px);
  overflow: hidden;
  position: relative;
  &__central-line {
    border-top: 1px dashed $c--secondary;
    height: 1px;
    position: absolute;
    width: 100%;
    max-width: 570px;
    top: calc(50% - 7px);
  }
  &__item {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    position: relative;

    &:last-of-type {
      padding: 0;
    }

    &:first-of-type {
      padding: 0;
    }

    &--container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    &__icon {
      border: 1px solid $c--secondary;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      background-color: #fff;
      &__wrapper {
        width: 50px;
        height: 50px;
        position: relative;
        margin-top: 5px;
      }
    }
    &__link {
      &:focus {
        outline: none;
        svg {
          box-shadow: 0 0 4px 2px $c--chrome-select;
          border-radius: 50%;
        }
      }
    }
    &__label {
      color: $c--secondary;
      font-size: 0.75rem;
      font-weight: 400;
      margin-bottom: 0;
      margin-top: 4px;
      text-transform: uppercase;
    }

    &--selected {
      .metro-line__item {
        &__icon {
          border: 1px solid $c--primary;
          background-color: $c--primary;
          path {
            fill: $white;
          }
          &__wrapper::before {
            border-color: $c--secondary;
          }
        }
        &__label {
          color: $c--primary;
        }
      }
    }

    &--is-completed {
      .metro-line__item {
        &__icon {
          border: 1px solid $c--primary;
          path {
            fill: $c--primary;
          }
        }
        &__label {
          color: $c--primary;
        }
      }
    }
  }
}

@media screen and (min-width: $screen-lg-min) {
  .metro-line {
    margin: 8px auto;
    margin-bottom: 15px;
  }
}
