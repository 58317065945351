.complex_list_item {
  list-style-type: none;
  display: inline-block;
  width: 100%;
  text-decoration: none;
  &__content {
    display: flex;
    border-bottom: 1px solid $c--secondary;
    padding: 20px 0px;
    align-items: center;
    margin-bottom: -1px;
    position: relative;
  }
  &--selected {
    border: 1px solid $c--primary;
    .complex_list_item__title {
      &__h3,
      &__h4 {
        color: $c--primary;
      }
    }
  }
  &__left_content {
    &__icon {
      height: 46px;
      flex-basis: 46px;
      max-width: 50px;
    }
    &__radio {
      padding: 0px !important;
      margin-left: -5px !important;
      svg {
        font-size: 30px !important;
      }
    }
  }
  &__title {
    &__wrapper {
      margin-left: 20px;
      width: calc(100% - 40px);
    }
    &__h3,
    &__h4 {
      color: $c--secondary;
    }
    &__h3 {
      margin: 0;
      margin-bottom: 5px;
      font-size: 15pt;
    }
    &__text_field {
      width: calc(100% - 140px);
      position: absolute;
      top: 18px;
    }
  }
  &__right-content {
    margin-left: auto;
    &__icon {
      width: 25px;
      max-height: 30px;
      flex-basis: 25px;
      float: right;
      background-color: white;
    }
    &__chevron {
      width: 25px;
      max-height: 25px;
      flex-basis: 25px;
      transform: rotate(-90deg);
      float: right;
    }

    .button_hidden {
      padding: 0;
    }
  }
  &--warning {
    .complex_list_item__title__text_field {
      label,
      p {
        color: $c--tertiary;
      }
    }
  }
}

@media only screen and (min-width: $screen-xs-min) {
  .complex_list_item {
    margin-bottom: 10px;
    &__content {
      border: 1px solid $c--secondary;
      border-radius: 3px;
      padding: 20px;
    }
  }
}

@mixin responsiveGrid($minWidthMedia, $width) {
  @media screen and (min-width: $minWidthMedia) {
    .complex_list_item {
      max-width: $width;
      margin: 6px;
      margin-bottom: 5px;
    }
  }
}

@include responsiveGrid(880px, calc(100% / 2 - 12px));
@include responsiveGrid($screen-xl-min, calc(100% / 3 - 12px));
@include responsiveGrid(2000px, calc(100% / 4 - 12px));
