.timeline {
  &__item {
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
    min-height: 180px;
    padding-bottom: 12px;
    padding-top: 20px;
    position: relative;
    text-align: center;

    &__hidden_button {
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: none;
      &:focus {
        border: 1px solid $c--primary;
        box-shadow: inset 0px 0px 0px 1px $c--primary;
        outline: none;
      }
      &::-moz-focus-inner {
        border: none;
      }
    }
  }
}

.timeline__item-allplans {
  min-height: 97px;
}

.timeline-separator {
  border: 0;
  border-top: 1px dashed $c--secondary;
  margin: 0;
  margin-bottom: 8px;
  margin-left: 2px;
}

.timeline__item--label {
  color: $c--secondary;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  width: 100%;

  &:only-child {
    margin-top: auto;
  }
}

.timeline__step-num {
  align-items: center;
  background-color: $c--secondary;
  border-radius: 50%;
  color: $white;
  display: flex;
  font-weight: 600;
  height: 28px;
  justify-content: center;
  margin: 0 auto;
  width: 28px;
}

.step-num--big {
  display: inline-flex;
  font-size: 1.125rem;
  height: 34px;
  line-height: 34px;
  margin: 0;
  margin-right: 16px;
  min-width: 34px;
  width: 34px;
}

.timeline__step-name {
  color: $c--secondary;
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 30px;
  margin-top: 20px;
  padding: 0 4px;
  word-break: break-word;
}

.timeline__step-box {
  color: white;
  background-color: $c--secondary;
  height: 15px;
  width: 60px;
  margin: 3px 16% 0;
  width: 68%;
}

.timeline--step-ellipsis {
  height: 15px;
  width: 1px;
  margin: 3px auto;
  border-left: 1px dashed;
  background: none;
}

.timeline__item--empty {
  display: none;

  &:hover {
    cursor: default;
  }
}

.timeline__item--active {
  &.timeline__box-info {
    border: 1px solid $c--primary;

    &:hover {
      cursor: default;
    }

    ~ .timeline__item--label {
      color: $c--primary;

      .timeline__step-box {
        background-color: $c--primary;
      }
    }
  }

  .timeline__item--label {
    color: $c--primary;
    top: 143px;
  }

  .timeline-separator {
    border-top: 1px dashed $c--primary;
  }

  .timeline__step-num {
    background-color: $c--primary;
  }

  .timeline__step-name {
    color: $c--primary;
  }

  .timeline__step-box {
    background-color: $c--primary;
    top: 129px;
  }
}

.timeline {
  display: flex;
  margin: 0 auto;
  min-height: 169px;
  padding-bottom: 30px;
  width: calc(100% - 48px);

  .slick-list {
    margin-top: auto;
    width: 100%;
  }

  .slick-arrow {
    bottom: 0;
    height: 19px;
    margin: auto 0;
    top: 0;
    width: 12px;

    &::before {
      background-size: 12px 19px;
      content: '';
      display: inline-block;
      height: 19px;
      opacity: 1;
      width: 12px;
    }

    &.slick-disabled {
      opacity: 0.3;
    }
  }

  .slick-next {
    right: -36px;

    &::before {
      background-image: url('assets/images/chevron-right.svg');
      background-repeat: no-repeat;
    }
  }

  .slick-prev {
    left: -36px;

    &::before {
      background-image: url('assets/images/chevron-left.svg');
      background-repeat: no-repeat;
    }
  }
}

.timeline__box-info {
  border: 1px solid $c--secondary;
  flex: 1 1 auto;
  height: auto;
  margin: 3px 4px;
  padding: 10px 2px;

  &:hover {
    cursor: pointer;
  }
}

.timeline__my-plan {
  display: flex;
  flex: 1;
  justify-content: center;
  &__inner_wrapper {
    display: flex;
    overflow: auto;
  }
  .timeline__box-info {
    display: flex;
    flex-direction: column;
  }

  .timeline__item {
    height: auto;
    justify-content: flex-end;
    margin: 0;
    width: 150px;

    .timeline__step-name {
      margin: auto;
      padding: 4px 0;
    }
  }
}

@media only screen and (min-width: $screen-sm-min) {
  .timeline__step-name {
    font-size: 0.875rem;
  }

  .timeline__box-info {
    padding: 10px 5px;
  }

  .timeline__my-plan {
    .timeline__item--empty {
      display: flex;
      max-width: 50px;
    }
  }
  /*
  .timeline__card-detail {
    .tab-column {
      flex-basis: 100%;
    }
  }*/
}
/*

@media only screen and (min-width: $screen-xsm-min) {
  .timeline__card-detail {
    padding-top: 50px;
    flex-direction: row;
    hr {
      display: block;
    }
  }
  .tab-column::before {
    background-color: transparent;
  }
}

*/
@media only screen and (min-width: $screen-md-min) {
  .timeline__item--empty {
    max-width: 60px;
  }
}

@media only screen and (min-width: $screen-lg-min) {
  .timeline {
    width: 100%;
  }

  .timeline__item--empty {
    max-width: 70px;
  }
}

@media print {
  .timeline__item--empty {
    display: flex;
    max-width: 52px;
  }
}

.application_map {
  &__box {
    border: 5px;
    background-color: $c--primary;
    max-width: 140px;
    height: 35px;
  }
  &__text {
    color: $white;
    font-size: 10px;
    font-weight: bold;
    margin-top: 8%;
    text-transform: uppercase;
  }
}
