.expansion-panel__question {
  color: $c--secondary-dark;
  font-size: 1.2em;
  margin: 0;
  padding: 15px 0;
}

.expansion-panel__chevron {
  width: 16px;
}
