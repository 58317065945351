@mixin plan-body {
  background-color: $c--secondary;
  &.active {
    background-color: $c--primary;
  }
  padding: 0.5rem;
}

@mixin cover-body {
  background: repeating-linear-gradient(
    135deg,
    $c--secondary-light-7,
    $c--secondary-light-7 1px,
    $c--secondary 2px,
    $c--secondary 8px
  );
  padding: 0.5rem;
}

.crop-rotation-calendar-placeholder {
  margin: 1rem 0;
  height: 3rem;
}

.crop-rotation-calendar {
  margin: 1rem 0;
  &__plans-row {
    display: flex;
    flex-direction: row;
    border-bottom: 1px dashed $c--secondary;
    font-weight: bold;
    color: $white;
    &--plan {
      @include plan-body();
      margin-left: 0.3rem;
      text-align: center;
    }
    &--cover {
      @include cover-body();
      margin-left: 0.3rem;
      text-align: center;
    }
  }
  &__years-row {
    display: flex;
    flex-direction: row;
    padding: 0.5rem 0;
    &--year {
      color: $c--secondary;
      text-align: center;
      flex: 2;
      &--gap {
        flex: 10;
      }
    }
  }
}

.crop-rotation-calendar-mobile {
  margin: 1rem 0;
  &__title {
    color: $c--primary;
    padding: 0.5rem 0;
  }
  &__plans-carousel {
    color: $white;
    text-align: left;
    &--plan {
      @include plan-body();
      padding: 1rem;
      &--title {
        font-weight: bold;
        margin-bottom: 0.5rem;
      }
      &.mop {
        height: 100%;
      }
    }
    &--cover {
      @include cover-body();
      font-weight: bold;
      padding: 1rem;
      height: 100%;
    }
  }
}
