.legend {
  &__wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  &__box {
    width: 12px;
    height: 12px;
    &--primary {
      background-color: $c--primary;
    }
    &--secondary {
      background-color: $c--secondary;
    }
    &--tertiary {
      background-color: $c--tertiary;
    }
  }
  &__text {
    margin: 0;
    margin-left: 10px;
    font-size: 10pt;
  }
}
