.draw_the_boundaries {
  text-align: center;
  font-size: 1rem;
  color: $c--secondary;
  max-width: 30rem;
  margin: 0 auto 2rem auto;
}

@media only screen and (max-width: $screen-xsm-min) {
  .draw_the_boundaries {
    margin: 0 0.5rem 1rem 0.5rem;
  }
}
