.vertical_bars {
  display: flex;
  margin-top: 25px;
  .vertical_bar__wrapper__super {
    margin-right: 20px;
  }
}

$height-line: calc(100% - 30px);

.arrow {
  &__wrapper {
    white-space: nowrap;
    height: 200px;
    position: relative;
    width: 50px;
  }
  &__line {
    position: absolute;
    height: $height-line;
    bottom: -8px;
    border: none;
    width: 1px;
    background-color: $c--secondary;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
  &__triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7px 12px 7px;
    border-color: transparent transparent $c--secondary transparent;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: $height-line;
  }
  &__label {
    color: $c--secondary;
    text-align: center;
    font-weight: normal;
    margin: 0;
    font-size: 9pt;
  }
}
