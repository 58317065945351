@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .react-bodymovin-container {
    background: url('assets/images/tractor-color.svg') no-repeat;
    background-size: 100%;
    height: 150px;
    width: 300px;

    svg {
      display: none;
    }
  }

  .ie--flex-box-fix,
  .form__buttons {
    flex: 1 1 auto;
  }

  .btn--picto__svg {
    max-height: 18px;
  }

  .tab-accordion__child__wrapper {
    width: 100%;
  }
  /*
  .timeline__my-plan {
    justify-content: flex-start;
    margin-bottom: 30px;

    .timeline__item {
      flex: 1 1 auto;
      max-width: inherit;
      min-width: 50px;
    }

    .timeline__item--label:only-child {
      margin-top: 0;
    }
  }
  */

  .round-label--balance {
    height: 40px;
  }

  .logo {
    height: 52px;
  }

  .picto-chevron--left {
    height: 20px;
  }
  /*
  .content-wrapper {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    overflow: auto;
  }
  */
  .content-main {
    display: block;
    flex: 1 0 auto;
  }

  .picto-title {
    flex: 0 1 auto;
  }

  .splash-screen__container {
    overflow: auto;
  }

  .metro-line__picto-wrapper {
    height: 48px;
  }

  .main-nav__item__picto img {
    margin: 0;
  }

  .tab-accordion__button {
    //display: inline-block;
    min-width: 300px;
  }

  .tab-row-picto svg {
    max-height: 36px;
  }

  input[type='range'] {
    background: transparent;
    height: 45px;
    margin: 0;
    padding: 0;

    &::-ms-thumb {
      background: $c--secondary;
      border: none;
      border-radius: 50%;
      box-sizing: border-box;
      height: 36px;
      width: 36px;
      background: $white;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.14);
    }

    &::-ms-fill-lower {
      background: $c--primary;
    }

    &::-ms-fill-upper {
      background: $c--secondary-light-4;
    }

    &::-ms-track {
      background: $c--secondary;
      box-sizing: border-box;
      height: 1px;
      border: none;
    }

    &::-ms-tooltip {
      display: none;
    }
  }

  .form-slider--progress {
    display: none;
  }

  .form__field-picto {
    p {
      flex: 0;
    }
  }

  .logo-lat {
    height: 78px;
    width: 130px;
  }

  .month-list {
    &__crop-picto {
      height: 46px;
    }

    &__link {
      button {
        display: none;
      }
    }
  }

  .layout__buttons-wrapper {
    flex: 0 1 auto;
  }

  .month-list__month-item__wrapper {
    &:after {
      content: '';
      flex: auto;
      position: relative;
      display: block;
    }
  }

  .main-nav__item__picto {
    img {
      height: 54px;
      width: 54px;
    }
  }

  .tab-accordion__header {
    margin-bottom: 30px;
    padding-top: 0;

    .picto-separator {
      margin-top: 0;
    }
  }

  .timeline__my-plan .timeline {
    &--item--empty {
      display: none;
    }

    &--item {
      flex: 1 1 auto;
    }
  }

  .timeline__my-plan .timeline__box-info {
    cursor: default;
    pointer-events: none;
  }

  .tab-accordion__children .tab-accordion__panel,
  .tab-accordion__children .fade-out-schrink.tab-accordion__panel {
    order: 0;
  }

  .tab-accordion__children .tab-accordion__button {
    justify-content: flex-start;
  }
}

@media only screen and (min-width: $screen-sm-min) and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
  .month-list__link,
  .month-list__no-link {
    width: calc((100% / 3) - (74px * 2) / 3);
  }

  .react-bodymovin-container {
    height: 300px;
    width: 600px;
  }
}

@media only screen and (min-width: $screen-lg-min) and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
  .footer {
    &__copyright {
      padding: 0;
      width: auto;
    }

    &__link {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
    }
  }

  .month-list__link,
  .month-list__no-link {
    width: calc((100% / 3) - (74px * 2) / 3);
  }
}

@media only screen and (min-width: $screen-xl-min) and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
  .month-list__link,
  .month-list__no-link {
    width: calc((100% / 4) - (74px * 3) / 4);
  }
}
