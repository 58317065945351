.crop-rotation-plan-list-mobile {
  &__mop {
    border: 1px $c--secondary solid;
    padding: 1rem 0.5rem;
    border-radius: 0.5rem;
    color: $c--secondary;
    font-size: 1.2rem;
    &--title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
    &--action {
      width: 100%;
      text-align: center;
      padding-top: 2rem;
    }
  }
  &__cover {
    border: 1px $c--secondary solid;
    padding: 1rem 0.5rem;
    border-radius: 0.5rem;
    color: $c--secondary;
    font-size: 1.2rem;
    display: flex;
    justify-content: space-between;
  }
  &__element {
    width: 100%;
    &--title-area {
      display: flex;
      flex-direction: row;
      align-items: center;
      &--icon {
        margin-right: 1rem;
        .icon-element {
          max-height: 80px;
        }
      }
      &--title {
        color: $c--secondary;
        font-weight: bold;
        font-size: 1.2rem;
        a {
          text-decoration: none;
        }
      }
    }
    &--duration-area {
      padding-bottom: 1rem;
      color: $c--secondary;
      font-weight: bold;
      width: 100%;
      &--label {
        border-bottom: 1px solid $c--primary;
        padding: 0.5rem 0;
      }
      &--value {
        display: flex;
        flex-direction: row;
        align-items: center;
        .picker:first-child {
          margin-right: 0.5rem;
        }
        .picker:last-child {
          margin-left: 0.5rem;
        }
      }
    }
    &--shopping-list {
      padding: 1rem 0 0.5rem 0;
      display: flex;
      justify-content: center;
      .print-button {
        border-radius: 1rem;
        text-transform: none;
        font-weight: bold;
        padding: 0.5rem 0.8rem;
        min-width: 150px;
      }
    }
    &--delete {
      padding-bottom: 1rem;
      display: flex;
      justify-content: center;
      .button {
        color: $white;
        background-color: $c--orange;
        font-weight: bold;
        padding: 0.5rem 0.8rem;
        border-radius: 1rem;
        text-transform: none;
        min-width: 150px;
      }
    }
    &--add-new-plan {
      width: 100%;
    }
  }
}
