@media screen {
  .fade-in {
    animation-name: fadeIn;
    animation-duration: 1s;
  }

  .fade-in-expand {
    animation-name: fadeInExpand;
    animation-duration: 1s;
  }

  .fade-out-schrink {
    animation-name: fadeOutSchrink;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    height: 0;
    overflow: hidden;
    input {
      display: none;
    }
  }

  .scale-fade-in {
    animation-name: scaleFadeIn;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    opacity: 0;
    transform: scale(0);
  }

  .scale-fade-out {
    animation-name: scaleFadeOut;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    display: none;
    opacity: 0;
    transform: scale(0);
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeInExpand {
    0% {
      opacity: 0;
      max-height: 0;
    }
    100% {
      opacity: 1;
      max-height: 2000px;
    }
  }

  @keyframes fadeOutSchrink {
    0% {
      opacity: 1;
      height: 2000px;
    }
    50% {
      opacity: 0.2;
      height: 200px;
    }
    100% {
      opacity: 0;
      height: 0;
    }
  }
  @keyframes scaleFadeIn {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    70% {
      transform: scale(1.2);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes scaleFadeOut {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(0);
    }
  }
}

@keyframes slideRAndScaleY {
  20% {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    transform: translateX(80vw) scale(0.95, 0.95);
    box-shadow: 0 0 90px rgba(0, 0, 0, 0.3);
  }
}

@keyframes reverseSlideRAndScaleY {
  0% {
    transform: translateX(80vw) scale(0.95, 0.95);
    box-shadow: 0 0 90px rgba(0, 0, 0, 0.3);
    height: 100vh;
  }

  99% {
    height: 100vh;
  }

  100% {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    transform: translateX(0) scale(1, 1);
    height: auto;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
