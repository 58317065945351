.myaccount-read-only--container {
  text-align: center;
  max-width: 320px;
  margin: 32px auto;

  p {
    margin: 32px 0;
  }
}

.exitButton--image,
.resetPassword--image {
  padding-right: 4px;
}

.exitButton--image {
  width: 16px;
  height: 16px;
}

.resetPassword--image {
  width: 22px;
  height: 22px;
}

.myaccount-center--container {
  text-align: center;
  max-width: 320px;
  margin: 8px auto;
  padding: 0;
}

.myaccount-center--buttons {
  margin: 16px auto;
  padding: 32px 0;
  width: calc(100% - 16px);
}

.myaccount-center--container button {
  border-radius: 32px;
  width: 250px;
  margin: 16px 0;
}

.myaccount__button {
  display: flex;
  justify-content: space-between;
}

.account__checkbox {
  margin: 32px 0;
}

div.account__userInfo {
  margin: 32px 0;
}

.account__userInfo {
  font-weight: bold;
  line-height: 1.25;
  p {
    margin: 0;
  }
}

.account__userInfo--vatNumber {
  font-weight: normal;
  font-size: 14px;
}

.account__signOut {
  margin: 64px 0;
}

.account__role {
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  color: $c--primary;
}

.myaccount-center--signOut {
  display: flex;
  justify-content: center;
}

.myaccount__contact--button {
  color: $c--primary;
  text-transform: inherit;
}
