.my-crop-rotations-table {
  width: 100%;
  margin-top: 1rem;
  &__header {
    color: $c--secondary;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    &--column--1 {
      flex: 1;
    }
    &--column--2 {
      flex: 2;
      .unit-display {
        font-size: 0.9rem;
      }
    }
    &--column--3 {
      flex: 3;
    }
  }
}

@media only screen and (max-width: $screen-md-min) {
  .my-crop-rotations-table {
    &__header {
      display: none;
    }
  }
}
