@mixin buttonBody {
  border: none;
  font-size: 1rem;
  padding: 0;
  background-color: transparent;
  &:focus {
    outline: none;
  }
}
.crop-rotation-plan-list {
  &__element {
    border: 1px $c--secondary solid;
    padding: 1rem 0.5rem;
    border-radius: 0.2rem;
    height: 100%;
    transition: border 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.mop {
      background-color: $c--primary--pale-dark-5;
      border-color: $c--primary--pale-dark-5;
    }
    &:hover {
      border-color: $c--primary;
    }
    &--title-area {
      display: flex;
      flex-direction: row;
      &--icon {
        flex: 1;
        width: 60px;
        height: auto;
        margin-right: 1rem;
        .icon-element {
          width: 100%;
          max-height: 100px;
        }
      }
      &--title {
        flex: 3;
        color: $c--secondary;
        font-weight: bold;
        font-size: 1.2rem;
        align-self: center;
        word-break: break-all;
        &.mop {
          align-self: flex-start;
          margin-top: 1rem;
          border-bottom: 1px solid $white;
          padding-bottom: 1rem;
          font-weight: bold;
          display: flex;
          flex: 0;
          flex-direction: row;
          &__icon {
            color: $white;
            flex: 1;
            font-size: 3.5rem;
            position: relative;
            text-align: right;
            padding-right: 1rem;
            &--plus {
              position: absolute;
              top: 0;
              right: 0;
              font-size: 2rem;
              color: $white;
            }
          }
          &__title {
            color: $white;
            flex: 4;
            word-break: keep-all;
            padding-left: 1rem;
          }
        }
      }
      &--details {
        flex: 1;
        color: $c--primary;
        height: 100%;
        text-align: right;
      }
    }
    &--duration-area {
      padding: 1rem 0;
      border-bottom: 1px solid $c--secondary;
      color: $c--secondary;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      &.mop {
        border-bottom: none;
        flex-direction: column;
        color: $white;
      }
      &--label {
        margin-right: 0.5rem;
        line-height: 2rem;
      }
      &--value {
        font-weight: bold;
        display: flex;
        flex-direction: row;
        align-items: center;
        .picker:first-child {
          margin-right: 0.5rem;
        }
        .picker:last-child {
          margin-left: 0.5rem;
        }
      }
    }
    &--products-area {
      .product-element {
        color: $c--secondary;
        padding: 0.5rem 0;
      }
    }
    &--tooltip-body {
      width: 200%;
    }
    &--bottom-area {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &--change-time {
        color: $white;
        @include buttonBody();
      }
      &--recommendation {
        color: $c--primary;
        @include buttonBody();
      }
      &--delete {
        color: $c--orange;
        @include buttonBody();
      }
      .action-bar {
        width: 100%;
        text-align: center;
        margin: 1rem 0;
        &__print {
          border-radius: 1rem;
          text-transform: none;
          &--icon {
            margin-right: 1rem;
          }
        }
      }
    }
    &.add-new-plan {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      & .title {
        color: $c--secondary;
        margin-top: 0.5rem;
      }
    }
  }
}
