.my-crop-rotations-list {
  &__section-header {
    font-size: 0.8rem;
    color: $c--primary;
    margin: 0.5rem 0;
  }
}

@media only screen and (max-width: $screen-md-min) {
  .my-crop-rotations-list {
    max-height: none;
  }
}
