/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("assets/fontFiles/KFOlCnqEu92Fr1MmSU5fCRc4AMP6lbBP.woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("assets/fontFiles/KFOlCnqEu92Fr1MmSU5fABc4AMP6lbBP.woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("assets/fontFiles/KFOlCnqEu92Fr1MmSU5fCBc4AMP6lbBP.woff2");
  unicode-range: U+1F00-1FFF; }

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("assets/fontFiles/KFOlCnqEu92Fr1MmSU5fBxc4AMP6lbBP.woff2");
  unicode-range: U+0370-03FF; }

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("assets/fontFiles/KFOlCnqEu92Fr1MmSU5fChc4AMP6lbBP.woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("assets/fontFiles/KFOlCnqEu92Fr1MmSU5fBBc4AMP6lQ.woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("assets/fontFiles/KFOmCnqEu92Fr1Mu72xKKTU1Kvnz.woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("assets/fontFiles/KFOmCnqEu92Fr1Mu5mxKKTU1Kvnz.woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("assets/fontFiles/KFOmCnqEu92Fr1Mu7mxKKTU1Kvnz.woff2");
  unicode-range: U+1F00-1FFF; }

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("assets/fontFiles/KFOmCnqEu92Fr1Mu4WxKKTU1Kvnz.woff2");
  unicode-range: U+0370-03FF; }

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("assets/fontFiles/KFOmCnqEu92Fr1Mu7GxKKTU1Kvnz.woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("assets/fontFiles/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("assets/fontFiles/KFOlCnqEu92Fr1MmEU9fCRc4AMP6lbBP.woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("assets/fontFiles/KFOlCnqEu92Fr1MmEU9fABc4AMP6lbBP.woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("assets/fontFiles/KFOlCnqEu92Fr1MmEU9fCBc4AMP6lbBP.woff2");
  unicode-range: U+1F00-1FFF; }

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("assets/fontFiles/KFOlCnqEu92Fr1MmEU9fBxc4AMP6lbBP.woff2");
  unicode-range: U+0370-03FF; }

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("assets/fontFiles/KFOlCnqEu92Fr1MmEU9fChc4AMP6lbBP.woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("assets/fontFiles/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("assets/fontFiles/KFOlCnqEu92Fr1MmWUlfCRc4AMP6lbBP.woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("assets/fontFiles/KFOlCnqEu92Fr1MmWUlfABc4AMP6lbBP.woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("assets/fontFiles/KFOlCnqEu92Fr1MmWUlfCBc4AMP6lbBP.woff2");
  unicode-range: U+1F00-1FFF; }

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("assets/fontFiles/KFOlCnqEu92Fr1MmWUlfBxc4AMP6lbBP.woff2");
  unicode-range: U+0370-03FF; }

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("assets/fontFiles/KFOlCnqEu92Fr1MmWUlfChc4AMP6lbBP.woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("assets/fontFiles/KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

.app-busy-placeholder {
  align-items: center;
  background-color: rgba(131, 156, 169, 0.7);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999; }
  .app-busy-placeholder .form__spinner {
    position: fixed;
    top: 50%; }
    .app-busy-placeholder .form__spinner svg {
      color: #fff; }
  .app-busy-placeholder--text {
    color: #fff;
    font-size: 1.4rem;
    max-width: 90%;
    position: fixed;
    text-align: center;
    top: calc(50% + 50px); }
  .app-busy-placeholder--white {
    background-color: white; }
    .app-busy-placeholder--white .form__spinner svg {
      color: #839ca9; }

.linear-progress--in-container {
  height: 2px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.error_text {
  width: 100%;
  text-align: center; }

.logo-nutriguide--white path {
  fill: white; }

@media screen {
  .fade-in {
    animation-name: fadeIn;
    animation-duration: 1s; }
  .fade-in-expand {
    animation-name: fadeInExpand;
    animation-duration: 1s; }
  .fade-out-schrink {
    animation-name: fadeOutSchrink;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    height: 0;
    overflow: hidden; }
    .fade-out-schrink input {
      display: none; }
  .scale-fade-in {
    animation-name: scaleFadeIn;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    opacity: 0;
    transform: scale(0); }
  .scale-fade-out {
    animation-name: scaleFadeOut;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    display: none;
    opacity: 0;
    transform: scale(0); }
  @keyframes fadeIn {
    0% {
      opacity: 0; }
    100% {
      opacity: 1; } }
  @keyframes fadeInExpand {
    0% {
      opacity: 0;
      max-height: 0; }
    100% {
      opacity: 1;
      max-height: 2000px; } }
  @keyframes fadeOutSchrink {
    0% {
      opacity: 1;
      height: 2000px; }
    50% {
      opacity: 0.2;
      height: 200px; }
    100% {
      opacity: 0;
      height: 0; } }
  @keyframes scaleFadeIn {
    0% {
      opacity: 0;
      transform: scale(0); }
    70% {
      transform: scale(1.2); }
    100% {
      opacity: 1;
      transform: scale(1); } }
  @keyframes scaleFadeOut {
    0% {
      opacity: 1;
      transform: scale(1); }
    100% {
      opacity: 0;
      transform: scale(0); } } }

@keyframes slideRAndScaleY {
  20% {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0); }
  100% {
    transform: translateX(80vw) scale(0.95, 0.95);
    box-shadow: 0 0 90px rgba(0, 0, 0, 0.3); } }

@keyframes reverseSlideRAndScaleY {
  0% {
    transform: translateX(80vw) scale(0.95, 0.95);
    box-shadow: 0 0 90px rgba(0, 0, 0, 0.3);
    height: 100vh; }
  99% {
    height: 100vh; }
  100% {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    transform: translateX(0) scale(1, 1);
    height: auto; } }

@keyframes appear {
  0% {
    opacity: 0;
    transform: scale(0); }
  50% {
    transform: scale(1.2); }
  100% {
    opacity: 1;
    transform: scale(1); } }

body {
  background-color: #fff;
  font-family: 'Roboto', sans-serif; }

h2 {
  color: #71bf44; }

h3 {
  color: #71bf44;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 30px; }

h4 {
  color: #71bf44;
  font-weight: 400;
  margin: 0; }

a:not([role='button']) {
  transition: color 0.4s; }
  a:not([role='button']):link, a:not([role='button']):visited {
    color: #71bf44; }
  a:not([role='button']):hover {
    color: #5f9501; }

.big-title {
  color: #71bf44;
  font-size: 2.5rem;
  font-weight: 300;
  margin: 0; }

.content-label {
  color: #839ca9; }

.form__title {
  color: #71bf44;
  font-size: 1rem;
  font-weight: 400;
  margin: 0; }

p {
  color: #839ca9; }

.text-small {
  color: #839ca9;
  display: inline;
  font-size: 0.8rem; }

.grid-container {
  padding: 0 16px; }

.body__wrapper {
  padding: 0 8px;
  padding-bottom: 30px;
  width: 100%; }

.body__full-h {
  min-height: calc(100vh - 96px); }

.body--fit-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  min-height: calc(100vh - 96px);
  min-height: calc((var(--vh, 1vh) * 100) - 96px);
  padding: 0 8px;
  width: 100%; }

.body__full-h__buttons {
  flex: 1 1 auto;
  align-items: flex-end;
  align-self: flex-end;
  display: flex;
  flex-basis: auto;
  justify-self: flex-end;
  margin: 0 auto;
  margin-top: auto;
  width: 100%; }
  .body__full-h__buttons button,
  .body__full-h__buttons a {
    margin: 0 auto; }
  .body__full-h__buttons button {
    margin-top: 30px; }

.content-wrapper {
  overflow-x: hidden;
  overflow-y: visible; }
  .content-wrapper:only-child {
    flex-basis: 100%;
    max-width: 100vw;
    width: 100%; }

.content-main {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  position: relative;
  z-index: 1090; }
  .content-main main {
    padding-top: 66px; }

.content--loading-screen {
  z-index: 1090; }

.content-row {
  margin-top: 30px; }

.content-row__grid-wrapper {
  margin: 0 auto;
  width: 100%; }

.content-row--grid-wrapper {
  margin: 0 auto; }
  .appbar .content-row--grid-wrapper,
  .tab-accordion__children .content-row--grid-wrapper {
    width: 100%; }

.terms-conditions__wrapper {
  -webkit-overflow-scrolling: touch;
  border: 1px solid rgba(131, 156, 169, 0.4);
  border-radius: 2px;
  color: #505f66;
  overflow: auto;
  padding: 10px 24px;
  position: relative;
  text-align: justify; }
  .terms-conditions__wrapper h2 {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 0.85rem;
    margin-top: 1.2rem; }
  .terms-conditions__wrapper p {
    color: #505f66; }

.terms-conditions__wrapper--read {
  border: 0;
  height: initial;
  margin-top: 24px;
  padding: 0; }

.no-results {
  font-size: 2rem;
  font-weight: 200;
  margin-top: 90px; }

.navigation-open + .content-wrapper .content-main {
  animation: slideRAndScaleY 0.3s ease-in-out 0.05s;
  animation-fill-mode: forwards;
  height: 100vh;
  overflow: hidden;
  transform-origin: 50% 50%;
  z-index: 1090; }
  .navigation-open + .content-wrapper .content-main main > div:not(.page-header),
  .navigation-open + .content-wrapper .content-main .header-content,
  .navigation-open + .content-wrapper .content-main .appbar__logo,
  .navigation-open + .content-wrapper .content-main .subheader {
    opacity: 0.3;
    transition: opacity 0.3s; }
  .navigation-open + .content-wrapper .content-main::before {
    background-color: transparent;
    content: '';
    height: 100vh;
    left: 0;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 1; }

.navigation-close + .content-wrapper .content-main {
  animation: reverseSlideRAndScaleY 0.3s ease-in-out 0s;
  overflow-y: hidden;
  transform-origin: 50% 50%;
  z-index: 1090; }

.terms__content {
  flex-basis: 100%;
  max-width: 100%;
  padding-bottom: 1rem;
  margin: 0 auto;
  width: 100%; }

.terms__content--NG4--text-fields {
  width: 100%; }

.dialog__terms {
  max-width: 980px;
  width: 94vw; }
  .dialog__terms .body__full-h__buttons {
    margin-top: 0; }

.separator__full-width {
  border: 0;
  border-bottom: 1px solid rgba(131, 156, 169, 0.4);
  margin: 0;
  width: calc(100% + 48px); }

.separator__right-aligned {
  border: 0;
  border-bottom: 1px solid rgba(131, 156, 169, 0.4);
  margin: 0;
  width: calc(100% + 0px); }

.layout__buttons-wrapper {
  margin: 0 auto;
  padding: 40px 0;
  padding-top: 16px;
  width: calc(100% - 16px); }
  .layout__buttons-wrapper button:first-of-type {
    margin-top: 36px; }

@media only screen and (min-width: 768px) {
  .body__full-h__buttons button,
  .body__full-h__buttons a {
    max-width: 320px; } }

@media only screen and (min-width: 1280px) {
  .content-wrapper {
    height: 100%;
    overflow-y: auto;
    position: fixed;
    right: 0;
    width: 100%;
    max-width: calc(100% - 320px); }
  .content-wrapper--fullscreen {
    height: auto;
    width: auto;
    overflow-y: visible;
    position: static; }
  .body--fit-screen {
    min-height: calc(100vh - 118px);
    min-height: calc((var(--vh, 1vh) * 100) - 118px);
    padding-bottom: 30px; }
  .body--fit-screen--no-header {
    min-height: 100vh; }
  .navigation-open + .content-wrapper .content-main {
    animation: none; }
    .navigation-open + .content-wrapper .content-main::before {
      display: none; }
  .navigation-close + .content-wrapper .content-main {
    animation: none; }
  .content-main main {
    padding-top: 0px; } }

.form__wrapper {
  padding: 0 8px;
  width: 100%;
  flex: 0;
  max-width: 600px; }

.form__section {
  display: inline-block;
  margin: 8px 0;
  padding: 24px 0;
  position: relative;
  width: 100%; }
  .form__section.periodic-inline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; }
  .form__section__info {
    color: #839ca9;
    size: 0.9rem; }
  .form__section__row {
    margin-top: 30px; }
    .form__section__row > .form__title {
      margin-top: 0; }
  .form__section--sm {
    display: inline-block;
    padding: 20px 60px;
    width: 100%; }
    .form__section--sm:last-of-type {
      padding-bottom: 0; }

.form__row__picto-field {
  align-items: center;
  display: flex;
  margin-top: 24px; }
  .form__row__picto-field .form__field {
    margin-bottom: 16px;
    margin-top: 0; }
  .form__row__picto-field svg {
    height: 20px;
    margin-right: 14px;
    width: 20px; }

.form__row--two-inputs {
  display: flex;
  justify-content: space-between;
  align-items: flex-end; }
  .form__row--two-inputs > div {
    width: 40%; }

.form__break-message {
  display: inline-block;
  padding-top: 0;
  text-align: center;
  width: 100%; }
  .form__break-message p {
    font-size: 1.125rem; }
    .form__break-message p.text-small {
      font-size: 0.8rem;
      margin-bottom: 0; }

.form__field {
  margin-bottom: 0;
  margin-top: 24px;
  width: 100%; }
  .form__field label[data-shrink='false'] {
    color: #839ca9; }
  .form__field:nth-of-type(2) {
    align-self: flex-start; }

.form__postalcode {
  margin-top: 15px;
  width: 100%;
  padding: 0 0.5rem; }

.form__title + .form__field,
.form__title + .form__section__row {
  margin-top: 20px; }

.form__radio--column label {
  margin-bottom: -6px;
  margin-right: auto; }

.form__picto-select {
  background-color: rgba(113, 191, 68, 0.07);
  border-radius: 50%;
  height: 100px;
  transition: background-color 0.2s, border 0.2s;
  width: 100px; }
  .form__picto-select__wrapper {
    flex-direction: column;
    margin: 0;
    text-align: center; }
    .form__picto-select__wrapper:hover .form__picto-select {
      background-color: rgba(113, 191, 68, 0.12);
      border: 1px solid rgba(113, 191, 68, 0.2); }
  .form__picto-select--active .form__picto-select {
    background-color: rgba(113, 191, 68, 0.12);
    border: 1px solid rgba(113, 191, 68, 0.2); }
    .form__picto-select--active .form__picto-select:before {
      animation-duration: 0.4s;
      animation-name: fadeIn;
      background: #71bf44 url("assets/images/check.svg") center no-repeat;
      background-size: 17px 11px;
      border-radius: 50%;
      content: '';
      height: 28px;
      padding: 8px 6px;
      position: absolute;
      right: 0;
      top: 0;
      width: 28px; }
  .form__picto-select__title {
    color: #71bf44;
    font-size: 1rem;
    margin-top: 10px;
    text-transform: uppercase; }

.form__radio {
  flex-direction: row;
  margin-bottom: -9px;
  margin-top: 9px; }
  .form__radio > label {
    margin-left: -11px;
    margin-right: 40px; }
  .form__radio span {
    color: #839ca9; }
  .form__radio__button {
    height: 56px;
    width: 56px; }
    .form__radio__button--disabled > span:first-of-type {
      border: 2px solid rgba(131, 156, 169, 0.5);
      border-radius: 50%;
      height: 34px;
      width: 34px; }
    .form__radio__button--disabled svg {
      background: transparent;
      border-radius: 50%;
      color: transparent;
      height: 22px;
      stroke: transparent;
      width: 22px; }
    .form__radio__button--disabled.form__radio--checked svg {
      background: rgba(131, 156, 169, 0.5); }
    .form__radio__button--disabled.form__radio--checked + span {
      color: #505f66; }
    .form__radio__button > span:first-of-type {
      border: 2px solid #839ca9;
      border-radius: 50%;
      height: 34px;
      width: 34px; }
    .form__radio__button svg {
      background: transparent;
      border-radius: 50%;
      color: transparent;
      height: 22px;
      stroke: transparent;
      width: 22px; }
    .form__radio__button.form__radio--checked svg {
      background: #71bf44; }
    .form__radio__button.form__radio--checked + span {
      color: #505f66; }
    .form__radio__button__white span {
      color: #fff; }
    .form__radio__button__white .form__radio--checked svg {
      background: #fff; }
    .form__radio__button__white .form__radio--checked + span {
      color: #fff; }
    .form__radio__button__white .form__radio__button > span:first-of-type {
      border: 2px solid #fff; }

.form__error-text {
  bottom: -20px;
  color: #d0021b !important;
  position: absolute; }

.form__error-message {
  transition: all 0.4s; }
  .form__error-message h2 {
    color: #d0021b;
    font-size: 1.2rem;
    font-weight: 300;
    margin: 0;
    text-align: center;
    width: 100%; }
  .form__error-message + button:first-of-type {
    margin-top: 12px; }

.form__buttons {
  padding: 16px 0 0;
  width: calc(100% - 16px); }
  .form__buttons button:first-of-type,
  .form__buttons a:first-of-type {
    margin-top: 36px; }
    .form__buttons button:first-of-type + button,
    .form__buttons button:first-of-type + a,
    .form__buttons a:first-of-type + button,
    .form__buttons a:first-of-type + a {
      margin-top: 0; }

.form__card {
  background-color: rgba(131, 156, 169, 0.05);
  border: 1px solid rgba(131, 156, 169, 0.1);
  border-radius: 4px;
  margin: 20px 0;
  padding: 30px;
  padding-top: 0; }
  .form__card:last-of-type {
    margin-bottom: 0; }

.form__spinner {
  margin: 0 auto;
  display: block; }

.form__progress-field {
  bottom: 0;
  left: 0;
  height: 2px;
  position: absolute;
  width: 100%; }

.picto-separator {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  align-items: center; }

p[class*='MuiFormHelperText']:not(.form__error-text) {
  position: relative; }
  p[class*='MuiFormHelperText']:not(.form__error-text) .form__progress-field {
    bottom: initial;
    top: -5px; }

.dropdown-white label {
  color: rgba(255, 255, 255, 0.7); }
  .dropdown-white label + div::before {
    border-color: rgba(255, 255, 255, 0.7); }
  .dropdown-white label + div::after {
    background-color: rgba(255, 255, 255, 0.87); }
  .dropdown-white label[data-shrink='false'] {
    color: rgba(255, 255, 255, 0.7); }

.dropdown-white div {
  color: #fff; }

.dropdown-white svg {
  fill: #fff; }

.checkbox__wrapper {
  align-self: flex-start;
  margin-bottom: auto;
  margin-top: -12px; }

.dropdown-long-protect {
  white-space: normal;
  line-height: initial; }

.dropdown-unfix-height {
  height: initial; }

@media only screen and (min-width: 768px) {
  .form__picto-select--square {
    align-items: center;
    display: flex;
    height: 184px;
    justify-content: center;
    width: 284px; }
    .form__picto-select--square__wrapper:first-of-type {
      margin: 0; }
  .form-section {
    display: inline-block;
    margin: 16px 0; } }

.slick-slide {
  height: auto; }
  .slick-slide > div {
    height: 100%; }

.slick-track {
  display: flex; }

.slider-container {
  padding: 0 48px; }
  .slider-container div:focus {
    outline: 0; }

.slide-with-picto {
  text-align: center; }

.slide__title {
  color: #839ca9;
  font-size: 1.375rem;
  font-weight: 900;
  margin: 0;
  text-transform: uppercase;
  min-height: 4rem; }

.slide--subtitle {
  color: #839ca9;
  font-size: 1rem;
  font-style: italic;
  font-weight: 200;
  margin: 0;
  margin-top: 12px; }

.slick-arrow {
  height: 16px;
  top: calc(50% + 30px);
  width: 28px; }
  .slick-arrow::before {
    background-size: 28px 16px;
    content: '';
    display: inline-block;
    height: 16px;
    opacity: 1;
    width: 28px; }

.slick-next {
  right: -40px; }
  .slick-next::before {
    background-image: url("assets/images/arrow-right.svg");
    opacity: 1; }
  .slick-next:focus {
    background-color: rgba(131, 156, 169, 0.4); }

.slick-prev {
  left: -40px; }
  .slick-prev::before {
    background-image: url("assets/images/arrow-left.svg"); }
  .slick-prev:focus {
    background-color: rgba(131, 156, 169, 0.4); }

.slick-next.slick-disabled::before,
.slick-prev.slick-disabled::before {
  opacity: 0.3; }

.slide-picto__wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 150px;
  width: 100%; }
  .slide-picto__wrapper svg {
    max-width: 100%; }

.slide-container {
  display: flex !important;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%; }

.slide-subtext {
  color: #839ca9;
  font-size: 0.75rem;
  text-align: center;
  margin: 32px 0 0 0; }

.slide-subtext--picto {
  vertical-align: middle; }

.slick-dots {
  position: relative;
  bottom: 0;
  margin: 24px 0 0 0; }
  .slick-dots li {
    height: 8px;
    margin: 0;
    margin-left: 6px;
    width: 8px; }
    .slick-dots li:first-of-type {
      margin-left: 0; }
    .slick-dots li button {
      height: 8px;
      padding: 0;
      width: 8px; }
      .slick-dots li button::before {
        border: 1px solid #71bf44;
        border-radius: 50%;
        content: '';
        opacity: 1;
        height: 8px;
        line-height: inherit;
        width: 8px; }
    .slick-dots li.slick-active button::before {
      opacity: 1;
      background-color: #71bf44; }

.btn-white--outline {
  border: 2px solid #fff;
  color: #fff; }
  .btn-white--outline[disabled] {
    color: #fff;
    opacity: 0.6; }
  .btn-white--outline:hover {
    background-color: rgba(255, 255, 255, 0.2);
    border: 2px solid #fff; }

.btn-white--text {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.3); }
  .btn-white--text:hover {
    background-color: rgba(255, 255, 255, 0); }

.go_back,
.go_back__label {
  display: flex;
  text-decoration: none; }

.go_back__label {
  font-family: 'Roboto', sans-serif;
  font-size: 16px; }

.go_back * {
  align-items: center; }

#go_back {
  white-space: nowrap;
  text-transform: uppercase;
  cursor: pointer;
  color: #839ca9; }

button.go_back {
  border: none;
  align-items: center;
  background-color: #ffffff; }

.button_hidden {
  border: none;
  background-color: transparent; }

.button-thick--green {
  border-color: #71bf44 !important;
  border-width: 2px !important; }

button {
  cursor: pointer; }

.btn-yellow {
  background-color: #ffcc00; }
  .btn-yellow:hover {
    background-color: #ffcc00; }

.btn--border {
  border: 2px solid #fff;
  color: black; }
  .btn--border:hover {
    background-color: #ffcc00;
    border: 2px solid #fff;
    color: black; }

.btn--red {
  background-color: #fff;
  border: 2px solid #d0021b !important;
  color: #d0021b !important; }
  .btn--red:hover {
    border: 2px solid #fff;
    color: black; }

.button__center {
  margin: auto;
  display: block; }

.button__loading {
  position: relative;
  overflow: hidden; }
  .button__loading .linear_progress {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 5px; }

.expansion-panel__question {
  color: #505f66;
  font-size: 1.2em;
  margin: 0;
  padding: 15px 0; }

.expansion-panel__chevron {
  width: 16px; }

@keyframes open {
  0% {
    width: 0%; }
  50% {
    width: 80%; }
  0% {
    width: 0%; } }

@keyframes progress {
  0% {
    transform: translate3d(-100%, 0, 0); }
  100% {
    transform: translate3d(100%, 0, 0); } }

@keyframes appear {
  5% {
    opacity: 1; }
  37.5% {
    opacity: 1; }
  42.5% {
    opacity: 0; } }

@keyframes appear2 {
  5% {
    opacity: 0; }
  15% {
    opacity: 1; }
  32.5% {
    opacity: 1; }
  37.5% {
    opacity: 0; } }

@keyframes appear3 {
  15% {
    opacity: 0; }
  25% {
    opacity: 1; }
  27.5% {
    opacity: 1; }
  32.5% {
    opacity: 0; } }

.loading-skeleton button {
  opacity: 0.5; }

.loading-skeleton .hambutton {
  opacity: 1; }

.loading-skeleton .subheader__title_skeleton {
  width: 300px;
  margin: auto;
  margin-top: 12px;
  background-color: #839ca9;
  opacity: 0.5;
  position: relative;
  overflow: hidden; }
  .loading-skeleton .subheader__title_skeleton::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #839ca9, #b0c0c8, #839ca9);
    animation: progress 1.5s ease infinite; }

.loading-skeleton #skeleton_tab_accordion_title h2 {
  width: 200px;
  margin: auto;
  color: transparent;
  background-color: #839ca9;
  opacity: 0.5;
  position: relative;
  overflow: hidden; }
  .loading-skeleton #skeleton_tab_accordion_title h2::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #839ca9, #b0c0c8, #839ca9);
    animation: progress 1.5s ease infinite; }

.loading-skeleton .search-field__wrapper {
  border-radius: 4px;
  background-color: #f2f4f6;
  position: relative;
  overflow: hidden; }
  .loading-skeleton .search-field__wrapper::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #f2f4f6, #fbfcfc, #f2f4f6);
    animation: progress 1.5s ease infinite; }

.loading-skeleton .timeline__item--label span {
  height: 17px;
  width: 30px;
  display: block;
  margin: auto;
  background-color: #839ca9;
  opacity: 0.5;
  position: relative;
  overflow: hidden; }
  .loading-skeleton .timeline__item--label span::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #839ca9, #b0c0c8, #839ca9);
    animation: progress 1.5s ease infinite; }

.loading-skeleton .timeline__step-box {
  animation-duration: 3.5s;
  opacity: 0;
  animation-iteration-count: infinite; }

.loading-skeleton #timeline__step-box-1 {
  animation-name: appear; }

.loading-skeleton #timeline__step-box-2 {
  animation-name: appear2; }

.loading-skeleton #timeline__step-box-3 {
  animation-name: appear3; }

.loading-skeleton .month-list__title {
  width: 100px;
  height: 20px;
  background-color: #f2f4f6;
  position: relative;
  overflow: hidden; }
  .loading-skeleton .month-list__title::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #f2f4f6, #fbfcfc, #f2f4f6);
    animation: progress 1.5s ease infinite; }

.loading-skeleton .month-list__picto {
  height: 48px;
  width: 56px;
  position: relative;
  overflow: hidden;
  background-color: #71bf44;
  opacity: 0.3;
  position: relative;
  overflow: hidden; }
  .loading-skeleton .month-list__picto::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #71bf44, #abd990, #71bf44);
    animation: progress 1.5s ease infinite; }

.loading-skeleton .month-list__plot-size {
  height: 19px;
  width: 40px;
  margin: 0;
  margin-top: 5px;
  background-color: #71bf44;
  opacity: 0.3;
  position: relative;
  overflow: hidden; }
  .loading-skeleton .month-list__plot-size::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #71bf44, #abd990, #71bf44);
    animation: progress 1.5s ease infinite; }

.loading-skeleton .month-list__item__content h4 {
  height: 19px;
  width: 200px;
  background-color: #71bf44;
  opacity: 0.3;
  position: relative;
  overflow: hidden; }
  .loading-skeleton .month-list__item__content h4::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #71bf44, #abd990, #71bf44);
    animation: progress 1.5s ease infinite; }

.loading-skeleton .month-list__text {
  width: 200px;
  height: 19px;
  margin-top: 3px;
  background-color: #f2f4f6;
  position: relative;
  overflow: hidden; }
  .loading-skeleton .month-list__text::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #f2f4f6, #fbfcfc, #f2f4f6);
    animation: progress 1.5s ease infinite; }

.loading-skeleton .timeline__my-plan {
  height: 180px;
  position: relative; }
  .loading-skeleton .timeline__my-plan hr {
    width: 0%;
    height: 0;
    position: absolute;
    border: none;
    border-top: 1px dashed #839ca9;
    bottom: 27px;
    animation: open 10s ease infinite; }

.loading-skeleton .default_skeleton_text {
  width: 165px;
  height: 20px;
  background-color: #f2f4f6;
  position: relative;
  overflow: hidden; }
  .loading-skeleton .default_skeleton_text::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #f2f4f6, #fbfcfc, #f2f4f6);
    animation: progress 1.5s ease infinite; }
  .loading-skeleton .default_skeleton_text__small {
    width: 100px; }

.loading-skeleton .default_skeleton_text__with-fit {
  width: 100%;
  height: 20px;
  margin-bottom: 0.5rem;
  background-color: #f2f4f6;
  position: relative;
  overflow: hidden; }
  .loading-skeleton .default_skeleton_text__with-fit::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #f2f4f6, #fbfcfc, #f2f4f6);
    animation: progress 1.5s ease infinite; }

.loading-skeleton .default_skeleton_text__with-margin-bottom {
  width: 150px;
  height: 20px;
  margin-bottom: 0.5rem;
  background-color: #f2f4f6;
  position: relative;
  overflow: hidden; }
  .loading-skeleton .default_skeleton_text__with-margin-bottom::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #f2f4f6, #fbfcfc, #f2f4f6);
    animation: progress 1.5s ease infinite; }
  .loading-skeleton .default_skeleton_text__with-margin-bottom__small {
    width: 100px; }

.loading-skeleton .default_cell_skeleton {
  width: 100%;
  height: 2rem;
  margin-bottom: 1rem;
  background-color: #f2f4f6;
  position: relative;
  overflow: hidden; }
  .loading-skeleton .default_cell_skeleton::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #f2f4f6, #fbfcfc, #f2f4f6);
    animation: progress 1.5s ease infinite; }

.loading-skeleton .calendar_skeleton {
  width: 100%;
  height: 3rem;
  margin: 1rem 0;
  background-color: #f2f4f6;
  position: relative;
  overflow: hidden; }
  .loading-skeleton .calendar_skeleton::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #f2f4f6, #fbfcfc, #f2f4f6);
    animation: progress 1.5s ease infinite; }

.loading-skeleton .calendar_skeleton_mobile {
  width: 100%;
  height: 6rem;
  margin: 1rem 0;
  background-color: #f2f4f6;
  position: relative;
  overflow: hidden; }
  .loading-skeleton .calendar_skeleton_mobile::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #f2f4f6, #fbfcfc, #f2f4f6);
    animation: progress 1.5s ease infinite; }

.loading-skeleton .map_skeleton {
  width: 100%;
  height: 45vh;
  background-color: #f2f4f6;
  position: relative;
  overflow: hidden; }
  .loading-skeleton .map_skeleton::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #f2f4f6, #fbfcfc, #f2f4f6);
    animation: progress 1.5s ease infinite; }

.loading-skeleton .periodic-row span {
  width: 14px;
  height: 20px;
  background-color: #f2f4f6;
  position: relative;
  overflow: hidden; }
  .loading-skeleton .periodic-row span::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #f2f4f6, #fbfcfc, #f2f4f6);
    animation: progress 1.5s ease infinite; }

.loading-skeleton .periodic-row h3 {
  width: 50px;
  height: 20px;
  background-color: #f2f4f6;
  position: relative;
  overflow: hidden; }
  .loading-skeleton .periodic-row h3::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #f2f4f6, #fbfcfc, #f2f4f6);
    animation: progress 1.5s ease infinite; }

.loading-skeleton .periodic-row .content-label {
  width: 70px;
  height: 19px;
  background-color: #71bf44;
  opacity: 0.3;
  position: relative;
  overflow: hidden; }
  .loading-skeleton .periodic-row .content-label::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #71bf44, #abd990, #71bf44);
    animation: progress 1.5s ease infinite; }

.loading-skeleton .timeline__card-title-wrapper h2 {
  width: 200px;
  height: 22px;
  background-color: #71bf44;
  opacity: 0.3;
  position: relative;
  overflow: hidden; }
  .loading-skeleton .timeline__card-title-wrapper h2::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #71bf44, #abd990, #71bf44);
    animation: progress 1.5s ease infinite; }

.loading-skeleton .timeline__card-title-wrapper h3 {
  width: 200px;
  height: 19px;
  margin-top: 2px;
  background-color: #71bf44;
  opacity: 0.3;
  position: relative;
  overflow: hidden; }
  .loading-skeleton .timeline__card-title-wrapper h3::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #71bf44, #abd990, #71bf44);
    animation: progress 1.5s ease infinite; }

.loading-skeleton .nutrient_comparison__wrapper {
  opacity: 0.5; }

.loading-skeleton .periodic_el_bars__bar__foreground {
  width: 0; }

.loading-skeleton .section_title p {
  margin: 0;
  width: 100px; }

.loading-skeleton .accordion__card-title {
  color: transparent;
  background-color: #f2f4f6;
  position: relative;
  overflow: hidden; }
  .loading-skeleton .accordion__card-title::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #f2f4f6, #fbfcfc, #f2f4f6);
    animation: progress 1.5s ease infinite; }

.loading-skeleton .content-label {
  color: transparent;
  margin-top: 5px;
  background-color: #f2f4f6;
  position: relative;
  overflow: hidden; }
  .loading-skeleton .content-label::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #f2f4f6, #fbfcfc, #f2f4f6);
    animation: progress 1.5s ease infinite; }

.picto-grass {
  flex-basis: 112px;
  flex-shrink: 0;
  height: 64px; }
  .picto-grass svg {
    height: 64px; }

.picto-corn-cards {
  flex-basis: 124px;
  flex-shrink: 0;
  width: 124px; }

.picto-target {
  flex-basis: 84px;
  flex-shrink: 0;
  width: 84px; }

.picto-atomic-bottle {
  flex-basis: 75px;
  flex-shrink: 0;
  width: 75px; }

.picto-chat-bubbles {
  flex-basis: 100px;
  flex-shrink: 0;
  width: 100px; }

.picto-corn-winter {
  flex-basis: 68px;
  flex-shrink: 0;
  width: 68px; }

.picto-plot {
  flex-basis: 141px;
  flex-shrink: 0;
  width: 141px; }

.picto-product-big {
  width: 62px; }

.picto-calendar {
  flex-basis: 34px;
  flex-shrink: 0;
  width: 34px;
  height: 34px; }

.picto-product {
  flex-basis: 36px;
  flex-shrink: 0;
  width: 36px;
  height: 52px; }

.picto-quantity {
  flex-basis: 36px;
  flex-shrink: 0;
  width: 36px; }

.picto-farmer-select {
  flex-basis: 54px;
  flex-shrink: 0;
  width: 54px; }

.picto-reseller-select {
  flex-basis: 54px;
  flex-shrink: 0;
  width: 54px; }

.picto-account {
  width: 60px; }

.picto-chevron--left {
  transform: rotate(90deg);
  width: 14px; }

.react-bodymovin-container {
  margin: 24px auto 0;
  max-width: 70%;
  min-width: 240px; }

svg[class*='MuiSelect'] {
  color: #839ca9; }

.full-width {
  width: 100%; }

.half-width {
  width: 100%; }

.wrapper--all-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 66px);
  margin: 0 auto;
  width: 100%; }

.greyed-out {
  opacity: 0.3; }

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.margin-h--auto {
  margin-left: auto;
  margin-right: auto; }

.margin--zero {
  margin: 0; }

.margin-l--auto {
  margin-left: auto; }

.margin-top--zero {
  margin-top: 0; }

.margin-top--56 {
  margin-top: 56px; }

.margin-bottom--zero {
  margin-bottom: 0; }

.margin-top--eight {
  margin-top: 8px !important; }

.margin-top--sixteen {
  margin-top: 16px; }

.margin-bottom--24 {
  margin-bottom: 24px; }

.margin-top--sm {
  margin-top: 20px; }

.margin--minus-24 {
  margin-left: -24px; }

.margin-top--minus-22 {
  margin-top: -22px; }

.margin-top--sixteen-important {
  margin-top: 16px !important; }

.tab-row-picto.margin-bottom--zero,
.margin-bottom--zero {
  margin-bottom: 0; }

.tab-row-picto.margin-top--24,
.margin-top--24 {
  margin-top: 24px; }

.padding--zero {
  padding: 0; }

.padding-top--zero {
  padding-top: 0; }

.flex-column {
  display: flex;
  flex-direction: column; }

.flex-row {
  display: flex;
  flex-direction: row; }

.flex-v--center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh; }

.justify-content--end {
  justify-content: flex-end; }

.justify-content--space-between {
  justify-content: space-between; }

.align-self--center {
  align-self: center; }

.text-align--center {
  text-align: center; }

.text-align--left {
  text-align: left; }

.color-primary {
  color: #71bf44; }

.color-secondary {
  color: #839ca9; }

.break-word {
  word-break: break-word;
  word-wrap: break-word; }

.undecorated {
  text-decoration: none; }

.img-center {
  display: block;
  margin: 0 auto; }

.max-width--400 {
  max-width: 400px; }

.overflow-hidden {
  overflow: hidden; }

@media only screen and (min-width: 768px) {
  .half-width {
    width: 50%; } }

.periodic-element {
  align-items: center;
  color: #839ca9;
  display: flex;
  justify-content: center;
  font-size: 1.25rem;
  font-weight: 700;
  border: 2px solid #839ca9;
  height: 44px;
  min-width: 44px;
  text-decoration: none; }
  .periodic-element span {
    color: #839ca9; }
  .periodic-element:focus {
    border: 2px solid #71bf44; }
    .periodic-element:focus span {
      color: #71bf44; }

.btn--picto {
  background: transparent;
  border: 0;
  color: #839ca9;
  display: flex;
  font-size: 0.75rem;
  font-style: italic;
  justify-content: center;
  margin-top: 20px;
  padding: 0;
  transition: opacity 0.2s;
  padding: 3px 5px;
  margin-left: -3px; }
  .btn--picto:hover, .btn--picto:active, .btn--picto:focus {
    cursor: pointer;
    outline: none;
    background-color: rgba(131, 156, 169, 0.4);
    border-radius: 5px; }

.btn--picto__svg {
  margin-right: 8px;
  width: 18px; }

.btn--picto--label {
  padding-top: 2px; }

.btn--picto--remove {
  color: #d0021b; }
  .btn--picto--remove path {
    fill: #d0021b; }
  .btn--picto--remove circle {
    stroke: #d0021b; }
  .btn--picto--remove:hover, .btn--picto--remove:active, .btn--picto--remove:focus {
    background-color: rgba(208, 2, 27, 0.4); }

.btn--scroll-to-top {
  z-index: 12;
  position: fixed;
  bottom: 20px;
  right: 20px; }
  .btn--scroll-to-top svg {
    height: 40px; }

.search-field__wrapper {
  margin: 0 20px 30px 20px;
  position: relative;
  height: 50px; }
  .search-field__wrapper.search-field--active .search-field--input {
    border: 1px solid #839ca9;
    outline: none;
    text-align: left; }
  .search-field__wrapper.search-field--active .search-field--label {
    transform: translate(0%, 0%);
    margin-left: 15px; }
    .search-field__wrapper.search-field--active .search-field--label p {
      display: none; }

.search-field--input {
  background-color: rgba(131, 156, 169, 0.1);
  border: 1px solid transparent;
  border-radius: 4px;
  color: #839ca9;
  display: inline-block;
  font-size: 1rem;
  position: absolute;
  padding-left: 45px;
  transition: border 0.4s;
  height: 100%;
  width: 100%; }
  .search-field--input:focus {
    border: 1px solid #839ca9;
    outline: none;
    text-align: left; }
    .search-field--input:focus + .search-field--label {
      color: rgba(131, 156, 169, 0.4);
      transform: translate(0%, 0%);
      margin-left: 20px; }

.search-field--label {
  display: flex;
  align-items: center;
  color: #839ca9;
  white-space: nowrap;
  position: absolute;
  height: 100%;
  margin-left: 50%;
  transform: translate(-50%, 0%);
  transition: margin-left 0.2s, transform 0.2s; }
  .search-field--label p {
    font-size: 12pt; }
  .search-field--label::before {
    background: url("assets/images/search.svg") no-repeat center;
    background-size: 100%;
    content: '';
    display: inline-block;
    height: 20px;
    margin-right: 6px;
    min-width: 20px; }

.highlight {
  font-weight: bold;
  color: #71bf44;
  background-color: transparent; }

@media only screen and (min-width: 1280px) {
  .search-field__wrapper {
    margin-bottom: 60px;
    margin-top: 30px; } }

.legend__wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 5px; }

.legend__box {
  width: 12px;
  height: 12px; }
  .legend__box--primary {
    background-color: #71bf44; }
  .legend__box--secondary {
    background-color: #839ca9; }
  .legend__box--tertiary {
    background-color: #ffcc00; }

.legend__text {
  margin: 0;
  margin-left: 10px;
  font-size: 10pt; }

.seperator {
  border: none;
  height: 1px;
  background-color: rgba(131, 156, 169, 0.4);
  margin: 0px; }

.icon_title {
  display: flex;
  margin: 0 0 30px;
  align-items: flex-start; }
  .icon_title--list-style {
    margin-top: 10px;
    margin-bottom: 10px; }
    .icon_title--list-style h4 {
      color: #839ca9; }
    .icon_title--list-style .picto-calendar {
      margin-left: -8px; }
  .icon_title__text_wrapper {
    width: 100%;
    margin-left: 10px;
    margin-top: 5px; }

.round_button {
  width: 30px;
  height: 30px;
  border: 1px solid #839ca9;
  border-radius: 50%;
  background-color: transparent;
  padding: 0;
  position: relative; }
  .round_button svg {
    width: 14px;
    flex-basis: 14px;
    height: 18px;
    position: absolute;
    margin-left: auto;
    left: 0;
    margin-right: auto;
    right: 0;
    margin-top: auto;
    top: 0;
    margin-bottom: auto;
    bottom: 0; }
  .round_button:focus {
    outline: none; }
  .round_button:focus, .round_button:hover {
    background-color: rgba(131, 156, 169, 0.2); }

.section_title {
  border-bottom: 2px solid #71bf44;
  color: #839ca9;
  font-weight: normal;
  padding: 10px;
  font-size: 14pt;
  margin: 0px; }

.bread_crumb {
  border: 2px solid #71bf44;
  border-radius: 30px;
  height: 45px;
  min-height: 45px;
  padding: 0px 30px 0px 40px;
  position: relative;
  margin-left: 5px; }
  .bread_crumb__icon {
    flex-basis: 13px;
    flex-shrink: 0;
    width: 13px;
    position: absolute;
    left: 15px;
    margin: 0;
    color: #71bf44; }

.accordion__card {
  width: 100%;
  max-width: 1000px;
  box-shadow: none !important;
  border: 1px solid #839ca9;
  margin-top: -1px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%); }
  .accordion__card-list {
    margin-top: 30px; }
  .accordion__card-detail {
    flex-direction: column;
    position: relative;
    padding: 24px 24px 24px !important; }
    .accordion__card-detail .seperator:first-of-type {
      width: 100%;
      left: 0;
      position: absolute;
      top: 0;
      background-color: #839ca9; }
    .accordion__card-detail .seperator:nth-of-type(2) {
      margin: 30px 0px;
      display: inline; }
    .accordion__card-detail__section--alone {
      width: 100% !important; }
  .accordion__card-title {
    color: #839ca9;
    font-size: 1.125rem;
    font-weight: 400;
    margin: 0; }
    .accordion__card-title-wrapper {
      width: calc(100% - 70px); }
  .accordion__card-header-wrapper {
    margin: 10px 0px;
    width: 100%;
    display: flex;
    align-items: center; }
  .accordion__card--selected {
    border: 1px solid #71bf44; }
    .accordion__card--selected .accordion__card-title {
      color: #71bf44;
      font-weight: bold; }
    .accordion__card--selected .content-label {
      color: #71bf44; }
    .accordion__card--selected .timeline__step-num {
      background-color: #71bf44; }
  .accordion__card--single-section {
    max-width: 450px;
    width: calc(100% - 2px); }

.accordion__icon {
  margin-right: 15px; }
  .accordion__icon__radio {
    padding: 0px !important;
    margin-left: -5px !important; }
    .accordion__icon__radio svg {
      font-size: 30px !important; }
  .accordion__icon__product {
    height: 44px;
    flex-basis: 30px;
    flex-shrink: 0;
    width: 30px; }

@media only screen and (min-width: 600px) {
  .accordion__card-detail {
    flex-direction: row; }
    .accordion__card-detail__section {
      width: calc(50% - 30px); }
      .accordion__card-detail__section--1 {
        margin-right: 30px; }
      .accordion__card-detail__section--2 {
        margin-left: 30px; }
    .accordion__card-detail .seperator:nth-of-type(2) {
      margin: 0px;
      left: 50%;
      display: block;
      height: calc(100% - 56px);
      width: 1px;
      flex: 1;
      position: absolute; } }

.info_point__icon {
  width: 22px;
  height: 22px;
  padding: 0;
  color: #71bf44;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #71bf44;
  font-size: 13pt;
  font-family: 'Times New Roman', Times, serif;
  margin-left: 5px;
  background-color: #fff; }
  .info_point__icon:hover, .info_point__icon:focus, .info_point__icon__focus {
    background-color: #71bf44;
    color: #fff; }
  .info_point__icon__form {
    display: inline;
    margin-left: 5px !important; }

.info_point__info__wrapper {
  padding: 20px;
  border: 1px solid #71bf44;
  width: 100%;
  box-sizing: border-box;
  margin: 5px 0px 10px;
  position: relative; }

.info_point__info__close {
  width: 22px;
  height: 22px;
  color: #505f66;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(131, 156, 169, 0.2);
  border: none;
  font-size: 10pt;
  position: absolute;
  right: 15px; }
  .info_point__info__close:hover, .info_point__info__close:focus {
    background-color: #d0021b;
    color: #fff;
    outline: none; }

.info_point__info__title {
  margin-bottom: 2px;
  font-size: 14pt;
  font-weight: bold;
  width: calc(100% - 35px); }

.info_point__info__description {
  margin: 0; }
  .info_point__info__description ul {
    padding-left: 20px;
    padding-right: 40px; }
    .info_point__info__description ul li {
      margin-bottom: 15px; }

.vertical_bar__wrapper {
  height: 200px;
  width: 100%;
  position: relative; }
  .vertical_bar__wrapper__super {
    width: 90px; }

.vertical_bar__container {
  position: absolute;
  width: 100%;
  bottom: 0; }

.vertical_bar__background-bar--value {
  color: inherit;
  text-align: center;
  position: absolute;
  bottom: 100%;
  margin: 0;
  width: 100%; }

.vertical_bar__foreground-bar--value {
  color: inherit;
  text-align: center;
  position: absolute;
  bottom: 100%;
  margin: 0;
  width: 100%; }

.vertical_bar__title {
  margin: 0;
  margin-top: 5px;
  text-align: center;
  font-size: 12pt;
  color: #839ca9;
  font-weight: normal; }

.key_value__wrapper {
  color: #839ca9;
  list-style-type: none;
  justify-content: space-between;
  max-width: 500px;
  margin-bottom: 15px; }
  .key_value__wrapper:last-of-type {
    margin-bottom: 0px; }

.key_value__value {
  color: #505f66;
  font-size: 12pt;
  line-height: 14pt;
  margin-top: 3px; }
  .key_value__value--with-radio {
    margin-left: 35px;
    width: calc(100% - 35px);
    margin-top: 0px; }

.key_value__radio-label {
  margin-top: -3px; }
  .key_value__radio-label span:first-child {
    padding-top: 0;
    padding-bottom: 0; }
  .key_value__radio-label span:nth-child(2) {
    color: #839ca9;
    font-size: 12pt;
    line-height: 14pt; }

@media only screen and (min-width: 380px) {
  .key_value__wrapper {
    display: flex; }
  .key_value__key {
    width: calc(100% - 130px);
    margin-right: 10px; }
  .key_value__value {
    margin-top: 2px;
    width: 120px; }
    .key_value__value--with-radio {
      margin-left: 0px;
      width: 120px;
      margin-top: 1px; } }

.logo_item__list {
  width: 240px;
  padding: 0;
  margin: auto; }

.logo_item__wrapper {
  display: inline-block;
  margin: auto 20px;
  margin-bottom: 40px;
  width: 200px;
  height: 250px;
  list-style-type: none;
  border: 1px solid #839ca9;
  padding: 15px;
  padding-bottom: 5px;
  border-radius: 3px; }

.logo_item__link {
  width: 100%;
  height: 100%;
  display: block;
  text-decoration: none; }

.logo_item__image {
  max-width: 100%;
  max-height: 120px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; }
  .logo_item__image__wrapper {
    height: calc(100% - 40px);
    display: block;
    position: relative; }

.logo_item__title {
  font-size: 16pt;
  font-weight: bold;
  height: 40px;
  text-align: center;
  color: #839ca9; }

@media screen and (min-width: 600px) {
  .logo_item__list {
    width: calc(240px * 2); } }

@media screen and (min-width: 992px) {
  .logo_item__list {
    width: calc(240px * 3); } }

@media screen and (min-width: 1800px) {
  .logo_item__list {
    width: calc(240px * 6); } }

.new-select-with-right-element {
  position: relative;
  color: #71bf44;
  width: 100%;
  top: 5px;
  background-color: #fff; }
  .new-select-with-right-element__container {
    position: absolute;
    top: 0;
    right: 1.5rem;
    min-width: 20px;
    max-width: calc(100% - 20px); }

.info-tip-container {
  position: relative; }
  .info-tip-container-button {
    min-width: 1px;
    min-height: 1px; }
  .info-tip-container .tooltip-body {
    z-index: 9001;
    position: absolute;
    background: #fff;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
    border-radius: 0.5rem;
    padding: 0.5rem;
    left: calc(100% + 30px); }
    .info-tip-container .tooltip-body::after {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      background: white;
      box-shadow: -1px 2px 1px 0px rgba(0, 0, 0, 0.3);
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      top: 50%;
      left: 0; }

.info-tip-container-left {
  position: relative; }
  .info-tip-container-left .tooltip-body {
    z-index: 9001;
    position: absolute;
    background: #fff;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
    border-radius: 0.5rem;
    padding: 0.5rem;
    right: calc(100% + 15px); }
    .info-tip-container-left .tooltip-body::after {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      background: white;
      box-shadow: 1px -1px 1px 0px rgba(0, 0, 0, 0.3);
      transform: translateX(50%) translateY(-50%) rotate(45deg);
      top: 50%;
      right: 0; }

.referer-cell {
  display: flex;
  flex-direction: row;
  color: #839ca9;
  margin: 0.5rem 0;
  background-color: rgba(131, 156, 169, 0.05);
  font-size: 0.9rem;
  align-items: center; }
  .referer-cell__1 {
    padding: 0.3rem;
    flex: 1; }
    .referer-cell__1:last-of-type {
      text-align: right; }
  .referer-cell__2 {
    padding: 0.3rem;
    flex: 2;
    display: flex;
    flex-direction: row;
    align-items: center; }
    .referer-cell__2--left {
      margin-right: 0.5rem; }
  .referer-cell__3 {
    padding: 0.3rem;
    flex: 3; }

.referer-cell-mobile {
  border: solid 1px #839ca9;
  box-shadow: none !important;
  margin-bottom: 1rem;
  border-radius: 0.5rem; }
  .referer-cell-mobile.expanded {
    border: solid 1px #71bf44; }
  .referer-cell-mobile:first-child, .referer-cell-mobile:last-child {
    border-radius: 0.5rem; }
  .referer-cell-mobile__summary {
    color: #839ca9;
    font-size: 1.2rem;
    font-weight: bold; }
  .referer-cell-mobile__details--header {
    display: flex;
    flex-direction: row;
    color: #839ca9; }
    .referer-cell-mobile__details--header--1 {
      flex: 1; }
  .referer-cell-mobile__details--data--label {
    color: #71bf44;
    font-size: 0.8rem; }
  .referer-cell-mobile__details--data--value {
    display: flex;
    flex-direction: row;
    color: #839ca9;
    align-items: center; }
    .referer-cell-mobile__details--data--value--icon {
      margin: 3px 3px 3px 0px; }

.choose_language__header {
  justify-content: space-between;
  display: flex;
  padding: 25px 17px 25px 40px;
  text-align: center;
  justify-content: space-between;
  font-size: 18px;
  color: #839ca9;
  font-weight: bold; }

.choose_language__header_title {
  padding: 8px 0 4px 30px;
  max-width: 250px; }

.choose_language__line_container {
  justify-content: center;
  text-align: center;
  display: flex; }

.choose_language__line_separator {
  border-top: 1px solid;
  width: 20%;
  color: #71bf44; }

.choose_language__button {
  margin: 15px 0px; }

.choose_language__button {
  background-color: transparent;
  border: none;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 25px 30px 40px; }
  .choose_language__button:focus, .choose_language__button:hover {
    outline: none;
    background-color: rgba(131, 156, 169, 0.2); }
  .choose_language__button__flag_titles_wrapper {
    display: flex;
    align-items: center;
    width: 100%; }
  .choose_language__button__titles_wrapper {
    margin-left: 15px; }
  .choose_language__button__title {
    font-weight: bold;
    font-size: 16pt;
    color: #839ca9; }
  .choose_language__button__subtitle {
    color: #839ca9;
    font-weight: normal;
    font-size: 14pt;
    margin: 0;
    margin-top: 2px;
    text-align: left; }
  .choose_language__button__flag {
    width: 50px; }
  .choose_language__button__chevron {
    width: 30px;
    transform: rotate(270deg); }

@media only screen and (max-width: 992px) {
  .choose_language__header {
    padding: 16px 11px 16px 20px; }
  .choose_language__button {
    padding: 15px 20px; } }

.appbar {
  box-shadow: none; }
  .appbar--desktop-only {
    display: none !important; }
  .appbar--open .appbar-title {
    opacity: 0.3;
    transition: opacity 0.3s; }
  .appbar__backdrop {
    z-index: 5 !important;
    top: 66px !important;
    pointer-events: none; }
    .appbar__backdrop--active {
      pointer-events: auto; }
  .appbar__panel {
    transform: perspective(400px) rotateX(-90deg);
    transform-origin: top;
    width: 100%;
    height: 150px;
    background-color: white;
    position: fixed;
    top: 66px;
    transition: transform 0.3s;
    transition-timing-function: ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center; }
    .appbar__panel__wrapper {
      z-index: 6; }
    .appbar__panel--active {
      transform: perspective(800px) rotateX(0deg); }
    .appbar__panel__content {
      display: flex;
      align-items: center;
      flex-direction: column; }
    .appbar__panel__icon {
      flex-basis: 72px;
      width: 72px; }
    .appbar__panel__title {
      color: #839ca9;
      margin-top: 5px; }

.toolbar {
  height: 66px;
  text-align: center;
  position: fixed;
  width: 100%;
  z-index: 5;
  background-color: white;
  border-bottom: 1px solid #71bf44;
  box-shadow: 0px 8px 12px -2px white;
  top: 0;
  transition: margin-top 0.3s; }
  .toolbar__hide {
    margin-top: -66px;
    box-shadow: none; }
  .toolbar__show {
    margin-top: 0px; }

.appbar-title {
  color: #71bf44;
  font-size: 1em;
  font-weight: 400;
  padding: 0 48px;
  width: 100%; }

.appbar-title__description {
  color: #839ca9;
  font-size: 0.8rem; }

.appbar-title--hamburger {
  margin-left: -48px; }

.appbar-subtitle {
  color: #839ca9;
  font-size: 0.8rem;
  margin-bottom: 0;
  margin-top: 0.8rem;
  text-align: center;
  width: 100%; }

.hambutton {
  border-radius: 0;
  padding-left: 8px;
  z-index: 1090; }
  .hambutton > span {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .hambutton div {
    height: 6px;
    width: 32px;
    transition: margin 0.2s; }
    .hambutton div:first-of-type {
      background-color: #c8d200; }
    .hambutton div:nth-of-type(2) {
      background-color: #79b61d;
      margin-bottom: 4px;
      margin-top: 4px; }
    .hambutton div:nth-of-type(3) {
      background-color: #5f9501; }

.go_back {
  padding: 0px; }

.plus_button {
  background-color: #71bf44 !important;
  height: 35px;
  width: 35px;
  position: absolute;
  right: 20px;
  top: 0px;
  bottom: 0px;
  margin-bottom: auto;
  margin-top: auto;
  transition: transform 0.3s;
  display: flex;
  justify-content: center;
  align-items: center; }
  .plus_button__icon {
    min-width: 18px;
    min-height: 18px;
    flex-basis: 18px;
    margin-top: -3px; }
  .plus_button--open-panel {
    transform: rotate(45deg); }

@media screen and (max-width: 600px) {
  .appbar-title {
    display: none; } }

@media screen and (min-width: 600px) {
  .appbar__panel {
    max-width: 200px;
    right: 0; } }

@media screen and (min-width: 1280px) {
  .appbar--desktop-only {
    display: block !important;
    margin-bottom: 30px; }
  .appbar--mobile-only {
    display: none !important; }
  .appbar--not-landing {
    min-height: 118px; }
    .appbar--not-landing .appbar-title {
      font-size: 1.25rem;
      margin-left: 0;
      margin-top: 80px; }
    .appbar--not-landing .toolbar {
      height: auto;
      min-height: auto;
      background-color: transparent;
      position: static;
      width: auto;
      border: none;
      box-shadow: none;
      transition: none; }
      .appbar--not-landing .toolbar__hide {
        margin-top: 0px; }
    .appbar--not-landing .hambutton {
      display: none; }
  .appbar .go_back_overview,
  .appbar .plus_button {
    display: none; }
  .appbar__panel, .appbar__backdrop {
    display: none; }
  .appbar .go_back {
    position: absolute; } }

.footer {
  justify-self: flex-end;
  margin-top: 32px;
  position: relative; }
  .footer__wrapper {
    align-items: center;
    background-color: rgba(131, 156, 169, 0.1);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 24px;
    width: 100%;
    gap: 0.5rem;
    text-align: center; }
  .footer__copyright {
    margin: 0;
    margin-top: 16px;
    position: relative;
    text-align: center;
    z-index: 1; }
  .footer__logo {
    height: 54px;
    margin-bottom: 16px; }
  .footer__link:not([role='button']):link, .footer__link:not([role='button']):visited {
    color: #839ca9;
    margin: 16px;
    position: relative;
    text-decoration: none;
    transition: all 0.2s;
    z-index: 1; }
    .footer__link:not([role='button']):link:hover, .footer__link:not([role='button']):visited:hover {
      color: #71bf44;
      text-decoration: underline; }

@media only screen and (min-width: 825px) {
  .footer::after {
    bottom: 16px;
    height: 106px;
    top: initial;
    width: 102px; }
  .footer__logo {
    margin-right: auto; }
  .footer__wrapper {
    align-items: flex-end;
    flex-direction: row; }
  .footer__link {
    margin-left: 15px;
    margin-right: 0; }
  .footer__copyright {
    border-top: 1px dotted rgba(131, 156, 169, 0.4);
    text-align: center;
    padding-top: 15px;
    width: 100%; } }

@media only screen and (min-width: 1280px) {
  .footer::before {
    background-size: 100% 80px;
    height: 80px; }
  .footer::after {
    bottom: initial;
    height: 106px;
    top: 16px;
    width: 102px; }
  .footer__wrapper {
    align-items: initial;
    justify-content: flex-start; }
  .footer__logo {
    display: none; }
  .footer__copyright {
    border: 0;
    margin: 0 15px;
    margin-left: auto;
    text-align: right;
    width: initial; }
  .footer__link {
    margin: 0 15px; } }

.main-nav {
  display: flex;
  flex: 1;
  flex-direction: column; }
  .main-nav__wrapper {
    background-color: rgba(113, 191, 68, 0.07);
    display: flex;
    flex-direction: column;
    height: 100vh;
    left: 0;
    overflow-y: auto;
    padding: 15px 85px 25px 24px;
    position: absolute;
    top: 0;
    width: 100%; }
  .main-nav__header {
    max-width: 60vw;
    text-align: center; }
  .main-nav__hello-user {
    margin-bottom: 0;
    margin-top: 12px; }
  .main-nav__title {
    color: #839ca9;
    font-size: 0.75rem;
    margin-bottom: 15px;
    margin-top: 30px;
    max-width: 60vw;
    text-transform: uppercase; }
  .main-nav__avatar {
    align-items: center;
    background: rgba(113, 191, 68, 0.5);
    border-radius: 50%;
    display: inline-flex;
    height: 28px;
    justify-content: center;
    margin-right: 5px;
    width: 28px; }
  .main-nav__avatar__picto {
    width: 16px; }
    .main-nav__avatar__picto path {
      fill: #fff; }
  .main-nav__user-link {
    align-items: center;
    border-bottom: 1px solid transparent;
    border-radius: 3px;
    color: #839ca9;
    display: inline-flex;
    max-width: 60vw;
    padding: 4px;
    position: relative;
    text-decoration: none;
    transition: border 0.3s; }
    .main-nav__user-link:focus, .main-nav__user-link:hover {
      outline: none;
      background-color: rgba(113, 191, 68, 0.2); }
  .main-nav__item {
    align-items: center;
    border-radius: 3px;
    border-bottom-left-radius: 31px;
    border-top-left-radius: 31px;
    display: flex;
    margin-bottom: 8px;
    position: relative;
    text-decoration: none;
    transition: background-color 0.4s;
    width: 100%;
    min-height: 47px; }
    .main-nav__item--inactive {
      pointer-events: none;
      cursor: default; }
    .main-nav__item:focus, .main-nav__item:hover {
      outline: none;
      background-color: rgba(113, 191, 68, 0.2); }
    .main-nav__item:last-of-type {
      margin-bottom: 0; }
  .main-nav__item--secondary {
    align-self: flex-end;
    color: #839ca9;
    font-weight: 200;
    margin: 0;
    margin-top: auto;
    padding: 4px; }
    .main-nav__item--secondary::after {
      display: none; }
    .main-nav__item--secondary:hover, .main-nav__item--secondary:focus {
      background-color: rgba(131, 156, 169, 0.2); }
    .main-nav__item--secondary .main-nav__item__title {
      color: #839ca9;
      font-size: 1rem;
      text-transform: uppercase; }
    .main-nav__item--secondary .main-nav__item__picto {
      background-color: transparent;
      margin-right: 8px;
      min-width: auto;
      width: 20px; }
      .main-nav__item--secondary .main-nav__item__picto img {
        max-height: auto;
        max-width: auto;
        width: 20px; }
  .main-nav__picto-svg {
    margin: auto;
    height: 70%;
    width: 80%; }
  .main-nav__label-wrapper {
    margin: 0; }
  .main-nav__link--active {
    background: rgba(113, 191, 68, 0.5); }
    .main-nav__link--active--add {
      pointer-events: none; }
    .main-nav__link--active .main-nav__item__title,
    .main-nav__link--active span {
      color: #fff; }
    .main-nav__link--active:hover, .main-nav__link--active:focus {
      background: rgba(113, 191, 68, 0.5); }
      .main-nav__link--active:hover .main-nav__item__title,
      .main-nav__link--active:hover span, .main-nav__link--active:focus .main-nav__item__title,
      .main-nav__link--active:focus span {
        color: #fff; }
    .main-nav__link--active.main-nav__user-link {
      color: #fff;
      pointer-events: none; }
  .main-nav__list-links:last-of-type {
    margin-bottom: 20px; }
  .main-nav__item__picto {
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    height: 47px;
    justify-content: center;
    min-width: 47px;
    position: relative;
    width: 47px; }
    .main-nav__item__picto--inactive {
      position: absolute;
      z-index: 2;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 50%;
      pointer-events: none; }
    .main-nav__item__picto img,
    .main-nav__item__picto svg {
      display: inline-block;
      max-height: 60%;
      max-width: 80%; }
  .main-nav__label-wrapper {
    margin-left: 12px; }
  .main-nav__item__title {
    color: #71bf44;
    display: block;
    flex-basis: 100%;
    font-size: 1.2rem; }
    .main-nav__item__title--inactive {
      color: rgba(131, 156, 169, 0.4); }
  .main-nav__item-sub {
    color: #839ca9;
    display: block;
    flex-basis: 100%;
    font-size: 0.85rem;
    margin-top: 10px; }

.logo__img {
  width: 116px;
  height: 52px; }

.logo:focus, .logo:hover {
  outline: none; }
  .logo:focus img, .logo:hover img {
    background-color: rgba(113, 191, 68, 0.2); }

.nav_application {
  margin: 0 0 0 8px; }

#nav_application {
  margin-left: 16px; }

@media only screen and (min-width: 1280px) {
  .main-nav {
    flex: 1 0 auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%; }
    .main-nav--header {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      width: 100%; }
    .main-nav__wrapper {
      width: 320px;
      background-color: rgba(113, 191, 68, 0.07);
      border-right: 1px solid rgba(131, 156, 169, 0.4);
      position: fixed;
      padding: 15px 40px 25px 35px; }
    .main-nav--item::after {
      display: none; } }

@media print {
  .main-nav__wrapper {
    display: none; } }

@media only screen and (min-height: 768px) {
  .main-nav__wrapper {
    padding-top: 25px;
    padding-bottom: 25px; }
  .main-nav__title {
    margin-top: 40px; }
  .main-nav__item {
    margin-bottom: 15px; } }

@media only screen and (min-height: 900px) {
  .main-nav__hello-user {
    margin-bottom: 0;
    margin-top: 15px; }
  .main-nav__item__picto {
    height: 55px;
    min-width: 55px;
    width: 55px; } }

@media only screen and (min-height: 1000px) {
  .main-nav__title {
    margin-bottom: 20px; }
  .main-nav__item {
    margin-bottom: 20px; }
    .main-nav__item__picto {
      height: 60px;
      min-width: 60px;
      width: 60px; } }

@media only screen and (min-height: 1200px) {
  .main-nav__wrapper {
    padding-top: 35px;
    padding-bottom: 10px; }
  .main-nav__title {
    margin-top: 60px;
    margin-bottom: 30px; }
  .main-nav__item {
    margin-bottom: 20px; }
    .main-nav__item__picto {
      left: -3px;
      height: 70px;
      min-width: 70px;
      width: 70px; } }

.metro-line {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 25px;
  width: calc(100% - 60px);
  overflow: hidden;
  position: relative; }
  .metro-line__central-line {
    border-top: 1px dashed #839ca9;
    height: 1px;
    position: absolute;
    width: 100%;
    max-width: 570px;
    top: calc(50% - 7px); }
  .metro-line__item {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    position: relative; }
    .metro-line__item:last-of-type {
      padding: 0; }
    .metro-line__item:first-of-type {
      padding: 0; }
    .metro-line__item--container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center; }
    .metro-line__item__icon {
      border: 1px solid #839ca9;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      background-color: #fff; }
      .metro-line__item__icon__wrapper {
        width: 50px;
        height: 50px;
        position: relative;
        margin-top: 5px; }
    .metro-line__item__link:focus {
      outline: none; }
      .metro-line__item__link:focus svg {
        box-shadow: 0 0 4px 2px #4d90fe;
        border-radius: 50%; }
    .metro-line__item__label {
      color: #839ca9;
      font-size: 0.75rem;
      font-weight: 400;
      margin-bottom: 0;
      margin-top: 4px;
      text-transform: uppercase; }
    .metro-line__item--selected .metro-line__item__icon {
      border: 1px solid #71bf44;
      background-color: #71bf44; }
      .metro-line__item--selected .metro-line__item__icon path {
        fill: #fff; }
      .metro-line__item--selected .metro-line__item__icon__wrapper::before {
        border-color: #839ca9; }
    .metro-line__item--selected .metro-line__item__label {
      color: #71bf44; }
    .metro-line__item--is-completed .metro-line__item__icon {
      border: 1px solid #71bf44; }
      .metro-line__item--is-completed .metro-line__item__icon path {
        fill: #71bf44; }
    .metro-line__item--is-completed .metro-line__item__label {
      color: #71bf44; }

@media screen and (min-width: 1280px) {
  .metro-line {
    margin: 8px auto;
    margin-bottom: 15px; } }

.form-ruler {
  display: flex;
  flex-wrap: no-wrap;
  justify-content: space-between;
  list-style: none;
  margin-top: 16px;
  margin-bottom: 8px;
  padding: 0; }

.form-ruler--item {
  color: #839ca9;
  text-align: center;
  width: 32px; }

.form-ruler--item--active {
  color: #71bf44;
  font-weight: bold; }

.form-slider {
  height: 40px;
  margin-bottom: 0;
  margin-top: 16px;
  position: relative; }

.form-slider--input {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  box-shadow: 0 0 0;
  height: 1px;
  margin: 0;
  margin-top: 15px;
  outline: none;
  padding: 0;
  transition: opacity 0.2s;
  width: 100%;
  position: relative;
  z-index: 1; }
  .form-slider--input::-moz-range-track {
    background: transparent;
    border: 0; }
  .form-slider--input::-moz-focus-outer {
    border: 0; }
  .form-slider--input::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    appearance: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.14);
    cursor: pointer; }
  .form-slider--input::-moz-range-thumb {
    background: #fff;
    border: none;
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.14);
    cursor: pointer;
    height: 40px;
    width: 40px; }

.form-slider--baseline {
  position: absolute;
  border: none;
  border-top: 1px solid #839ca9;
  width: 100%;
  top: 7px; }

.form-slider--progress {
  border: none;
  border-top: 1px solid #71bf44;
  margin: 0;
  position: absolute;
  left: 0;
  top: 15px;
  z-index: 0; }

@supports (-ms-ime-align: auto) {
  .form-slider--baseline {
    display: none; }
  .form-slider--progress {
    display: none; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-slider--baseline {
    display: none; }
  .form-slider--progress {
    display: none; } }

.periodic_el_quantity__wrapper {
  margin-top: 56px; }
  .periodic_el_quantity__wrapper:first-of-type {
    margin-top: 34px; }
  .periodic_el_quantity__wrapper--single {
    margin-top: 30px;
    display: flex;
    justify-content: space-between; }
    .periodic_el_quantity__wrapper--single:first-of-type {
      margin-top: 24px; }
    .periodic_el_quantity__wrapper--single .periodic_el_quantity__field {
      width: calc(100% - 250px);
      min-width: 110px; }
  .periodic_el_quantity__wrapper p[class*='MuiFormHelperText']:not(.form__error-text) {
    position: absolute;
    top: 30px; }

.periodic-element {
  margin-right: 8px; }

.periodic_el_quantity__element_title_wrapper,
.picto-title {
  display: flex;
  align-items: center;
  padding-right: 15px;
  flex: 1; }
  .periodic_el_quantity__element_title_wrapper h3,
  .picto-title h3 {
    color: #839ca9;
    margin: 0;
    font-weight: 400; }

.periodic-row {
  align-items: center;
  display: flex;
  justify-content: space-between; }
  .periodic-row .picto-title {
    max-width: 150px; }

.periodic_el_bars__wrapper {
  width: 100%;
  display: flex;
  align-items: center; }

.periodic_el_bars__container {
  width: calc(100% - 45px);
  margin-left: 5px; }

.periodic_el_bars__bar__wrapper {
  width: 100%;
  display: flex;
  margin-bottom: 7px; }
  .periodic_el_bars__bar__wrapper:last-child {
    margin-bottom: 0px; }

.periodic_el_bars__bar__background {
  width: 100%;
  height: 10px;
  background-color: rgba(131, 156, 169, 0.2);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }

.periodic_el_bars__bar__number {
  margin: 0px;
  font-size: 10pt;
  line-height: 10px;
  margin-left: 5px;
  width: 30px; }

.round-label {
  border: 2px solid #71bf44;
  border-radius: 50%;
  height: 180px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  width: 180px; }
  .round-label:hover {
    cursor: default; }
  .round-label::before {
    background: #71bf44;
    border-radius: 50%;
    content: '';
    height: 28px;
    position: absolute;
    right: 18px;
    top: 6px;
    width: 28px; }

.round-label--text {
  margin: 0 auto; }
  .round-label--fr .round-label--text {
    padding: 0 10px; }

.round-label--text-normal {
  color: #839ca9;
  display: inline-block;
  font-size: 0.875rem;
  text-transform: uppercase;
  width: 100%; }

.round-label--text-big {
  color: #839ca9;
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 900;
  text-transform: uppercase; }
  .round-label--de .round-label--text-big {
    font-size: 19px;
    margin: 2px 0;
    background-color: #fff;
    margin-left: -14px; }

.round-label--balance {
  margin: 20px 0 10px;
  width: 40px; }

.round-label--check {
  height: 28px;
  padding: 8px 6px;
  position: absolute;
  right: 18px;
  top: 6px;
  width: 28px; }

@media only screen and (min-width: 1280px) {
  .round-label {
    margin-top: 30px; } }

.subheader {
  margin-bottom: 30px;
  margin-top: 45px;
  text-align: center;
  position: relative; }
  .subheader__picto-wrapper {
    background-color: rgba(113, 191, 68, 0.07);
    border-radius: 50%;
    display: flex;
    height: 80px;
    margin: 0 auto;
    width: 80px;
    position: relative;
    justify-content: center;
    align-items: center; }
  .subheader__right-button {
    position: absolute;
    right: -100px;
    top: 40px;
    transform: translate(0%, -50%); }
  .subheader__picto-svg {
    margin: auto;
    max-height: 55px;
    width: 65px; }
    .subheader__picto-svg--small {
      width: 55px; }
  .subheader__title {
    color: #839ca9;
    font-size: 1.2rem;
    margin-bottom: 0;
    margin-top: 12px; }
  .subheader__buttons-wrapper button,
  .subheader__buttons-wrapper a {
    text-transform: initial;
    border-radius: 15px;
    display: flex;
    margin: auto;
    margin-top: 15px;
    max-width: 225px;
    width: calc(100% - 10px);
    letter-spacing: 0px; }
  .subheader__buttons-picto {
    margin-right: 5px;
    width: 15px; }
  .subheader__select {
    max-width: 220px;
    width: calc(100% - 40px);
    margin: auto;
    margin-top: 15px; }

@media screen and (min-width: 600px) {
  .subheader__buttons-wrapper {
    flex-direction: column; }
    .subheader__buttons-wrapper button,
    .subheader__buttons-wrapper a {
      display: inline-flex;
      margin: 15px 5px 0;
      width: auto; } }

.table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  color: #839ca9; }
  .table__row {
    border-top: 1px solid rgba(131, 156, 169, 0.4); }
    .table__row:nth-child(2), .table__row:nth-child(1) {
      border-top: none; }
    .table__row:last-of-type {
      background-color: rgba(131, 156, 169, 0.2);
      border-top: none; }
      .table__row:last-of-type td {
        font-weight: bold; }
  .table__td, .table__th {
    text-align: center;
    padding-right: 10px;
    vertical-align: top;
    padding-left: 10px; }
    .table__td:first-of-type, .table__th:first-of-type {
      text-align: left; }
  .table__td {
    padding-top: 15px;
    padding-bottom: 15px; }
    .table__td--orange {
      color: #ffcc00; }
    .table__td--green {
      color: #71bf44; }
    .table__td--red {
      color: #d0021b; }
  .table__th {
    color: #505f66; }

.short_product_info__paragraph {
  margin: 0; }

.short_product_info__detail-button {
  margin-top: 20px !important; }

.short_product_info__selected {
  border: 2px solid #71bf44; }

@media screen and (min-width: 600px) {
  .short_product_info {
    border-radius: 4px; } }

.confirm_selection__wrapper {
  width: 100%;
  overflow: auto;
  position: absolute;
  bottom: 0px;
  background-color: #fff; }

.confirm_selection__button {
  display: block !important;
  margin: auto !important;
  margin-bottom: 10px !important;
  margin-top: 10px !important; }

@media screen and (min-width: 600px) {
  .confirm_selection__wrapper {
    position: static; } }

.filter_button {
  padding: 0px 80px;
  border-radius: 30px;
  position: relative;
  height: 45px;
  min-height: 45px;
  overflow: hidden; }
  .filter_button__icon {
    flex-basis: 18px;
    flex-shrink: 0;
    width: 18px; }
    .filter_button__icon__wrapper {
      left: -2px;
      height: 45px;
      width: 45px;
      background-color: #71bf44;
      position: absolute;
      border-radius: 100%;
      top: -2px;
      display: flex;
      align-items: center;
      justify-content: center; }
  .filter_button__wrapper {
    white-space: nowrap;
    overflow: auto; }
  .filter_button__small {
    font-size: 0;
    padding: 0;
    min-width: 45px; }

@media screen and (min-width: 992px) {
  .filter_button__wrapper {
    display: none; } }

.vertical_bars {
  display: flex;
  margin-top: 25px; }
  .vertical_bars .vertical_bar__wrapper__super {
    margin-right: 20px; }

.arrow__wrapper {
  white-space: nowrap;
  height: 200px;
  position: relative;
  width: 50px; }

.arrow__line {
  position: absolute;
  height: calc(100% - 30px);
  bottom: -8px;
  border: none;
  width: 1px;
  background-color: #839ca9;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.arrow__triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7px 12px 7px;
  border-color: transparent transparent #839ca9 transparent;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: calc(100% - 30px); }

.arrow__label {
  color: #839ca9;
  text-align: center;
  font-weight: normal;
  margin: 0;
  font-size: 9pt; }

.circular_progress__bar {
  position: relative;
  text-align: center;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-color: #71bf44;
  background-image: linear-gradient(95deg, transparent 50%, #cdd7dd 50%), linear-gradient(90deg, #cdd7dd 50%, transparent 50%);
  margin: auto; }
  .circular_progress__bar__background {
    position: relative;
    top: 10px;
    left: 10px;
    text-align: center;
    width: 230px;
    height: 230px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center; }

.circular_progress__content__h4 {
  font-size: 26pt; }

.circular_progress__content__h5 {
  margin: 0;
  color: #839ca9;
  font-weight: normal;
  font-size: 13pt;
  max-width: 150px; }

.complex_list_item {
  list-style-type: none;
  display: inline-block;
  width: 100%;
  text-decoration: none; }
  .complex_list_item__content {
    display: flex;
    border-bottom: 1px solid #839ca9;
    padding: 20px 0px;
    align-items: center;
    margin-bottom: -1px;
    position: relative; }
  .complex_list_item--selected {
    border: 1px solid #71bf44; }
    .complex_list_item--selected .complex_list_item__title__h3, .complex_list_item--selected .complex_list_item__title__h4 {
      color: #71bf44; }
  .complex_list_item__left_content__icon {
    height: 46px;
    flex-basis: 46px;
    max-width: 50px; }
  .complex_list_item__left_content__radio {
    padding: 0px !important;
    margin-left: -5px !important; }
    .complex_list_item__left_content__radio svg {
      font-size: 30px !important; }
  .complex_list_item__title__wrapper {
    margin-left: 20px;
    width: calc(100% - 40px); }
  .complex_list_item__title__h3, .complex_list_item__title__h4 {
    color: #839ca9; }
  .complex_list_item__title__h3 {
    margin: 0;
    margin-bottom: 5px;
    font-size: 15pt; }
  .complex_list_item__title__text_field {
    width: calc(100% - 140px);
    position: absolute;
    top: 18px; }
  .complex_list_item__right-content {
    margin-left: auto; }
    .complex_list_item__right-content__icon {
      width: 25px;
      max-height: 30px;
      flex-basis: 25px;
      float: right;
      background-color: white; }
    .complex_list_item__right-content__chevron {
      width: 25px;
      max-height: 25px;
      flex-basis: 25px;
      transform: rotate(-90deg);
      float: right; }
    .complex_list_item__right-content .button_hidden {
      padding: 0; }
  .complex_list_item--warning .complex_list_item__title__text_field label,
  .complex_list_item--warning .complex_list_item__title__text_field p {
    color: #ffcc00; }

@media only screen and (min-width: 426px) {
  .complex_list_item {
    margin-bottom: 10px; }
    .complex_list_item__content {
      border: 1px solid #839ca9;
      border-radius: 3px;
      padding: 20px; } }

@media screen and (min-width: 880px) {
  .complex_list_item {
    max-width: calc(100% / 2 - 12px);
    margin: 6px;
    margin-bottom: 5px; } }

@media screen and (min-width: 1600px) {
  .complex_list_item {
    max-width: calc(100% / 3 - 12px);
    margin: 6px;
    margin-bottom: 5px; } }

@media screen and (min-width: 2000px) {
  .complex_list_item {
    max-width: calc(100% / 4 - 12px);
    margin: 6px;
    margin-bottom: 5px; } }

.my-plot-cell {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  color: #839ca9;
  background-color: rgba(131, 156, 169, 0.05);
  border-radius: 0.2rem;
  cursor: pointer;
  font-size: 0.9rem; }
  .my-plot-cell.selected {
    background-color: rgba(113, 191, 68, 0.2); }
  .my-plot-cell__data {
    flex: 7;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative; }
    .my-plot-cell__data--checkbox {
      width: 48px; }
    .my-plot-cell__data--icon-holder {
      width: 30px; }
      .my-plot-cell__data--icon-holder .icon {
        height: 20px;
        width: 20px; }
    .my-plot-cell__data--name {
      padding: 0.5rem;
      flex: 3;
      word-break: break-all; }
    .my-plot-cell__data--area {
      flex: 1;
      text-align: right; }
    .my-plot-cell__data--action-layer {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0; }
  .my-plot-cell__actions {
    flex: 6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end; }
    .my-plot-cell__actions--button {
      width: 44px;
      height: 44px; }
      .my-plot-cell__actions--button--icon {
        width: 44px;
        height: 44px; }
    .my-plot-cell__actions--edit {
      padding: 0 0.5rem;
      color: #71bf44;
      border-right: #839ca9 1px solid;
      text-align: right; }
      .my-plot-cell__actions--edit:hover {
        text-decoration: underline; }
    .my-plot-cell__actions--delete {
      padding: 0 0.5rem;
      color: #eb6909; }
      .my-plot-cell__actions--delete:hover {
        text-decoration: underline; }
  .my-plot-cell__modal-opener {
    display: none; }

@media only screen and (max-width: 992px) {
  .my-plot-cell__data--action-layer {
    right: 50px; } }

@media only screen and (max-width: 768px) {
  .my-plot-cell__data {
    flex: 7; }
    .my-plot-cell__data--name {
      flex: 5; }
    .my-plot-cell__data--area {
      flex: 2; }
  .my-plot-cell__actions {
    display: none; }
  .my-plot-cell__modal-opener {
    display: flex;
    flex: 1;
    justify-content: flex-end; }
    .my-plot-cell__modal-opener--icon {
      width: 1rem;
      height: 1rem; } }

.file-upload {
  width: 100%;
  min-height: 10vh;
  cursor: pointer;
  background-color: #f9fafb;
  border: 1px solid #ebebeb;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4% 0;
  position: relative; }
  .file-upload.drag-over {
    background-color: rgba(113, 191, 68, 0.07);
    border-color: #71bf44; }
  .file-upload__drop-zone {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute; }
  .file-upload__icon {
    width: 50px;
    height: auto;
    margin-bottom: 1rem; }
  .file-upload__text {
    font-weight: 400;
    font-size: 1rem;
    color: #839ca9; }
    .file-upload__text--mobile {
      display: none; }

@media only screen and (max-width: 992px) {
  .file-upload {
    width: 100%;
    min-height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2% 0; }
    .file-upload__icon {
      flex: 1;
      margin: 1rem;
      max-width: 40px; }
    .file-upload__text {
      flex: 4;
      font-size: 1.2rem; }
      .file-upload__text--desktop {
        display: none; }
      .file-upload__text--mobile {
        display: block; } }

.plot-xml-cell {
  color: #839ca9;
  background-color: rgba(131, 156, 169, 0.05);
  border-radius: 0.2rem;
  margin: 0.5rem;
  margin-left: 2rem;
  font-weight: 300;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-content: center;
  padding: 0.5rem;
  padding-left: 0px;
  line-height: 2rem; }
  .plot-xml-cell__column {
    display: flex;
    margin-left: 1rem; }
    .plot-xml-cell__column:first-child {
      margin-left: 0; }
    .plot-xml-cell__column--input {
      border: none;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%; }
    .plot-xml-cell__column--new-select-with-right-element {
      margin-top: 32px; }
    .plot-xml-cell__column--delete-button {
      color: #eb6909;
      font-size: 1rem;
      border: none;
      cursor: pointer;
      background-color: transparent; }
      .plot-xml-cell__column--delete-button:focus {
        outline: none; }
  .plot-xml-cell .plot-xml-cell-width__check {
    width: 1.5rem; }
    .plot-xml-cell .plot-xml-cell-width__check--checkbox {
      padding: 0.2rem 0; }
  .plot-xml-cell .plot-xml-cell-width__1 {
    flex: 1; }
    .plot-xml-cell .plot-xml-cell-width__1.with-delete-button {
      display: flex;
      flex-direction: row;
      justify-content: flex-end; }
    .plot-xml-cell .plot-xml-cell-width__1.numbers {
      text-align: right;
      align-items: center; }
  .plot-xml-cell .plot-xml-cell-width__2 {
    flex: 2; }
  .plot-xml-cell .plot-xml-cell-width__3 {
    flex: 3; }
  .plot-xml-cell .plot-xml-cell-width__4 {
    flex: 4; }

.plot-xml-cell-mobile {
  border: solid 1px #839ca9;
  box-shadow: none !important;
  margin-bottom: 1rem;
  border-radius: 0.5rem; }
  .plot-xml-cell-mobile.expanded {
    border: solid 1px #71bf44; }
  .plot-xml-cell-mobile:first-child, .plot-xml-cell-mobile:last-child {
    border-radius: 0.5rem; }
  .plot-xml-cell-mobile__summary {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .plot-xml-cell-mobile__summary--check {
      width: 1.5rem; }
      .plot-xml-cell-mobile__summary--check--checkbox {
        padding: 0.2rem 0; }
    .plot-xml-cell-mobile__summary--title {
      color: #839ca9;
      font-size: 1rem;
      font-weight: bold;
      margin-left: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center; }
  .plot-xml-cell-mobile__details--item.uneditable {
    min-height: 4rem;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between; }
  .plot-xml-cell-mobile__details--item--key {
    color: #71bf44; }
  .plot-xml-cell-mobile__details--item--value {
    margin-left: 1rem;
    color: #839ca9; }

.crop-rotation-cell {
  background-color: rgba(131, 156, 169, 0.05);
  border-radius: 0.2rem;
  color: #839ca9;
  font-size: 0.9rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0.5rem;
  margin: 0.5rem 0; }
  .crop-rotation-cell.small-header {
    background-color: white;
    font-size: 0.7rem;
    margin-left: 1rem; }
  .crop-rotation-cell.small-element {
    margin-left: 1rem; }
    .crop-rotation-cell.small-element.active {
      border: solid 1px #71bf44; }
  .crop-rotation-cell__column--1 {
    flex: 1; }
  .crop-rotation-cell__column--2 {
    flex: 2;
    display: flex;
    flex-direction: row; }
    .crop-rotation-cell__column--2.with-info {
      flex-direction: column; }
    .crop-rotation-cell__column--2--left {
      flex: 1; }
      .crop-rotation-cell__column--2--left.light {
        color: rgba(131, 156, 169, 0.7); }
    .crop-rotation-cell__column--2--right {
      flex: 1; }
    .crop-rotation-cell__column--2--info {
      font-size: 0.8rem; }
  .crop-rotation-cell__column--3 {
    flex: 3; }
  .crop-rotation-cell__tool-tip {
    width: 400%; }
  .crop-rotation-cell .button-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-weight: 400; }
    .crop-rotation-cell .button-list__button--icon {
      width: 20px;
      height: 20px; }
    .crop-rotation-cell .button-list__button--bar {
      width: 100%;
      text-align: center;
      margin: 1rem 0; }
      .crop-rotation-cell .button-list__button--bar--print {
        border-radius: 1rem;
        text-transform: none; }
        .crop-rotation-cell .button-list__button--bar--print--icon {
          margin-right: 1rem; }

.crop-rotation-cell-mobile {
  border: solid 1px #839ca9;
  box-shadow: none !important;
  margin-bottom: 1rem;
  border-radius: 0.5rem; }
  .crop-rotation-cell-mobile.expanded {
    border: solid 1px #71bf44; }
  .crop-rotation-cell-mobile:first-child, .crop-rotation-cell-mobile:last-child {
    border-radius: 0.5rem; }
  .crop-rotation-cell-mobile__summary {
    color: #839ca9;
    font-size: 1.2rem;
    font-weight: bold; }
  .crop-rotation-cell-mobile__details--header {
    display: flex;
    flex-direction: row;
    color: #839ca9; }
    .crop-rotation-cell-mobile__details--header--1 {
      flex: 1; }
  .crop-rotation-cell-mobile__details--data--label {
    color: #71bf44;
    font-size: 0.8rem; }
  .crop-rotation-cell-mobile__details--data--value {
    display: flex;
    flex-direction: row;
    color: #839ca9; }
    .crop-rotation-cell-mobile__details--data--value.with-info {
      flex-direction: column; }
    .crop-rotation-cell-mobile__details--data--value--1 {
      flex: 1; }
    .crop-rotation-cell-mobile__details--data--value--info {
      font-size: 0.8rem; }
  .crop-rotation-cell-mobile__details--actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end; }
    @media (max-width: 426px) {
      .crop-rotation-cell-mobile__details--actions {
        flex-direction: column; } }
  .crop-rotation-cell-mobile--button {
    text-transform: initial;
    border-radius: 15px;
    margin-left: 1rem; }
    .crop-rotation-cell-mobile--button--delete {
      background-color: #eb6909;
      border-radius: 15px;
      text-transform: initial;
      margin-left: 1rem; }
      .crop-rotation-cell-mobile--button--delete:hover {
        background-color: #eb6909; }
  .crop-rotation-cell-mobile__tool-tip {
    width: 200%; }
    .crop-rotation-cell-mobile__tool-tip--title {
      color: #839ca9;
      font-size: 1.2rem;
      font-weight: bold; }
    .crop-rotation-cell-mobile__tool-tip--step-title {
      color: #839ca9;
      margin-top: 0.8rem;
      padding-bottom: 0.4rem;
      margin-bottom: 0.4rem;
      border-bottom: solid 1px #71bf44;
      font-size: 1rem;
      font-weight: bold; }
    .crop-rotation-cell-mobile__tool-tip--content {
      color: #839ca9;
      font-weight: 400; }

.npks-bars {
  width: 100%; }
  .npks-bars__row {
    padding: 0.5rem 0; }
    .npks-bars__row--element {
      display: inline-flex;
      color: #839ca9; }
    .npks-bars__row--title {
      padding-bottom: 0.2rem; }
    .npks-bars__row--value {
      margin-left: 0.5rem; }

.carousel-slider {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }
  .carousel-slider__left-button {
    cursor: pointer;
    width: 15px;
    transform: rotate(90deg);
    padding: 1rem 0rem 0 0rem; }
  .carousel-slider__right-button {
    cursor: pointer;
    width: 15px;
    transform: rotate(270deg);
    padding: 1rem 0rem 0 0rem; }
  .carousel-slider__container {
    width: calc(100% - 40px);
    overflow: hidden;
    display: flex;
    cursor: grab; }
    .carousel-slider__container--element {
      float: left; }

.application-step-list {
  width: 200%; }
  .application-step-list__title {
    color: #839ca9;
    font-size: 1rem;
    font-weight: bold; }
  .application-step-list__step-title {
    margin-top: 0.8rem;
    padding-bottom: 0.4rem;
    margin-bottom: 0.4rem;
    border-bottom: solid 1px #71bf44;
    color: #839ca9;
    font-size: 1rem;
    font-weight: bold; }
  .application-step-list__text {
    color: #839ca9; }

.plan-list-cell {
  display: flex;
  flex-direction: row;
  background-color: rgba(131, 156, 169, 0.05);
  border-radius: 0.2rem;
  color: #839ca9;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem;
  margin: 0.5rem 0;
  align-items: center; }
  .plan-list-cell__checkbox {
    background-color: #71bf44;
    font-size: 14px;
    color: #fff;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px; }
  .plan-list-cell__1 {
    flex: 1;
    margin: 0 0.5rem; }
  .plan-list-cell__2 {
    flex: 2;
    margin: 0 0.5rem; }
  .plan-list-cell__3 {
    flex: 3;
    margin: 0 0.5rem; }
  .plan-list-cell .button-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-weight: 400; }
    .plan-list-cell .button-list__button {
      padding: 0 0.5rem;
      cursor: pointer; }
      .plan-list-cell .button-list__button:hover {
        text-decoration: underline; }
      .plan-list-cell .button-list__button:not(:first-child) {
        border-left: 1px solid #839ca9; }
      .plan-list-cell .button-list__button.green {
        color: #71bf44; }

.plan-list-mobile-cell {
  border: solid 1px #839ca9;
  box-shadow: none !important;
  margin-bottom: 1rem;
  border-radius: 0.5rem; }
  .plan-list-mobile-cell.expanded {
    border: solid 1px #71bf44; }
  .plan-list-mobile-cell:first-child, .plan-list-mobile-cell:last-child {
    border-radius: 0.5rem; }
  .plan-list-mobile-cell__summary {
    color: #839ca9;
    font-size: 1.2rem;
    font-weight: bold; }
    .plan-list-mobile-cell__summary--checkbox {
      padding: 0.2rem 0;
      margin-right: 0.5rem; }
      .plan-list-mobile-cell__summary--checkbox--checked {
        background-color: #71bf44;
        font-size: 14px;
        border-radius: 2px;
        color: #fff;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center; }
  .plan-list-mobile-cell__details--data--label {
    color: #71bf44;
    font-size: 0.8rem; }
  .plan-list-mobile-cell__details--data--value {
    color: #839ca9;
    font-weight: bold; }
  .plan-list-mobile-cell__details--actions {
    display: flex;
    flex-direction: row;
    justify-content: center; }
  .plan-list-mobile-cell--button {
    text-transform: initial;
    border-radius: 15px; }

.rail-outer {
  position: relative;
  width: 100%;
  height: 42px;
  transform: translate(0%, -50%);
  border-radius: 7px;
  cursor: pointer; }

.rail-inner {
  position: absolute;
  width: 100%;
  height: 14px;
  transform: translate(0%, -50%);
  border-radius: 7px;
  pointer-events: 'none';
  background-color: rgba(131, 156, 169, 0.5); }

.range-slider-handle {
  position: absolute;
  transform: translate(-50%, -50%);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 5;
  width: 28px;
  height: 42px;
  cursor: pointer;
  background-color: none; }

.range-slider-handle-bottom {
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.3); }

.range-slider-track {
  border-radius: 7px;
  cursor: pointer;
  position: absolute;
  transform: translate(0%, -50%);
  height: 14px;
  z-index: 1; }

.range-slider-tick {
  position: absolute;
  margin-top: 14px;
  width: 1px;
  height: 5px;
  background-color: #c8c8c8; }

.range-slider-tick-bottom {
  position: absolute;
  margin-top: 22px;
  font-size: 10px;
  text-align: center; }

.my-application-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  color: #839ca9;
  background-color: rgba(131, 156, 169, 0.05);
  margin: 0.5rem 0;
  padding: 0.5rem; }
  .my-application-cell__column {
    display: flex;
    flex-direction: row; }
    .my-application-cell__column.block-column {
      display: block; }
  .my-application-cell__column-group {
    display: flex;
    flex-direction: column; }
    .my-application-cell__column-group--row {
      display: flex;
      flex-direction: row; }

.my-application-cell-mobile {
  border: solid 1px #839ca9;
  box-shadow: none !important;
  margin-bottom: 1rem;
  border-radius: 0.5rem; }
  .my-application-cell-mobile.expanded {
    border: solid 1px #71bf44; }
  .my-application-cell-mobile:first-child, .my-application-cell-mobile:last-child {
    border-radius: 0.5rem; }
  .my-application-cell-mobile__summary {
    color: #839ca9;
    font-size: 1.2rem; }
  .my-application-cell-mobile__details--header {
    display: flex;
    flex-direction: row;
    color: #839ca9; }
    .my-application-cell-mobile__details--header--1 {
      flex: 1; }
  .my-application-cell-mobile__details--data--label {
    color: #71bf44;
    font-size: 0.8rem; }
  .my-application-cell-mobile__details--data--value {
    display: flex;
    flex-direction: row;
    color: #839ca9;
    flex-wrap: wrap; }
    .my-application-cell-mobile__details--data--value.block-column {
      display: block; }
    .my-application-cell-mobile__details--data--value--1 {
      flex: 1; }
  .my-application-cell-mobile__details--data--values {
    display: flex;
    flex-direction: column; }

.soil-number-data {
  height: 48px;
  display: flex;
  justify-content: center; }

.radio-selector-with-number-input-title__info {
  position: relative;
  display: inline-block; }
  .radio-selector-with-number-input-title__info--info-button--mobile {
    border: solid #839ca9 1px;
    cursor: pointer;
    border-radius: 50%;
    color: #839ca9;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 1rem;
    height: 1rem;
    margin-left: 5px;
    padding: 0.5rem; }
  .radio-selector-with-number-input-title__info--info-button {
    position: relative;
    border: solid #839ca9 1px;
    cursor: pointer;
    border-radius: 50%;
    color: #839ca9;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    position: absolute;
    bottom: -5px;
    left: 5px;
    font-size: 0.8rem;
    width: 1rem;
    height: 1rem; }
  .radio-selector-with-number-input-title__info--info-text {
    color: #839ca9;
    font-size: 0.8rem; }
  .radio-selector-with-number-input-title__info--tool-tip-body {
    width: 200px; }

.city-postal-code-selector-container {
  border: none;
  width: 100%;
  background-color: transparent;
  text-align: left;
  padding: 0; }
  .city-postal-code-selector-container:focus {
    outline: none; }

.pac-container {
  z-index: 10000; }

.refer-farmer-fields {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 1rem auto; }
  .refer-farmer-fields__field-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px; }
    .refer-farmer-fields__field-row--column:first-child {
      min-width: 50px;
      display: flex;
      justify-content: center;
      align-items: center; }
    .refer-farmer-fields__field-row--column:last-child {
      flex: 1; }

.refer-farmer-list {
  margin-top: 1rem; }
  .refer-farmer-list__header {
    display: flex;
    flex-direction: row;
    color: #839ca9;
    font-weight: bold;
    align-items: center; }
    .refer-farmer-list__header--1 {
      flex: 1;
      padding: 0.3rem; }
    .refer-farmer-list__header--2 {
      flex: 2;
      padding: 0.3rem; }
    .refer-farmer-list__header--3 {
      flex: 3;
      padding: 0.3rem; }
  .refer-farmer-list__footer {
    border-top: 1px solid #839ca9;
    margin-top: 1rem; }
    .refer-farmer-list__footer--text {
      margin: 1rem 0;
      color: #839ca9; }

@media only screen and (max-width: 960px) {
  .refer-farmer-list__header {
    display: none; } }

.my-application-map-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #839ca9;
  background-color: rgba(131, 156, 169, 0.05);
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  border-radius: 0.5rem;
  min-height: 60px; }
  .my-application-map-cell__1 {
    flex: 1; }
  .my-application-map-cell__2 {
    flex: 2; }
    .my-application-map-cell__2.right {
      display: flex;
      justify-content: flex-end; }
  .my-application-map-cell__3 {
    flex: 3; }
  .my-application-map-cell .button-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-weight: 400; }
    .my-application-map-cell .button-list__button--icon {
      width: 20px;
      height: 20px; }

.step-item-component {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem; }
  .step-item-component__step-nr {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #839ca9;
    border-radius: 50%;
    color: #839ca9;
    font-weight: bold;
    font-size: 0.9rem;
    width: 25px;
    height: 25px; }
    .step-item-component__step-nr.completed {
      border-color: #71bf44;
      background-color: #71bf44;
      color: #fff; }
  .step-item-component__text {
    color: #839ca9;
    font-weight: bold;
    font-size: 16px;
    flex: 1; }

.nutrient_comparison__wrapper .periodic_el_bars__wrapper {
  margin-bottom: 25px; }

.product_info__wrapper {
  align-items: flex-start;
  display: flex;
  margin: 0 0 30px;
  max-width: 600px; }
  .product_info__wrapper.center {
    justify-content: center; }
  .product_info__wrapper svg {
    display: none; }

.product_info__spreader_icon {
  display: block;
  width: 38px;
  height: 25px; }
  .product_info__spreader_icon path {
    fill: #71bf44; }

.product_info__content {
  width: 100%; }
  .product_info__content :first-of-type {
    margin: 0; }
  .product_info__content :last-of-type {
    margin-bottom: 0; }
  .product_info__content__button {
    margin-top: 15px !important; }
    .product_info__content__button--product-detail {
      border: 2px solid #71bf44 !important; }
    .product_info__content__button--change-formula {
      border: 2px solid #839ca9 !important; }
  .product_info__content b {
    font-size: 120%; }
  .product_info__content__spreader_callibration_link {
    margin-top: 16px;
    display: block; }

.product_info__img {
  width: 38px; }

.product_info__icon_text {
  margin-top: 24px;
  margin-bottom: 0px; }

.product_info__change_formulae {
  margin-top: 20px !important; }

@media screen and (min-width: 992px) {
  .product_info__wrapper svg {
    display: block; }
  .product_info__content {
    padding-left: 16px; }
    .product_info__content__spreader_callibration_link {
      margin-left: 51px; } }

.macro_rutrients_recommendation {
  max-width: 450px; }
  .macro_rutrients_recommendation__button {
    margin-top: 30px !important; }
  .macro_rutrients_recommendation__center {
    margin: 0 auto;
    margin-top: 20px; }

.filter_products__wrapper--isolated {
  height: calc(100vh - 150px);
  height: calc((var(--vh, 1vh) * 100) - 150px);
  overflow: auto; }
  .filter_products__wrapper--isolated .filter_products__group {
    margin-top: 10px;
    margin-bottom: 30px; }
    .filter_products__wrapper--isolated .filter_products__group__label {
      font-size: 18pt; }
  .filter_products__wrapper--isolated .filter_products__control-label {
    font-size: 20px; }
    .filter_products__wrapper--isolated .filter_products__control-label p {
      font-size: 16pt; }
    .filter_products__wrapper--isolated .filter_products__control-label svg {
      font-size: 38px; }

.filter_products__control {
  display: block !important;
  margin-bottom: 15px !important; }

.filter_products__group {
  margin-top: 2px; }
  .filter_products__group__label {
    color: #71bf44 !important; }

.filter_products__control-label span {
  padding-bottom: 2px;
  padding-top: 2px; }

.filter_products__control-label p {
  margin: 0; }

.product_form {
  max-width: 600px;
  width: calc(100% - 50px);
  margin: auto; }

.dialogue__ul {
  padding-left: 0px; }
  .dialogue__ul li {
    list-style-type: none; }

.n_format {
  max-width: 500px;
  margin: auto;
  margin-top: 50px; }
  .n_format__radio_wrapper {
    margin-top: 35px;
    margin-bottom: 40px; }
  .n_format__legend_wrapper {
    margin-top: 25px; }
  .n_format__key_value_wrapper {
    margin-top: 40px; }
  .n_format__icon_title_wrapper {
    margin-top: 40px;
    margin-bottom: 20px; }

.impact__wrapper {
  margin: auto; }
  .impact__wrapper .circular_progress_with_title__wrapper {
    max-width: 500px;
    margin: auto; }
  .impact__wrapper .table {
    margin-top: 30px;
    margin-bottom: 60px; }
    .impact__wrapper .table__wrapper {
      max-width: 500px;
      margin: 0 auto;
      margin-top: 70px; }
    .impact__wrapper .table__title {
      color: #839ca9; }
    .impact__wrapper .table__subtitle {
      color: #839ca9;
      margin-bottom: -15px;
      margin-top: 30px; }

.filter_my_overview_entities {
  min-width: 150px; }
  .filter_my_overview_entities__select {
    margin-top: 15px; }
    .filter_my_overview_entities__select:first-of-type {
      margin-top: 10px; }

.tab-accordion-full-width .tab-accordion__button {
  display: flex; }

.tab-accordion-full-width .tab-accordion__title-wrapper {
  width: 100%; }

.tab-accordion {
  display: flex;
  flex-wrap: wrap;
  position: relative; }
  .tab-accordion__panel {
    flex-basis: 100%;
    order: 1;
    padding: 30px 24px;
    width: 100%; }
    .tab-accordion__children .tab-accordion__panel {
      padding: 30px 0; }
    .tab-accordion__panel-schrink {
      padding: 0;
      height: 0;
      overflow: hidden; }
  .tab-accordion__header {
    padding-bottom: 30px;
    text-align: center;
    width: 100%; }
  .tab-accordion__title-wrapper {
    margin-right: auto;
    padding: 20px 16px;
    position: relative;
    top: 1px; }
  .tab-accordion__button {
    background: none;
    border: 0;
    color: #839ca9;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    margin: 0;
    margin-top: 20px 0;
    min-height: auto;
    min-width: auto;
    padding: 0;
    order: 0;
    position: relative;
    text-align: center;
    transition: all 0.4s;
    width: 100%;
    z-index: 1; }
    .tab-accordion__button:focus {
      outline: none; }
      .tab-accordion__button:focus .tab-accordion__title-wrapper {
        background-color: rgba(113, 191, 68, 0.2); }
    .tab-accordion__button::-moz-focus-inner {
      border: none; }
    .tab-accordion__button:not(.tab-accordion__button--active):hover {
      cursor: pointer; }
      .tab-accordion__button:not(.tab-accordion__button--active):hover::after {
        background-color: #505f66; }
    .tab-accordion__button--active {
      border-bottom-color: #71bf44; }
      .tab-accordion__button--active .tab-accordion__title {
        color: #71bf44; }
      .tab-accordion__button--active .tab-accordion__chevron {
        transform: rotate(0deg); }
      .tab-accordion__button--active .timeline__step-num {
        background-color: #71bf44; }
      .tab-accordion__button--active .tab-accordion__title-wrapper::after {
        background-color: #71bf44;
        bottom: 0;
        color: #71bf44;
        content: '';
        height: 3px;
        left: 0;
        position: absolute;
        transition: background-color 0.2s;
        width: calc(100% + 2px);
        margin-left: -1px; }
    .tab-accordion__button:last-of-type + .tab-accordion__underline {
      display: inline;
      width: 100%;
      margin: 0;
      z-index: 0;
      margin-top: 0px;
      border: none;
      border-top: 1px solid rgba(131, 156, 169, 0.4); }
  .tab-accordion__title {
    color: #839ca9;
    font-size: 1.125rem;
    font-weight: 400;
    margin: 0;
    text-transform: uppercase; }
  .tab-accordion__info {
    position: absolute;
    background-color: #fff;
    padding: 0.5rem;
    box-shadow: -1px 2px 3px -1px rgba(0, 0, 0, 0.3);
    width: 200px;
    top: -40%; }
    .tab-accordion__info.left {
      right: calc(100% + 5px); }
      .tab-accordion__info.left::after {
        content: '';
        position: absolute;
        width: 15px;
        height: 15px;
        background: white;
        box-shadow: 2px -1px 0px 0px rgba(0, 0, 0, 0.3);
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        top: 50%;
        left: 100%; }
    .tab-accordion__info.right {
      left: calc(100% + 5px); }
      .tab-accordion__info.right::after {
        content: '';
        position: absolute;
        width: 15px;
        height: 15px;
        background: white;
        box-shadow: -1px 2px 1px 0px rgba(0, 0, 0, 0.3);
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        top: 50%;
        left: 0; }
  .tab-accordion__info-button {
    position: absolute;
    right: 5%;
    border: 1px solid #71bf44;
    color: #71bf44;
    border-radius: 50%;
    display: flex;
    height: 30px;
    width: 30px;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    z-index: 1; }
    .tab-accordion__info-button:focus {
      outline: none; }
  .tab-accordion__subtitle {
    font-size: 0.875rem;
    color: #839ca9;
    display: inline-block;
    margin: 8px 0 0; }
  .tab-accordion__chevron {
    display: none;
    min-width: 19px;
    transform: rotate(-90deg);
    transition: transform 0.2s;
    width: 19px; }
  .tab-accordion__header__title {
    color: #839ca9;
    font-weight: normal;
    margin: 0;
    margin-top: 20px; }
    svg + .tab-accordion__header__title {
      margin-top: 20px; }
  .tab-accordion__header__subtitle {
    color: #839ca9;
    font-weight: normal;
    margin: 0;
    margin-top: 4px; }
  .tab-accordion__child__wrapper {
    width: 100%; }
  .tab-accordion__underline {
    display: none; }

.tab-column {
  padding: 30px 0;
  position: relative; }
  .tab-column__wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; }
  .tab-column::before {
    background-color: rgba(131, 156, 169, 0.4);
    content: '';
    height: 1px;
    right: -24px;
    position: absolute;
    top: 0;
    width: calc(100% + 24px); }
  .tab-column:last-of-type {
    border: 0; }
  .tab-column .periodic-row {
    margin-top: 30px;
    max-width: initial; }

.tab-accordion__children .content-row__grid-wrapper:last-of-type .tab-accordion__button {
  margin-bottom: 0; }

.tab-accordion__children + .separator__full-width {
  display: none; }

.tab-accordion__children .tab-accordion__panel {
  order: initial;
  padding-left: 30px;
  padding-top: 0; }

.tab-accordion__children .tab-accordion__chevron {
  display: inline-block; }

.tab-accordion__children .tab-accordion__button {
  align-items: center;
  border: 0;
  display: flex;
  justify-content: center;
  margin-top: 0;
  order: initial;
  padding: 30px 0;
  padding-left: 30px;
  text-align: left; }
  .tab-accordion__children .tab-accordion__button .tab-accordion__title {
    color: #839ca9; }
  .tab-accordion__children .tab-accordion__button .tab-accordion__title-wrapper {
    border: 0;
    padding: 0; }
    .tab-accordion__children .tab-accordion__button .tab-accordion__title-wrapper::after {
      display: none; }
  .tab-accordion__children .tab-accordion__button.tab-accordion__button--active {
    pointer-events: initial; }
    .tab-accordion__children .tab-accordion__button.tab-accordion__button--active .tab-accordion__title {
      color: #71bf44; }
    .tab-accordion__children .tab-accordion__button.tab-accordion__button--active::after {
      display: none; }
    .tab-accordion__children .tab-accordion__button.tab-accordion__button--active:last-of-type {
      margin-bottom: 0; }
  .tab-accordion__children .tab-accordion__button::before {
    background-color: rgba(131, 156, 169, 0.4);
    content: '';
    height: 1px;
    left: -24px;
    position: absolute;
    top: 0;
    width: calc(100% + 48px); }
  .tab-accordion__children .tab-accordion__button::after {
    left: -24px;
    width: calc(100% + 48px); }

@media only screen and (min-width: 600px) {
  .tab-column {
    border-right: 1px solid rgba(131, 156, 169, 0.4);
    padding-right: 30px; }
    .tab-column__wrapper {
      flex-direction: row; }
    .tab-column:first-child:nth-last-child(3) {
      border: none; }
    .tab-column:nth-last-child(2) {
      padding-left: 0; }
    .tab-column:first-child {
      padding-bottom: 30px; }
    .tab-column:last-child {
      padding-left: 30px;
      padding-right: 0; }
  .tab-accordion__subtitle {
    display: none; }
  .tab-accordion__children .tab-accordion__panel,
  .tab-accordion__children .tab-accordion__panel.fade-out-schrink {
    animation-duration: 1s;
    animation-name: fadeInExpand;
    display: inline-block;
    height: auto;
    opacity: 1;
    padding: 0; }
  .tab-accordion__children .tab-column {
    border-bottom: 0;
    margin-top: 30px;
    padding-top: 0; }
    .tab-accordion__children .tab-column::before {
      display: none; }
    .tab-accordion__children .tab-column:first-child {
      padding-bottom: 30px; }
    .tab-accordion__children .tab-column:last-child {
      padding-left: 30px;
      padding-right: 0; }
    .tab-accordion__children .tab-column:first-child:nth-last-child(3)::before {
      background-color: rgba(131, 156, 169, 0.4);
      bottom: 0;
      content: '';
      height: 1px;
      left: 0;
      position: absolute;
      width: calc(100vw + 500px); }
  .tab-accordion__children .tab-accordion__button {
    border: 0;
    display: flex;
    margin: 0;
    padding-left: 0;
    pointer-events: none;
    position: relative;
    width: 100%; }
    .tab-accordion__children .tab-accordion__button:first-of-type {
      border-top: 0;
      margin-top: 0; }
    .tab-accordion__children .tab-accordion__button:hover {
      border-top: 0; }
    .tab-accordion__children .tab-accordion__button.tab-accordion__button--active:last-of-type {
      margin-bottom: 0; }
    .tab-accordion__children .tab-accordion__button.tab-accordion__button--active::after {
      display: block; }
    .tab-accordion__children .tab-accordion__button::before {
      background-color: rgba(131, 156, 169, 0.4);
      top: 0;
      content: '';
      height: 1px;
      left: -250px;
      position: absolute;
      width: calc(100vw + 500px); }
    .tab-accordion__children .tab-accordion__button::after {
      background-color: rgba(131, 156, 169, 0.4);
      bottom: 0;
      content: '';
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      width: 100vw; }
    .tab-accordion__children .tab-accordion__button:not(:first-of-type):hover {
      border-bottom: 1px solid rgba(131, 156, 169, 0.4);
      border-top: 1px solid rgba(131, 156, 169, 0.4);
      color: #839ca9;
      cursor: default; }
  .tab-accordion__children .tab-accordion__title {
    margin: 0;
    max-width: auto;
    padding: 0; }
  .tab-accordion__children + .separator__full-width {
    display: inherit; }
  .tab-accordion__children .tab-accordion__title-wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column; }
  .tab-accordion__children .tab-accordion__subtitle {
    display: inline-block; } }

@media only screen and (max-width: 768px) {
  .tab-accordion__info-button {
    top: -20px;
    right: calc(50% - 15px); } }

@media only screen and (min-width: 768px) {
  .tab-accordion--three-tabs .tab-accordion__button {
    display: inline; }
    .tab-accordion--three-tabs .tab-accordion__button:nth-of-type(1) .tab-accordion__title-wrapper {
      float: right; }
    .tab-accordion--three-tabs .tab-accordion__button:nth-of-type(2) {
      flex-grow: 0; }
    .tab-accordion--three-tabs .tab-accordion__button:nth-of-type(3) .tab-accordion__title-wrapper {
      float: left; }
  .tab-accordion__button {
    display: flex; }
    .tab-accordion__button--active .tab-accordion__title-wrapper {
      background: #fff;
      border-left: 1px solid rgba(131, 156, 169, 0.4);
      border-right: 1px solid rgba(131, 156, 169, 0.4);
      position: relative; }
    .tab-accordion__button .tab-accordion__title-wrapper {
      margin: 0 auto; }
    .tab-accordion__button:first-of-type .tab-accordion__title-wrapper {
      margin-left: auto;
      margin-right: 0; }
    .tab-accordion__button:last-of-type .tab-accordion__title-wrapper {
      margin-left: 0;
      margin-right: auto; }
    .tab-accordion__button::after {
      display: none; }
  .tab-accordion__title {
    margin: 0 auto;
    max-width: 440px;
    padding: 0 10px; }
    .tab-accordion__title-wrapper {
      display: inline-block; }
      .tab-accordion__title-wrapper::after {
        bottom: initial;
        top: 0; }
  .tab-column {
    border-bottom: 0;
    padding: 0 30px;
    padding-bottom: 60px; }
    .tab-column:first-child {
      padding-left: 0; }
    .tab-column:last-child {
      border: 0;
      padding-right: 0; }
  .tab-accordion__header {
    padding-top: 30px; }
  .tab-accordion__children .tab-accordion__chevron {
    display: none; } }

@media only screen and (min-width: 992px) {
  .tab-column::before {
    display: none; }
  .tab-column:first-child:nth-last-child(3) {
    border-right: 1px solid rgba(131, 156, 169, 0.4); }
  .tab-column:first-child:nth-last-child(2) {
    padding-left: 30px; }
  .tab-column:nth-last-child(2) {
    padding-left: 30px; } }

.my-plot-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #839ca9;
  padding: 0.5rem 0;
  border-radius: 0.2rem;
  margin: 0.5rem 0;
  font-weight: bold;
  font-size: 1rem; }
  .my-plot-header__data {
    flex: 7;
    display: flex;
    flex-direction: row;
    align-items: center; }
    .my-plot-header__data__checkbox {
      width: 48px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .my-plot-header__data__checkbox--checked {
        background-color: rgba(131, 156, 169, 0.7);
        color: #fff;
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px; }
    .my-plot-header__data__name {
      flex: 3;
      padding-left: 0.5rem; }
    .my-plot-header__data__filter {
      flex: 3; }
      .my-plot-header__data__filter--element {
        min-width: 170px; }
    .my-plot-header__data__area {
      flex: 1;
      text-align: right; }
  .my-plot-header__actions {
    flex: 6;
    text-align: right; }
  .my-plot-header__mobile-actions {
    flex: 1;
    text-align: right;
    display: none; }

.my-plot-list {
  max-height: 45vh;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem; }
  .my-plot-list::-webkit-scrollbar {
    width: 0.2rem; }
  .my-plot-list::-webkit-scrollbar-thumb {
    background-color: #71bf44; }
  .my-plot-list .my-plot-cell__text-and-button {
    justify-content: space-between;
    padding: 0 1rem; }
    .my-plot-list .my-plot-cell__text-and-button button {
      text-transform: initial;
      border-radius: 15px;
      letter-spacing: 0px; }

@media only screen and (max-width: 992px) {
  .my-plot-list {
    max-height: none; } }

@media only screen and (max-width: 768px) {
  .my-plot-header__name {
    flex: 3; }
  .my-plot-header__area {
    flex: 1; }
  .my-plot-header__actions {
    display: none; }
  .my-plot-header__mobile-actions {
    display: block; } }

.plot-details {
  margin: 0.5rem; }
  .plot-details__title {
    color: #839ca9;
    font-weight: bold;
    font-size: 16px;
    padding: 1.2rem 0 1rem; }
  .plot-details__table--row {
    display: flex;
    flex-direction: row;
    align-content: center;
    margin-bottom: 1rem; }
    .plot-details__table--row--left {
      color: #839ca9;
      font-size: 16px;
      flex: 1;
      line-height: 2rem; }
    .plot-details__table--row--right {
      flex: 2;
      position: relative; }
      .plot-details__table--row--right .input-adornment-button {
        position: absolute;
        right: 0;
        top: 0; }

@media only screen and (max-width: 992px) {
  .plot-details__table--row {
    flex-direction: column; }
    .plot-details__table--row--left {
      display: none; } }

.google-map-header {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .google-map-header__title {
    display: inline-block;
    color: #839ca9;
    padding: 1rem 0;
    margin-right: 0.5rem;
    font-weight: bold;
    font-size: 1rem; }
  .google-map-header__info {
    position: relative;
    display: inline-block; }
    .google-map-header__info--info-button {
      position: relative;
      border: solid #839ca9 1px;
      cursor: pointer;
      border-radius: 50%;
      color: #839ca9;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      position: absolute;
      bottom: -9px;
      font-size: 0.8rem;
      width: 1rem;
      height: 1rem; }
    .google-map-header__info--info-text {
      color: #839ca9;
      font-size: 0.8rem; }
    .google-map-header__info--tool-tip-body {
      width: 200px; }

.google-map-container {
  height: 45vh;
  display: block;
  border-radius: 0.5rem; }

.google-map-container-my-applications {
  height: calc(100vh - 273px);
  display: block;
  border-radius: 0.5rem;
  position: relative; }
  .google-map-container-my-applications__map-button__save {
    position: absolute;
    z-index: 1;
    margin: 0.25rem 0 0 0.5rem; }
    .google-map-container-my-applications__map-button__save__inside {
      text-transform: initial;
      font-size: 1rem;
      padding: 1.25rem 1rem;
      color: #fff; }
  .google-map-container-my-applications__map-button__right-side {
    position: absolute;
    align-items: center;
    margin: 0 auto;
    right: 0;
    z-index: 1;
    display: flex;
    margin: 0.25rem 0.75rem 0 0; }
    .google-map-container-my-applications__map-button__right-side__move {
      margin-right: 0.25rem; }
      .google-map-container-my-applications__map-button__right-side__move__inside {
        text-transform: initial;
        font-size: 1rem; }
        .google-map-container-my-applications__map-button__right-side__move__inside .button-icon {
          padding-right: 0.5rem; }
    .google-map-container-my-applications__map-button__right-side__draw {
      margin-left: 0.25rem; }
      .google-map-container-my-applications__map-button__right-side__draw__inside {
        text-transform: initial;
        font-size: 1rem;
        justify-content: space-between; }
        .google-map-container-my-applications__map-button__right-side__draw__inside .button-icon {
          padding-right: 0.5rem; }
  .google-map-container-my-applications__vegetation-container {
    position: absolute;
    bottom: 0;
    padding: 1rem;
    border-top-right-radius: 1rem;
    background-color: rgba(255, 255, 255, 0.9);
    color: #839ca9;
    width: 300px; }
    .google-map-container-my-applications__vegetation-container--title {
      font-size: 1rem;
      margin-bottom: 0.4rem; }
    .google-map-container-my-applications__vegetation-container--gradient {
      width: 100%;
      height: 15px;
      background: linear-gradient(90deg, #b95700 20%, #eb6909 40%, #5f9501 60%, #79b61d 80%, #c8d200 100%); }
    .google-map-container-my-applications__vegetation-container--description {
      font-size: 0.8rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }

@media only screen and (max-width: 768px) {
  .google-map-container-my-applications__map-button__save {
    margin: 1.25rem 0 0 1rem; }
    .google-map-container-my-applications__map-button__save__inside {
      padding: 1.5rem;
      font-weight: bolder; }
  .google-map-container-my-applications__map-button__right-side {
    bottom: 0;
    left: 0;
    margin: 0 0 1.3rem 1.25rem; } }

.plot-xml-list {
  border-top: rgba(131, 156, 169, 0.2) solid 1px;
  margin-top: 2rem; }
  .plot-xml-list__top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #839ca9;
    font-size: 1rem; }
    .plot-xml-list__top--title {
      color: #839ca9;
      font-weight: bold;
      font-size: 1.2rem;
      padding: 1.2rem 0 1rem; }
    .plot-xml-list__top--select-all {
      display: none; }
      .plot-xml-list__top--select-all--checkbox {
        padding: 0.2rem 0;
        margin-left: 1rem; }
  .plot-xml-list__farmer-selector {
    max-width: 250px; }
  .plot-xml-list__table--header {
    color: #839ca9;
    font-weight: bold;
    font-size: 1rem;
    margin: 0.5rem;
    padding: 0.5rem;
    padding-left: 0;
    margin-left: 0;
    display: flex;
    flex-direction: row;
    align-content: center;
    line-height: 2rem; }
    .plot-xml-list__table--header--check {
      width: 1.5rem; }
      .plot-xml-list__table--header--check--checkbox {
        padding: 0.2rem 0; }
    .plot-xml-list__table--header--column {
      margin-left: 1rem; }
      .plot-xml-list__table--header--column:first-child {
        margin-left: 0; }
    .plot-xml-list__table--header--1 {
      flex: 1; }
      .plot-xml-list__table--header--1.numbers {
        text-align: center; }
    .plot-xml-list__table--header--2 {
      flex: 2; }
      .plot-xml-list__table--header--2.with-select {
        min-width: 200px; }
    .plot-xml-list__table--header--3 {
      flex: 3; }
    .plot-xml-list__table--header--4 {
      flex: 4; }
  .plot-xml-list__table--content--section-header {
    margin: 0.5rem 0 0.2rem 0;
    padding: 0.5rem;
    background-color: rgba(131, 156, 169, 0.2);
    width: 100%;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center; }
    .plot-xml-list__table--content--section-header--right {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      width: 100%; }
      .plot-xml-list__table--content--section-header--right--1 {
        flex: 1; }
        .plot-xml-list__table--content--section-header--right--1.numbers {
          text-align: center; }
      .plot-xml-list__table--content--section-header--right--2 {
        flex: 2; }
        .plot-xml-list__table--content--section-header--right--2.with-select {
          min-width: 200px; }
      .plot-xml-list__table--content--section-header--right--3 {
        flex: 3; }
      .plot-xml-list__table--content--section-header--right--4 {
        flex: 4; }
    .plot-xml-list__table--content--section-header--input {
      margin-left: 1rem;
      min-width: 240px; }
    .plot-xml-list__table--content--section-header--check {
      padding: 0.2rem 0; }
  .plot-xml-list__bottom {
    padding-top: 1rem;
    text-align: right; }
    .plot-xml-list__bottom__mobile {
      display: none;
      margin-bottom: 1rem; }
    .plot-xml-list__bottom:not(:first-child) {
      margin-left: 1rem; }
    .plot-xml-list__bottom--button {
      text-transform: initial;
      border-radius: 15px; }
      .plot-xml-list__bottom--button--delete {
        background-color: rgba(131, 156, 169, 0.2);
        border-radius: 15px;
        text-transform: initial;
        margin-left: 1rem; }
        .plot-xml-list__bottom--button--delete.active {
          background-color: #eb6909; }

@media only screen and (max-width: 1600px) {
  .plot-xml-list {
    padding: 2rem 0 2rem 0; }
    .plot-xml-list__top--select-all {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: right; }
    .plot-xml-list__table--header {
      display: none; }
    .plot-xml-list__table--content {
      max-height: none; }
    .plot-xml-list__bottom {
      display: none; }
      .plot-xml-list__bottom__mobile {
        display: flex;
        flex-direction: row;
        justify-content: center; } }

@media only screen and (max-width: 1280px) {
  .plot-xml-list__table--content--section-header--right {
    flex-direction: row; } }

@media only screen and (max-width: 992px) {
  .plot-xml-list__table--content--section-header--right {
    flex-direction: column; } }

.my-crop-rotation-filters__search-field {
  width: 100%;
  margin-bottom: 1rem; }

.my-crop-rotation-filters__filters {
  width: 100%;
  justify-content: left; }
  .my-crop-rotation-filters__filters--top-text {
    color: #839ca9;
    font-size: 0.8rem; }
  .my-crop-rotation-filters__filters--list {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .my-crop-rotation-filters__filters--list--text {
      color: #839ca9;
      font-size: 1rem;
      font-weight: bold;
      margin-right: 0.5rem; }
      .my-crop-rotation-filters__filters--list--text:not(:first-child) {
        margin-left: 0.5rem; }
      .my-crop-rotation-filters__filters--list--text.mark {
        margin: 0 1rem 0 -1rem;
        padding-top: 1rem; }
      .my-crop-rotation-filters__filters--list--text.category {
        margin-left: 1rem; }
    .my-crop-rotation-filters__filters--list--select {
      min-width: 11.3rem;
      margin: 0 2rem 0 0; }
      .my-crop-rotation-filters__filters--list--select.period {
        min-width: 6rem; }

@media only screen and (max-width: 992px) {
  .my-crop-rotation-filters__filters--top-text {
    display: none; }
  .my-crop-rotation-filters__filters--list {
    flex-direction: column; }
    .my-crop-rotation-filters__filters--list--text {
      display: none; }
    .my-crop-rotation-filters__filters--list--select {
      width: 100%;
      margin-top: 1rem;
      margin-right: 0; } }

@media only screen and (max-width: 1280px) {
  .my-crop-rotation-filters__search-field {
    margin-top: 0.5rem; } }

.my-crop-rotations-table {
  width: 100%;
  margin-top: 1rem; }
  .my-crop-rotations-table__header {
    color: #839ca9;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    flex-direction: row; }
    .my-crop-rotations-table__header--column--1 {
      flex: 1; }
    .my-crop-rotations-table__header--column--2 {
      flex: 2; }
      .my-crop-rotations-table__header--column--2 .unit-display {
        font-size: 0.9rem; }
    .my-crop-rotations-table__header--column--3 {
      flex: 3; }

@media only screen and (max-width: 992px) {
  .my-crop-rotations-table__header {
    display: none; } }

.my-crop-rotations-table {
  width: 100%;
  margin-top: 1rem; }
  .my-crop-rotations-table__header {
    color: #839ca9;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    flex-direction: row; }
    .my-crop-rotations-table__header--column--1 {
      flex: 1; }
    .my-crop-rotations-table__header--column--2 {
      flex: 2; }
      .my-crop-rotations-table__header--column--2 .unit-display {
        font-size: 0.9rem; }
    .my-crop-rotations-table__header--column--3 {
      flex: 3; }

@media only screen and (max-width: 992px) {
  .my-crop-rotations-table__header {
    display: none; } }

.my-crop-rotations-list__section-header {
  font-size: 0.8rem;
  color: #71bf44;
  margin: 0.5rem 0; }

@media only screen and (max-width: 992px) {
  .my-crop-rotations-list {
    max-height: none; } }

.box-with-title {
  border: 1px solid #839ca9;
  padding: 1rem 0.5rem;
  border-radius: 0.2rem;
  height: 100%; }

.box-with-title-mobile {
  border: solid 1px #839ca9;
  box-shadow: none !important;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  width: 100%; }
  .box-with-title-mobile.expanded {
    border: solid 1px #71bf44; }
  .box-with-title-mobile:first-child, .box-with-title-mobile:last-child {
    border-radius: 0.5rem; }

.calculation-results {
  margin-top: 1rem; }
  .calculation-results__title {
    color: #71bf44;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem; }
    .calculation-results__title--icon {
      height: 70px;
      min-width: 70px;
      margin-bottom: 0.5rem; }
  .calculation-results .field-info {
    width: 100%; }
    .calculation-results .field-info__element {
      display: flex;
      flex-direction: row;
      color: #839ca9;
      margin-top: 0.5rem; }
      .calculation-results .field-info__element--key {
        flex: 1; }
      .calculation-results .field-info__element--value {
        flex: 1;
        font-weight: bold; }
  .calculation-results .product-quality {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1rem; }
    .calculation-results .product-quality__element {
      color: #839ca9;
      padding: 0.5rem 0.5rem; }
      .calculation-results .product-quality__element:not(:nth-child(3n)) {
        border-right: 1px solid #839ca9; }
  .calculation-results .info-message {
    color: #71bf44;
    padding: 0.5rem 0; }
  .calculation-results .action-bar {
    width: 100%;
    display: block;
    text-align: center;
    margin: 1rem 0; }
    .calculation-results .action-bar__print {
      border-radius: 1rem;
      text-transform: none; }
      .calculation-results .action-bar__print--icon {
        margin-right: 1rem; }

@media only screen and (max-width: 959px) {
  .calculation-results__title {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0rem; }
    .calculation-results__title--icon {
      margin-right: 1rem; } }

@media only screen and (max-width: 600px) {
  .calculation-results .product-quality {
    grid-template-columns: repeat(2, 1fr); }
    .calculation-results .product-quality__element:not(:nth-child(3n)) {
      border-right: none; }
    .calculation-results .product-quality__element:not(:nth-child(2n)) {
      border-right: 1px solid #839ca9; } }

@media only screen and (max-width: 426px) {
  .calculation-results .product-quality {
    grid-template-columns: 1fr; }
    .calculation-results .product-quality__element:not(:nth-child(2n)) {
      border-right: none; } }

.crop-rotation-calendar-placeholder {
  margin: 1rem 0;
  height: 3rem; }

.crop-rotation-calendar {
  margin: 1rem 0; }
  .crop-rotation-calendar__plans-row {
    display: flex;
    flex-direction: row;
    border-bottom: 1px dashed #839ca9;
    font-weight: bold;
    color: #fff; }
    .crop-rotation-calendar__plans-row--plan {
      background-color: #839ca9;
      padding: 0.5rem;
      margin-left: 0.3rem;
      text-align: center; }
      .crop-rotation-calendar__plans-row--plan.active {
        background-color: #71bf44; }
    .crop-rotation-calendar__plans-row--cover {
      background: repeating-linear-gradient(135deg, rgba(131, 156, 169, 0.7), rgba(131, 156, 169, 0.7) 1px, #839ca9 2px, #839ca9 8px);
      padding: 0.5rem;
      margin-left: 0.3rem;
      text-align: center; }
  .crop-rotation-calendar__years-row {
    display: flex;
    flex-direction: row;
    padding: 0.5rem 0; }
    .crop-rotation-calendar__years-row--year {
      color: #839ca9;
      text-align: center;
      flex: 2; }
      .crop-rotation-calendar__years-row--year--gap {
        flex: 10; }

.crop-rotation-calendar-mobile {
  margin: 1rem 0; }
  .crop-rotation-calendar-mobile__title {
    color: #71bf44;
    padding: 0.5rem 0; }
  .crop-rotation-calendar-mobile__plans-carousel {
    color: #fff;
    text-align: left; }
    .crop-rotation-calendar-mobile__plans-carousel--plan {
      background-color: #839ca9;
      padding: 0.5rem;
      padding: 1rem; }
      .crop-rotation-calendar-mobile__plans-carousel--plan.active {
        background-color: #71bf44; }
      .crop-rotation-calendar-mobile__plans-carousel--plan--title {
        font-weight: bold;
        margin-bottom: 0.5rem; }
      .crop-rotation-calendar-mobile__plans-carousel--plan.mop {
        height: 100%; }
    .crop-rotation-calendar-mobile__plans-carousel--cover {
      background: repeating-linear-gradient(135deg, rgba(131, 156, 169, 0.7), rgba(131, 156, 169, 0.7) 1px, #839ca9 2px, #839ca9 8px);
      padding: 0.5rem;
      font-weight: bold;
      padding: 1rem;
      height: 100%; }

.crop-rotation-plan-list__element {
  border: 1px #839ca9 solid;
  padding: 1rem 0.5rem;
  border-radius: 0.2rem;
  height: 100%;
  transition: border 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .crop-rotation-plan-list__element.mop {
    background-color: rgba(113, 191, 68, 0.5);
    border-color: rgba(113, 191, 68, 0.5); }
  .crop-rotation-plan-list__element:hover {
    border-color: #71bf44; }
  .crop-rotation-plan-list__element--title-area {
    display: flex;
    flex-direction: row; }
    .crop-rotation-plan-list__element--title-area--icon {
      flex: 1;
      width: 60px;
      height: auto;
      margin-right: 1rem; }
      .crop-rotation-plan-list__element--title-area--icon .icon-element {
        width: 100%;
        max-height: 100px; }
    .crop-rotation-plan-list__element--title-area--title {
      flex: 3;
      color: #839ca9;
      font-weight: bold;
      font-size: 1.2rem;
      align-self: center;
      word-break: break-all; }
      .crop-rotation-plan-list__element--title-area--title.mop {
        align-self: flex-start;
        margin-top: 1rem;
        border-bottom: 1px solid #fff;
        padding-bottom: 1rem;
        font-weight: bold;
        display: flex;
        flex: 0;
        flex-direction: row; }
        .crop-rotation-plan-list__element--title-area--title.mop__icon {
          color: #fff;
          flex: 1;
          font-size: 3.5rem;
          position: relative;
          text-align: right;
          padding-right: 1rem; }
          .crop-rotation-plan-list__element--title-area--title.mop__icon--plus {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 2rem;
            color: #fff; }
        .crop-rotation-plan-list__element--title-area--title.mop__title {
          color: #fff;
          flex: 4;
          word-break: keep-all;
          padding-left: 1rem; }
    .crop-rotation-plan-list__element--title-area--details {
      flex: 1;
      color: #71bf44;
      height: 100%;
      text-align: right; }
  .crop-rotation-plan-list__element--duration-area {
    padding: 1rem 0;
    border-bottom: 1px solid #839ca9;
    color: #839ca9;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center; }
    .crop-rotation-plan-list__element--duration-area.mop {
      border-bottom: none;
      flex-direction: column;
      color: #fff; }
    .crop-rotation-plan-list__element--duration-area--label {
      margin-right: 0.5rem;
      line-height: 2rem; }
    .crop-rotation-plan-list__element--duration-area--value {
      font-weight: bold;
      display: flex;
      flex-direction: row;
      align-items: center; }
      .crop-rotation-plan-list__element--duration-area--value .picker:first-child {
        margin-right: 0.5rem; }
      .crop-rotation-plan-list__element--duration-area--value .picker:last-child {
        margin-left: 0.5rem; }
  .crop-rotation-plan-list__element--products-area .product-element {
    color: #839ca9;
    padding: 0.5rem 0; }
  .crop-rotation-plan-list__element--tooltip-body {
    width: 200%; }
  .crop-rotation-plan-list__element--bottom-area {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .crop-rotation-plan-list__element--bottom-area--change-time {
      color: #fff;
      border: none;
      font-size: 1rem;
      padding: 0;
      background-color: transparent; }
      .crop-rotation-plan-list__element--bottom-area--change-time:focus {
        outline: none; }
    .crop-rotation-plan-list__element--bottom-area--recommendation {
      color: #71bf44;
      border: none;
      font-size: 1rem;
      padding: 0;
      background-color: transparent; }
      .crop-rotation-plan-list__element--bottom-area--recommendation:focus {
        outline: none; }
    .crop-rotation-plan-list__element--bottom-area--delete {
      color: #eb6909;
      border: none;
      font-size: 1rem;
      padding: 0;
      background-color: transparent; }
      .crop-rotation-plan-list__element--bottom-area--delete:focus {
        outline: none; }
    .crop-rotation-plan-list__element--bottom-area .action-bar {
      width: 100%;
      text-align: center;
      margin: 1rem 0; }
      .crop-rotation-plan-list__element--bottom-area .action-bar__print {
        border-radius: 1rem;
        text-transform: none; }
        .crop-rotation-plan-list__element--bottom-area .action-bar__print--icon {
          margin-right: 1rem; }
  .crop-rotation-plan-list__element.add-new-plan {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    .crop-rotation-plan-list__element.add-new-plan .title {
      color: #839ca9;
      margin-top: 0.5rem; }

.crop-rotation-plan-list-mobile__mop {
  border: 1px #839ca9 solid;
  padding: 1rem 0.5rem;
  border-radius: 0.5rem;
  color: #839ca9;
  font-size: 1.2rem; }
  .crop-rotation-plan-list-mobile__mop--title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem; }
  .crop-rotation-plan-list-mobile__mop--action {
    width: 100%;
    text-align: center;
    padding-top: 2rem; }

.crop-rotation-plan-list-mobile__cover {
  border: 1px #839ca9 solid;
  padding: 1rem 0.5rem;
  border-radius: 0.5rem;
  color: #839ca9;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between; }

.crop-rotation-plan-list-mobile__element {
  width: 100%; }
  .crop-rotation-plan-list-mobile__element--title-area {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .crop-rotation-plan-list-mobile__element--title-area--icon {
      margin-right: 1rem; }
      .crop-rotation-plan-list-mobile__element--title-area--icon .icon-element {
        max-height: 80px; }
    .crop-rotation-plan-list-mobile__element--title-area--title {
      color: #839ca9;
      font-weight: bold;
      font-size: 1.2rem; }
      .crop-rotation-plan-list-mobile__element--title-area--title a {
        text-decoration: none; }
  .crop-rotation-plan-list-mobile__element--duration-area {
    padding-bottom: 1rem;
    color: #839ca9;
    font-weight: bold;
    width: 100%; }
    .crop-rotation-plan-list-mobile__element--duration-area--label {
      border-bottom: 1px solid #71bf44;
      padding: 0.5rem 0; }
    .crop-rotation-plan-list-mobile__element--duration-area--value {
      display: flex;
      flex-direction: row;
      align-items: center; }
      .crop-rotation-plan-list-mobile__element--duration-area--value .picker:first-child {
        margin-right: 0.5rem; }
      .crop-rotation-plan-list-mobile__element--duration-area--value .picker:last-child {
        margin-left: 0.5rem; }
  .crop-rotation-plan-list-mobile__element--shopping-list {
    padding: 1rem 0 0.5rem 0;
    display: flex;
    justify-content: center; }
    .crop-rotation-plan-list-mobile__element--shopping-list .print-button {
      border-radius: 1rem;
      text-transform: none;
      font-weight: bold;
      padding: 0.5rem 0.8rem;
      min-width: 150px; }
  .crop-rotation-plan-list-mobile__element--delete {
    padding-bottom: 1rem;
    display: flex;
    justify-content: center; }
    .crop-rotation-plan-list-mobile__element--delete .button {
      color: #fff;
      background-color: #eb6909;
      font-weight: bold;
      padding: 0.5rem 0.8rem;
      border-radius: 1rem;
      text-transform: none;
      min-width: 150px; }
  .crop-rotation-plan-list-mobile__element--add-new-plan {
    width: 100%; }

.my-plans-table {
  width: 100%;
  margin-top: 1rem; }
  .my-plans-table__header-desktop {
    color: #839ca9;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center; }
    .my-plans-table__header-desktop--column--1 {
      flex: 1; }
    .my-plans-table__header-desktop--column--2 {
      flex: 2; }
    .my-plans-table__header-desktop--column--3 {
      flex: 3; }
    .my-plans-table__header-desktop .button-holder {
      text-align: right; }
      .my-plans-table__header-desktop .button-holder__button {
        border-radius: 1rem;
        text-transform: none; }
  .my-plans-table__header-mobile {
    display: none; }

@media only screen and (max-width: 992px) {
  .my-plans-table__header-desktop {
    display: none; }
  .my-plans-table__header-mobile {
    color: #839ca9;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem; } }

.my-plans-list__section-header {
  font-size: 0.8rem;
  color: #71bf44;
  margin: 0.5rem 0; }

@media only screen and (max-width: 992px) {
  .my-plans-list {
    max-height: none; } }

.my-plans-calendar {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 1rem 0;
  align-items: flex-end; }
  .my-plans-calendar__element {
    flex: 1; }
    .my-plans-calendar__element--container {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .my-plans-calendar__element--container--plan-element {
        background-color: #839ca9;
        width: 80%;
        height: 0.5rem;
        margin-top: 0.2rem;
        border-radius: 0.2rem; }
    .my-plans-calendar__element--interval {
      border-top: dashed 1px #839ca9;
      color: #839ca9;
      font-size: 0.8rem;
      text-align: center;
      padding-top: 0.2rem; }

.multi-radio-option-selector {
  margin: 1rem 5% 0 5%;
  display: flex;
  flex-direction: row; }
  .multi-radio-option-selector__section {
    flex: 1; }
    .multi-radio-option-selector__section:not(:first-child) {
      margin-left: 1rem; }
    .multi-radio-option-selector__section--title {
      color: #839ca9;
      margin: 1rem 0 0.5rem 0; }

@media only screen and (max-width: 992px) {
  .multi-radio-option-selector {
    margin: 1rem 5% 0 5%;
    flex-direction: column; }
    .multi-radio-option-selector__section:not(:first-child) {
      margin-left: 0; } }

.plan-selection-popup-body {
  border-top: solid 1px rgba(131, 156, 169, 0.4);
  border-bottom: solid 1px rgba(131, 156, 169, 0.4);
  width: 100%;
  margin-top: 1rem;
  padding: 0.5rem 0;
  position: relative;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto; }
  .plan-selection-popup-body__message {
    width: 100%;
    color: #839ca9;
    text-align: center;
    margin: 1rem 0; }
  .plan-selection-popup-body__plan {
    cursor: pointer;
    padding: 0.5rem;
    border: 1px rgba(131, 156, 169, 0.7) solid;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: 1rem; }
    .plan-selection-popup-body__plan:focus {
      outline: none;
      border-color: rgba(113, 191, 68, 0.5); }
    .plan-selection-popup-body__plan.selected {
      border: solid 2px #71bf44; }
    .plan-selection-popup-body__plan--icon {
      flex: 1; }
      .plan-selection-popup-body__plan--icon .crop-icon {
        height: 35px;
        width: 35px; }
    .plan-selection-popup-body__plan--data {
      flex: 4;
      margin-left: 0.5rem; }
      .plan-selection-popup-body__plan--data--element {
        display: flex;
        flex-direction: row;
        justify-content: space-between; }
        .plan-selection-popup-body__plan--data--element:first-child {
          color: #71bf44; }
        .plan-selection-popup-body__plan--data--element:last-child {
          color: #839ca9; }

.fertilizer-needs-filter {
  padding: 0rem 5% 2rem 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .fertilizer-needs-filter__select {
    min-width: 140px; }
    .fertilizer-needs-filter__select.period-selector {
      display: flex;
      align-items: flex-end; }
    .fertilizer-needs-filter__select.print-button {
      border-radius: 2rem;
      max-width: 230px; }

@media only screen and (max-width: 991px) {
  .fertilizer-needs-filter {
    flex-direction: column; }
    .fertilizer-needs-filter__select {
      text-align: center;
      padding: 0.5rem 0; }
      .fertilizer-needs-filter__select.print-button {
        margin: 0 auto;
        padding-top: 3rem;
        text-align: center; }
      .fertilizer-needs-filter__select.check-box {
        padding-top: 3rem;
        display: flex; } }

.fertilizer-needs-list {
  padding: 0rem 5% 2rem 5%; }
  .fertilizer-needs-list__no-elements {
    text-align: center;
    color: #839ca9;
    font-weight: bold;
    padding-top: 3rem; }
  .fertilizer-needs-list__header {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    color: #839ca9; }
    .fertilizer-needs-list__header--name {
      flex: 2; }
    .fertilizer-needs-list__header--quantity {
      flex: 1; }
  .fertilizer-needs-list__table--element {
    display: flex;
    flex-direction: row;
    background-color: rgba(131, 156, 169, 0.05);
    padding: 0.5rem;
    margin: 0.5rem 0; }
    .fertilizer-needs-list__table--element--name {
      flex: 2;
      color: #839ca9; }
    .fertilizer-needs-list__table--element--quantity {
      flex: 1;
      color: #71bf44; }

.my-application-needs-list {
  padding: 0rem 5% 2rem 5%;
  width: 100%; }
  .my-application-needs-list .col-1 {
    flex: 1;
    padding-left: 5px; }
  .my-application-needs-list .col-2 {
    flex: 2;
    padding-left: 5px; }
  .my-application-needs-list .col-3 {
    flex: 3;
    padding-left: 5px; }
  .my-application-needs-list__header {
    display: flex;
    flex-direction: row;
    color: #839ca9; }
    .my-application-needs-list__header--column .header-top {
      font-weight: bold; }
    .my-application-needs-list__header--column .header-bottom {
      display: flex;
      flex-direction: row; }
  .my-application-needs-list__content--no-content {
    text-align: center;
    color: #839ca9;
    font-weight: bold;
    margin-top: 3rem; }
  .my-application-needs-list__content--month-section {
    color: #71bf44;
    font-size: 0.9rem;
    margin-top: 0.5rem; }

@media only screen and (max-width: 1600px) {
  .my-application-needs-list__header {
    display: none; } }

.soil-data-form {
  margin-top: 2rem;
  width: 100%; }
  .soil-data-form__box {
    margin: 1rem 0.5rem; }
    .soil-data-form__box--title {
      display: inline; }

.organic-fertilization {
  max-width: 500px;
  margin: 0 auto; }
  .organic-fertilization__title {
    width: 100%;
    color: #839ca9;
    font-weight: bold;
    background-color: rgba(113, 191, 68, 0.2);
    border-radius: 0.2rem;
    padding: 0.2rem 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.2rem;
    margin-bottom: 30px; }

.application-map-options {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 10px 0px 6px -6px rgba(0, 0, 0, 0.2); }
  .application-map-options__group {
    flex: 1;
    position: relative; }
    .application-map-options__group--disabled {
      background-color: rgba(255, 255, 255, 0.7);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #839ca9;
      font-size: 1.2rem;
      font-weight: bold;
      padding: 2rem;
      text-align: center; }
    .application-map-options__group .map-option-group-header {
      background-color: rgba(113, 191, 68, 0.07);
      text-align: center;
      padding: 1rem;
      color: #839ca9;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem; }
      .application-map-options__group .map-option-group-header.disabled {
        background-color: rgba(131, 156, 169, 0.4);
        color: #fff; }
      .application-map-options__group .map-option-group-header__title {
        font-size: 1.4rem;
        font-weight: bold; }
      .application-map-options__group .map-option-group-header__description {
        font-size: 0.8rem; }
      .application-map-options__group .map-option-group-header__info-button {
        border-radius: 50%;
        border: solid #839ca9 1px;
        color: #839ca9; }
      .application-map-options__group .map-option-group-header__info-tooltip-container {
        width: 2px;
        text-align: center; }
        .application-map-options__group .map-option-group-header__info-tooltip-container--info-button {
          border: solid #839ca9 1px;
          cursor: pointer;
          color: #839ca9;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          padding: 0.5rem;
          font-size: 0.8rem;
          width: 1rem;
          height: 1rem;
          border-radius: 50%; }
        .application-map-options__group .map-option-group-header__info-tooltip-container--info-body {
          right: 30px;
          text-align: left; }
    .application-map-options__group .map-options-zoneing-mode-selector {
      background-color: rgba(113, 191, 68, 0.07);
      border-top: 1px solid #71bf44;
      padding: 0.5rem 1.5rem;
      position: relative; }
    .application-map-options__group .map-options-zones-selector {
      background-color: rgba(113, 191, 68, 0.07);
      border-top: 1px solid #71bf44;
      padding: 1rem 0; }
      .application-map-options__group .map-options-zones-selector__description {
        font-size: 0.8rem;
        text-align: center;
        color: #839ca9;
        padding: 1rem 0; }
      .application-map-options__group .map-options-zones-selector__box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 1rem; }
        .application-map-options__group .map-options-zones-selector__box .element {
          position: relative;
          cursor: pointer;
          padding: 1rem;
          font-size: 1.4rem;
          font-weight: bold;
          color: #839ca9;
          background-color: #fff;
          border: 1px solid #839ca9;
          display: flex;
          justify-content: center;
          align-items: center; }
          .application-map-options__group .map-options-zones-selector__box .element__disabled {
            position: absolute;
            background-color: rgba(255, 255, 255, 0.7);
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 500;
            cursor: default; }
          .application-map-options__group .map-options-zones-selector__box .element.selected {
            color: #fff;
            background-color: #71bf44;
            border: #71bf44; }
    .application-map-options__group .map-option-group-container {
      padding: 1rem; }
      .application-map-options__group .map-option-group-container__element {
        color: #839ca9;
        margin: 1rem 0; }
        .application-map-options__group .map-option-group-container__element.green {
          color: #71bf44; }
        .application-map-options__group .map-option-group-container__element .description-text {
          font-size: 0.75rem; }
        .application-map-options__group .map-option-group-container__element .data-text {
          font-size: 1.2rem;
          font-weight: bold; }
          .application-map-options__group .map-option-group-container__element .data-text .product-name {
            color: #839ca9;
            font-size: 1rem;
            font-weight: normal; }
    .application-map-options__group .map-option-group-zones-header {
      display: flex;
      flex-direction: row;
      border-top: 1px solid #71bf44; }
      .application-map-options__group .map-option-group-zones-header__element--left {
        background-color: rgba(113, 191, 68, 0.5);
        width: 55px; }
        .application-map-options__group .map-option-group-zones-header__element--left.isPresenting {
          width: 150px; }
      .application-map-options__group .map-option-group-zones-header__element--rigth {
        background-color: rgba(113, 191, 68, 0.07);
        padding: 1rem;
        flex: 1;
        display: flex;
        flex-direction: row;
        gap: 0.8rem;
        color: #839ca9; }
        .application-map-options__group .map-option-group-zones-header__element--rigth .header-description {
          flex: 1;
          text-align: center;
          font-size: 0.8rem; }
          .application-map-options__group .map-option-group-zones-header__element--rigth .header-description__top {
            font-weight: bold; }
    .application-map-options__group .map-options-humidity-selector {
      background-color: rgba(113, 191, 68, 0.07);
      padding: 0.5rem 2rem;
      color: #839ca9; }
      .application-map-options__group .map-options-humidity-selector .description-text {
        font-size: 0.75rem;
        color: #839ca9; }
      .application-map-options__group .map-options-humidity-selector .humidity-selector-component--undefined {
        background-color: #fff;
        padding: 0.2rem;
        border: solid 1px rgba(113, 191, 68, 0.5);
        border-radius: 4px; }
      .application-map-options__group .map-options-humidity-selector .humidity-selector-component--defined {
        background-color: unset;
        padding: 0.2rem;
        border: solid 1px transparent; }
    .application-map-options__group .map-option-group-zones__element {
      display: flex;
      flex-direction: row;
      position: relative; }
      .application-map-options__group .map-option-group-zones__element--left {
        display: flex;
        font-size: 1.2rem;
        font-weight: bold;
        color: #fff;
        padding: 1rem;
        width: 55px;
        min-height: 71px; }
        .application-map-options__group .map-option-group-zones__element--left.isPresenting {
          width: 150px; }
        .application-map-options__group .map-option-group-zones__element--left .zone-index {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center; }
        .application-map-options__group .map-option-group-zones__element--left .zone-details {
          flex: 2;
          font-size: 0.7rem; }
          .application-map-options__group .map-option-group-zones__element--left .zone-details__item {
            display: flex;
            flex-direction: row;
            gap: 0.5rem; }
            .application-map-options__group .map-option-group-zones__element--left .zone-details__item--left {
              flex: 1;
              text-align: right; }
            .application-map-options__group .map-option-group-zones__element--left .zone-details__item--rigth {
              flex: 1;
              text-align: left; }
        .application-map-options__group .map-option-group-zones__element--left.zone-color-0 {
          background-color: #b95700; }
        .application-map-options__group .map-option-group-zones__element--left.zone-color-1 {
          background-color: #eb6909; }
        .application-map-options__group .map-option-group-zones__element--left.zone-color-2 {
          background-color: #c8d200; }
        .application-map-options__group .map-option-group-zones__element--left.zone-color-3 {
          background-color: #79b61d; }
        .application-map-options__group .map-option-group-zones__element--left.zone-color-4 {
          background-color: #5f9501; }
      .application-map-options__group .map-option-group-zones__element--right {
        padding: 1rem;
        flex: 1;
        border-bottom: 1px solid rgba(131, 156, 169, 0.4);
        display: flex;
        flex-direction: row;
        gap: 0.8rem;
        justify-content: space-between; }
    .application-map-options__group .map-option-group-footer {
      padding: 0.5rem 1.5rem; }
      .application-map-options__group .map-option-group-footer__title {
        text-align: center;
        color: #839ca9;
        font-weight: bold;
        font-size: 1.4rem;
        padding-bottom: 0.5rem;
        padding-top: 1rem; }
      .application-map-options__group .map-option-group-footer__description {
        text-align: center;
        color: #839ca9;
        font-size: 0.8rem;
        padding-bottom: 0.5rem; }
      .application-map-options__group .map-option-group-footer .map-option-group-footer__button {
        text-transform: none; }

@media only screen and (max-width: 1280px) {
  .application-map-options {
    display: block; }
    .application-map-options__group .map-option-group-header {
      background: none;
      color: #71bf44; }
      .application-map-options__group .map-option-group-header__description {
        display: none; } }

.plot-drawing-alert-dialogue-content {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .plot-drawing-alert-dialogue-content__title {
    color: #71bf44;
    font-weight: bold;
    font-size: 1rem;
    margin: 1rem 0 0.5rem 0; }
  .plot-drawing-alert-dialogue-content__description {
    color: #839ca9;
    font-size: 0.8rem;
    margin-bottom: 1rem; }
  .plot-drawing-alert-dialogue-content__image-description {
    color: #839ca9;
    font-size: 0.8rem;
    margin-bottom: 0.5rem; }

.plot-drawing-alert-dialogue-actions {
  justify-content: center;
  padding: 1rem; }

.my-application-maps-table__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #839ca9;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem; }
  .my-application-maps-table__header--1 {
    flex: 1; }
  .my-application-maps-table__header--2 {
    flex: 2; }
  .my-application-maps-table__header--3 {
    flex: 3; }

.steps-container-component {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.14); }
  .steps-container-component__steps-box {
    font-weight: bold;
    padding: 1rem;
    font-size: 0.9rem;
    background-color: rgba(113, 191, 68, 0.07);
    color: #839ca9; }

.steps-container-component-mobile {
  max-width: 320px;
  margin: 0 auto; }
  .steps-container-component-mobile__top {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 0.5rem 0.5rem; }
    .steps-container-component-mobile__top .steps-box {
      font-weight: bold;
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
      color: #839ca9; }
  .steps-container-component-mobile__bottom {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    padding: 0.5rem 1rem; }
    .steps-container-component-mobile__bottom .step-mark {
      background-color: rgba(131, 156, 169, 0.2);
      width: 100px;
      height: 10px;
      border-radius: 5px; }
      .steps-container-component-mobile__bottom .step-mark.completed {
        background-color: #839ca9; }

.download-nz-alert-dialogue-content {
  max-width: 1050px;
  margin: 0 auto; }
  .download-nz-alert-dialogue-content__title {
    color: #71bf44;
    font-weight: bold;
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 1rem; }
  .download-nz-alert-dialogue-content__description {
    color: #839ca9;
    text-align: center;
    margin-bottom: 1rem; }
  .download-nz-alert-dialogue-content__main-content {
    position: relative;
    width: 100%; }
    .download-nz-alert-dialogue-content__main-content .bottom-layer {
      position: absolute;
      z-index: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%; }
      .download-nz-alert-dialogue-content__main-content .bottom-layer__green-background {
        height: 50%;
        width: 100%;
        border-radius: 10px;
        background-color: rgba(113, 191, 68, 0.12); }
    .download-nz-alert-dialogue-content__main-content .top-layer {
      z-index: 1100;
      position: relative;
      display: grid;
      grid-auto-columns: 1fr;
      grid-auto-rows: 1fr;
      column-gap: 2rem;
      row-gap: 1rem;
      width: 100%;
      height: 100%;
      max-height: 600px;
      grid-template-rows: repeat(6, 1fr);
      grid-template-areas: 'text1 text1 image1 image1 image1 image2 image2 image2 text2 text2' 'text1 text1 image1 image1 image1 image2 image2 image2 text2 text2' 'text1 text1 image1 image1 image1 image2 image2 image2 text2 text2' 'button1 button1 image1 image1 image1 image2 image2 image2 button2 button2' 'qr1 qr1 image1 image1 image1 image2 image2 image2 qr2 qr2' 'qr1 qr1 image1 image1 image1 image2 image2 image2 qr2 qr2'; }
      .download-nz-alert-dialogue-content__main-content .top-layer .store-button {
        width: 100%;
        cursor: pointer;
        border: none;
        background-color: transparent;
        padding: 0; }
        .download-nz-alert-dialogue-content__main-content .top-layer .store-button__image {
          width: 100%;
          height: auto; }
      .download-nz-alert-dialogue-content__main-content .top-layer .mobile-image {
        width: 100%;
        height: 100%; }
      .download-nz-alert-dialogue-content__main-content .top-layer .qr-image {
        width: 100%;
        height: auto; }
      .download-nz-alert-dialogue-content__main-content .top-layer__text-1 {
        grid-area: text1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        color: #839ca9;
        text-align: right; }
      .download-nz-alert-dialogue-content__main-content .top-layer__text-2 {
        grid-area: text2;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        color: #839ca9; }
      .download-nz-alert-dialogue-content__main-content .top-layer__image-1 {
        grid-area: image1;
        padding-top: 2rem; }
      .download-nz-alert-dialogue-content__main-content .top-layer__image-2 {
        grid-area: image2;
        padding-top: 2rem; }
      .download-nz-alert-dialogue-content__main-content .top-layer__qr-1 {
        grid-area: qr1;
        display: flex;
        align-items: flex-end; }
      .download-nz-alert-dialogue-content__main-content .top-layer__qr-2 {
        grid-area: qr2;
        display: flex;
        align-items: flex-end; }
      .download-nz-alert-dialogue-content__main-content .top-layer__button-1 {
        grid-area: button1;
        display: flex;
        align-items: center;
        justify-content: flex-start; }
      .download-nz-alert-dialogue-content__main-content .top-layer__button-2 {
        grid-area: button2;
        display: flex;
        align-items: center;
        justify-content: flex-end; }

@media only screen and (max-width: 768px) {
  .download-nz-alert-dialogue-content {
    max-width: 320px; }
    .download-nz-alert-dialogue-content__main-content {
      padding: 0 1.5rem; }
      .download-nz-alert-dialogue-content__main-content .bottom-layer {
        display: none; }
      .download-nz-alert-dialogue-content__main-content .top-layer {
        grid-template-rows: repeat(5, 1fr);
        column-gap: 1rem;
        grid-template-areas: 'image1 image2' 'image1 image2' 'image1 image2' 'button1 button1' 'button2 button2'; }
        .download-nz-alert-dialogue-content__main-content .top-layer__text-1 {
          display: none; }
        .download-nz-alert-dialogue-content__main-content .top-layer__text-2 {
          display: none; }
        .download-nz-alert-dialogue-content__main-content .top-layer__qr-1 {
          display: none; }
        .download-nz-alert-dialogue-content__main-content .top-layer__qr-2 {
          display: none; }
        .download-nz-alert-dialogue-content__main-content .top-layer__button-1 {
          justify-content: center; }
        .download-nz-alert-dialogue-content__main-content .top-layer__button-2 {
          justify-content: center; } }

.product_select__wrapper {
  margin-top: 10px;
  margin-right: 24px;
  margin-left: 24px; }
  .product_select__wrapper .filter_products__wrapper {
    display: none; }
  .product_select__wrapper .filter_button {
    margin-bottom: 5px !important;
    margin-top: 0px !important; }
  .product_select__wrapper .accordion__card--single-section {
    max-width: 100%; }

.product_select__section__wrapper {
  position: relative; }

.product_select__section__product_wrapper {
  margin: 10px 0px 20px;
  position: relative; }

.product_select__scroller {
  overflow: auto;
  height: calc(100vh - 127px);
  height: calc((var(--vh, 1vh) * 100) - 127px);
  padding-bottom: 60px;
  -webkit-overflow-scrolling: touch; }

@media screen and (min-width: 600px) {
  .product_select__section__product_wrapper .accordion__card {
    left: 0;
    transform: none;
    margin-top: 10px !important;
    margin-right: 10px; }
  .product_select__section__product_wrapper .short_product_info {
    display: inline-block;
    vertical-align: top; } }

@media screen and (max-width: 600px) {
  .product_select {
    background-color: red; }
    .product_select__scroller::-webkit-scrollbar {
      display: none; } }

@media screen and (min-width: 992px) {
  .product_select__wrapper {
    display: flex; }
    .product_select__wrapper .filter_products__wrapper {
      width: 251px;
      display: block;
      margin-top: 10px; }
  .product_select__section__wrapper {
    width: calc(100% - 261px); } }

@media screen and (min-width: 1280px) {
  .product_select__scroller {
    overflow: auto;
    height: calc(100vh - 200px);
    height: calc((var(--vh, 1vh) * 100) - 200px);
    padding-bottom: 60px; } }

@media screen and (min-width: 600px) {
  .product_select__wrapper .accordion__card--single-section {
    max-width: calc(100% / 2 - 12px); } }

@media screen and (min-width: 1600px) {
  .product_select__wrapper .accordion__card--single-section {
    max-width: calc(100% / 3 - 12px); } }

@media screen and (min-width: 2000px) {
  .product_select__wrapper .accordion__card--single-section {
    max-width: calc(100% / 4 - 12px); } }

.product_filter_mobile__wrapper {
  margin-top: 20px;
  margin-left: 20px;
  width: calc(100% - 40px); }

.product_filter_mobile__sub_wrapper {
  height: calc(100vh - 170px);
  height: calc((var(--vh, 1vh) * 100) - 170px); }

.product_filter_mobile__button {
  display: block;
  margin: auto; }
  .product_filter_mobile__button__wrapper {
    width: 100%;
    display: flex; }
  .product_filter_mobile__button--link {
    margin: auto;
    margin-top: 20px; }

@media only screen and (min-width: 600px) {
  .product_portfolio .product_select__scroller {
    height: calc(100vh - 76px); } }

@media only screen and (min-width: 1280px) {
  .product_portfolio .product_select__scroller {
    height: calc(100vh - 128px); } }

.plan_detail__seperator {
  margin: auto;
  margin-top: 60px;
  max-width: 1000px; }

.plan_detail .step_change_explenation {
  max-width: 1000px;
  width: 100%;
  margin: auto;
  margin-top: 15px; }

.my_overview_entities {
  margin-top: 10px; }
  .my_overview_entities .tab-accordion__panel-expand {
    padding: 10px; }
  .my_overview_entities .filter_my_overview_entities {
    display: none; }
  .my_overview_entities .filter_button__wrapper {
    margin-left: 5px; }
  .my_overview_entities__complex_list_item__container {
    min-height: calc(100vh - 340px);
    min-height: calc((var(--vh, 1vh) * 100) - 340px); }
  .my_overview_entities__manage_button {
    display: block !important;
    margin: auto !important;
    margin-top: 10px !important; }

@media only screen and (min-width: 426px) {
  .my_overview_entities .filter_button__wrapper {
    margin-left: 25px; }
  .my_overview_entities .tab-accordion__panel-expand {
    padding: 30px 24px; } }

@media only screen and (min-width: 768px) {
  .my_overview_entities .filter_button__wrapper {
    margin-bottom: 10px; } }

@media only screen and (min-width: 992px) {
  .my_overview_entities {
    display: flex; }
    .my_overview_entities .filter_my_overview_entities {
      display: block;
      margin-right: 50px;
      margin-left: 26px;
      margin-top: 57px; }
    .my_overview_entities__tab_accordion_wrapper {
      width: calc(100% - 295px); }
    .my_overview_entities__complex_list_item__container {
      margin-left: -30px;
      width: calc(100% + 60px); } }

@media only screen and (min-width: 1280px) {
  .my_overview_entities .filter_my_overview_entities {
    margin-top: 207px; } }

.my-plots-template {
  border-top: rgba(131, 156, 169, 0.2) solid 1px;
  padding: 2rem 5% 2rem 5%; }
  .my-plots-template__map--mobile {
    display: none; }

@media only screen and (max-width: 992px) {
  .my-plots-template {
    padding: 2rem 2% 2rem 2%; }
    .my-plots-template__map--mobile {
      display: block;
      width: 100%;
      top: 0;
      z-index: 1000;
      background-color: #fff; } }

@media only screen and (max-width: 992px) {
  .my-plots-template {
    padding: 2rem 2% 2rem 2%; }
    .my-plots-template__map--desktop {
      display: none; } }

.add-plot-template {
  padding: 2rem 5% 2rem 5%; }
  .add-plot-template__bottom {
    width: 100%;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #839ca9;
    text-align: right; }
    .add-plot-template__bottom--button {
      text-transform: initial;
      border-radius: 15px; }

@media only screen and (max-width: 992px) {
  .add-plot-template {
    padding: 2rem 2% 2rem 2%; }
    .add-plot-template__map {
      display: none; }
    .add-plot-template__bottom {
      border: none;
      text-align: center; } }

.import-plot-template {
  border-top: rgba(131, 156, 169, 0.2) solid 1px;
  padding: 2rem 5% 2rem 5%; }
  .import-plot-template__duplicates-dialogue {
    max-height: 300px;
    overflow-y: auto;
    width: 100%;
    margin: 0.5rem 0; }
    .import-plot-template__duplicates-dialogue::-webkit-scrollbar {
      width: 0.2rem; }
    .import-plot-template__duplicates-dialogue::-webkit-scrollbar-thumb {
      background-color: #71bf44; }
    .import-plot-template__duplicates-dialogue--element {
      color: #839ca9;
      padding: 0.2rem 0; }
      .import-plot-template__duplicates-dialogue--element-left {
        display: inline-block;
        width: 50%;
        font-weight: bold;
        text-align: right;
        padding-right: 0.5rem; }
      .import-plot-template__duplicates-dialogue--element-right {
        display: inline-block;
        width: 50%; }
    .import-plot-template__duplicates-dialogue--bottom-text {
      color: #839ca9;
      text-align: center;
      margin-top: 1rem; }

@media only screen and (max-width: 992px) {
  .import-plot-template {
    padding: 2rem 2% 2rem 2%; } }

.my-crop-rotations-template {
  padding: 0rem 5% 2rem 5%; }
  .my-crop-rotations-template__top-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .my-crop-rotations-template__top-area--select {
      min-width: 220px;
      max-width: 300px;
      margin-bottom: 0.5rem; }
    .my-crop-rotations-template__top-area--button {
      text-transform: initial;
      border-radius: 15px; }
    .my-crop-rotations-template__top-area--search-field {
      width: 100%; }

@media only screen and (max-width: 1280px) {
  .my-crop-rotations-template__top-area--search-field {
    margin-top: 0.5rem; } }

@media only screen and (max-width: 992px) {
  .my-crop-rotations-template {
    padding: 2rem 5% 2rem 5%; } }

.edit-crop-rotation-template {
  padding: 2rem 5% 2rem 5%; }
  .edit-crop-rotation-template__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .edit-crop-rotation-template__top--title {
      font-weight: bold;
      color: #839ca9; }
  .edit-crop-rotation-template__save-button {
    width: 100%; }

.add-crop-rotation-template {
  padding: 2rem 30% 2rem 30%; }
  .add-crop-rotation-template__crop-rotation-name {
    width: 100%;
    padding: 0rem 0.5rem; }
  .add-crop-rotation-template__bottom {
    width: 100%;
    text-align: center;
    padding-right: 2rem; }
    .add-crop-rotation-template__bottom--button {
      margin-top: 3rem;
      width: 50%; }

@media only screen and (max-width: 768px) {
  .add-crop-rotation-template {
    padding: 2rem 5% 2rem 5%; } }

.my-plan-list-template {
  padding: 0rem 5% 2rem 5%; }
  .my-plan-list-template__top-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .my-plan-list-template .search-field__wrapper {
    margin: 0;
    margin-bottom: 1rem; }

@media only screen and (max-width: 992px) {
  .my-plan-list-template {
    padding: 2rem 5% 2rem 5%; } }

.printer-container {
  display: none; }

@media print {
  .do-not-print {
    display: none; }
  .printer-container {
    display: block; }
  @page :first {
    margin-top: 0; }
  @page :last {
    margin-bottom: 0; } }

.my-application-maps-template {
  padding: 0rem 5% 2rem 5%; }
  .my-application-maps-template__instruction-text {
    color: #839ca9;
    font-size: 0.8rem;
    text-align: center;
    padding: 0.5rem 1rem; }

@media only screen and (max-width: 992px) {
  .my-crop-rotations-template {
    padding: 2rem 5% 2rem 5%; } }

.onboarding--container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%; }
  .onboarding--container .slick-slider {
    width: calc(100% - 60px); }

.picto--crop {
  width: 38px; }

.picto--plot {
  width: 173px; }

.picto--usage {
  width: 98px; }

.picto--soil {
  width: 121px; }

.slick-slider-inner .slide-container {
  justify-content: end; }

.timeline__item {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  height: 100%;
  justify-content: space-between;
  margin: 0 auto;
  min-height: 180px;
  padding-bottom: 12px;
  padding-top: 20px;
  position: relative;
  text-align: center; }
  .timeline__item__hidden_button {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none; }
    .timeline__item__hidden_button:focus {
      border: 1px solid #71bf44;
      box-shadow: inset 0px 0px 0px 1px #71bf44;
      outline: none; }
    .timeline__item__hidden_button::-moz-focus-inner {
      border: none; }

.timeline__item-allplans {
  min-height: 97px; }

.timeline-separator {
  border: 0;
  border-top: 1px dashed #839ca9;
  margin: 0;
  margin-bottom: 8px;
  margin-left: 2px; }

.timeline__item--label {
  color: #839ca9;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  width: 100%; }
  .timeline__item--label:only-child {
    margin-top: auto; }

.timeline__step-num {
  align-items: center;
  background-color: #839ca9;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-weight: 600;
  height: 28px;
  justify-content: center;
  margin: 0 auto;
  width: 28px; }

.step-num--big {
  display: inline-flex;
  font-size: 1.125rem;
  height: 34px;
  line-height: 34px;
  margin: 0;
  margin-right: 16px;
  min-width: 34px;
  width: 34px; }

.timeline__step-name {
  color: #839ca9;
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 30px;
  margin-top: 20px;
  padding: 0 4px;
  word-break: break-word; }

.timeline__step-box {
  color: white;
  background-color: #839ca9;
  height: 15px;
  width: 60px;
  margin: 3px 16% 0;
  width: 68%; }

.timeline--step-ellipsis {
  height: 15px;
  width: 1px;
  margin: 3px auto;
  border-left: 1px dashed;
  background: none; }

.timeline__item--empty {
  display: none; }
  .timeline__item--empty:hover {
    cursor: default; }

.timeline__item--active.timeline__box-info {
  border: 1px solid #71bf44; }
  .timeline__item--active.timeline__box-info:hover {
    cursor: default; }
  .timeline__item--active.timeline__box-info ~ .timeline__item--label {
    color: #71bf44; }
    .timeline__item--active.timeline__box-info ~ .timeline__item--label .timeline__step-box {
      background-color: #71bf44; }

.timeline__item--active .timeline__item--label {
  color: #71bf44;
  top: 143px; }

.timeline__item--active .timeline-separator {
  border-top: 1px dashed #71bf44; }

.timeline__item--active .timeline__step-num {
  background-color: #71bf44; }

.timeline__item--active .timeline__step-name {
  color: #71bf44; }

.timeline__item--active .timeline__step-box {
  background-color: #71bf44;
  top: 129px; }

.timeline {
  display: flex;
  margin: 0 auto;
  min-height: 169px;
  padding-bottom: 30px;
  width: calc(100% - 48px); }
  .timeline .slick-list {
    margin-top: auto;
    width: 100%; }
  .timeline .slick-arrow {
    bottom: 0;
    height: 19px;
    margin: auto 0;
    top: 0;
    width: 12px; }
    .timeline .slick-arrow::before {
      background-size: 12px 19px;
      content: '';
      display: inline-block;
      height: 19px;
      opacity: 1;
      width: 12px; }
    .timeline .slick-arrow.slick-disabled {
      opacity: 0.3; }
  .timeline .slick-next {
    right: -36px; }
    .timeline .slick-next::before {
      background-image: url("assets/images/chevron-right.svg");
      background-repeat: no-repeat; }
  .timeline .slick-prev {
    left: -36px; }
    .timeline .slick-prev::before {
      background-image: url("assets/images/chevron-left.svg");
      background-repeat: no-repeat; }

.timeline__box-info {
  border: 1px solid #839ca9;
  flex: 1 1 auto;
  height: auto;
  margin: 3px 4px;
  padding: 10px 2px; }
  .timeline__box-info:hover {
    cursor: pointer; }

.timeline__my-plan {
  display: flex;
  flex: 1;
  justify-content: center; }
  .timeline__my-plan__inner_wrapper {
    display: flex;
    overflow: auto; }
  .timeline__my-plan .timeline__box-info {
    display: flex;
    flex-direction: column; }
  .timeline__my-plan .timeline__item {
    height: auto;
    justify-content: flex-end;
    margin: 0;
    width: 150px; }
    .timeline__my-plan .timeline__item .timeline__step-name {
      margin: auto;
      padding: 4px 0; }

@media only screen and (min-width: 768px) {
  .timeline__step-name {
    font-size: 0.875rem; }
  .timeline__box-info {
    padding: 10px 5px; }
  .timeline__my-plan .timeline__item--empty {
    display: flex;
    max-width: 50px; }
  /*
  .timeline__card-detail {
    .tab-column {
      flex-basis: 100%;
    }
  }*/ }

/*

@media only screen and (min-width: $screen-xsm-min) {
  .timeline__card-detail {
    padding-top: 50px;
    flex-direction: row;
    hr {
      display: block;
    }
  }
  .tab-column::before {
    background-color: transparent;
  }
}

*/
@media only screen and (min-width: 992px) {
  .timeline__item--empty {
    max-width: 60px; } }

@media only screen and (min-width: 1280px) {
  .timeline {
    width: 100%; }
  .timeline__item--empty {
    max-width: 70px; } }

@media print {
  .timeline__item--empty {
    display: flex;
    max-width: 52px; } }

.application_map__box {
  border: 5px;
  background-color: #71bf44;
  max-width: 140px;
  height: 35px; }

.application_map__text {
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  margin-top: 8%;
  text-transform: uppercase; }

.nformat-microscope {
  margin: 20px auto;
  flex-basis: 80px;
  flex-shrink: 0;
  width: 80px; }

.nformat-graph--bar-container {
  width: 320px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 auto; }

.nformat-graph--label-container {
  width: 320px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto; }

.nformat-graph--label {
  width: 100px;
  text-align: center;
  padding: 0 10px; }

.nformat-graph--bar {
  width: 100px;
  height: 100px;
  background-color: #839ca9;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 20px;
  position: relative; }
  .nformat-graph--bar p {
    color: white;
    text-align: center; }

.nformat-graph--check {
  height: 32px;
  padding: 8px 6px;
  position: absolute;
  right: 34px;
  top: -16px;
  width: 32px;
  background-color: white;
  border-radius: 20px;
  border: 2px solid #71bf44; }
  .nformat-graph--check path {
    fill: #71bf44; }

.nformat-graph--bar--first {
  height: 90px;
  background-color: #71bf44; }

.nformat-graph--bar--second {
  height: 120px; }

.nformat-graph--bar--third {
  height: 140px; }

.nformat--explanation-title {
  color: #839ca9;
  font-size: 1.125rem;
  font-weight: 400;
  max-width: 400px;
  margin: 20px auto;
  text-align: center; }

.nformat--explanation-subtitle {
  color: #839ca9;
  font-size: 0.75rem;
  font-weight: 400;
  max-width: 400px;
  margin: 20px auto;
  text-align: center; }

.nformat__explanation-link {
  text-align: center; }

.nformat__title-row {
  margin: 24px 0 30px 0;
  align-items: center;
  display: flex; }
  .nformat__title-row svg {
    margin-right: 11px;
    margin-left: 3px; }

.nformat--container {
  display: flex;
  flex-direction: column;
  justify-content: space-around; }

@media screen and (min-width: 1280px) {
  .nformat-graph--container {
    margin-left: 17px; }
  .nformat-microscope {
    display: none; }
  .nformat--container {
    flex-direction: row; }
  .nformat--explanation-title {
    text-align: left; }
  .nformat--explanation-subtitle {
    text-align: left; } }

.role-select--container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  margin-top: 15vh;
  width: 100%; }

@media only screen and (min-width: 992px) {
  .role-select--container {
    margin-bottom: 0;
    padding-top: 0; } }

.flow-select--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 6vh;
  width: 100%; }
  .flow-select--container .form__title {
    align-self: flex-start; }

.flow-select--option-container {
  text-align: center; }
  .flow-select--option-container .form__field {
    justify-content: center; }
  .flow-select--option-container .from_field_bavaria {
    text-align: left; }

.picto-welcome {
  flex-basis: 111px;
  width: 111px;
  margin-bottom: 30px; }

.flow-select--separator {
  background-color: rgba(131, 156, 169, 0.4);
  width: 100%;
  height: 1px;
  margin: 0 auto 10px; }

.flow-select--options-box {
  display: flex;
  flex-direction: column;
  width: auto;
  text-align: center; }

.flow-select--option-subtitle {
  margin-left: 34px;
  margin-top: 10px; }

.buttons_container {
  flex-direction: column; }
  .buttons_container .add-plot-button {
    display: block;
    display: flex;
    flex-direction: row;
    justify-content: flex-start; }
    .buttons_container .add-plot-button__icon {
      height: 40px;
      width: 40px; }
    .buttons_container .add-plot-button__text {
      padding-left: 1rem; }

.flow-select--welcome {
  color: #71bf44;
  font-size: 20px; }

.flow-select--creating-plan {
  color: #839ca9;
  text-align: center;
  font-size: 26px; }

.flow-select--add-field {
  color: #839ca9;
  text-align: center;
  font-size: 16px;
  max-width: 800px;
  margin-top: 1vh; }

@media only screen and (min-width: 600px) {
  .flow-select--option-subtitle {
    margin-top: 16px;
    width: 220px;
    top: 0; }
  .flow-select__wrap_width {
    width: 146px; }
  .flow-select--options-box {
    flex-direction: row;
    margin: 3vh 0; }
  .flow-select--separator {
    width: 1px;
    height: 24vh;
    margin: 0 41px; }
  .picto-welcome {
    flex-basis: 170px;
    width: 170px; }
  .flow-select--option-container {
    margin-top: 2vh;
    width: 320px; }
  .flow-select--welcome {
    color: #71bf44; }
  .flow-select--creating-plan {
    color: #839ca9;
    text-align: center;
    font-size: 45px; }
  .flow-select--add-field {
    color: #839ca9;
    text-align: center;
    font-size: 15px;
    width: 510px;
    margin-top: 1vh; } }

.top-caroussel-silder-container {
  width: 100%;
  height: calc(100vh - 66px);
  background-color: #e7c8b2;
  position: relative;
  overflow: hidden;
  min-height: 505px; }
  .top-caroussel-silder-container__video {
    position: absolute;
    width: auto;
    min-width: 100%;
    min-height: 100%;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    transform: translateX(-50%) translateY(-50%); }
  .top-caroussel-silder-container__element {
    padding: 0 10%;
    width: 100%;
    height: calc(100vh - 66px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    min-height: 505px; }
    .top-caroussel-silder-container__element .double-text-content-slide-container {
      display: flex;
      flex-direction: column;
      gap: 0.5rem; }
      .top-caroussel-silder-container__element .double-text-content-slide-container__row {
        display: flex;
        flex-direction: row;
        gap: 1.5rem; }
        .top-caroussel-silder-container__element .double-text-content-slide-container__row--cell {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          text-align: left;
          color: #fff; }
          .top-caroussel-silder-container__element .double-text-content-slide-container__row--cell.top {
            justify-content: flex-end; }
          .top-caroussel-silder-container__element .double-text-content-slide-container__row--cell.title {
            font-size: 2.8rem;
            font-weight: bold; }
          .top-caroussel-silder-container__element .double-text-content-slide-container__row--cell.green {
            margin-top: 2%;
            font-weight: bold;
            color: #71bf44;
            margin-bottom: 1rem; }
          .top-caroussel-silder-container__element .double-text-content-slide-container__row--cell.message {
            margin-bottom: 1.5rem; }
          .top-caroussel-silder-container__element .double-text-content-slide-container__row--cell .nutriguide-logo {
            height: 95px; }
          .top-caroussel-silder-container__element .double-text-content-slide-container__row--cell .nutrizones-logo {
            height: 95px; }
    .top-caroussel-silder-container__element .slide1-left {
      display: flex;
      justify-content: center;
      align-items: center; }
      .top-caroussel-silder-container__element .slide1-left__image {
        width: auto; }
    .top-caroussel-silder-container__element .slide1-right {
      text-align: left;
      margin-top: 10%;
      margin-left: 10%; }
      .top-caroussel-silder-container__element .slide1-right__top-logo {
        border-bottom: 2px solid #71bf44;
        padding-bottom: 1rem; }
        .top-caroussel-silder-container__element .slide1-right__top-logo .nutriguide-logo {
          height: 95px; }
      .top-caroussel-silder-container__element .slide1-right__title {
        color: #fff;
        font-size: 2.8rem;
        font-weight: bold; }
        .top-caroussel-silder-container__element .slide1-right__title.with-bottom-margin {
          margin-bottom: 2rem; }
      .top-caroussel-silder-container__element .slide1-right__subTitle {
        margin-top: 2%;
        font-weight: bold;
        color: #71bf44;
        margin-bottom: 1rem; }
      .top-caroussel-silder-container__element .slide1-right__message {
        color: #fff;
        margin-bottom: 1.5rem; }
    .top-caroussel-silder-container__element .slide2-left {
      display: flex;
      justify-content: center;
      align-items: center; }
      .top-caroussel-silder-container__element .slide2-left__image {
        width: auto; }
    .top-caroussel-silder-container__element .slide2-right {
      text-align: left;
      margin-top: 5%; }
      .top-caroussel-silder-container__element .slide2-right__top-logo {
        border-bottom: 2px solid #71bf44;
        padding-bottom: 1rem; }
        .top-caroussel-silder-container__element .slide2-right__top-logo .farmdok-logo {
          height: 3.5rem;
          width: 18rem; }
      .top-caroussel-silder-container__element .slide2-right__title {
        color: #fff;
        font-size: 2.4rem;
        font-weight: bold; }
      .top-caroussel-silder-container__element .slide2-right__subTitle {
        margin-top: 2%;
        font-weight: bold;
        color: #71bf44;
        margin-bottom: 1rem; }
      .top-caroussel-silder-container__element .slide2-right__message {
        color: #fff;
        margin-bottom: 1.5rem; }
  .top-caroussel-silder-container .carousel .slider-wrapper.axis-horizontal .slider .slide {
    background-color: rgba(0, 0, 0, 0); }
  .top-caroussel-silder-container__lines-holder {
    position: absolute;
    bottom: 15%;
    left: calc(50% - 50px);
    display: flex;
    flex-direction: row; }
    .top-caroussel-silder-container__lines-holder--element {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0);
      border: none;
      padding: 20px 5px; }
      .top-caroussel-silder-container__lines-holder--element:focus {
        outline: none; }
      .top-caroussel-silder-container__lines-holder--element--line {
        background-color: rgba(131, 156, 169, 0.7);
        width: 40px;
        height: 3px;
        transition: background-color 1s; }
        .top-caroussel-silder-container__lines-holder--element--line.active {
          background-color: #fff; }
  .top-caroussel-silder-container__scroll-down {
    position: absolute;
    bottom: 3rem;
    left: calc(50% - 19px);
    display: block;
    cursor: pointer;
    background: none;
    border: none; }
    .top-caroussel-silder-container__scroll-down:focus {
      outline: none; }

@media only screen and (max-width: 1280px) {
  .top-caroussel-silder-container__element .double-text-content-slide-container__row--cell.title {
    font-size: 2rem; } }

@media only screen and (max-width: 959px) {
  .top-caroussel-silder-container__element {
    padding: 0 10%; }
    .top-caroussel-silder-container__element .double-text-content-slide-container__row--cell.title {
      font-size: 1.5rem; }
    .top-caroussel-silder-container__element .slide1-right {
      text-align: left;
      margin-top: 1.5rem; }
      .top-caroussel-silder-container__element .slide1-right__title {
        font-size: 2rem; }
      .top-caroussel-silder-container__element .slide1-right__subTitle {
        margin-top: 1rem;
        font-size: 1.4rem; }
      .top-caroussel-silder-container__element .slide1-right__button {
        text-align: center; }
      .top-caroussel-silder-container__element .slide1-right__message {
        font-size: 1.2rem; }
    .top-caroussel-silder-container__element .slide2-right {
      text-align: left;
      margin-top: 1.5rem; }
      .top-caroussel-silder-container__element .slide2-right__title {
        font-size: 2rem; }
      .top-caroussel-silder-container__element .slide2-right__subTitle {
        margin-top: 1rem;
        font-size: 1.4rem; }
      .top-caroussel-silder-container__element .slide2-right__button {
        text-align: center; }
      .top-caroussel-silder-container__element .slide2-right__message {
        font-size: 1.2rem; }
  .top-caroussel-silder-container .carousel .slider-wrapper.axis-horizontal .slider img {
    display: none; }
  .top-caroussel-silder-container__lines-holder {
    bottom: 17%; } }

@media screen and (max-width: 959px) and (orientation: landscape) {
  .top-caroussel-silder-container__element {
    align-items: flex-start; } }

@media only screen and (max-width: 767px) {
  .top-caroussel-silder-container__element {
    padding: 0 10%;
    position: relative; }
    .top-caroussel-silder-container__element .double-text-content-slide-container {
      position: relative;
      bottom: 3.5rem; }
    .top-caroussel-silder-container__element .slide1-right {
      text-align: left;
      margin-top: 1rem; }
      .top-caroussel-silder-container__element .slide1-right__top-logo {
        border-bottom: 2px solid #71bf44;
        padding-bottom: 1rem; }
        .top-caroussel-silder-container__element .slide1-right__top-logo .nutriguide-logo {
          height: 4rem; }
      .top-caroussel-silder-container__element .slide1-right__button {
        font-size: 0.9rem; }
        .top-caroussel-silder-container__element .slide1-right__button button {
          width: 100%; }
      .top-caroussel-silder-container__element .slide1-right__title {
        font-size: 1rem;
        margin-top: 0.5rem;
        width: 100%; }
      .top-caroussel-silder-container__element .slide1-right__subTitle {
        margin-top: 0.6rem;
        font-size: 1rem; }
      .top-caroussel-silder-container__element .slide1-right__message {
        font-size: 0.9rem; }
    .top-caroussel-silder-container__element .slide2-right {
      text-align: left;
      margin-top: 1rem; }
      .top-caroussel-silder-container__element .slide2-right__top-logo {
        border-bottom: 2px solid #71bf44;
        padding-bottom: 1rem; }
        .top-caroussel-silder-container__element .slide2-right__top-logo .nutriguide-logo {
          height: 2rem; }
        .top-caroussel-silder-container__element .slide2-right__top-logo .farmdok-logo {
          height: 2.5rem;
          width: 11rem; }
      .top-caroussel-silder-container__element .slide2-right__button {
        font-size: 0.9rem; }
        .top-caroussel-silder-container__element .slide2-right__button button {
          width: 100%;
          height: 2.7rem; }
      .top-caroussel-silder-container__element .slide2-right__title {
        font-size: 1rem;
        margin-top: 0.5rem; }
      .top-caroussel-silder-container__element .slide2-right__subTitle {
        margin-top: 1.5rem;
        font-size: 1rem; }
      .top-caroussel-silder-container__element .slide2-right__message {
        font-size: 1rem; }
  .top-caroussel-silder-container .carousel .slider-wrapper.axis-horizontal .slider img {
    display: none; } }

.footer-top {
  background-size: cover;
  background-position: center;
  min-height: 70vh;
  padding: 0 10%; }
  .footer-top__box {
    background-color: rgba(113, 191, 68, 0.5);
    width: 50%;
    color: #fff;
    padding: 2rem 15% 2rem 2rem; }
    .footer-top__box--logo {
      width: 40%;
      padding: 1.5rem 0.5rem 0.5rem 0.5rem; }
    .footer-top__box--text {
      font-size: 0.6rem;
      padding-bottom: 1.5rem; }
    .footer-top__box--title {
      font-size: 2.5rem; }
    .footer-top__box--content {
      font-size: 1rem;
      margin: 1rem 0; }

@media only screen and (max-width: 1279px) {
  .footer-top__box {
    width: 70%; } }

@media only screen and (max-width: 960px) {
  .footer-top__box {
    width: 100%; } }

@media only screen and (max-width: 600px) {
  .footer-top {
    padding: 0; }
    .footer-top__box--logo {
      width: 60%; }
    .footer-top__box--title {
      font-size: 1.5rem; } }

.nutrizones-footer-top {
  background-size: cover;
  background-position: center;
  min-height: 70vh;
  padding: 0 10%; }
  .nutrizones-footer-top__box {
    background-color: rgba(113, 191, 68, 0.5);
    width: 50%;
    color: #fff;
    padding: 2rem 10% 2rem 2rem; }
    .nutrizones-footer-top__box--logo {
      width: 40%;
      padding: 1.5rem 0.5rem 0.5rem 0.5rem; }
    .nutrizones-footer-top__box--text {
      font-size: 0.6rem;
      padding-bottom: 1.5rem; }
    .nutrizones-footer-top__box--title {
      font-size: 2.2rem; }
    .nutrizones-footer-top__box--content {
      font-size: 1rem;
      margin: 1rem 0; }
    .nutrizones-footer-top__box--content-large {
      font-size: 1.5rem;
      margin: 1rem 0; }
    .nutrizones-footer-top__box--actions .title {
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 1rem; }
    .nutrizones-footer-top__box--actions .buttons {
      display: flex;
      flex-direction: row;
      gap: 1rem; }

@media only screen and (max-width: 1279px) {
  .nutrizones-footer-top__box {
    width: 70%; } }

@media only screen and (max-width: 960px) {
  .nutrizones-footer-top__box {
    width: 100%; } }

@media only screen and (max-width: 600px) {
  .nutrizones-footer-top {
    padding: 0; }
    .nutrizones-footer-top__box--logo {
      width: 60%; }
    .nutrizones-footer-top__box--title {
      font-size: 1.5rem; }
    .nutrizones-footer-top__box--actions .buttons {
      flex-direction: column; }
      .nutrizones-footer-top__box--actions .buttons svg {
        width: 300px;
        height: 50px; } }

.videos-container {
  padding: 0 10%;
  width: 100%; }
  .videos-container__top {
    display: flex;
    flex-direction: row; }
    .videos-container__top--video-box {
      display: inline-block;
      width: 50%;
      border: none;
      background-color: transparent;
      padding: 0;
      position: relative; }
      .videos-container__top--video-box:focus {
        outline: none; }
      .videos-container__top--video-box--description {
        color: #fff;
        position: absolute;
        bottom: 1.5rem;
        left: 1rem;
        right: 1rem;
        text-align: left;
        font-size: 1rem; }
      .videos-container__top--video-box--line {
        position: absolute;
        height: 1px;
        background-color: #71bf44;
        width: 50%;
        bottom: 1rem;
        left: 1rem; }
      .videos-container__top--video-box img {
        width: 100%; }
  .videos-container__bottom {
    display: flex;
    flex-direction: row; }
    .videos-container__bottom--left {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 5rem;
      color: #839ca9;
      font-size: 2.5rem;
      font-weight: bold; }
      .videos-container__bottom--left sup {
        top: -2px;
        position: relative;
        font-size: 1.4rem; }
      .videos-container__bottom--left--button {
        font-size: 1rem;
        width: 260px;
        margin-top: 2.5rem; }
    .videos-container__bottom--right {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative; }
      .videos-container__bottom--right picture {
        display: flex;
        justify-content: center;
        align-items: center; }
        .videos-container__bottom--right picture img {
          top: 5rem;
          position: relative;
          width: 90%;
          height: auto; }

@media only screen and (max-width: 768px) {
  .videos-container__top {
    flex-direction: column; }
    .videos-container__top--video-box {
      width: 100%; }
  .videos-container__bottom {
    flex-direction: column; }
    .videos-container__bottom--right picture img {
      height: 70vh;
      width: auto;
      top: 3rem; }
    .videos-container__bottom--left {
      text-align: center;
      font-size: 1.7rem;
      margin-top: 3rem; }
      .videos-container__bottom--left--button {
        width: 100%; } }

@media screen and (max-width: 600px) {
  .videos-container__top {
    flex-direction: column; }
    .videos-container__top--video-box--description {
      color: #839ca9;
      font-size: 1rem;
      position: relative;
      margin-bottom: 1rem;
      bottom: 0;
      left: 0;
      text-align: center; }
    .videos-container__top--video-box--line {
      display: none; }
  .videos-container__bottom {
    flex-direction: column; }
    .videos-container__bottom--right picture img {
      height: 40vh;
      width: auto;
      top: 2rem; } }

.youtubepopup-container {
  widows: 100%;
  height: 100%; }
  .youtubepopup-container__player {
    height: 50vw; }
  .youtubepopup-container__close {
    position: absolute;
    right: 6px;
    top: 6px; }

.supported-crops-container {
  display: flex;
  flex-direction: row; }
  .supported-crops-container__left {
    flex: 1;
    overflow: hidden; }
    .supported-crops-container__left img {
      width: 100%;
      height: auto; }
  .supported-crops-container__right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .supported-crops-container__right--title {
      color: #839ca9;
      font-weight: bold;
      font-size: 2rem;
      margin: 1rem 20% 2rem 3rem;
      width: 50%; }
    .supported-crops-container__right--crop-container {
      margin: 1rem 20% 1rem 3rem; }
      .supported-crops-container__right--crop-container .crop-element {
        flex-basis: 20%;
        position: relative;
        margin-bottom: 2rem;
        box-sizing: border-box; }
        .supported-crops-container__right--crop-container .crop-element__icon {
          height: 5.7rem;
          text-align: center; }
          .supported-crops-container__right--crop-container .crop-element__icon--svg {
            width: 7rem; }
        .supported-crops-container__right--crop-container .crop-element__name {
          color: #839ca9;
          text-align: center; }
    .supported-crops-container__right--row {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start; }
    .supported-crops-container__right--all-crops {
      border-top: 3px solid #71bf44;
      margin: 1rem 20% 1rem 3rem;
      padding-top: 2rem; }
      .supported-crops-container__right--all-crops img {
        width: 100%; }

@media (max-width: 1600px) {
  .supported-crops-container__right--crop-container .crop-element {
    flex-basis: 25%; } }

@media (max-width: 1280px) {
  .supported-crops-container__right--crop-container .crop-element {
    flex-basis: 33.33%; } }

@media (max-width: 992px) {
  .supported-crops-container__right--crop-container .crop-element {
    margin: 2rem 0; } }

@media (max-width: 600px) {
  .supported-crops-container__right--crop-container .crop-element {
    flex-basis: 50%;
    margin: 3rem 0; } }

@media only screen and (max-width: 992px) {
  .supported-crops-container__left {
    display: none; }
  .supported-crops-container__right {
    padding: 0 2%; }
    .supported-crops-container__right--title {
      width: 96%;
      margin: 3rem 2% 0 2%;
      text-align: center;
      font-size: 1.7rem; }
    .supported-crops-container__right--crop-container {
      margin: 0; }
    .supported-crops-container__right--all-crops {
      margin: 0; } }

.phone-mock-container {
  display: flex;
  flex-direction: row; }
  .phone-mock-container__left {
    display: inline-flex;
    flex-direction: column;
    width: 50%;
    padding-left: 10%; }
    .phone-mock-container__left--section {
      height: 100vh;
      min-height: 500px;
      display: flex;
      align-items: center;
      position: relative; }
      .phone-mock-container__left--section--box {
        display: flex;
        flex-direction: row;
        width: 100%; }
        .phone-mock-container__left--section--box .phone-mock-box__left {
          flex: 1; }
        .phone-mock-container__left--section--box .phone-mock-box__rigth {
          padding-left: 2rem;
          flex: 6; }
          .phone-mock-container__left--section--box .phone-mock-box__rigth--title {
            font-size: 2rem;
            font-weight: bold; }
            .phone-mock-container__left--section--box .phone-mock-box__rigth--title .title1 {
              color: #839ca9; }
            .phone-mock-container__left--section--box .phone-mock-box__rigth--title .title2 {
              color: #71bf44; }
            .phone-mock-container__left--section--box .phone-mock-box__rigth--title .title3 {
              color: #839ca9; }
              .phone-mock-container__left--section--box .phone-mock-box__rigth--title .title3 b {
                color: #71bf44; }
          .phone-mock-container__left--section--box .phone-mock-box__rigth--desc {
            color: #839ca9; }
          .phone-mock-container__left--section--box .phone-mock-box__rigth--desc-title {
            color: #839ca9; }
          .phone-mock-container__left--section--box .phone-mock-box__rigth--desc-item {
            color: #839ca9;
            list-style-type: disc; }
        .phone-mock-container__left--section--box .phone-mock-container__right--phone-box {
          display: none; }
      .phone-mock-container__left--section--icon {
        width: 100%;
        background-color: #fff;
        bottom: 0;
        left: 0;
        padding-bottom: 8rem;
        position: absolute; }
        .phone-mock-container__left--section--icon .phone-mock-left-bottom-text {
          color: #839ca9;
          font-size: 0.8rem;
          font-weight: bold;
          margin-bottom: 0.5rem; }
        .phone-mock-container__left--section--icon .tablet-desktop-mobile {
          width: 165px;
          height: auto; }
  .phone-mock-container__right {
    display: inline-flex;
    width: 50%;
    height: 100vh;
    position: relative;
    top: 0;
    right: 0;
    padding-right: 10%; }
    .phone-mock-container__right--phone-box {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative; }
      .phone-mock-container__right--phone-box--video {
        width: 270px;
        position: absolute;
        left: 50%;
        margin-left: -135px;
        top: 50%;
        margin-top: -286px; }
      .phone-mock-container__right--phone-box img {
        width: 290px;
        height: auto;
        z-index: 2; }
      .phone-mock-container__right--phone-box picture {
        width: 290px;
        height: auto;
        z-index: 2; }

@media screen and (max-width: 960px) {
  .phone-mock-container__left {
    width: 100%;
    padding: 0 10%; }
    .phone-mock-container__left--section {
      height: auto;
      margin: 1rem 0 1rem;
      min-height: unset; }
      .phone-mock-container__left--section--box {
        flex-direction: column; }
        .phone-mock-container__left--section--box .phone-mock-box__rigth {
          padding-left: 0; }
          .phone-mock-container__left--section--box .phone-mock-box__rigth--title {
            font-size: 1.5rem; }
          .phone-mock-container__left--section--box .phone-mock-box__rigth--desc {
            margin-bottom: 1rem; }
          .phone-mock-container__left--section--box .phone-mock-box__rigth button {
            width: 100%; }
        .phone-mock-container__left--section--box .phone-mock-container__right--phone-box {
          display: flex; }
      .phone-mock-container__left--section--icon {
        display: none; }
  .phone-mock-container__right {
    display: none; } }

@media screen and (max-width: 426px) {
  .phone-mock-container__left--section--box .phone-mock-box__rigth--desc {
    font-size: 0.8rem; }
  .phone-mock-container__left--section--box .phone-mock-box__rigth--desc-title {
    font-size: 0.8rem; }
  .phone-mock-container__left--section--box .phone-mock-box__rigth--desc-item {
    font-size: 0.8rem; } }

.nutrizones-phone-mock-container {
  display: flex;
  flex-direction: row; }
  .nutrizones-phone-mock-container__left {
    display: inline-flex;
    flex-direction: column;
    width: 50%;
    padding-left: 10%; }
    .nutrizones-phone-mock-container__left--section {
      height: 100vh;
      min-height: 500px;
      display: flex;
      align-items: center;
      position: relative; }
      .nutrizones-phone-mock-container__left--section--box {
        display: flex;
        flex-direction: row;
        width: 100%; }
        .nutrizones-phone-mock-container__left--section--box .nutrizones-phone-mock-box__left {
          flex: 1; }
        .nutrizones-phone-mock-container__left--section--box .nutrizones-phone-mock-box__rigth {
          padding-left: 2rem;
          flex: 6; }
          .nutrizones-phone-mock-container__left--section--box .nutrizones-phone-mock-box__rigth--title {
            font-size: 2rem;
            font-weight: bold; }
            .nutrizones-phone-mock-container__left--section--box .nutrizones-phone-mock-box__rigth--title .title1 {
              color: #839ca9; }
            .nutrizones-phone-mock-container__left--section--box .nutrizones-phone-mock-box__rigth--title .title2 {
              color: #71bf44; }
            .nutrizones-phone-mock-container__left--section--box .nutrizones-phone-mock-box__rigth--title .title3 {
              color: #839ca9; }
              .nutrizones-phone-mock-container__left--section--box .nutrizones-phone-mock-box__rigth--title .title3 b {
                color: #71bf44; }
          .nutrizones-phone-mock-container__left--section--box .nutrizones-phone-mock-box__rigth--desc {
            color: #839ca9; }
          .nutrizones-phone-mock-container__left--section--box .nutrizones-phone-mock-box__rigth--desc-title {
            color: #839ca9; }
          .nutrizones-phone-mock-container__left--section--box .nutrizones-phone-mock-box__rigth--desc-item {
            color: #839ca9;
            list-style-type: disc; }
        .nutrizones-phone-mock-container__left--section--box .nutrizones-phone-mock-container__right--phone-box {
          display: none; }
      .nutrizones-phone-mock-container__left--section--icon {
        width: 100%;
        background-color: #fff;
        bottom: 0;
        left: 0;
        padding-bottom: 8rem;
        position: absolute; }
        .nutrizones-phone-mock-container__left--section--icon .nutrizones-phone-mock-left-bottom-text {
          color: #839ca9;
          font-size: 0.8rem;
          font-weight: bold;
          margin-bottom: 0.5rem; }
        .nutrizones-phone-mock-container__left--section--icon .tablet-desktop-mobile {
          width: 60px;
          height: auto; }
  .nutrizones-phone-mock-container__right {
    display: inline-flex;
    width: 50%;
    height: 100vh;
    position: relative;
    top: 0;
    right: 0;
    padding-right: 10%; }
    .nutrizones-phone-mock-container__right--phone-box {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative; }
      .nutrizones-phone-mock-container__right--phone-box--video {
        width: 270px;
        position: absolute;
        left: 50%;
        margin-left: -135px;
        top: 50%;
        margin-top: -286px; }
      .nutrizones-phone-mock-container__right--phone-box img {
        width: 270px;
        position: absolute;
        left: 50%;
        margin-left: -135px;
        top: 50%;
        margin-top: -286px; }

@media screen and (max-width: 960px) {
  .nutrizones-phone-mock-container__left {
    width: 100%;
    padding: 0 10%; }
    .nutrizones-phone-mock-container__left--section {
      height: auto;
      margin: 1rem 0 1rem;
      min-height: unset; }
      .nutrizones-phone-mock-container__left--section--box {
        flex-direction: column; }
        .nutrizones-phone-mock-container__left--section--box .nutrizones-phone-mock-box__rigth {
          padding-left: 0; }
          .nutrizones-phone-mock-container__left--section--box .nutrizones-phone-mock-box__rigth--title {
            font-size: 1.5rem; }
          .nutrizones-phone-mock-container__left--section--box .nutrizones-phone-mock-box__rigth--desc {
            margin-bottom: 1rem; }
          .nutrizones-phone-mock-container__left--section--box .nutrizones-phone-mock-box__rigth button {
            width: 100%; }
        .nutrizones-phone-mock-container__left--section--box .nutrizones-phone-mock-container__right--phone-box {
          display: flex;
          position: relative;
          margin: 2rem 0; }
          .nutrizones-phone-mock-container__left--section--box .nutrizones-phone-mock-container__right--phone-box--video {
            width: 270px;
            position: relative;
            left: unset;
            margin-left: unset;
            margin-top: unset;
            top: unset; }
          .nutrizones-phone-mock-container__left--section--box .nutrizones-phone-mock-container__right--phone-box img {
            width: 270px;
            position: absolute;
            left: unset;
            margin-left: unset;
            margin-top: unset;
            top: unset; }
      .nutrizones-phone-mock-container__left--section--icon {
        display: none; }
  .nutrizones-phone-mock-container__right {
    display: none; } }

@media screen and (max-width: 426px) {
  .nutrizones-phone-mock-container__left--section--box .nutrizones-phone-mock-box__rigth--desc {
    font-size: 0.8rem; }
  .nutrizones-phone-mock-container__left--section--box .nutrizones-phone-mock-box__rigth--desc-title {
    font-size: 0.8rem; }
  .nutrizones-phone-mock-container__left--section--box .nutrizones-phone-mock-box__rigth--desc-item {
    font-size: 0.8rem; } }

@media screen and (max-height: 500px) {
  .nutrizones-phone-mock-container__left--section--icon {
    padding-bottom: 2rem; } }

.nutrizones-section {
  margin: 0 10%;
  padding-bottom: 6rem; }
  .nutrizones-section__title {
    color: #839ca9;
    font-weight: bold;
    font-size: 2rem;
    margin-top: 2rem; }
  .nutrizones-section__description {
    color: #839ca9; }
  .nutrizones-section__list {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: space-evenly;
    margin: 6% 0;
    flex-wrap: wrap; }
    .nutrizones-section__list__element {
      width: 200px; }
      .nutrizones-section__list__element--top {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center; }
        .nutrizones-section__list__element--top .text {
          color: rgba(131, 156, 169, 0.2);
          font-size: 10rem;
          font-weight: bold;
          line-height: 8rem; }
        .nutrizones-section__list__element--top svg {
          position: absolute;
          bottom: 10px;
          right: calc(50% - 60px);
          height: 60px;
          width: auto; }
      .nutrizones-section__list__element--bottom {
        font-weight: bold;
        font-size: 0.8rem;
        text-align: center;
        color: #839ca9; }

.campaign-container {
  margin: 0 5%;
  position: relative;
  top: -3rem;
  background-size: cover;
  background-position: center; }
  .campaign-container__content-box {
    padding: 2rem;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .campaign-container__content-box--title {
      font-size: 2rem;
      font-weight: bold; }
    .campaign-container__content-box--description {
      margin: 1rem 0; }
    .campaign-container__content-box--button {
      width: 100%;
      text-align: center;
      bottom: 0; }
      .campaign-container__content-box--button .button-element {
        border-color: #fff;
        color: #fff; }

@media only screen and (max-width: 600px) {
  .campaign-container {
    margin: 0;
    position: relative;
    top: 0;
    background-size: cover;
    background-position: center; }
    .campaign-container__image-box {
      padding-bottom: 75%; }
    .campaign-container__content-box--title {
      font-size: 1.7rem;
      font-weight: bold; }
    .campaign-container__content-box--button .button-element {
      width: 100%;
      height: 2.7rem; } }

.top-video-container {
  padding: 3rem 10%;
  width: 100%;
  display: flex;
  align-items: center; }
  @media screen and (min-width: 1280px) {
    .top-video-container {
      height: 34rem; } }
  @media screen and (max-width: 1600px) {
    .top-video-container {
      height: 27rem; } }
  @media screen and (max-width: 1280px) {
    .top-video-container {
      height: 24rem; } }
  @media screen and (max-width: 992px) {
    .top-video-container {
      height: auto; } }
  .top-video-container__video-box {
    display: inline-block;
    border: none;
    background-color: transparent;
    padding: 0;
    position: relative; }
    .top-video-container__video-box:focus {
      outline: none; }
    .top-video-container__video-box--description {
      color: #fff;
      position: absolute;
      bottom: 1.5rem;
      left: 1rem;
      right: 1rem;
      text-align: left;
      font-size: 0.8rem; }
    .top-video-container__video-box--line {
      position: absolute;
      height: 1px;
      background-color: #71bf44;
      width: 50%;
      bottom: 1rem;
      left: 1rem; }
    .top-video-container__video-box img {
      width: auto;
      height: 27rem; }
      @media screen and (max-width: 1600px) {
        .top-video-container__video-box img {
          height: 20rem; } }
      @media screen and (max-width: 1280px) {
        .top-video-container__video-box img {
          height: 15rem; } }
      @media screen and (max-width: 992px) {
        .top-video-container__video-box img {
          width: 100%;
          height: auto; } }
  .top-video-container__right {
    display: inline-block; }
    .top-video-container__right--icon {
      width: 100px;
      height: 100px; }
    .top-video-container__right--new {
      color: #71bf44;
      text-transform: capitalize; }
    .top-video-container__right--title {
      color: #839ca9;
      font-size: 2rem;
      font-weight: bold; }
      .top-video-container__right--title.second {
        width: 90%; }
    .top-video-container__right--description {
      color: #839ca9;
      font-size: 1rem;
      padding: 1rem 0; }
  .top-video-container__full {
    display: flex;
    width: 100%;
    flex-direction: row; }
    .top-video-container__full--left {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center; }
      .top-video-container__full--left.icon {
        width: 150px;
        height: 150px; }
    .top-video-container__full--right {
      flex: 2; }
      .top-video-container__full--right.new {
        color: #71bf44; }
      .top-video-container__full--right.title {
        color: #839ca9;
        font-size: 2rem;
        font-weight: bold; }
      .top-video-container__full--right.description {
        color: #839ca9;
        font-size: 1rem;
        padding: 1rem 0; }

@media only screen and (max-width: 768px) {
  .top-video-container {
    display: block; }
    .top-video-container__video-box {
      width: 100%;
      padding: 0 !important; }
      .top-video-container__video-box--description {
        color: #839ca9;
        font-size: 1rem;
        position: relative;
        margin-bottom: 1rem;
        bottom: 0;
        left: 0;
        margin-top: 2rem;
        text-align: center; }
      .top-video-container__video-box--line {
        display: none; }
    .top-video-container__right {
      width: 100%;
      padding-left: 0 !important; }
      .top-video-container__right--title {
        font-size: 1.7rem; }
      .top-video-container__right button {
        width: 100%; }
      .top-video-container__right--icon {
        display: block;
        margin: 2rem auto 0; }
    .top-video-container__full {
      flex-direction: column; }
      .top-video-container__full--left.icon {
        width: 150px;
        height: 150px; } }

.landing-page-promotion {
  margin: 3rem 10%;
  display: flex;
  flex-direction: row; }
  .landing-page-promotion__left {
    width: 50%;
    padding-right: 10px; }
    .landing-page-promotion__left .promotion-title {
      font-size: 2rem;
      font-weight: bold;
      color: #839ca9; }
      .landing-page-promotion__left .promotion-title span {
        color: #71bf44; }
    .landing-page-promotion__left .promotion-text {
      color: #839ca9;
      font-size: 1rem;
      padding: 1rem 0; }
    .landing-page-promotion__left .promotion-prices {
      padding: 1rem 0; }
      .landing-page-promotion__left .promotion-prices .promotion-price {
        font-size: 1rem;
        color: #839ca9; }
    .landing-page-promotion__left .promotion-instructions {
      padding: 1rem 0;
      color: #839ca9;
      font-size: 1rem; }
      .landing-page-promotion__left .promotion-instructions a {
        font-weight: bold;
        text-decoration: none;
        color: #839ca9; }
    .landing-page-promotion__left .promotion-final-info {
      padding: 1rem 0;
      color: #839ca9;
      font-size: 1rem; }
  .landing-page-promotion__right {
    width: 50%; }
    .landing-page-promotion__right--image {
      width: 100%;
      height: auto; }

@media only screen and (max-width: 768px) {
  .landing-page-promotion {
    flex-direction: column; }
    .landing-page-promotion__left {
      width: 100%;
      padding-right: 0px; }
      .landing-page-promotion__left .promotion-button {
        width: 100%;
        margin: 1rem 0; }
    .landing-page-promotion__right {
      width: 100%; } }

.newsletter-subscription-container {
  padding: 5rem 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem; }
  .newsletter-subscription-container__title {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start; }
    .newsletter-subscription-container__title--text {
      color: #839ca9;
      font-size: 2rem;
      padding-left: 1rem;
      font-weight: bold; }
  .newsletter-subscription-container__buttons {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end; }
    .newsletter-subscription-container__buttons--text {
      color: #839ca9;
      font-size: 3rem;
      font-weight: bold; }
    .newsletter-subscription-container__buttons--button {
      margin-left: 1rem; }
      .newsletter-subscription-container__buttons--button--icon {
        width: 30px;
        height: 30px;
        margin-right: 5px; }

@media screen and (max-width: 992px) {
  .newsletter-subscription-container {
    flex-direction: column; } }

@media screen and (max-width: 600px) {
  .newsletter-subscription-container__title {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .newsletter-subscription-container__title--box {
      margin-bottom: 1rem; }
    .newsletter-subscription-container__title--text {
      padding-left: 0;
      font-size: 1.4rem;
      text-align: center; }
  .newsletter-subscription-container__buttons {
    flex-direction: column;
    justify-content: flex-end; }
    .newsletter-subscription-container__buttons--text {
      color: #839ca9;
      font-size: 3rem;
      font-weight: bold; }
    .newsletter-subscription-container__buttons--button {
      margin-left: 0; } }

.newsletter-subscription-dialogue-container {
  color: #839ca9;
  font-size: 1rem; }
  .newsletter-subscription-dialogue-container__title {
    color: #839ca9;
    font-size: 1.2rem;
    text-align: center;
    font-weight: 700; }
  .newsletter-subscription-dialogue-container__close {
    position: absolute;
    right: 6px;
    top: 6px; }
  .newsletter-subscription-dialogue-container__normal-text {
    padding: 0.5rem 0;
    font-size: 1rem;
    color: #839ca9; }
  .newsletter-subscription-dialogue-container__highlighted-text {
    font-weight: bold;
    padding: 0.5rem 0; }
  .newsletter-subscription-dialogue-container__actions {
    justify-content: flex-start;
    padding: 1rem 2rem 1rem 2rem; }
  .newsletter-subscription-dialogue-container__full-name {
    margin: 0 1rem 1.5rem 0;
    min-width: 200px; }
  .newsletter-subscription-dialogue-container__email {
    min-width: 200px; }
  .newsletter-subscription-dialogue-container__dialog {
    padding: 0 3rem; }

@media screen and (max-width: 600px) {
  .newsletter-subscription-dialogue-container__title {
    margin-top: 2rem; } }

@media screen and (max-width: 600px) {
  .newsletter-subscription-dialogue-container__full-name {
    width: 100%; } }

@media screen and (max-width: 600px) {
  .newsletter-subscription-dialogue-container__email {
    width: 100%;
    margin-bottom: 1.5rem; } }

@media screen and (max-width: 600px) {
  .newsletter-subscription-dialogue-container__actions {
    padding: 0 0.4rem 0.4rem; } }

@media screen and (max-width: 600px) {
  .newsletter-subscription-dialogue-container__dialog {
    padding: 0 1rem; } }

.newsletter-subscription-success-dialogue-container__dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 6rem; }
  .newsletter-subscription-success-dialogue-container__dialog--title {
    text-align: center;
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: #839ca9; }
  .newsletter-subscription-success-dialogue-container__dialog--top-text {
    text-align: center;
    padding: 1rem;
    color: #839ca9; }
  .newsletter-subscription-success-dialogue-container__dialog--text {
    text-align: center;
    padding: 1rem;
    color: #71bf44; }

@media screen and (max-width: 600px) {
  .newsletter-subscription-success-dialogue-container__dialog {
    padding: 3rem 3rem; } }

.lp-banner {
  padding: 3rem 10%;
  display: flex;
  flex-direction: row; }
  .lp-banner__left-side {
    padding-right: 1rem;
    width: 100%; }
  .lp-banner__right-side {
    padding-left: 1rem;
    width: 100%; }
  .lp-banner .left-side__alert {
    font-size: 2rem;
    font-weight: bold;
    color: #839ca9; }
    .lp-banner .left-side__alert span {
      color: #71bf44; }
  .lp-banner .left-side__description {
    color: #839ca9;
    font-size: 1rem;
    padding: 1rem 0; }
  .lp-banner .right-side__image {
    width: 100%;
    height: auto; }

@media only screen and (max-width: 991px) {
  .lp-banner {
    flex-direction: column-reverse; }
    .lp-banner__left-side {
      padding: 3rem 0 2rem 0; }
    .lp-banner__right-side {
      padding: 0; } }

.nurizones-video-container {
  padding: 0 10%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem; }
  .nurizones-video-container.brand-video {
    margin-top: 2rem; }
  .nurizones-video-container__video-box {
    flex: 1;
    display: inline-block;
    border: none;
    background-color: transparent;
    padding: 0;
    position: relative; }
    .nurizones-video-container__video-box:focus {
      outline: none; }
    .nurizones-video-container__video-box img {
      width: 100%; }
      @media screen and (max-width: 992px) {
        .nurizones-video-container__video-box img {
          width: 100%;
          height: auto; } }
  .nurizones-video-container__text-content {
    flex: 1; }
    .nurizones-video-container__text-content .title {
      color: #839ca9;
      font-weight: bold;
      font-size: 2rem; }
      .nurizones-video-container__text-content .title.green {
        color: #71bf44; }
    .nurizones-video-container__text-content .description {
      color: #839ca9;
      padding: 1rem 0; }

@media only screen and (max-width: 768px) {
  .nurizones-video-container {
    flex-direction: column; } }

@media screen and (max-width: 600px) {
  .nurizones-video-container__text-content .description {
    text-align: center; } }

.appbar--landing .toolbar {
  box-shadow: none;
  display: block; }
  .appbar--landing .toolbar .landing_page_content {
    display: flex;
    width: 100%;
    max-width: 1400px;
    margin: auto;
    height: 100%;
    align-items: center;
    justify-content: space-between; }
    .appbar--landing .toolbar .landing_page_content__access_button_wrapper {
      display: flex;
      align-items: center;
      margin-right: -5px; }
      .appbar--landing .toolbar .landing_page_content__access_button_wrapper .flag_wrapper {
        height: 40px;
        margin-right: 5px; }
        .appbar--landing .toolbar .landing_page_content__access_button_wrapper .flag_wrapper button {
          padding: 0; }
        .appbar--landing .toolbar .landing_page_content__access_button_wrapper .flag_wrapper svg {
          width: 40px;
          height: 40px; }
        .appbar--landing .toolbar .landing_page_content__access_button_wrapper .flag_wrapper__flag-icon {
          pointer-events: none; }
    .appbar--landing .toolbar .landing_page_content__button {
      margin: 5px;
      display: none; }
    .appbar--landing .toolbar .landing_page_content__logo {
      height: 45px;
      width: 13rem;
      flex-basis: 45px; }
  .appbar--landing .toolbar h1 {
    display: none; }

.landing-page-login-section {
  display: flex; }
  .landing-page-login-section__button {
    border-radius: 0;
    margin: 0; }

.splash-screen-v3 {
  overflow: hidden; }
  .splash-screen-v3 .extra_footer_landing {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 80%;
    padding: 1rem;
    margin: auto;
    height: 100%;
    align-items: center;
    justify-content: space-between; }
    .splash-screen-v3 .extra_footer_landing__logo {
      flex: 1;
      display: flex;
      flex-direction: row;
      height: 90px; }
      .splash-screen-v3 .extra_footer_landing__logo--nutriguide {
        width: 165px;
        padding-right: 2rem;
        display: flex;
        align-items: center; }
        .splash-screen-v3 .extra_footer_landing__logo--nutriguide:not(:only-child) {
          border-right: 1px solid #839ca9; }
        .splash-screen-v3 .extra_footer_landing__logo--nutriguide svg {
          width: 6rem; }
      .splash-screen-v3 .extra_footer_landing__logo--fachbeirat__wrapper {
        padding-left: 2rem;
        display: flex;
        flex-direction: row;
        align-items: center; }
        .splash-screen-v3 .extra_footer_landing__logo--fachbeirat__wrapper.text {
          color: #826528;
          font-size: 0.9rem; }
        .splash-screen-v3 .extra_footer_landing__logo--fachbeirat__wrapper svg {
          width: 9rem; }
    .splash-screen-v3 .extra_footer_landing__links {
      flex: 2;
      text-align: center; }
      .splash-screen-v3 .extra_footer_landing__links--bottom {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center; }
        .splash-screen-v3 .extra_footer_landing__links--bottom-link {
          display: flex;
          padding: 0 0.8rem; }
          .splash-screen-v3 .extra_footer_landing__links--bottom-link a {
            margin: auto;
            text-decoration: none; }
      .splash-screen-v3 .extra_footer_landing__links--cookie-button {
        display: inline; }
        .splash-screen-v3 .extra_footer_landing__links--cookie-button img {
          max-width: 9rem;
          position: relative;
          top: 0.5rem; }

@media only screen and (max-width: 1280px) {
  .splash-screen-v3 .extra_footer_landing__links--bottom-link a {
    margin-left: 1rem; } }

@media only screen and (max-width: 959px) {
  .splash-screen-v3 .extra_footer_landing {
    max-width: 100%;
    flex-direction: column; } }

@media only screen and (max-width: 600px) {
  .splash-screen-v3 .extra_footer_landing__logo {
    flex-direction: column;
    justify-content: flex-start;
    height: auto; }
    .splash-screen-v3 .extra_footer_landing__logo--nutriguide {
      border-right: none;
      display: block;
      align-items: center;
      padding-right: 0;
      width: 100%;
      text-align: center; }
      .splash-screen-v3 .extra_footer_landing__logo--nutriguide svg {
        width: 10rem; }
    .splash-screen-v3 .extra_footer_landing__logo--fachbeirat__wrapper {
      display: block;
      margin: 1.5rem 0 0 0;
      text-align: center;
      padding: 0; }
  .splash-screen-v3 .extra_footer_landing__links {
    text-align: center; }
    .splash-screen-v3 .extra_footer_landing__links--bottom {
      flex-direction: column;
      padding-bottom: 2rem; }
      .splash-screen-v3 .extra_footer_landing__links--bottom-link {
        padding-top: 0.5rem; }
        .splash-screen-v3 .extra_footer_landing__links--bottom-link a {
          margin: 0; }
    .splash-screen-v3 .extra_footer_landing__links--cookie-button img {
      top: 0.6rem; } }

@media only screen and (min-width: 768px) {
  .splash-screen-v3__buttons--mobile-only {
    display: none; }
  .splash-screen-v3__buttons--desktop-only {
    display: inline; }
  .appbar--landing .toolbar .landing_page_content__button {
    display: inline; } }

@media screen and (min-width: 426px) {
  .appbar--landing .toolbar__button__login {
    display: inline !important; } }

@media only screen and (min-width: 1280px) {
  .splash-screen-v3 {
    margin-top: 66px; } }

.month-list__link {
  align-items: center;
  display: flex;
  margin-bottom: 18px;
  padding: 12px 0;
  position: relative;
  width: 100%; }
  .month-list__link::after {
    background-color: rgba(131, 156, 169, 0.4);
    bottom: 0;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%; }
  .month-list__link:focus {
    outline: none;
    border: 1px solid #71bf44;
    box-shadow: inset 0px 0px 0px 1px #71bf44; }

.month-list__no-link {
  border: 1px solid #cdd7dd;
  display: inline-block;
  flex-grow: 0;
  margin-bottom: 30px;
  margin-right: 30px;
  padding: 30px 24px;
  width: calc(50% - 15px); }
  .month-list__no-link:nth-of-type(2n) {
    margin-right: 0; }
  .month-list__no-link::after {
    display: none; }
  .month-list__no-link:last-of-type {
    margin-right: 0; }

.month-list__title {
  color: #839ca9;
  display: inline-flex;
  font-size: 0.8rem;
  font-weight: 500;
  text-transform: uppercase; }

.month-list__item {
  align-items: flex-start;
  display: flex; }
  .month-list__item__content {
    flex: 1; }
    .month-list__item__content h4 {
      margin-bottom: 4px;
      text-transform: uppercase;
      word-wrap: break-word; }
  .month-list__item__wrapper {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: space-between; }

.month-list__text {
  margin: 0; }
  .month-list__text :first-of-type {
    margin-top: 10px; }

.month-list__picto-wrapper {
  margin-right: 10px;
  width: 80px; }

.month-list__picto-dynamic {
  margin-left: auto;
  text-align: right;
  width: 44px; }

.month-list__chevron {
  min-width: 19px;
  transform: rotate(-90deg);
  transition: transform 0.2s;
  width: 19px; }

.month-list__crop-picto {
  flex-basis: 34px;
  flex-shrink: 0;
  height: 48px;
  max-width: 56px; }

.month-list__plot-size {
  color: #71bf44;
  font-weight: 900;
  margin: 0 auto;
  width: 100%;
  word-wrap: break-word; }

.month-list__delete-picto, .month-list__chevron {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 18px; }

.month-list__month-item__wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start; }

.mysales__farmer-row {
  display: flex; }

.mysales__farmer-list__wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 11px;
  margin-left: 0;
  width: calc(100% * (2 / 3)); }
  .mysales__farmer-list__wrapper p {
    margin: 0;
    margin-bottom: 5px;
    margin-top: 10px; }
    .mysales__farmer-list__wrapper p:first-of-type {
      margin-top: 0; }

.mysales__farmer-list__quantity {
  margin-left: 30px; }
  .mysales__farmer-list__quantity p {
    margin-bottom: 5px;
    margin-top: 10px; }
    .mysales__farmer-list__quantity p:first-of-type {
      margin-top: 0; }

.mysales__item__wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 45px;
  width: 100%; }
  .mysales__item__wrapper .month-list__link,
  .mysales__item__wrapper .month-list__no-link {
    justify-content: space-around;
    margin-bottom: 15px;
    width: 100%; }
    .mysales__item__wrapper .month-list__link::after,
    .mysales__item__wrapper .month-list__no-link::after {
      display: none; }

@media only screen and (min-width: 600px) {
  .month-list__icon-button, .month-list__chevron {
    display: none; }
  .month-list__item {
    flex-direction: column; }
  .month-list__crop-wrapper {
    margin-bottom: 24px; }
  .month-list__month-item__wrapper::after {
    content: '';
    flex: auto; }
  .month-list__item__content h4 {
    margin-top: 24px; }
  .month-list__link {
    border: 1px solid #cdd7dd;
    margin-bottom: 30px;
    margin-right: 45px;
    min-height: 220px;
    padding: 30px 24px;
    display: block;
    width: calc((100% / 2) - 23px); }
    .month-list__link:nth-of-type(2n) {
      margin-right: 0; }
    .month-list__link::after {
      display: none; }
  .month-list__no-link {
    margin-right: 72px;
    min-height: 220px;
    width: calc(50% - 36px); }
    .month-list__no-link:nth-of-type(2n) {
      margin-right: 0; }
    .month-list__no-link::after {
      display: none; }
  .month-list__picto-dynamic {
    position: absolute;
    right: 0;
    top: 0;
    width: auto; }
    .month-list__picto-dynamic button {
      height: 24px;
      padding: 0;
      width: 30px; }
      .month-list__picto-dynamic button:hover {
        cursor: pointer; }
    .month-list__picto-dynamic__delete-picto, .month-list__picto-dynamic__chevron {
      bottom: 0;
      left: 0;
      margin: auto; }
  .separator__right-aligned {
    display: none; }
  .mysales__farmer-list__wrapper {
    margin-left: 30px;
    width: calc(100% * (2 / 3)); }
  .mysales__item__wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    align-items: center; }
    .mysales__item__wrapper .month-list__link,
    .mysales__item__wrapper .month-list__no-link {
      width: calc(100% / 3); } }

.products-table td {
  border-bottom: 1px solid rgba(131, 156, 169, 0.1); }

@media only screen and (min-width: 768px) {
  .mysales__farmer-list__wrapper {
    margin-left: 80px; }
  .mysales__item__wrapper .month-list__link,
  .mysales__item__wrapper .month-list__no-link {
    width: calc(100% / 3); }
  .month-list__picto-wrapper {
    width: 100%; }
  .month-list__link {
    width: calc((100% / 3) - 31px); }
    .month-list__link:nth-of-type(2n) {
      margin-right: 45px; }
    .month-list__link:nth-of-type(3n) {
      margin-right: 0px; } }

@media only screen and (min-width: 1600px) {
  .month-list__link {
    width: calc((100% / 4) - 35px); }
    .month-list__link:nth-of-type(2n) {
      margin-right: 45px; }
    .month-list__link:nth-of-type(3n) {
      margin-right: 45px; }
    .month-list__link:nth-of-type(4n) {
      margin-right: 0px; } }

.myaccount-read-only--container {
  text-align: center;
  max-width: 320px;
  margin: 32px auto; }
  .myaccount-read-only--container p {
    margin: 32px 0; }

.exitButton--image,
.resetPassword--image {
  padding-right: 4px; }

.exitButton--image {
  width: 16px;
  height: 16px; }

.resetPassword--image {
  width: 22px;
  height: 22px; }

.myaccount-center--container {
  text-align: center;
  max-width: 320px;
  margin: 8px auto;
  padding: 0; }

.myaccount-center--buttons {
  margin: 16px auto;
  padding: 32px 0;
  width: calc(100% - 16px); }

.myaccount-center--container button {
  border-radius: 32px;
  width: 250px;
  margin: 16px 0; }

.myaccount__button {
  display: flex;
  justify-content: space-between; }

.account__checkbox {
  margin: 32px 0; }

div.account__userInfo {
  margin: 32px 0; }

.account__userInfo {
  font-weight: bold;
  line-height: 1.25; }
  .account__userInfo p {
    margin: 0; }

.account__userInfo--vatNumber {
  font-weight: normal;
  font-size: 14px; }

.account__signOut {
  margin: 64px 0; }

.account__role {
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  color: #71bf44; }

.myaccount-center--signOut {
  display: flex;
  justify-content: center; }

.myaccount__contact--button {
  color: #71bf44;
  text-transform: inherit; }

@keyframes questionMark {
  0% {
    top: -50px; }
  50% {
    top: -35px; } }

@keyframes sunRising {
  0% {
    top: 50px;
    opacity: 0; }
  5% {
    opacity: 1; }
  10% {
    opacity: 1;
    top: -15px; }
  55% {
    opacity: 1;
    top: -15px; }
  60% {
    opacity: 1;
    top: 20px; }
  65% {
    opacity: 0;
    top: 50px; } }

@keyframes apearSunRising {
  0% {
    fill: #505f66; }
  5% {
    fill: #71bf44; }
  60% {
    fill: #71bf44; }
  65% {
    fill: #505f66; } }

.catch_error__content__wrapper {
  padding-left: 24px;
  padding-right: 24px;
  text-align: center; }

.catch_error__content__visual__wrapper {
  position: relative;
  margin-bottom: 30px; }

.catch_error__content__visual__logo {
  position: relative;
  z-index: 1;
  width: 190px;
  height: 190px; }
  .catch_error__content__visual__logo--field {
    margin-top: -30px; }
    .catch_error__content__visual__logo--field g {
      fill: #505f66;
      animation: apearSunRising 12s ease infinite;
      animation-fill-mode: forwards;
      animation-delay: 0.4s; }
  .catch_error__content__visual__logo--plan path {
    fill: #71bf44; }
  .catch_error__content__visual__logo--tractor {
    width: 190px;
    height: 120px; }
    .catch_error__content__visual__logo--tractor path {
      fill: #71bf44; }

.catch_error__content__visual__title {
  position: absolute;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  right: 0;
  text-align: center; }
  .catch_error__content__visual__title--plan {
    top: -50px;
    left: 5px;
    font-size: 140px;
    animation: questionMark 1s ease-in-out infinite; }
  .catch_error__content__visual__title--field {
    font-weight: bold;
    opacity: 0;
    font-size: 30pt;
    top: 50px;
    animation: sunRising 12s linear infinite;
    animation-delay: 0.4s; }

.catch_error__content__explenation {
  max-width: 350px;
  margin: auto; }

@media screen and (min-width: 768px) {
  .catch_error__content__wrapper {
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: center; }
  .catch_error__content__visual__wrapper {
    margin-bottom: 0px; }
  .catch_error__content__visual__logo--field {
    margin-bottom: 30px; }
  .catch_error__content__explenation {
    margin: 0;
    margin-left: 40px; } }

.my-crop-rotations-subheader-icon {
  width: 75%;
  height: 75%; }

.add-plan-selection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  min-height: 60vh; }
  .add-plan-selection__option {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100px; }
    .add-plan-selection__option:last-child {
      margin-left: 10%; }
    .add-plan-selection__option--icon {
      padding: 1rem;
      width: 100%;
      border-radius: 50%;
      height: 100px;
      background-color: rgba(113, 191, 68, 0.07);
      text-align: center; }
    .add-plan-selection__option--text {
      color: #71bf44;
      font-size: 1rem;
      text-align: center;
      margin-top: 0.5rem;
      font-weight: bold;
      position: relative; }

.shopping-list-container {
  font-size: 12px; }
  .shopping-list-container .shopping-list-header {
    position: relative;
    display: block;
    width: 100%;
    height: 200px; }
    .shopping-list-container .shopping-list-header__background {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .shopping-list-container .shopping-list-header__logo {
      position: absolute;
      z-index: 1;
      width: 20%;
      height: auto;
      left: 5%;
      top: 20%; }
  .shopping-list-container .shopping-list-main-content {
    position: relative; }
    .shopping-list-container .shopping-list-main-content__title-section {
      width: 100%;
      text-align: center;
      color: #71bf44;
      margin: 20px 0; }
      .shopping-list-container .shopping-list-main-content__title-section--title {
        font-weight: bold;
        font-size: 2em; }
      .shopping-list-container .shopping-list-main-content__title-section--subtitle {
        font-size: 1.5em; }
    .shopping-list-container .shopping-list-main-content .plot-section {
      width: 100%;
      margin: 1em 0; }
      .shopping-list-container .shopping-list-main-content .plot-section__title {
        color: #71bf44;
        width: 50%;
        border-bottom: solid 1px #839ca9;
        padding: 0.2rem;
        margin: 1em 0;
        font-weight: bold;
        font-size: 1.2em; }
      .shopping-list-container .shopping-list-main-content .plot-section__properties {
        display: flex;
        flex-direction: row; }
        .shopping-list-container .shopping-list-main-content .plot-section__properties--item {
          flex: 1;
          margin: 0.5em 0;
          position: relative; }
          .shopping-list-container .shopping-list-main-content .plot-section__properties--item--sub {
            margin: 0.5em 0;
            display: flex;
            flex-direction: row; }
            .shopping-list-container .shopping-list-main-content .plot-section__properties--item--sub .plot-property-key {
              flex: 1;
              color: #71bf44; }
            .shopping-list-container .shopping-list-main-content .plot-section__properties--item--sub .plot-property-value {
              flex: 1;
              color: #839ca9;
              font-weight: bold; }
          .shopping-list-container .shopping-list-main-content .plot-section__properties--item:not(:first-child) {
            padding-left: 1em; }
          .shopping-list-container .shopping-list-main-content .plot-section__properties--item:not(:last-child)::after {
            content: '';
            height: 40px;
            width: 1px;
            background-color: #839ca9;
            position: absolute;
            right: 0;
            top: 13px; }
    .shopping-list-container .shopping-list-main-content .timeline-section {
      width: 100%;
      margin: 40px 0 20px 0;
      position: relative; }
      .shopping-list-container .shopping-list-main-content .timeline-section__top {
        text-align: right; }
        .shopping-list-container .shopping-list-main-content .timeline-section__top .shopping-list-crop {
          padding-right: 20px;
          float: right;
          display: flex;
          flex-direction: row; }
          .shopping-list-container .shopping-list-main-content .timeline-section__top .shopping-list-crop__logo {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 8px; }
            .shopping-list-container .shopping-list-main-content .timeline-section__top .shopping-list-crop__logo--icon {
              width: 18px;
              height: 18px; }
          .shopping-list-container .shopping-list-main-content .timeline-section__top .shopping-list-crop__crop-name {
            color: #839ca9;
            line-height: 32px;
            font-size: 1em; }
      .shopping-list-container .shopping-list-main-content .timeline-section__bottom {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        align-items: flex-end; }
        .shopping-list-container .shopping-list-main-content .timeline-section__bottom .shopping-list-timeline {
          flex: 1;
          margin: 8px; }
          .shopping-list-container .shopping-list-main-content .timeline-section__bottom .shopping-list-timeline__top--crop-element {
            height: 10px;
            margin-bottom: 5px; }
          .shopping-list-container .shopping-list-main-content .timeline-section__bottom .shopping-list-timeline__bottom {
            color: #839ca9;
            padding-top: 5px;
            text-align: center;
            border-top: 1px #839ca9 solid; }
    .shopping-list-container .shopping-list-main-content .applications-section {
      margin: 10px 0;
      position: relative; }
      .shopping-list-container .shopping-list-main-content .applications-section__element--title {
        color: #71bf44;
        font-weight: bold;
        margin: 55px 0 10px 0;
        font-size: 1.2em; }
      .shopping-list-container .shopping-list-main-content .applications-section__element--header {
        color: rgba(131, 156, 169, 0.7);
        font-size: 1em;
        display: flex;
        flex-direction: row;
        padding-left: 1rem; }
        .shopping-list-container .shopping-list-main-content .applications-section__element--header .header-element-1 {
          flex: 1; }
        .shopping-list-container .shopping-list-main-content .applications-section__element--header .header-element-2 {
          flex: 2; }
      .shopping-list-container .shopping-list-main-content .applications-section__element--container {
        position: relative; }
        .shopping-list-container .shopping-list-main-content .applications-section__element--container .application-month {
          page-break-inside: avoid; }
          .shopping-list-container .shopping-list-main-content .applications-section__element--container .application-month__title {
            border-bottom: 1px solid #71bf44;
            width: 15%;
            margin: 1rem 0; }
            .shopping-list-container .shopping-list-main-content .applications-section__element--container .application-month__title--box {
              padding: 0.3rem;
              width: 40%;
              background-color: #71bf44;
              color: #fff;
              text-align: center; }
          .shopping-list-container .shopping-list-main-content .applications-section__element--container .application-month .application-element {
            display: flex;
            margin: 0.5rem 0;
            flex-direction: row;
            align-items: center;
            font-weight: bold;
            font-size: 0.7rem;
            color: #839ca9;
            padding: 0.5rem;
            padding-left: 1rem;
            background-color: rgba(131, 156, 169, 0.05); }
            .shopping-list-container .shopping-list-main-content .applications-section__element--container .application-month .application-element__step {
              background-color: #71bf44;
              color: #fff;
              border-radius: 1rem;
              font-size: 0.5rem;
              padding: 0.2rem;
              text-align: center; }
            .shopping-list-container .shopping-list-main-content .applications-section__element--container .application-month .application-element .width-1 {
              flex: 1; }
            .shopping-list-container .shopping-list-main-content .applications-section__element--container .application-month .application-element .width-2 {
              flex: 2; }
    .shopping-list-container .shopping-list-main-content .products-section {
      position: relative;
      page-break-after: always; }
      .shopping-list-container .shopping-list-main-content .products-section__header {
        display: flex;
        flex-direction: row;
        padding: 5px 0;
        border-bottom: 1px solid #839ca9; }
        .shopping-list-container .shopping-list-main-content .products-section__header--cell-box {
          margin-right: 5px;
          color: #71bf44;
          font-size: 1em; }
          .shopping-list-container .shopping-list-main-content .products-section__header--cell-box.width-1 {
            flex: 1; }
          .shopping-list-container .shopping-list-main-content .products-section__header--cell-box.width-2 {
            flex: 2; }
          .shopping-list-container .shopping-list-main-content .products-section__header--cell-box.width-3 {
            flex: 3; }
      .shopping-list-container .shopping-list-main-content .products-section__container {
        position: relative; }
        .shopping-list-container .shopping-list-main-content .products-section__container--cell {
          position: relative;
          page-break-inside: avoid;
          padding: 0.5rem 0;
          border-bottom: 1px solid rgba(131, 156, 169, 0.2);
          display: flex;
          flex-direction: row; }
          .shopping-list-container .shopping-list-main-content .products-section__container--cell--box {
            font-size: 1em;
            color: #839ca9;
            margin-right: 5px; }
            .shopping-list-container .shopping-list-main-content .products-section__container--cell--box.width-1 {
              flex: 1; }
            .shopping-list-container .shopping-list-main-content .products-section__container--cell--box.width-2 {
              flex: 2; }
            .shopping-list-container .shopping-list-main-content .products-section__container--cell--box.width-3 {
              flex: 3; }
            .shopping-list-container .shopping-list-main-content .products-section__container--cell--box:nth-child(1) {
              color: #71bf44; }
            .shopping-list-container .shopping-list-main-content .products-section__container--cell--box:nth-child(2), .shopping-list-container .shopping-list-main-content .products-section__container--cell--box:nth-child(3) {
              color: #eb6909; }
  .shopping-list-container .notes-section {
    width: 90%;
    margin: 0 auto; }
    .shopping-list-container .notes-section__title {
      color: #71bf44;
      font-weight: bold;
      margin-bottom: 10px;
      margin-top: 20px; }
    .shopping-list-container .notes-section__box {
      border: 1px solid #71bf44;
      height: 400px; }
  .shopping-list-container .legal-section {
    width: 90%;
    margin: 10px auto 20% auto;
    color: #839ca9;
    text-align: justify; }
  .shopping-list-container .shopping-list-footer {
    display: block;
    width: 100%;
    height: 200px;
    position: relative; }
    .shopping-list-container .shopping-list-footer__background {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .shopping-list-container .shopping-list-footer__logos {
      z-index: 1;
      position: absolute;
      top: 35%;
      left: 15%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 70%;
      height: 100px; }
      .shopping-list-container .shopping-list-footer__logos .logo-element {
        z-index: 2;
        width: 30%;
        height: auto;
        color: white; }
        .shopping-list-container .shopping-list-footer__logos .logo-element.lat {
          height: 4rem;
          width: 15rem;
          margin: 1rem -10rem 0 0; }
    .shopping-list-container .shopping-list-footer__web-address {
      color: #fff;
      position: absolute;
      bottom: 10%;
      z-index: 1;
      width: 100%;
      text-align: center; }

.xml-upload {
  display: inline-block;
  position: relative;
  margin-right: 20px; }
  .xml-upload__info {
    display: inline-block; }

.xml-upload-info-button__mobile {
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #71bf44;
  color: #71bf44;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  bottom: 0;
  font-size: 0.8rem;
  width: 1rem;
  height: 1rem;
  top: 7px;
  position: relative; }

.xml-upload-info-button {
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #71bf44;
  color: #71bf44;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  position: absolute;
  bottom: 0;
  font-size: 0.8rem;
  right: -1.5rem;
  width: 1rem;
  height: 1rem;
  top: -7px;
  left: 100%; }
  .xml-upload-info-button__content {
    color: #839ca9;
    text-decoration: none;
    font-size: 0.8rem;
    min-width: 250px; }
    .xml-upload-info-button__content a {
      margin: 0; }
    .xml-upload-info-button__content--link {
      text-transform: capitalize !important; }

@media screen and (max-width: 600px) {
  .xml-upload {
    width: calc(100% - 10px); } }

.forage-crop-text {
  color: #839ca9;
  margin-top: 8px;
  font-size: 0.75rem; }

.copy-crop-rotation-template {
  padding: 2rem 30% 2rem 30%; }
  .copy-crop-rotation-template__crop-rotation-name {
    width: 100%; }
  .copy-crop-rotation-template__bottom {
    width: 100%;
    text-align: center; }
    .copy-crop-rotation-template__bottom--button {
      margin-top: 3rem;
      width: 80%; }

@media only screen and (max-width: 768px) {
  .copy-crop-rotation-template {
    padding: 2rem 5% 2rem 5%; } }

.refer_a_farmer {
  color: #71bf44;
  text-align: center;
  max-width: 600px;
  margin: 1rem auto; }

.edit-organic-fertilizer-dialogue__title {
  color: #839ca9;
  font-size: 1.2rem;
  text-align: center;
  font-weight: 700; }

.edit-organic-fertilizer-dialogue__close {
  position: absolute;
  right: 6px;
  top: 6px;
  width: 40px;
  height: 40px; }

@media screen and (max-width: 600px) {
  .edit-organic-fertilizer-dialogue__actions--delete-button {
    margin-top: 1rem; } }

.draw_the_boundaries {
  text-align: center;
  font-size: 1rem;
  color: #839ca9;
  max-width: 30rem;
  margin: 0 auto 2rem auto; }

@media only screen and (max-width: 600px) {
  .draw_the_boundaries {
    margin: 0 0.5rem 1rem 0.5rem; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .react-bodymovin-container {
    background: url("assets/images/tractor-color.svg") no-repeat;
    background-size: 100%;
    height: 150px;
    width: 300px; }
    .react-bodymovin-container svg {
      display: none; }
  .ie--flex-box-fix,
  .form__buttons {
    flex: 1 1 auto; }
  .btn--picto__svg {
    max-height: 18px; }
  .tab-accordion__child__wrapper {
    width: 100%; }
  /*
  .timeline__my-plan {
    justify-content: flex-start;
    margin-bottom: 30px;

    .timeline__item {
      flex: 1 1 auto;
      max-width: inherit;
      min-width: 50px;
    }

    .timeline__item--label:only-child {
      margin-top: 0;
    }
  }
  */
  .round-label--balance {
    height: 40px; }
  .logo {
    height: 52px; }
  .picto-chevron--left {
    height: 20px; }
  /*
  .content-wrapper {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    overflow: auto;
  }
  */
  .content-main {
    display: block;
    flex: 1 0 auto; }
  .picto-title {
    flex: 0 1 auto; }
  .splash-screen__container {
    overflow: auto; }
  .metro-line__picto-wrapper {
    height: 48px; }
  .main-nav__item__picto img {
    margin: 0; }
  .tab-accordion__button {
    min-width: 300px; }
  .tab-row-picto svg {
    max-height: 36px; }
  input[type='range'] {
    background: transparent;
    height: 45px;
    margin: 0;
    padding: 0; }
    input[type='range']::-ms-thumb {
      background: #839ca9;
      border: none;
      border-radius: 50%;
      box-sizing: border-box;
      height: 36px;
      width: 36px;
      background: #fff;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.14); }
    input[type='range']::-ms-fill-lower {
      background: #71bf44; }
    input[type='range']::-ms-fill-upper {
      background: rgba(131, 156, 169, 0.4); }
    input[type='range']::-ms-track {
      background: #839ca9;
      box-sizing: border-box;
      height: 1px;
      border: none; }
    input[type='range']::-ms-tooltip {
      display: none; }
  .form-slider--progress {
    display: none; }
  .form__field-picto p {
    flex: 0; }
  .logo-lat {
    height: 78px;
    width: 130px; }
  .month-list__crop-picto {
    height: 46px; }
  .month-list__link button {
    display: none; }
  .layout__buttons-wrapper {
    flex: 0 1 auto; }
  .month-list__month-item__wrapper:after {
    content: '';
    flex: auto;
    position: relative;
    display: block; }
  .main-nav__item__picto img {
    height: 54px;
    width: 54px; }
  .tab-accordion__header {
    margin-bottom: 30px;
    padding-top: 0; }
    .tab-accordion__header .picto-separator {
      margin-top: 0; }
  .timeline__my-plan .timeline--item--empty {
    display: none; }
  .timeline__my-plan .timeline--item {
    flex: 1 1 auto; }
  .timeline__my-plan .timeline__box-info {
    cursor: default;
    pointer-events: none; }
  .tab-accordion__children .tab-accordion__panel,
  .tab-accordion__children .fade-out-schrink.tab-accordion__panel {
    order: 0; }
  .tab-accordion__children .tab-accordion__button {
    justify-content: flex-start; } }

@media only screen and (min-width: 768px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .month-list__link,
  .month-list__no-link {
    width: calc((100% / 3) - (74px * 2) / 3); }
  .react-bodymovin-container {
    height: 300px;
    width: 600px; } }

@media only screen and (min-width: 1280px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .footer__copyright {
    padding: 0;
    width: auto; }
  .footer__link {
    margin-bottom: 0 !important;
    margin-top: 0 !important; }
  .month-list__link,
  .month-list__no-link {
    width: calc((100% / 3) - (74px * 2) / 3); } }

@media only screen and (min-width: 1600px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .month-list__link,
  .month-list__no-link {
    width: calc((100% / 4) - (74px * 3) / 4); } }

@supports (-ms-ime-align: auto) {
  .form-slider--progress {
    top: 22px;
    z-index: -1; }
  input[type='range'] {
    background: transparent;
    height: 44px;
    margin: 0;
    padding: 0; }
    input[type='range']::-ms-thumb {
      background: #839ca9;
      border: none;
      border-radius: 50%;
      box-sizing: border-box;
      height: 36px;
      width: 36px;
      background: #fff;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.14); }
    input[type='range']::-ms-fill-lower {
      background: #71bf44; }
    input[type='range']::-ms-fill-upper {
      background: rgba(131, 156, 169, 0.4); }
    input[type='range']::-ms-track {
      background: #839ca9;
      box-sizing: border-box;
      height: 1px;
      border: none; }
    input[type='range']::-ms-tooltip {
      display: none; } }

.print__back-cover {
  display: none; }

.print-only {
  display: none; }

@media print {
  .content-main {
    display: block; }
  /* FOR DEBUGGING REFERENCE ADDED the BREAK BEFORE */
  .month-container .month-list__month-item__wrapper::after {
    color: #839ca9;
    display: inline-block;
    font-size: 10pt;
    margin: 0 auto;
    margin-top: 15px;
    text-align: center;
    width: 100%;
    break-before: always;
    page-break-before: always; }
  .page-break__before {
    display: block;
    break-before: always;
    page-break-before: always; }
  .hambutton,
  button:not(.tab-accordion__button),
  a[role='button'],
  .tab-accordion__chevron,
  .subheader__picto-wrapper,
  .main-nav__wrapper,
  .round-label,
  .subheader__buttons-wrapper,
  .picto-separator,
  .footer {
    display: none !important; }
  .search-field__wrapper {
    margin-top: 40px !important;
    display: none; }
  .search-field--active {
    display: inherit !important; }
  .print-only {
    display: block;
    display: initial; }
  @page {
    orientation: portrait;
    size: portrait; }
  * {
    color-adjust: exact;
    -webkit-print-color-adjust: exact; }
  p,
  h4 {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    display: block; }
  .slick-slider {
    display: none;
    page-break-inside: avoid;
    break-inside: avoid;
    width: 100%; }
  .slick-track {
    display: none;
    transform: translate3d(0, 0, 0) !important;
    width: 100% !important; }
  .slick-arrow {
    display: none;
    display: none !important; }
  .content-row__grid-wrapper {
    display: block;
    min-width: 100%;
    width: 100%; }
  .month-container {
    clear: both;
    display: block;
    break-inside: avoid;
    page-break-inside: avoid;
    width: 100%; }
  .mysales__item__wrapper {
    clear: both;
    display: block;
    page-break-inside: avoid;
    margin-bottom: 0px; }
    .mysales__item__wrapper::before {
      vertical-align: middle; }
    .mysales__item__wrapper::after {
      display: none; }
    .mysales__item__wrapper .month-list__no-link {
      vertical-align: middle;
      width: 300px; }
  .mysales__farmer-list__wrapper {
    display: inline-block;
    margin-left: 10px;
    margin-right: 40px;
    vertical-align: middle; }
    .mysales__farmer-list__wrapper > div {
      float: left; }
  .mysales__farmer-list__quantity {
    float: left; }
    .mysales__farmer-list__quantity p {
      width: auto; }
  .month-list__picto-wrapper {
    display: block;
    page-break-inside: avoid;
    min-height: 60px;
    margin-top: 30px;
    width: 100%; }
  .month-list__month-item__wrapper {
    display: block;
    width: 100%;
    overflow: auto; }
  .month-list__no-link {
    display: inline-block !important;
    min-height: 230px;
    width: 300px;
    break-inside: avoid;
    page-break-inside: avoid;
    margin-right: 30px;
    vertical-align: middle; }
  .month-list__title {
    display: inline-block;
    font-size: 12pt;
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 30px;
    padding-top: 15px;
    width: 100%; }
  .month-list__link {
    display: inline-block !important;
    margin-top: 30px;
    min-height: 250px;
    padding-left: 30px;
    width: calc(50% - 63px);
    vertical-align: middle; }
    .month-list__link:nth-of-type(2n) {
      margin-left: 10%; }
    .month-list__link:nth-of-type(2n + 1) {
      clear: both; }
  .month-list__link, .month-list__no-link {
    border: 2px solid rgba(131, 156, 169, 0.4);
    page-break-inside: avoid; }
    .month-list__link::after, .month-list__no-link::after {
      display: none; }
  .month-list__icon-button {
    display: none; }
  .month-list__crop-picto {
    min-height: 50px;
    page-break-inside: avoid; }
  .my-overview--reseller .month-list__title {
    min-height: inherit; }
  .content__my-products {
    flex-basis: 100%;
    min-width: 100%;
    width: 100%; }
    .content__my-products .month-list__no-link {
      height: 200px; }
  .page-header {
    align-items: flex-end;
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 330px;
    justify-content: flex-end;
    position: relative;
    width: 100%; }
  .print-logo {
    bottom: 0;
    left: 0;
    height: 104px;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 234px;
    display: block; }
  .print-header-images {
    height: 330px;
    width: 1260px; }
  .print-header {
    position: absolute;
    width: 100%;
    height: 330px;
    left: 0;
    object-fit: cover;
    top: 0;
    z-index: -1; }
  .toolbar {
    height: initial;
    min-height: initial;
    padding: 20px 24px 5px;
    text-align: right;
    width: 100%; }
  .appbar-title {
    color: #fff;
    font-size: 14pt;
    margin: 0;
    padding: 0; }
    .appbar-title--hamburger {
      margin-left: 0; }
  .subheader {
    margin: 0;
    padding: 5px 24px 20px;
    text-align: right;
    width: 100%;
    z-index: 9; }
    .subheader__title {
      color: #fff;
      font-size: 18pt;
      margin: 0;
      padding: 0; }
  .tab-accordion {
    display: block; }
    .tab-accordion.accordion--myplan {
      display: table; }
      .tab-accordion.accordion--myplan .tab-accordion__panel {
        float: none; }
        .tab-accordion.accordion--myplan .tab-accordion__panel:nth-of-type(2) {
          display: table-header-group; }
    .tab-accordion::after {
      clear: both;
      content: ''; }
    .tab-accordion__wrapper {
      display: block; }
    .tab-accordion__button {
      display: none; }
    .tab-accordion__children {
      display: block; }
    .tab-accordion__title {
      color: #71bf44 !important; }
    .tab-accordion__button {
      padding: 0 !important; }
      .tab-accordion__button::after, .tab-accordion__button::before {
        display: none; }
    .tab-accordion__header__main-title {
      color: #71bf44;
      display: block;
      margin-bottom: 0;
      text-align: left; }
    .tab-accordion__header__sub-title {
      color: #71bf44;
      display: block;
      margin-top: 0;
      text-align: left; }
    .tab-accordion__header__title {
      color: #71bf44;
      display: block;
      font-size: 16pt;
      margin-top: 60px;
      text-align: left; }
    .tab-accordion__panel {
      display: block;
      padding: 0; }
      .tab-accordion__panel-schrink {
        height: inherit; }
      .tab-accordion__panel:nth-of-type(2) {
        margin-top: 20px;
        page-break-inside: avoid; }
        .tab-accordion__panel:nth-of-type(2) .content-row__grid-wrapper {
          position: relative; }
        .tab-accordion__panel:nth-of-type(2)::after {
          bottom: 0;
          color: #839ca9;
          font-size: 10pt;
          left: 0;
          margin: 0 auto;
          margin-top: auto;
          right: 0;
          text-align: center;
          width: 100%;
          display: inline-block; }
    .tab-accordion__children .tab-accordion__child__wrapper {
      display: block;
      overflow: auto;
      margin-top: 60px;
      page-break-inside: avoid;
      position: relative; }
      .tab-accordion__children .tab-accordion__child__wrapper:first-of-type {
        margin-top: 0; }
      .tab-accordion__children .tab-accordion__child__wrapper:nth-child(odd)::after, .tab-accordion__children .tab-accordion__child__wrapper:nth-last-of-type(n + 1):last-of-type::after {
        display: inline-block;
        font-size: 10pt;
        margin: 0 auto;
        margin-top: 15px;
        text-align: center;
        width: 100%; }
  .appbar .content-row__grid-wrapper,
  .tab-accordion__children .content-row__grid-wrapper {
    max-width: 100% !important; }
  .tab-column {
    display: block;
    float: left;
    page-break-inside: always;
    width: 48%; }
    .tab-column__wrapper {
      display: block !important; }
    .tab-column * {
      page-break-inside: always; }
    .tab-column:first-child:nth-last-child(2) {
      border: 1px solid rgba(131, 156, 169, 0.4);
      border-radius: 3px;
      float: right;
      margin-top: 30px;
      padding: 24px;
      position: relative; }
      .tab-column:first-child:nth-last-child(2)::before {
        background: transparent;
        border-left: 20px solid rgba(131, 156, 169, 0.4);
        border-right: 40px solid transparent;
        border-top: 20px solid transparent;
        content: '';
        display: block;
        height: 0;
        left: -16px;
        position: absolute;
        top: 24px;
        transform: rotate(45deg);
        width: 0;
        z-index: 1; }
      .tab-column:first-child:nth-last-child(2)::after {
        background: transparent;
        border-left: 18px solid #fff;
        border-right: 40px solid transparent;
        border-top: 18px solid transparent;
        content: '';
        display: block;
        height: 0;
        left: -16px;
        position: absolute;
        top: 24px;
        transform: rotate(45deg) translateX(2px);
        width: 0;
        z-index: 9; }
    .tab-column:last-of-type {
      padding-right: 0; }
    .tab-column > button {
      display: none; }
    .tab-column::before {
      display: none; }
    .tab-column__wrapper {
      display: block;
      page-break-inside: always; }
  .timeline__overview {
    display: flex;
    flex-direction: row;
    align-items: flex-end; }
  .timeline__card {
    page-break-inside: avoid;
    box-shadow: none !important;
    border: 2px solid rgba(131, 156, 169, 0.4); }
    .timeline__card .expansion-panel__chevron {
      display: none; }
  .timeline__my-plan {
    margin-bottom: 15px;
    margin-top: 15px; }
    .timeline__my-plan .timeline__item {
      font-size: 10pt;
      flex: 1 2 auto; }
      .timeline__my-plan .timeline__item--empty {
        display: none; }
      .timeline__my-plan .timeline__item--active.timeline__box-info {
        border-color: #839ca9; }
        .timeline__my-plan .timeline__item--active.timeline__box-info .timeline__step-name {
          color: #505f66; }
  .timeline__step-num, .timeline__step-box {
    background-color: #71bf44; }
  .timeline__step-name {
    color: #505f66;
    font-size: 12pt; }
  .timeline__item--active.timeline__box-info ~ .timeline__item--label {
    color: #839ca9; }
  .periodic-row {
    width: 48%; }
    .periodic-row.greyed-out {
      display: none; }
    .tab-column .periodic-row {
      width: auto; }
  .nformat--container {
    float: right;
    overflow: hidden;
    width: 48%; }
  .nformat__title-row {
    position: absolute;
    right: 0;
    top: 42px;
    width: 48%; }
  .nformat-graph--check {
    left: 0;
    margin: auto;
    position: relative;
    right: 0;
    top: -22px; }
  .nformat-graph--label {
    padding: 0;
    width: 30%; }
  .nformat-graph--bar {
    margin-top: 24px;
    padding: 0;
    width: 30%; }
    .nformat-graph--bar p {
      display: inline-block; }
  .nformat-graph--bar-container, .nformat-graph--label-container {
    width: 100%; }
  .nformat-microscope {
    display: none; }
    .nformat-microscope + div {
      display: none; }
  .print__back-cover {
    clear: both;
    display: block;
    max-height: 100%;
    padding-top: 30px;
    position: relative;
    size: a4;
    page-break-before: always;
    page-break-inside: avoid; }
  .print-page-footer {
    align-items: flex-end;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    text-align: center;
    width: 100%; }
  .print-notes {
    border: solid #839ca9 2px;
    color: #839ca9;
    display: block;
    height: 450px;
    margin-bottom: 2em;
    padding: 15px;
    width: 100%; }
  .print__back-info-wrapper {
    margin-top: 30px; }
  .print-legal {
    width: 100%; }
  .print-left-logo {
    width: 300px; }
  .print-right-logo {
    width: 200px; }
  .print-page-footer__copyrights {
    font-size: 10pt;
    text-align: center;
    width: 100%; }
  *:last-child {
    page-break-after: avoid; }
  @-moz-document url-prefix() {
    .tab-accordion__panel,
    .month-list__link,
    .month-list__picto-wrapper .month-list__plot-size {
      display: block; }
    .page-header {
      background: none;
      overflow: hidden;
      position: relative; }
    .tab-accordion.accordion--myplan .tab-accordion__panel:nth-of-type(2) {
      display: block; } } }

@media print and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .nformat__title-row {
    top: 0; } }
