.key_value {
  &__wrapper {
    color: $c--secondary;
    list-style-type: none;
    justify-content: space-between;
    max-width: 500px;
    margin-bottom: 15px;
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
  &__value {
    color: $c--secondary-dark;
    // width: 120px;
    font-size: 12pt;
    line-height: 14pt;
    margin-top: 3px; //
    &--with-radio {
      //
      margin-left: 35px;
      width: calc(100% - 35px);
      margin-top: 0px;
    }
  }
  &__radio-label {
    margin-top: -3px;
    span:first-child {
      padding-top: 0;
      padding-bottom: 0;
    }
    span:nth-child(2) {
      color: $c--secondary;
      font-size: 12pt;
      line-height: 14pt;
    }
  }
}

@media only screen and (min-width: 380px) {
  .key_value {
    &__wrapper {
      display: flex;
    }
    &__key {
      width: calc(100% - 130px);
      margin-right: 10px;
    }
    &__value {
      margin-top: 2px; //
      width: 120px;
      &--with-radio {
        //
        margin-left: 0px;
        width: 120px;
        margin-top: 1px;
      }
    }
  }
}
