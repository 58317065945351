.plan-list-cell {
  display: flex;
  flex-direction: row;
  background-color: $c--secondary-light-05;
  border-radius: 0.2rem;
  color: $c--secondary;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem;
  margin: 0.5rem 0;
  align-items: center;
  &__checkbox {
    background-color: $c--primary;
    font-size: 14px;
    color: $white;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
  }
  &__1 {
    flex: 1;
    margin: 0 0.5rem;
  }
  &__2 {
    flex: 2;
    margin: 0 0.5rem;
  }
  &__3 {
    flex: 3;
    margin: 0 0.5rem;
  }
  .button-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-weight: 400;
    &__button {
      padding: 0 0.5rem;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      &:not(:first-child) {
        border-left: 1px solid $c--secondary;
      }
      &.green {
        color: $c--primary;
      }
    }
  }
}
