.lp-banner {
  padding: 3rem 10%;
  display: flex;
  flex-direction: row;

  &__left-side {
    padding-right: 1rem;
    width: 100%;
  }

  &__right-side {
    padding-left: 1rem;
    width: 100%;
  }

  .left-side {
    &__alert {
      font-size: 2rem;
      font-weight: bold;
      color: $c--secondary;

      span {
        color: $c--primary;
      }
    }

    &__description {
      color: $c--secondary;
      font-size: 1rem;
      padding: 1rem 0;
    }
  }

  .right-side {
    &__image {
      width: 100%;
      height: auto;
    }
  }
}

@media only screen and (max-width: $screen-sm-max) {
  .lp-banner {
    flex-direction: column-reverse;
    &__left-side {
      padding: 3rem 0 2rem 0;
    }

    &__right-side {
      padding: 0;
    }
  }
}
