.nformat-microscope {
  margin: 20px auto;
  flex-basis: 80px;
  flex-shrink: 0;
  width: 80px;
}

.nformat-graph--bar-container {
  width: 320px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 auto;
}

.nformat-graph--label-container {
  width: 320px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
}

.nformat-graph--label {
  width: 100px;
  text-align: center;
  padding: 0 10px;
}

.nformat-graph--bar {
  width: 100px;
  height: 100px;
  background-color: $c--secondary;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 20px;
  position: relative;

  p {
    color: white;
    text-align: center;
  }
}

.nformat-graph--check {
  height: 32px;
  padding: 8px 6px;
  position: absolute;
  right: 34px;
  top: -16px;
  width: 32px;
  background-color: white;
  border-radius: 20px;
  border: 2px solid $c--primary;

  path {
    fill: $c--primary;
  }
}

.nformat-graph--bar--first {
  height: 90px;
  background-color: $c--primary;
}

.nformat-graph--bar--second {
  height: 120px;
}

.nformat-graph--bar--third {
  height: 140px;
}

.nformat--explanation-title {
  color: #839ca9;
  font-size: 1.125rem;
  font-weight: 400;
  max-width: 400px;
  margin: 20px auto;
  text-align: center;
}

.nformat--explanation-subtitle {
  color: #839ca9;
  font-size: 0.75rem;
  font-weight: 400;
  max-width: 400px;
  margin: 20px auto;
  text-align: center;
}

.nformat__explanation-link {
  text-align: center;
}

.nformat__title-row {
  margin: 24px 0 30px 0;
  align-items: center;
  display: flex;

  svg {
    margin-right: 11px;
    margin-left: 3px;
  }
}

.nformat--container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

@media screen and (min-width: $screen-lg-min) {
  .nformat-graph--container {
    margin-left: 17px;
  }

  .nformat-microscope {
    display: none;
  }

  .nformat--container {
    flex-direction: row;
  }

  .nformat--explanation-title {
    text-align: left;
  }

  .nformat--explanation-subtitle {
    text-align: left;
  }
}
