.footer-top {
  background-size: cover;
  background-position: center;
  min-height: 70vh;
  padding: 0 10%;
  &__box {
    background-color: $c--primary--pale-dark-5;
    width: 50%;
    color: $white;
    padding: 2rem 15% 2rem 2rem;
    &--logo {
      width: 40%;
      padding: 1.5rem 0.5rem 0.5rem 0.5rem;
    }
    &--text {
      font-size: 0.6rem;
      padding-bottom: 1.5rem;
    }
    &--title {
      font-size: 2.5rem;
    }
    &--content {
      font-size: 1rem;
      margin: 1rem 0;
    }
  }
}

@media only screen and (max-width: $screen-md-max) {
  .footer-top {
    &__box {
      width: 70%;
    }
  }
}

@media only screen and (max-width: $screen-md-mui-min) {
  .footer-top {
    &__box {
      width: 100%;
    }
  }
}

@media only screen and (max-width: $screen-xsm-min) {
  .footer-top {
    padding: 0;
    &__box {
      &--logo {
        width: 60%;
      }
      &--title {
        font-size: 1.5rem;
      }
    }
  }
}
