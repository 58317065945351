.plot-xml-list {
  border-top: $c--secondary-light-2 solid 1px;
  margin-top: 2rem;
  &__top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: $c--secondary;
    font-size: 1rem;
    &--title {
      color: $c--secondary;
      font-weight: bold;
      font-size: 1.2rem;
      padding: 1.2rem 0 1rem;
    }
    &--select-all {
      display: none;
      &--checkbox {
        padding: 0.2rem 0;
        margin-left: 1rem;
      }
    }
  }
  &__farmer-selector {
    max-width: 250px;
  }
  &__table {
    &--header {
      color: $c--secondary;
      font-weight: bold;
      font-size: 1rem;
      margin: 0.5rem;
      padding: 0.5rem;
      padding-left: 0;
      margin-left: 0;
      display: flex;
      flex-direction: row;
      align-content: center;
      line-height: 2rem;
      &--check {
        width: 1.5rem;
        &--checkbox {
          padding: 0.2rem 0;
        }
      }
      &--column {
        &:first-child {
          margin-left: 0;
        }
        margin-left: 1rem;
      }
      &--1 {
        flex: 1;
        &.numbers {
          text-align: center;
        }
      }
      &--2 {
        flex: 2;
        &.with-select {
          min-width: 200px;
        }
      }
      &--3 {
        flex: 3;
      }
      &--4 {
        flex: 4;
      }
    }
    &--content {
      &--section-header {
        margin: 0.5rem 0 0.2rem 0;
        padding: 0.5rem;
        background-color: $c--secondary-light-2;
        width: 100%;
        border-radius: 0.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        &--right {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          width: 100%;
          &--1 {
            flex: 1;
            &.numbers {
              text-align: center;
            }
          }
          &--2 {
            flex: 2;
            &.with-select {
              min-width: 200px;
            }
          }
          &--3 {
            flex: 3;
          }
          &--4 {
            flex: 4;
          }
        }
        &--input {
          margin-left: 1rem;
          min-width: 240px;
        }
        &--check {
          padding: 0.2rem 0;
        }
      }
    }
  }
  &__bottom {
    &__mobile {
      display: none;
      margin-bottom: 1rem;
    }
    &:not(:first-child) {
      margin-left: 1rem;
    }
    padding-top: 1rem;
    text-align: right;
    &--button {
      text-transform: initial;
      border-radius: 15px;
      &--delete {
        background-color: $c--secondary-light-2;
        border-radius: 15px;
        text-transform: initial;
        margin-left: 1rem;
        &.active {
          background-color: $c--orange;
        }
      }
    }
  }
}

@media only screen and (max-width: $screen-xl-min) {
  .plot-xml-list {
    padding: 2rem 0 2rem 0;
    &__top {
      &--select-all {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: right;
      }
    }
    &__table {
      &--header {
        display: none;
      }
      &--content {
        max-height: none;
      }
    }
    &__bottom {
      display: none;
      &__mobile {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }
  }
}

@media only screen and (max-width: $screen-lg-min) {
  .plot-xml-list {
    &__table {
      &--content {
        &--section-header {
          &--right {
            flex-direction: row;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $screen-md-min) {
  .plot-xml-list {
    &__table {
      &--content {
        &--section-header {
          &--right {
            flex-direction: column;
          }
        }
      }
    }
  }
}
