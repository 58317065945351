.onboarding--container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .slick-slider {
    width: calc(100% - 60px);
  }
}
.picto--crop {
  width: 38px;
}

.picto--plot {
  width: 173px;
}

.picto--usage {
  width: 98px;
}

.picto--soil {
  width: 121px;
}

.slick-slider-inner {
  .slide-container {
    justify-content: end;
  }
}
