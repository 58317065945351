.my-plans-calendar {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 1rem 0;
  align-items: flex-end;
  &__element {
    flex: 1;
    &--container {
      display: flex;
      flex-direction: column;
      align-items: center;
      &--plan-element {
        background-color: $c--secondary;
        width: 80%;
        height: 0.5rem;
        margin-top: 0.2rem;
        border-radius: 0.2rem;
      }
    }
    &--interval {
      border-top: dashed 1px $c--secondary;
      color: $c--secondary;
      font-size: 0.8rem;
      text-align: center;
      padding-top: 0.2rem;
    }
  }
}
