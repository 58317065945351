.add-plan-selection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  min-height: 60vh;
  &__option {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100px;
    &:last-child {
      margin-left: 10%;
    }
    &--icon {
      padding: 1rem;
      width: 100%;
      border-radius: 50%;
      height: 100px;
      background-color: $c--primary--pale;
      text-align: center;
    }
    &--text {
      color: $c--primary;
      font-size: 1rem;
      text-align: center;
      margin-top: 0.5rem;
      font-weight: bold;
      position: relative;
    }
  }
}
