.periodic-element {
  align-items: center;
  color: $c--secondary;
  display: flex;
  justify-content: center;
  font-size: 1.25rem;
  font-weight: 700;
  border: 2px solid $c--secondary;
  height: 44px;
  min-width: 44px;
  text-decoration: none;
  span {
    color: $c--secondary;
  }
  &:focus {
    border: 2px solid $c--primary;
    span {
      color: $c--primary;
    }
  }
}
