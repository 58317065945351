.app-busy-placeholder {
  align-items: center;
  background-color: $c--secondary-light-7;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999;

  .form__spinner {
    position: fixed;
    top: 50%;

    svg {
      color: $white;
    }
  }

  &--text {
    color: $white;
    font-size: 1.4rem;
    max-width: 90%;
    position: fixed;
    text-align: center;
    top: calc(50% + 50px);
  }
  &--white {
    background-color: white;
    .form__spinner {
      svg {
        color: $c--secondary;
      }
    }
  }
}

.linear-progress--in-container {
  height: 2px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.error_text {
  width: 100%;
  text-align: center;
}

.logo-nutriguide--white {
  path {
    fill: white;
  }
}
