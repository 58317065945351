.printer-container {
  display: none;
}

@media print {
  .do-not-print {
    display: none;
  }
  .printer-container {
    display: block;
  }
  @page :first {
    margin-top: 0;
  }
  @page :last {
    margin-bottom: 0;
  }
}
