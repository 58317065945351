// FORMS
.form {
  &__wrapper {
    padding: 0 8px;
    width: 100%;
    flex: 0;
    max-width: 600px;
  }
  &__section {
    display: inline-block;
    margin: 8px 0;
    padding: 24px 0;
    position: relative;
    width: 100%;
    &.periodic-inline {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    &__info {
      color: $c--secondary;
      size: 0.9rem;
    }
    &__row {
      margin-top: 30px;
      & > .form__title {
        margin-top: 0;
      }
    }
    &--sm {
      display: inline-block;
      padding: 20px 60px;
      width: 100%;
      &:last-of-type {
        padding-bottom: 0;
      }
    }
  }
  &__row {
    &__picto-field {
      align-items: center;
      display: flex;
      margin-top: 24px;
      .form__field {
        margin-bottom: 16px;
        margin-top: 0;
      }
      svg {
        height: 20px;
        margin-right: 14px;
        width: 20px;
      }
    }
    &--two-inputs {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      & > div {
        width: 40%;
      }
    }
  }
  &__break-message {
    display: inline-block;
    padding-top: 0;
    text-align: center;
    width: 100%;
    p {
      font-size: 1.125rem;
      &.text-small {
        font-size: $fs-small;
        margin-bottom: 0;
      }
    }
  }
  &__field {
    margin-bottom: 0;
    margin-top: 24px;
    width: 100%;
    label[data-shrink='false'] {
      color: $c--secondary;
    }
    &:nth-of-type(2) {
      align-self: flex-start;
    }
  }
  &__postalcode {
    margin-top: 15px;
    width: 100%;
    padding: 0 0.5rem;
  }
  &__title {
    & + .form__field,
    & + .form__section__row {
      margin-top: 20px;
    }
  }
  &__radio--column {
    label {
      margin-bottom: -6px;
      margin-right: auto;
    }
  }
  &__picto-select {
    background-color: $c--primary--pale;
    border-radius: 50%;
    height: 100px;
    transition:
      background-color 0.2s,
      border 0.2s;
    width: 100px;
    &__wrapper {
      flex-direction: column;
      margin: 0;
      text-align: center;
      &:hover .form__picto-select {
        background-color: $c--primary--pale-dark;
        border: 1px solid $c--primary--pale-dark-2;
      }
    }
    &--active & {
      background-color: $c--primary--pale-dark;
      border: 1px solid $c--primary--pale-dark-2;
      &:before {
        animation-duration: 0.4s;
        animation-name: fadeIn;
        background: $c--primary url('assets/images/check.svg') center no-repeat;
        background-size: 17px 11px;
        border-radius: 50%;
        content: '';
        height: 28px;
        padding: 8px 6px;
        position: absolute;
        right: 0;
        top: 0;
        width: 28px;
      }
    }
    &__title {
      color: $c--primary;
      font-size: 1rem;
      margin-top: 10px;
      text-transform: uppercase;
    }
  }
  &__radio {
    flex-direction: row;
    margin-bottom: -9px;
    margin-top: 9px; // 20px - ((56px - 34px) / 2)
    & > label {
      margin-left: -11px;
      margin-right: 40px;
    }
    span {
      color: $c--secondary;
    }
    &__button {
      height: 56px;
      width: 56px;
      &--disabled {
        & > span:first-of-type {
          border: 2px solid $c--secondary-light-5;
          border-radius: 50%;
          height: 34px;
          width: 34px;
        }
        svg {
          background: transparent;
          border-radius: 50%;
          color: transparent;
          height: 22px;
          stroke: transparent;
          width: 22px;
        }
        &.form__radio--checked {
          svg {
            background: $c--secondary-light-5;
          }
          & + span {
            color: $c--secondary-dark;
          }
        }
      }
      & > span:first-of-type {
        border: 2px solid $c--secondary;
        border-radius: 50%;
        height: 34px;
        width: 34px;
      }
      svg {
        background: transparent;
        border-radius: 50%;
        color: transparent;
        height: 22px;
        stroke: transparent;
        width: 22px;
      }
      &.form__radio--checked {
        svg {
          background: $c--primary;
        }
        & + span {
          color: $c--secondary-dark;
        }
      }
      &__white {
        span {
          color: $white;
        }
        .form__radio--checked {
          svg {
            background: $white;
          }
          & + span {
            color: $white;
          }
        }
        .form__radio__button > span:first-of-type {
          border: 2px solid $white;
        }
      }
    }
  }
  &__error-text {
    bottom: -20px;
    color: $c--error !important;
    position: absolute;
  }
  &__error-message {
    transition: all 0.4s;
    h2 {
      color: $c--error;
      font-size: 1.2rem;
      font-weight: 300;
      margin: 0;
      text-align: center;
      width: 100%;
    }
    & + button:first-of-type {
      margin-top: 12px; //36px - 24px
    }
  }
  &__buttons {
    padding: 16px 0 0;
    width: calc(100% - 16px);
    button:first-of-type,
    a:first-of-type {
      margin-top: 36px;
      & + button,
      & + a {
        margin-top: 0;
      }
    }
  }
  &__card {
    background-color: $c--secondary-light-05;
    border: 1px solid $c--secondary-light;
    border-radius: 4px;
    margin: 20px 0;
    padding: 30px;
    padding-top: 0;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__spinner {
    margin: 0 auto;
    display: block;
  }
  &__progress-field {
    bottom: 0;
    left: 0;
    height: 2px;
    position: absolute;
    width: 100%;
  }
}

.picto-separator {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

p[class*='MuiFormHelperText']:not(.form__error-text) {
  position: relative;
  & .form__progress-field {
    bottom: initial;
    top: -5px;
  }
}

.dropdown-white {
  label {
    color: rgba(255, 255, 255, 0.7);
    & + div {
      &::before {
        border-color: rgba(255, 255, 255, 0.7);
      }
      &::after {
        background-color: rgba(255, 255, 255, 0.87);
      }
    }
    &[data-shrink='false'] {
      color: rgba(255, 255, 255, 0.7);
    }
  }
  div {
    color: $white;
  }
  svg {
    fill: $white;
  }
}

.checkbox__wrapper {
  align-self: flex-start;
  margin-bottom: auto;
  margin-top: -12px;
}

.dropdown-long-protect {
  white-space: normal;
  line-height: initial;
}

.dropdown-unfix-height {
  height: initial;
}

// .form__field {
//   z-index: 1;
// }

@media only screen and (min-width: $screen-sm-min) {
  .form {
    &__picto-select {
      &--square {
        align-items: center;
        display: flex;
        height: 184px;
        justify-content: center;
        width: 284px;
        &__wrapper:first-of-type {
          margin: 0;
        }
      }
    }
    &-section {
      display: inline-block;
      margin: 16px 0;
    }
  }
}
