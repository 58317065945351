@mixin backgroundBody {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin cellBody {
  &.width-1 {
    flex: 1;
  }
  &.width-2 {
    flex: 2;
  }
  &.width-3 {
    flex: 3;
  }
}

.shopping-list-container {
  font-size: 12px;
  .shopping-list-header {
    position: relative;
    display: block;
    width: 100%;
    height: 200px;
    &__background {
      @include backgroundBody();
    }
    &__logo {
      position: absolute;
      z-index: 1;
      width: 20%;
      height: auto;
      left: 5%;
      top: 20%;
    }
  }
  .shopping-list-main-content {
    position: relative;
    &__title-section {
      width: 100%;
      text-align: center;
      color: $c--primary;
      margin: 20px 0;
      &--title {
        font-weight: bold;
        font-size: 2em;
      }
      &--subtitle {
        font-size: 1.5em;
      }
    }
    .plot-section {
      width: 100%;
      margin: 1em 0;
      &__title {
        color: $c--primary;
        width: 50%;
        border-bottom: solid 1px $c--secondary;
        padding: 0.2rem;
        margin: 1em 0;
        font-weight: bold;
        font-size: 1.2em;
      }
      &__properties {
        display: flex;
        flex-direction: row;
        &--item {
          flex: 1;
          margin: 0.5em 0;
          position: relative;
          &--sub {
            margin: 0.5em 0;
            display: flex;
            flex-direction: row;
            .plot-property-key {
              flex: 1;
              color: $c--primary;
            }
            .plot-property-value {
              flex: 1;
              color: $c--secondary;
              font-weight: bold;
            }
          }
          &:not(:first-child) {
            padding-left: 1em;
          }
          &:not(:last-child)::after {
            content: '';
            height: 40px;
            width: 1px;
            background-color: $c--secondary;
            position: absolute;
            right: 0;
            top: 13px;
          }
        }
      }
    }
    .timeline-section {
      width: 100%;
      margin: 40px 0 20px 0;
      position: relative;
      &__top {
        text-align: right;
        .shopping-list-crop {
          padding-right: 20px;
          float: right;
          display: flex;
          flex-direction: row;
          &__logo {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 8px;
            &--icon {
              width: 18px;
              height: 18px;
            }
          }
          &__crop-name {
            color: $c--secondary;
            line-height: 32px;
            font-size: 1em;
          }
        }
      }
      &__bottom {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        align-items: flex-end;
        .shopping-list-timeline {
          flex: 1;
          margin: 8px;
          &__top {
            &--crop-element {
              height: 10px;
              margin-bottom: 5px;
            }
          }
          &__bottom {
            color: $c--secondary;
            padding-top: 5px;
            text-align: center;
            border-top: 1px $c--secondary solid;
          }
        }
      }
    }
    .applications-section {
      margin: 10px 0;
      position: relative;
      &__element {
        &--title {
          color: $c--primary;
          font-weight: bold;
          margin: 55px 0 10px 0;
          font-size: 1.2em;
        }
        &--header {
          color: $c--secondary-light-7;
          font-size: 1em;
          display: flex;
          flex-direction: row;
          padding-left: 1rem;
          .header-element-1 {
            flex: 1;
          }
          .header-element-2 {
            flex: 2;
          }
        }
        &--container {
          .application-month {
            page-break-inside: avoid;
            &__title {
              border-bottom: 1px solid $c--primary;
              width: 15%;
              margin: 1rem 0;
              &--box {
                padding: 0.3rem;
                width: 40%;
                background-color: $c--primary;
                color: $white;
                text-align: center;
              }
            }
            .application-element {
              display: flex;
              margin: 0.5rem 0;
              flex-direction: row;
              align-items: center;
              font-weight: bold;
              font-size: 0.7rem;
              color: $c--secondary;
              padding: 0.5rem;
              padding-left: 1rem;
              background-color: $c--secondary-light-05;
              &__step {
                background-color: $c--primary;
                color: $white;
                border-radius: 1rem;
                font-size: 0.5rem;
                padding: 0.2rem;
                text-align: center;
              }
              .width-1 {
                flex: 1;
              }
              .width-2 {
                flex: 2;
              }
            }
          }
          position: relative;
        }
      }
    }
    .products-section {
      position: relative;
      page-break-after: always;
      &__header {
        display: flex;
        flex-direction: row;
        padding: 5px 0;
        border-bottom: 1px solid $c--secondary;
        &--cell-box {
          margin-right: 5px;
          color: $c--primary;
          font-size: 1em;
          @include cellBody();
        }
      }
      &__container {
        position: relative;
        &--cell {
          position: relative;
          page-break-inside: avoid;
          padding: 0.5rem 0;
          border-bottom: 1px solid $c--secondary-light-2;
          display: flex;
          flex-direction: row;
          &--box {
            @include cellBody();
            font-size: 1em;
            color: $c--secondary;
            margin-right: 5px;
            &:nth-child(1) {
              color: $c--primary;
            }
            &:nth-child(2),
            &:nth-child(3) {
              color: $c--orange;
            }
          }
        }
      }
    }
  }
  .notes-section {
    width: 90%;
    margin: 0 auto;
    &__title {
      color: $c--primary;
      font-weight: bold;
      margin-bottom: 10px;
      margin-top: 20px;
    }
    &__box {
      border: 1px solid $c--primary;
      height: 400px;
    }
  }
  .legal-section {
    width: 90%;
    margin: 10px auto 20% auto;
    color: $c--secondary;
    text-align: justify;
  }
  .shopping-list-footer {
    display: block;
    width: 100%;
    height: 200px;
    position: relative;
    &__background {
      @include backgroundBody();
    }
    &__logos {
      z-index: 1;
      position: absolute;
      top: 35%;
      left: 15%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 70%;
      height: 100px;
      .logo-element {
        z-index: 2;
        width: 30%;
        height: auto;
        color: white;
        &.lat {
          height: 4rem;
          width: 15rem;
          margin: 1rem -10rem 0 0;
        }
      }
    }
    &__web-address {
      color: $white;
      position: absolute;
      bottom: 10%;
      z-index: 1;
      width: 100%;
      text-align: center;
    }
  }
}
