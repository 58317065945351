.calculation-results {
  margin-top: 1rem;
  &__title {
    color: $c--primary;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
    &--icon {
      height: 70px;
      min-width: 70px;
      margin-bottom: 0.5rem;
    }
  }
  .field-info {
    width: 100%;
    &__element {
      display: flex;
      flex-direction: row;
      color: $c--secondary;
      margin-top: 0.5rem;
      &--key {
        flex: 1;
      }
      &--value {
        flex: 1;
        font-weight: bold;
      }
    }
  }
  .product-quality {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1rem;
    &__element {
      color: $c--secondary;
      padding: 0.5rem 0.5rem;
      &:not(:nth-child(3n)) {
        border-right: 1px solid $c--secondary;
      }
    }
  }
  .info-message {
    color: $c--primary;
    padding: 0.5rem 0;
  }
  .action-bar {
    width: 100%;
    display: block;
    text-align: center;
    margin: 1rem 0;
    &__print {
      border-radius: 1rem;
      text-transform: none;
      &--icon {
        margin-right: 1rem;
      }
    }
  }
}

@media only screen and (max-width: $screen-md-mui-max) {
  .calculation-results {
    &__title {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 0rem;
      &--icon {
        margin-right: 1rem;
      }
    }
  }
}

@media only screen and (max-width: $screen-xsm-min) {
  .calculation-results {
    .product-quality {
      grid-template-columns: repeat(2, 1fr);
      &__element {
        &:not(:nth-child(3n)) {
          border-right: none;
        }
        &:not(:nth-child(2n)) {
          border-right: 1px solid $c--secondary;
        }
      }
    }
  }
}

@media only screen and (max-width: $screen-xs-min) {
  .calculation-results {
    .product-quality {
      grid-template-columns: 1fr;
      &__element {
        &:not(:nth-child(2n)) {
          border-right: none;
        }
      }
    }
  }
}
