.crop-rotation-cell {
  background-color: $c--secondary-light-05;
  border-radius: 0.2rem;
  color: $c--secondary;
  font-size: 0.9rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0.5rem;
  margin: 0.5rem 0;
  &.small-header {
    background-color: white;
    font-size: 0.7rem;
    margin-left: 1rem;
  }
  &.small-element {
    margin-left: 1rem;
    &.active {
      border: solid 1px $c--primary;
    }
  }
  &__column--1 {
    flex: 1;
  }
  &__column--2 {
    flex: 2;
    display: flex;
    flex-direction: row;
    &.with-info {
      flex-direction: column;
    }
    &--left {
      flex: 1;
      &.light {
        color: $c--secondary-light-7;
      }
    }
    &--right {
      flex: 1;
    }
    &--info {
      font-size: 0.8rem;
    }
  }
  &__column--3 {
    flex: 3;
  }
  &__tool-tip {
    width: 400%;
  }
  .button-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-weight: 400;
    &__button {
      &--icon {
        width: 20px;
        height: 20px;
      }
      &--bar {
        width: 100%;
        text-align: center;
        margin: 1rem 0;
        &--print {
          border-radius: 1rem;
          text-transform: none;
          &--icon {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
