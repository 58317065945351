.my-plan-list-template {
  padding: 0rem 5% 2rem 5%;
  &__top-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .search-field__wrapper {
    margin: 0;
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: $screen-md-min) {
  .my-plan-list-template {
    padding: 2rem 5% 2rem 5%;
  }
}
