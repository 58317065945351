.add-plot-template {
  padding: 2rem 5% 2rem 5%;
  &__bottom {
    width: 100%;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid $c--secondary;
    text-align: right;
    &--button {
      text-transform: initial;
      border-radius: 15px;
    }
  }
}

@media only screen and (max-width: $screen-md-min) {
  .add-plot-template {
    padding: 2rem 2% 2rem 2%;
    &__map {
      display: none;
    }
    &__bottom {
      border: none;
      text-align: center;
    }
  }
}
