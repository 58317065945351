.my-application-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  color: $c--secondary;
  background-color: $c--secondary-light-05;
  margin: 0.5rem 0;
  padding: 0.5rem;
  &__column {
    display: flex;
    flex-direction: row;
    &.block-column {
      display: block;
    }
  }
  &__column-group {
    display: flex;
    flex-direction: column;
    &--row {
      display: flex;
      flex-direction: row;
    }
  }
}
