.my-application-map-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $c--secondary;
  background-color: $c--secondary-light-05;
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  border-radius: 0.5rem;
  min-height: 60px;
  &__1 {
    flex: 1;
  }
  &__2 {
    flex: 2;
    &.right {
      display: flex;
      justify-content: flex-end;
    }
  }
  &__3 {
    flex: 3;
  }
  .button-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-weight: 400;
    &__button {
      &--icon {
        width: 20px;
        height: 20px;
      }
    }
  }
}
