.appbar {
  box-shadow: none;
  &--desktop-only {
    display: none !important;
  }
  &--open {
    .appbar-title {
      opacity: 0.3;
      transition: opacity 0.3s;
    }
  }
  &__backdrop {
    z-index: 5 !important;
    top: 66px !important;
    pointer-events: none;
    &--active {
      pointer-events: auto;
    }
  }
  &__panel {
    &__wrapper {
      z-index: 6;
    }
    transform: perspective(400px) rotateX(-90deg);
    transform-origin: top;
    width: 100%;
    height: 150px;
    background-color: white;
    position: fixed;
    top: 66px;
    transition: transform 0.3s;
    transition-timing-function: ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    &--active {
      transform: perspective(800px) rotateX(0deg);
    }
    &__content {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    &__icon {
      flex-basis: 72px;
      width: 72px;
    }
    &__title {
      color: $c--secondary;
      margin-top: 5px;
    }
  }
}

.toolbar {
  height: 66px;
  text-align: center;
  position: fixed;
  width: 100%;
  z-index: 5;
  background-color: white;
  border-bottom: 1px solid $c--primary;
  box-shadow: 0px 8px 12px -2px white;
  top: 0;
  transition: margin-top 0.3s;
  &__hide {
    margin-top: -66px;
    box-shadow: none;
  }
  &__show {
    margin-top: 0px;
  }
}

.appbar-title {
  color: $c--primary;
  font-size: 1em;
  font-weight: 400;
  padding: 0 48px;
  width: 100%;
}

.appbar-title__description {
  color: $c--secondary;
  font-size: 0.8rem;
}

.appbar-title--hamburger {
  margin-left: -48px;
}

.appbar-subtitle {
  color: $c--secondary;
  font-size: 0.8rem;
  margin-bottom: 0;
  margin-top: 0.8rem;
  text-align: center;
  width: 100%;
}

.hambutton {
  border-radius: 0;
  padding-left: 8px;
  z-index: 1090;

  & > span {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  div {
    height: 6px;
    width: 32px;
    transition: margin 0.2s;

    &:first-of-type {
      background-color: $c--gradient-light;
    }

    &:nth-of-type(2) {
      background-color: $c--gradient-main;
      margin-bottom: 4px;
      margin-top: 4px;
    }

    &:nth-of-type(3) {
      background-color: $c--gradient-dark;
    }
  }
}

.go_back {
  padding: 0px;
}

.plus_button {
  background-color: $c--primary !important;
  height: 35px;
  width: 35px;
  position: absolute;
  right: 20px;
  top: 0px;
  bottom: 0px;
  margin-bottom: auto;
  margin-top: auto;
  transition: transform 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  &__icon {
    min-width: 18px;
    min-height: 18px;
    flex-basis: 18px;
    margin-top: -3px;
  }
  &--open-panel {
    transform: rotate(45deg);
  }
}

@media screen and (max-width: $screen-xsm-min) {
  .appbar-title {
    display: none;
  }
}

@media screen and (min-width: $screen-xsm-min) {
  .appbar__panel {
    max-width: 200px;
    right: 0;
  }
}

@media screen and (min-width: $screen-lg-min) {
  .appbar {
    &--desktop-only {
      display: block !important;
      margin-bottom: 30px;
    }
    &--mobile-only {
      display: none !important;
    }
    &--not-landing {
      min-height: 118px;

      .appbar-title {
        font-size: $fs-h4;
        margin-left: 0;
        margin-top: 80px;
      }

      .toolbar {
        height: auto;
        min-height: auto;
        background-color: transparent;
        position: static;
        width: auto;
        border: none;
        box-shadow: none;
        transition: none;
        &__hide {
          margin-top: 0px;
        }
      }

      .hambutton {
        display: none;
      }
    }

    .go_back_overview,
    .plus_button {
      display: none;
    }
    &__panel,
    &__backdrop {
      display: none;
    }
    .go_back {
      position: absolute;
    }
  }
}
