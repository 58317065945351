.refer-farmer-fields {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 1rem auto;
  &__field-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    &--column {
      &:first-child {
        min-width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &:last-child {
        flex: 1;
      }
    }
  }
}
