.landing-page-promotion {
  margin: 3rem 10%;
  display: flex;
  flex-direction: row;
  &__left {
    width: 50%;
    padding-right: 10px;
    .promotion-title {
      font-size: 2rem;
      font-weight: bold;
      color: $c--secondary;
      span {
        color: $c--primary;
      }
    }
    .promotion-text {
      color: #839ca9;
      font-size: 1rem;
      padding: 1rem 0;
    }
    .promotion-prices {
      padding: 1rem 0;
      .promotion-price {
        font-size: 1rem;
        color: #839ca9;
      }
    }
    .promotion-instructions {
      padding: 1rem 0;
      color: #839ca9;
      font-size: 1rem;
      a {
        font-weight: bold;
        text-decoration: none;
        color: #839ca9;
      }
    }
    .promotion-final-info {
      padding: 1rem 0;
      color: #839ca9;
      font-size: 1rem;
    }
  }
  &__right {
    width: 50%;
    &--image {
      width: 100%;
      height: auto;
    }
  }
}

@media only screen and (max-width: $screen-sm-min) {
  .landing-page-promotion {
    flex-direction: column;
    &__left {
      width: 100%;
      padding-right: 0px;
      .promotion-button {
        width: 100%;
        margin: 1rem 0;
      }
    }
    &__right {
      width: 100%;
    }
  }
}
