.soil-data-form {
  margin-top: 2rem;
  width: 100%;
  &__box {
    margin: 1rem 0.5rem;
    &--title {
      display: inline;
    }
  }
}
