.newsletter-subscription-container {
  padding: 5rem 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  &__title {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    &--text {
      color: $c--secondary;
      font-size: 2rem;
      padding-left: 1rem;
      font-weight: bold;
    }
  }
  &__buttons {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    &--text {
      color: $c--secondary;
      font-size: 3rem;
      font-weight: bold;
    }
    &--button {
      margin-left: 1rem;
      &--icon {
        width: 30px;
        height: 30px;
        margin-right: 5px;
      }
    }
  }
}

@media screen and (max-width: $screen-md-min) {
  .newsletter-subscription-container {
    flex-direction: column;
  }
}

@media screen and (max-width: $screen-xsm-min) {
  .newsletter-subscription-container {
    &__title {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      &--box {
        margin-bottom: 1rem;
      }
      &--text {
        padding-left: 0;
        font-size: 1.4rem;
        text-align: center;
      }
    }
    &__buttons {
      flex-direction: column;
      justify-content: flex-end;
      &--text {
        color: $c--secondary;
        font-size: 3rem;
        font-weight: bold;
      }
      &--button {
        margin-left: 0;
      }
    }
  }
}
