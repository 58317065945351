.plot-details {
  margin: 0.5rem;
  &__title {
    color: $c--secondary;
    font-weight: bold;
    font-size: 16px;
    padding: 1.2rem 0 1rem;
  }
  &__table {
    &--row {
      display: flex;
      flex-direction: row;
      align-content: center;
      margin-bottom: 1rem;
      &--left {
        color: $c--secondary;
        font-size: 16px;
        flex: 1;
        line-height: 2rem;
      }
      &--right {
        flex: 2;
        position: relative;
        .input-adornment-button {
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: $screen-md-min) {
  .plot-details {
    &__table {
      &--row {
        flex-direction: column;
        &--left {
          display: none;
        }
      }
    }
  }
}
