.multi-radio-option-selector {
  margin: 1rem 5% 0 5%;
  display: flex;
  flex-direction: row;
  &__section {
    flex: 1;
    &:not(:first-child) {
      margin-left: 1rem;
    }
    &--title {
      color: $c--secondary;
      margin: 1rem 0 0.5rem 0;
    }
    &--option-list {
    }
  }
}

@media only screen and (max-width: $screen-md-min) {
  .multi-radio-option-selector {
    margin: 1rem 5% 0 5%;
    flex-direction: column;
    &__section {
      &:not(:first-child) {
        margin-left: 0;
      }
    }
  }
}
