.fertilizer-needs-list {
  padding: 0rem 5% 2rem 5%;
  &__no-elements {
    text-align: center;
    color: $c--secondary;
    font-weight: bold;
    padding-top: 3rem;
  }
  &__header {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    color: $c--secondary;
    &--name {
      flex: 2;
    }
    &--quantity {
      flex: 1;
    }
  }
  &__table {
    &--element {
      display: flex;
      flex-direction: row;
      background-color: $c--secondary-light-05;
      padding: 0.5rem;
      margin: 0.5rem 0;
      &--name {
        flex: 2;
        color: $c--secondary;
      }
      &--quantity {
        flex: 1;
        color: $c--primary;
      }
    }
  }
}
