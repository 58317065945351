.my-application-maps-template {
  padding: 0rem 5% 2rem 5%;
  &__instruction-text {
    color: $c--secondary;
    font-size: 0.8rem;
    text-align: center;
    padding: 0.5rem 1rem;
  }
}

@media only screen and (max-width: $screen-md-min) {
  .my-crop-rotations-template {
    padding: 2rem 5% 2rem 5%;
  }
}
