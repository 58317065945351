.nutrizones-section {
  margin: 0 10%;
  padding-bottom: 6rem;
  &__title {
    color: $c--secondary;
    font-weight: bold;
    font-size: 2rem;
    margin-top: 2rem;
  }
  &__description {
    color: $c--secondary;
  }
  &__list {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: space-evenly;
    margin: 6% 0;
    flex-wrap: wrap;
    &__element {
      width: 200px;
      &--top {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        .text {
          color: $c--secondary-light-2;
          font-size: 10rem;
          font-weight: bold;
          line-height: 8rem;
        }
        svg {
          position: absolute;
          bottom: 10px;
          right: calc(50% - 60px);
          height: 60px;
          width: auto;
        }
      }
      &--bottom {
        font-weight: bold;
        font-size: 0.8rem;
        text-align: center;
        color: $c--secondary;
      }
    }
  }
}
