.steps-container-component {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.14);
  &__steps-box {
    font-weight: bold;
    padding: 1rem;
    font-size: 0.9rem;
    background-color: $c--primary--pale;
    color: $c--secondary;
  }
}

.steps-container-component-mobile {
  max-width: 320px;
  margin: 0 auto;
  &__top {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 0.5rem 0.5rem;
    .steps-box {
      font-weight: bold;
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
      color: $c--secondary;
    }
  }
  &__bottom {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    .step-mark {
      background-color: $c--secondary-light-2;
      width: 100px;
      height: 10px;
      border-radius: 5px;
      &.completed {
        background-color: $c--secondary;
      }
    }
  }
}
