.product_select {
  &__wrapper {
    margin-top: 10px;
    margin-right: 24px;
    margin-left: 24px;
    .filter_products__wrapper {
      display: none;
    }
    .filter_button {
      margin-bottom: 5px !important;
      margin-top: 0px !important;
    }
    .accordion__card--single-section {
      max-width: 100%;
    }
  }
  &__section {
    &__wrapper {
      position: relative;
    }
    &__product_wrapper {
      margin: 10px 0px 20px;
      position: relative;
    }
  }
  &__scroller {
    overflow: auto;
    height: calc(100vh - 127px);
    height: calc((var(--vh, 1vh) * 100) - 127px);
    padding-bottom: 60px;
    -webkit-overflow-scrolling: touch;
  }
}

@media screen and (min-width: $screen-xsm-min) {
  .product_select {
    &__section {
      &__product_wrapper {
        .accordion__card {
          left: 0;
          transform: none;
          margin-top: 10px !important;
          margin-right: 10px;
        }
        .short_product_info {
          display: inline-block;
          vertical-align: top;
        }
      }
    }
  }
}

@media screen and (max-width: $screen-xsm-min) {
  .product_select {
    background-color: red;
    &__scroller {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

@media screen and (min-width: $screen-md-min) {
  .product_select {
    &__wrapper {
      display: flex;
      .filter_products__wrapper {
        width: 251px;
        display: block;
        margin-top: 10px;
      }
    }
    &__section {
      &__wrapper {
        width: calc(100% - 261px);
      }
    }
  }
}

@media screen and (min-width: $screen-lg-min) {
  .product_select {
    &__scroller {
      overflow: auto;
      height: calc(100vh - 200px);
      height: calc((var(--vh, 1vh) * 100) - 200px);
      padding-bottom: 60px;
    }
  }
}

@mixin responsiveGrid($minWidthMedia, $width) {
  @media screen and (min-width: $minWidthMedia) {
    .product_select {
      &__wrapper {
        .accordion__card--single-section {
          max-width: $width;
        }
      }
    }
  }
}

@include responsiveGrid($screen-xsm-min, calc(100% / 2 - 12px));
@include responsiveGrid($screen-xl-min, calc(100% / 3 - 12px));
@include responsiveGrid(2000px, calc(100% / 4 - 12px));
