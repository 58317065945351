.my-application-needs-list {
  padding: 0rem 5% 2rem 5%;
  width: 100%;
  .col-1 {
    flex: 1;
    padding-left: 5px;
  }
  .col-2 {
    flex: 2;
    padding-left: 5px;
  }
  .col-3 {
    flex: 3;
    padding-left: 5px;
  }
  &__header {
    display: flex;
    flex-direction: row;
    color: $c--secondary;
    &--column {
      .header-top {
        font-weight: bold;
      }
      .header-bottom {
        display: flex;
        flex-direction: row;
      }
    }
  }
  &__content {
    &--no-content {
      text-align: center;
      color: $c--secondary;
      font-weight: bold;
      margin-top: 3rem;
    }
    &--month-section {
      color: $c--primary;
      font-size: 0.9rem;
      margin-top: 0.5rem;
    }
  }
}

@media only screen and (max-width: $screen-xl-min) {
  .my-application-needs-list {
    &__header {
      display: none;
    }
  }
}
