.info_point {
  &__icon {
    width: 22px;
    height: 22px;
    padding: 0;
    color: $c--primary;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid $c--primary;
    font-size: 13pt;
    font-family: 'Times New Roman', Times, serif;
    margin-left: 5px;
    background-color: $white;
    &:hover,
    &:focus,
    &__focus {
      background-color: $c--primary;
      color: $white;
    }
    &__form {
      display: inline;
      margin-left: 5px !important;
    }
  }
  &__info {
    &__wrapper {
      padding: 20px;
      border: 1px solid $c--primary;
      width: 100%;
      box-sizing: border-box;
      margin: 5px 0px 10px;
      position: relative;
    }
    &__close {
      width: 22px;
      height: 22px;
      color: $c--secondary-dark;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: $c--secondary-light-2;
      border: none;
      font-size: 10pt;
      position: absolute;
      right: 15px;
      &:hover,
      &:focus {
        background-color: $c--error;
        color: $white;
        outline: none;
      }
    }
    &__title {
      margin-bottom: 2px;
      font-size: 14pt;
      font-weight: bold;
      width: calc(100% - 35px);
    }
    &__description {
      margin: 0;
      ul {
        padding-left: 20px;
        padding-right: 40px;
        li {
          margin-bottom: 15px;
        }
      }
    }
  }
}
