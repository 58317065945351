.product_form {
  max-width: 600px;
  width: calc(100% - 50px);
  margin: auto;
}

.dialogue__ul {
  padding-left: 0px;
  li {
    list-style-type: none;
  }
}
