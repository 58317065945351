.product_info {
  &__wrapper {
    align-items: flex-start;
    display: flex;
    margin: 0 0 30px;
    max-width: 600px;
    &.center {
      justify-content: center;
    }
    svg {
      display: none;
    }
  }
  &__spreader_icon {
    display: block;
    width: 38px;
    height: 25px;
    path {
      fill: #71bf44;
    }
  }
  &__content {
    width: 100%;
    :first-of-type {
      margin: 0;
    }

    :last-of-type {
      margin-bottom: 0;
    }
    &__button {
      margin-top: 15px !important;
      &--product-detail {
        border: 2px solid $c--primary !important;
      }
      &--change-formula {
        border: 2px solid $c--secondary !important;
      }
    }
    b {
      font-size: 120%;
    }
    &__spreader_callibration_link {
      // margin-left: 51px;
      margin-top: 16px;
      display: block;
    }
  }
  &__img {
    width: 38px;
  }
  &__icon_text {
    margin-top: 24px;
    margin-bottom: 0px;
  }
  &__change_formulae {
    margin-top: 20px !important;
  }
}

@media screen and (min-width: $screen-md-min) {
  .product_info {
    &__wrapper {
      svg {
        display: block;
      }
    }
    &__content {
      padding-left: 16px;
      &__spreader_callibration_link {
        margin-left: 51px;
      }
    }
  }
}
