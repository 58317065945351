.print__back-cover {
  display: none;
}

.print-only {
  display: none;
}

$copyright--de: '©2018 Borealis AG. Alle Rechte vorbehalten';
$copyright--en: '©2018 Borealis AG. All rights reserved';
$copyright--fr: '©2018 Borealis AG. Tous droits réservés';

@media print {
  .content-main {
    display: block;
  }

  /* FOR DEBUGGING REFERENCE ADDED the BREAK BEFORE */
  .month-container .month-list__month-item__wrapper::after {
    color: $c--secondary;
    display: inline-block;
    font-size: 10pt;
    margin: 0 auto;
    margin-top: 15px;
    text-align: center;
    width: 100%;
    break-before: always;
    page-break-before: always;
  }

  .page-break__before {
    display: block;
    break-before: always;
    page-break-before: always;
  }

  .hambutton,
  button:not(.tab-accordion__button),
  a[role='button'],
  .tab-accordion__chevron,
  .subheader__picto-wrapper,
  .main-nav__wrapper,
  .round-label,
  .subheader__buttons-wrapper,
  .picto-separator,
  .footer {
    display: none !important;
  }

  .search-field__wrapper {
    margin-top: 40px !important;
    display: none;
  }

  .search-field--active {
    display: inherit !important;
  }

  .print-only {
    display: block;
    display: initial;
  }

  @page {
    orientation: portrait;
    size: portrait;
  }

  * {
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  p,
  h4 {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    display: block;
  }

  .slick {
    &-slider {
      display: none;
      page-break-inside: avoid;
      break-inside: avoid;
      width: 100%;
    }

    &-track {
      display: none;
      transform: translate3d(0, 0, 0) !important;
      width: 100% !important;
    }

    &-arrow {
      display: none;
      display: none !important;
    }
  }

  .content-row__grid-wrapper {
    display: block;
    min-width: 100%;
    width: 100%;
  }

  .month-container {
    clear: both;
    display: block;
    break-inside: avoid;
    page-break-inside: avoid;
    width: 100%;
  }

  .mysales__item__wrapper {
    clear: both;
    display: block;
    page-break-inside: avoid;
    margin-bottom: 0px;

    &::before {
      vertical-align: middle;
    }
    &::after {
      display: none;
    }

    .month-list__no-link {
      vertical-align: middle;
      width: 300px;
    }
  }

  .mysales__farmer-list {
    &__wrapper {
      display: inline-block;
      margin-left: 10px;
      margin-right: 40px;
      vertical-align: middle;

      & > div {
        float: left;
      }
    }

    &__quantity {
      float: left;

      p {
        width: auto;
      }
    }
  }

  .month-list {
    &__picto-wrapper {
      display: block;
      page-break-inside: avoid;
      min-height: 60px;
      margin-top: 30px;
      width: 100%;
    }

    &__month-item__wrapper {
      display: block;
      width: 100%;
      overflow: auto;
    }

    &__no-link {
      display: inline-block !important;
      min-height: 230px;
      width: 300px;
      break-inside: avoid;
      page-break-inside: avoid;
      margin-right: 30px;
      vertical-align: middle;
    }

    &__title {
      display: inline-block;
      font-size: 12pt;
      font-weight: bold;
      margin-bottom: 15px;
      margin-top: 30px;
      padding-top: 15px;
      width: 100%;
    }

    &__link {
      display: inline-block !important;
      margin-top: 30px;
      min-height: 250px;
      padding-left: 30px;
      width: calc(50% - 63px);
      vertical-align: middle;

      &:nth-of-type(2n) {
        margin-left: 10%;
      }

      &:nth-of-type(2n + 1) {
        clear: both;
      }
    }

    &__link,
    &__no-link {
      border: 2px solid $c--secondary-light-4;
      page-break-inside: avoid;
      &::after {
        display: none;
      }
    }

    &__icon-button {
      display: none;
    }

    &__crop-picto {
      min-height: 50px;
      page-break-inside: avoid;
    }
  }

  .my-overview--reseller {
    .month-list {
      &__title {
        min-height: inherit;
      }
    }
  }

  .content__my-products {
    flex-basis: 100%;
    min-width: 100%;
    width: 100%;

    .month-list__no-link {
      height: 200px;
    }
  }

  .page-header {
    align-items: flex-end;
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 330px;
    justify-content: flex-end;
    position: relative;
    width: 100%;
  }

  .print-logo {
    bottom: 0;
    left: 0;
    height: 104px;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 234px;
    display: block;
  }

  .print-header-images {
    height: 330px;
    width: 1260px;
  }

  .print-header {
    position: absolute;
    width: 100%;
    height: 330px;
    left: 0;
    object-fit: cover;
    top: 0;
    z-index: -1;
  }

  .toolbar {
    height: initial;
    min-height: initial;
    padding: 20px 24px 5px;
    text-align: right;
    width: 100%;
  }

  .appbar-title {
    color: $white;
    font-size: 14pt;
    margin: 0;
    padding: 0;

    &--hamburger {
      margin-left: 0;
    }
  }

  .subheader {
    margin: 0;
    padding: 5px 24px 20px;
    text-align: right;
    width: 100%;
    z-index: 9;

    &__title {
      color: $white;
      font-size: 18pt;
      margin: 0;
      padding: 0;
    }
  }

  .tab-accordion {
    display: block;

    &.accordion--myplan {
      display: table;

      .tab-accordion__panel {
        float: none;

        &:nth-of-type(2) {
          display: table-header-group;
        }
      }
    }

    &::after {
      clear: both;
      content: '';
    }

    &__wrapper {
      display: block;
    }

    &__button {
      display: none;
    }

    &__children {
      display: block;
    }

    &__title {
      color: $c--primary !important;
    }

    &__button {
      padding: 0 !important;

      &::after,
      &::before {
        display: none;
      }
    }

    &__header__main-title {
      color: $c--primary;
      display: block;
      margin-bottom: 0;
      text-align: left;
    }

    &__header__sub-title {
      color: $c--primary;
      display: block;
      margin-top: 0;
      text-align: left;
    }

    &__header__title {
      color: $c--primary;
      display: block;
      font-size: 16pt;
      margin-top: 60px;
      text-align: left;
    }

    &__panel {
      display: block;
      padding: 0;

      &-schrink {
        height: inherit;
      }

      &:nth-of-type(2) {
        margin-top: 20px;
        page-break-inside: avoid;

        .content-row__grid-wrapper {
          position: relative;
        }

        &::after {
          bottom: 0;
          color: $c--secondary;
          font-size: 10pt;
          left: 0;
          margin: 0 auto;
          margin-top: auto;
          right: 0;
          text-align: center;
          width: 100%;
          display: inline-block;
        }
      }
    }

    &__children {
      .tab-accordion__child__wrapper {
        display: block;
        overflow: auto;
        margin-top: 60px;
        page-break-inside: avoid;
        position: relative;

        &:first-of-type {
          margin-top: 0;
        }

        &:nth-child(odd),
        &:nth-last-of-type(n + 1):last-of-type {
          &::after {
            display: inline-block;
            font-size: 10pt;
            margin: 0 auto;
            margin-top: 15px;
            text-align: center;
            width: 100%;
          }
        }
      }
    }
  }

  .appbar .content-row__grid-wrapper,
  .tab-accordion__children .content-row__grid-wrapper {
    max-width: 100% !important;
  }

  .tab-column {
    display: block;
    float: left;
    page-break-inside: always;
    width: 48%;

    &__wrapper {
      display: block !important;
    }

    * {
      page-break-inside: always;
    }

    &:first-child:nth-last-child(2) {
      border: 1px solid $c--secondary-light-4;
      border-radius: 3px;
      float: right;
      margin-top: 30px;
      padding: 24px;
      position: relative;

      &::before {
        background: transparent;
        border-left: 20px solid $c--secondary-light-4;
        border-right: 40px solid transparent;
        border-top: 20px solid transparent;
        content: '';
        display: block;
        height: 0;
        left: -16px;
        position: absolute;
        top: 24px;
        transform: rotate(45deg);
        width: 0;
        z-index: 1;
      }

      &::after {
        background: transparent;
        border-left: 18px solid $white;
        border-right: 40px solid transparent;
        border-top: 18px solid transparent;
        content: '';
        display: block;
        height: 0;
        left: -16px;
        position: absolute;
        top: 24px;
        transform: rotate(45deg) translateX(2px);
        width: 0;
        z-index: 9;
      }
    }

    &:last-of-type {
      padding-right: 0;
    }

    & > button {
      display: none;
    }

    &::before {
      display: none;
    }

    &__wrapper {
      display: block;
      page-break-inside: always;
    }
  }

  .timeline {
    &__overview {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }
    &__card {
      page-break-inside: avoid;
      box-shadow: none !important;
      border: 2px solid $c--secondary-light-4;
      .expansion-panel__chevron {
        display: none;
      }
    }
    &__my-plan {
      margin-bottom: 15px;
      margin-top: 15px;

      .timeline__item {
        font-size: 10pt;
        flex: 1 2 auto;

        &--empty {
          display: none;
        }

        &--active.timeline__box-info {
          border-color: $c--secondary;

          .timeline__step-name {
            color: $c--secondary-dark;
          }
        }
      }
    }

    &__step-num,
    &__step-box {
      background-color: $c--primary;
    }

    &__step-name {
      color: $c--secondary-dark;
      font-size: 12pt;
    }
  }

  .timeline__item--active.timeline__box-info ~ .timeline__item--label {
    color: $c--secondary;
  }

  .periodic-row {
    width: 48%;

    &.greyed-out {
      display: none;
    }

    .tab-column & {
      width: auto;
    }
  }

  .nformat {
    &--container {
      float: right;
      overflow: hidden;
      width: 48%;
    }

    &__title-row {
      position: absolute;
      right: 0;
      top: 42px;
      width: 48%;
    }

    &-graph--check {
      left: 0;
      margin: auto;
      position: relative;
      right: 0;
      top: -22px;
    }

    &-graph--label {
      padding: 0;
      width: 30%;
    }

    &-graph--bar {
      margin-top: 24px;
      padding: 0;
      width: 30%;

      p {
        display: inline-block;
      }
    }

    &-graph--bar-container,
    &-graph--label-container {
      width: 100%;
    }

    &-microscope {
      display: none;

      & + div {
        display: none;
      }
    }
  }

  .print {
    &__back-cover {
      clear: both;
      display: block;
      max-height: 100%;
      padding-top: 30px;
      position: relative;
      size: a4;
      page-break-before: always;
      page-break-inside: avoid;
    }

    &-page-footer {
      align-items: flex-end;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      text-align: center;
      width: 100%;
    }

    &-notes {
      border: solid $c--secondary 2px;
      color: $c--secondary;
      display: block;
      height: 450px;
      margin-bottom: 2em;
      padding: 15px;
      width: 100%;
    }

    &__back-info-wrapper {
      margin-top: 30px;
    }

    &-legal {
      width: 100%;
    }

    &-left-logo {
      width: 300px;
    }

    &-right-logo {
      width: 200px;
    }

    &-page-footer__copyrights {
      font-size: 10pt;
      text-align: center;
      width: 100%;
    }
  }

  *:last-child {
    page-break-after: avoid;
  }

  @-moz-document url-prefix() {
    .tab-accordion__panel,
    .month-list__link,
    .month-list__picto-wrapper .month-list__plot-size {
      display: block;
    }

    .page-header {
      background: none;
      overflow: hidden;
      position: relative;
    }

    .tab-accordion.accordion--myplan .tab-accordion__panel:nth-of-type(2) {
      display: block;
    }
  }
}

@media print and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .nformat {
    &__title-row {
      top: 0;
    }
  }
}
