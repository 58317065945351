.city-postal-code-selector-container {
  border: none;
  width: 100%;
  background-color: transparent;
  text-align: left;
  padding: 0;
  &:focus {
    outline: none;
  }
}

.pac-container {
  z-index: 10000;
}
