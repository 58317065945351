.subheader {
  margin-bottom: 30px;
  margin-top: 45px;
  text-align: center;
  position: relative;

  &__picto-wrapper {
    background-color: $c--primary--pale;
    border-radius: 50%;
    display: flex;
    height: 80px;
    margin: 0 auto;
    width: 80px;
    position: relative;
    justify-content: center;
    align-items: center;
  }

  &__right-button {
    position: absolute;
    right: -100px;
    top: 40px;
    transform: translate(0%, -50%);
  }

  &__picto-svg {
    margin: auto;
    max-height: 55px;
    width: 65px;
    &--small {
      width: 55px;
    }
  }

  &__title {
    color: $c--secondary;
    font-size: 1.2rem;
    margin-bottom: 0;
    margin-top: 12px;
  }

  &__buttons-wrapper {
    button,
    a {
      text-transform: initial;
      border-radius: 15px;
      display: flex;
      margin: auto;
      margin-top: 15px;
      max-width: 225px;
      width: calc(100% - 10px);
      letter-spacing: 0px;
    }
  }

  &__buttons-picto {
    margin-right: 5px;
    width: 15px;
  }

  &__select {
    max-width: 220px;
    width: calc(100% - 40px);
    margin: auto;
    margin-top: 15px;
  }
}

@media screen and (min-width: $screen-xsm-min) {
  .subheader__buttons-wrapper {
    flex-direction: column;
    button,
    a {
      display: inline-flex;
      margin: 15px 5px 0;
      width: auto;
    }
  }
}
