.short_product_info {
  &__paragraph {
    margin: 0;
  }
  &__detail-button {
    margin-top: 20px !important;
  }
  &__selected {
    border: 2px solid $c--primary;
  }
}

@media screen and (min-width: $screen-xsm-min) {
  .short_product_info {
    border-radius: 4px;
  }
}
