.step-item-component {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  &__step-nr {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $c--secondary;
    border-radius: 50%;
    color: $c--secondary;
    font-weight: bold;
    font-size: 0.9rem;
    width: 25px;
    height: 25px;
    &.completed {
      border-color: $c--primary;
      background-color: $c--primary;
      color: $white;
    }
  }
  &__text {
    color: $c--secondary;
    font-weight: bold;
    font-size: 16px;
    flex: 1;
  }
}
