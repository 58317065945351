.month-list {
  &__link {
    align-items: center;
    display: flex;
    // justify-content: space-between;
    margin-bottom: 18px;
    padding: 12px 0;
    position: relative;
    width: 100%;

    &::after {
      background-color: $c--secondary-light-4;
      bottom: 0;
      content: '';
      height: 1px;
      left: 0;
      position: absolute;
      width: 100%;
    }
    &:focus {
      outline: none;
      border: 1px solid $c--primary;
      box-shadow: inset 0px 0px 0px 1px $c--primary;
    }
  }

  &__no-link {
    border: 1px solid #cdd7dd;
    display: inline-block;
    flex-grow: 0;
    margin-bottom: 30px;
    margin-right: 30px;
    padding: 30px 24px;
    width: calc(50% - 15px); // 72 = (72 / 2) * 2

    &:nth-of-type(2n) {
      margin-right: 0;
    }

    &::after {
      display: none;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__title {
    color: $c--secondary;
    display: inline-flex;
    font-size: $fs-small;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__item {
    align-items: flex-start;
    display: flex;

    &__content {
      flex: 1;

      h4 {
        margin-bottom: 4px;
        text-transform: uppercase;
        word-wrap: break-word;
      }
    }

    &__wrapper {
      align-items: center;
      display: flex;
      flex: 1;
      justify-content: space-between;
    }
  }

  &__text {
    margin: 0;

    :first-of-type {
      margin-top: 10px;
    }
  }

  &__picto-wrapper {
    margin-right: 10px;
    width: 80px;
  }

  &__picto-dynamic {
    margin-left: auto;
    text-align: right;
    width: 44px;
  }

  &__chevron {
    min-width: 19px;
    transform: rotate(-90deg);
    transition: transform 0.2s;
    width: 19px;
  }

  &__crop-picto {
    flex-basis: 34px;
    flex-shrink: 0;
    height: 48px;
    max-width: 56px;
  }

  &__plot-size {
    color: $c--primary;
    font-weight: 900;
    margin: 0 auto;
    width: 100%;
    word-wrap: break-word;
  }

  &__delete-picto,
  &__chevron {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 18px;
  }

  &__month-item__wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.mysales {
  &__farmer-row {
    display: flex;
  }

  &__farmer-list {
    &__wrapper {
      display: flex;
      flex-direction: row;
      margin-bottom: 11px;
      margin-left: 0;
      width: calc(100% * (2 / 3));

      p {
        margin: 0;
        margin-bottom: 5px;
        margin-top: 10px;

        &:first-of-type {
          margin-top: 0;
        }
      }
    }

    &__quantity {
      margin-left: 30px;

      p {
        margin-bottom: 5px;
        margin-top: 10px;

        &:first-of-type {
          margin-top: 0;
        }
      }
    }
  }

  &__item__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 45px;
    width: 100%;

    .month-list__link,
    .month-list__no-link {
      justify-content: space-around;
      margin-bottom: 15px;
      width: 100%;

      &::after {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: $screen-xsm-min) {
  .month-list {
    &__icon-button,
    &__chevron {
      display: none;
    }

    &__item {
      flex-direction: column;
    }

    &__crop-wrapper {
      margin-bottom: 24px;
    }

    &__month-item {
      &__wrapper {
        &::after {
          content: '';
          flex: auto;
        }
      }
    }

    &__item__content {
      h4 {
        margin-top: 24px;
      }
    }

    &__link {
      border: 1px solid #cdd7dd;
      margin-bottom: 30px;
      margin-right: 45px;
      min-height: 220px;
      padding: 30px 24px;
      display: block;
      width: calc((100% / 2) - 23px);
      &:nth-of-type(2n) {
        margin-right: 0;
      }
      &::after {
        display: none;
      }
    }

    &__no-link {
      margin-right: 72px;
      min-height: 220px;
      width: calc(50% - 36px); // 72 = (72 / 2) * 2

      &:nth-of-type(2n) {
        margin-right: 0;
      }

      &::after {
        display: none;
      }
    }

    &__picto-dynamic {
      position: absolute;
      right: 0;
      top: 0;
      width: auto;

      button {
        height: 24px;
        padding: 0;
        width: 30px;

        &:hover {
          cursor: pointer;
        }
      }

      &__delete-picto,
      &__chevron {
        bottom: 0;
        left: 0;
        margin: auto;
      }
    }
  }

  .separator__right-aligned {
    display: none;
  }

  .mysales {
    &__farmer-list__wrapper {
      margin-left: 30px;
      width: calc(100% * (2 / 3));
    }

    &__item__wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      align-items: center;

      .month-list__link,
      .month-list__no-link {
        width: calc(100% / 3);
      }
    }
  }
}

.products-table td {
  border-bottom: 1px solid $c--secondary-light;
}

@media only screen and (min-width: $screen-sm-min) {
  .mysales {
    &__farmer-list {
      &__wrapper {
        margin-left: 80px;
      }
    }

    &__item__wrapper {
      .month-list__link,
      .month-list__no-link {
        width: calc(100% / 3);
      }
    }
  }

  .month-list {
    &__picto-wrapper {
      width: 100%;
    }
    &__link {
      width: calc((100% / 3) - 31px);
      &:nth-of-type(2n) {
        margin-right: 45px;
      }
      &:nth-of-type(3n) {
        margin-right: 0px;
      }
    }
  }
}

@media only screen and (min-width: $screen-xl-min) {
  .month-list {
    &__link {
      width: calc((100% / 4) - 35px);
      &:nth-of-type(2n) {
        margin-right: 45px;
      }
      &:nth-of-type(3n) {
        margin-right: 45px;
      }
      &:nth-of-type(4n) {
        margin-right: 0px;
      }
    }
  }
}
