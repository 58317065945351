.google-map-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  &__title {
    display: inline-block;
    color: $c--secondary;
    padding: 1rem 0;
    margin-right: 0.5rem;
    font-weight: bold;
    font-size: 1rem;
  }
  &__info {
    position: relative;
    display: inline-block;
    &--info-button {
      position: relative;
      border: solid $c--secondary 1px;
      cursor: pointer;
      border-radius: 50%;
      color: $c--secondary;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      position: absolute;
      bottom: -9px;
      font-size: 0.8rem;
      width: 1rem;
      height: 1rem;
    }
    &--info-text {
      color: $c--secondary;
      font-size: 0.8rem;
    }
    &--tool-tip-body {
      width: 200px;
    }
  }
}

.google-map-container {
  height: 45vh;
  display: block;
  border-radius: 0.5rem;
}

.google-map-container-my-applications {
  height: calc(100vh - 273px);
  display: block;
  border-radius: 0.5rem;
  position: relative;
  &__map-button__save {
    position: absolute;
    z-index: 1;
    margin: 0.25rem 0 0 0.5rem;
    &__inside {
      text-transform: initial;
      font-size: 1rem;
      padding: 1.25rem 1rem;
      color: $white;
    }
  }
  &__map-button__right-side {
    position: absolute;
    align-items: center;
    margin: 0 auto;
    right: 0;
    z-index: 1;
    display: flex;
    margin: 0.25rem 0.75rem 0 0;

    &__move {
      margin-right: 0.25rem;

      &__inside {
        text-transform: initial;
        font-size: 1rem;
        // color: $white;
        .button-icon {
          padding-right: 0.5rem;
        }
      }
    }
    &__draw {
      margin-left: 0.25rem;

      &__inside {
        text-transform: initial;
        font-size: 1rem;
        justify-content: space-between;
        .button-icon {
          padding-right: 0.5rem;
        }
      }
    }
  }
  &__vegetation-container {
    position: absolute;
    bottom: 0;
    padding: 1rem;
    border-top-right-radius: 1rem;
    background-color: $white--o9;
    color: $c--secondary;
    width: 300px;
    &--title {
      font-size: 1rem;
      margin-bottom: 0.4rem;
    }
    &--gradient {
      width: 100%;
      height: 15px;
      background: linear-gradient(
        90deg,
        rgba(185, 87, 0, 1) 20%,
        rgba(235, 105, 9, 1) 40%,
        rgba(95, 149, 1, 1) 60%,
        rgba(121, 182, 29, 1) 80%,
        rgba(200, 210, 0, 1) 100%
      );
    }
    &--description {
      font-size: 0.8rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

@media only screen and (max-width: $screen-sm-min) {
  .google-map-container-my-applications {
    &__map-button__save {
      margin: 1.25rem 0 0 1rem;
      &__inside {
        padding: 1.5rem;
        font-weight: bolder;
      }
    }
    &__map-button__right-side {
      bottom: 0;
      left: 0;
      margin: 0 0 1.3rem 1.25rem;
    }
  }
}
