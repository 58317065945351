.product_filter_mobile {
  &__wrapper {
    margin-top: 20px;
    margin-left: 20px;
    width: calc(100% - 40px);
  }
  &__sub_wrapper {
    height: calc(100vh - 170px);
    height: calc((var(--vh, 1vh) * 100) - 170px);
  }
  &__button {
    &__wrapper {
      width: 100%;
      display: flex;
    }
    display: block;
    margin: auto;
    &--link {
      margin: auto;
      margin-top: 20px;
    }
  }
}
