.application-step-list {
  width: 200%;
  &__title {
    color: $c--secondary;
    font-size: 1rem;
    font-weight: bold;
  }
  &__step-title {
    margin-top: 0.8rem;
    padding-bottom: 0.4rem;
    margin-bottom: 0.4rem;
    border-bottom: solid 1px $c--primary;
    color: $c--secondary;
    font-size: 1rem;
    font-weight: bold;
  }
  &__text {
    color: $c--secondary;
  }
}
