.confirm_selection {
  &__wrapper {
    width: 100%;
    overflow: auto;
    position: absolute;
    bottom: 0px;
    background-color: $white;
  }
  &__button {
    display: block !important;
    margin: auto !important;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }
}

@media screen and (min-width: $screen-xsm-min) {
  .confirm_selection {
    &__wrapper {
      position: static;
    }
  }
}
