.my-application-cell-mobile {
  border: solid 1px $c--secondary;
  box-shadow: none !important;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  &.expanded {
    border: solid 1px $c--primary;
  }
  &:first-child,
  &:last-child {
    border-radius: 0.5rem;
  }
  &__summary {
    color: $c--secondary;
    font-size: 1.2rem;
  }
  &__details {
    &--header {
      display: flex;
      flex-direction: row;
      color: $c--secondary;
      &--1 {
        flex: 1;
      }
    }
    &--data {
      &--label {
        color: $c--primary;
        font-size: 0.8rem;
      }
      &--value {
        display: flex;
        flex-direction: row;
        color: $c--secondary;
        flex-wrap: wrap;
        &.block-column {
          display: block;
        }
        &--1 {
          flex: 1;
        }
      }
      &--values {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
