.btn--picto {
  background: transparent;
  border: 0;
  color: $c--secondary;
  display: flex;
  font-size: 0.75rem;
  font-style: italic;
  justify-content: center;
  margin-top: 20px;
  padding: 0;
  transition: opacity 0.2s;
  padding: 3px 5px;
  margin-left: -3px;

  &:hover,
  &:active,
  &:focus {
    cursor: pointer;
    outline: none;
    background-color: $c--secondary-light-4;
    border-radius: 5px;
  }
}

.btn--picto__svg {
  margin-right: 8px;
  width: 18px;
}

.btn--picto--label {
  padding-top: 2px;
}

.btn--picto--remove {
  color: $c--error;
  path {
    fill: $c--error;
  }

  circle {
    stroke: $c--error;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $c--error-light-4;
  }
}
