@supports (-ms-ime-align: auto) {
  .form-slider--progress {
    top: 22px;
    z-index: -1;
  }

  input[type='range'] {
    background: transparent;
    height: 44px;
    margin: 0;
    padding: 0;

    &::-ms-thumb {
      background: $c--secondary;
      border: none;
      border-radius: 50%;
      box-sizing: border-box;
      height: 36px;
      width: 36px;
      background: $white;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.14);
    }

    &::-ms-fill-lower {
      background: $c--primary;
    }

    &::-ms-fill-upper {
      background: $c--secondary-light-4;
    }

    &::-ms-track {
      background: $c--secondary;
      box-sizing: border-box;
      height: 1px;
      border: none;
    }

    &::-ms-tooltip {
      display: none;
    }
  }
}
