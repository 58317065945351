.role-select--container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  margin-top: 15vh;
  width: 100%;
}

@media only screen and (min-width: $screen-md-min) {
  .role-select--container {
    margin-bottom: 0;
    padding-top: 0;
  }
}
