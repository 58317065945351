.newsletter-subscription-success-dialogue-container__dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 6rem;
  &--title {
    text-align: center;
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: $c--secondary;
  }
  &--top-text {
    text-align: center;
    padding: 1rem;
    color: $c--secondary;
  }
  &--text {
    text-align: center;
    padding: 1rem;
    color: $c--primary;
  }
}

@media screen and (max-width: $screen-xsm-min) {
  .newsletter-subscription-success-dialogue-container__dialog {
    padding: 3rem 3rem;
  }
}
