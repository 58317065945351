.periodic_el_bars {
  &__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
  }
  &__container {
    width: calc(100% - 45px);
    margin-left: 5px;
  }
  &__bar {
    &__wrapper {
      width: 100%;
      display: flex;
      margin-bottom: 7px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
    &__background {
      width: 100%;
      height: 10px;
      background-color: $c--secondary-light-2;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    &__number {
      margin: 0px;
      font-size: 10pt;
      line-height: 10px;
      margin-left: 5px;
      width: 30px;
    }
  }
}
