.download-nz-alert-dialogue-content {
  max-width: 1050px;
  margin: 0 auto;
  &__title {
    color: $c--primary;
    font-weight: bold;
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  &__description {
    color: $c--secondary;
    text-align: center;
    margin-bottom: 1rem;
  }
  &__main-content {
    position: relative;
    width: 100%;
    .bottom-layer {
      position: absolute;
      z-index: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      &__green-background {
        height: 50%;
        width: 100%;
        border-radius: 10px;
        background-color: $c--primary--pale-dark;
      }
    }
    .top-layer {
      z-index: 1100;
      position: relative;
      display: grid;
      grid-auto-columns: 1fr;
      grid-auto-rows: 1fr;
      column-gap: 2rem;
      row-gap: 1rem;
      width: 100%;
      height: 100%;
      max-height: 600px;
      grid-template-rows: repeat(6, 1fr);
      grid-template-areas:
        'text1 text1 image1 image1 image1 image2 image2 image2 text2 text2'
        'text1 text1 image1 image1 image1 image2 image2 image2 text2 text2'
        'text1 text1 image1 image1 image1 image2 image2 image2 text2 text2'
        'button1 button1 image1 image1 image1 image2 image2 image2 button2 button2'
        'qr1 qr1 image1 image1 image1 image2 image2 image2 qr2 qr2'
        'qr1 qr1 image1 image1 image1 image2 image2 image2 qr2 qr2';
      .store-button {
        width: 100%;
        cursor: pointer;
        border: none;
        background-color: transparent;
        padding: 0;
        &__image {
          width: 100%;
          height: auto;
        }
      }
      .mobile-image {
        width: 100%;
        height: 100%;
      }
      .qr-image {
        width: 100%;
        height: auto;
      }
      &__text-1 {
        grid-area: text1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        color: $c--secondary;
        text-align: right;
      }
      &__text-2 {
        grid-area: text2;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        color: $c--secondary;
      }
      &__image-1 {
        grid-area: image1;
        padding-top: 2rem;
      }
      &__image-2 {
        grid-area: image2;
        padding-top: 2rem;
      }
      &__qr-1 {
        grid-area: qr1;
        display: flex;
        align-items: flex-end;
      }
      &__qr-2 {
        grid-area: qr2;
        display: flex;
        align-items: flex-end;
      }
      &__button-1 {
        grid-area: button1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      &__button-2 {
        grid-area: button2;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}

@media only screen and (max-width: $screen-sm-min) {
  .download-nz-alert-dialogue-content {
    max-width: 320px;
    &__main-content {
      padding: 0 1.5rem;
      .bottom-layer {
        display: none;
      }
      .top-layer {
        grid-template-rows: repeat(5, 1fr);
        column-gap: 1rem;
        grid-template-areas:
          'image1 image2'
          'image1 image2'
          'image1 image2'
          'button1 button1'
          'button2 button2';
        &__text-1 {
          display: none;
        }
        &__text-2 {
          display: none;
        }
        &__qr-1 {
          display: none;
        }
        &__qr-2 {
          display: none;
        }
        &__button-1 {
          justify-content: center;
        }
        &__button-2 {
          justify-content: center;
        }
      }
    }
  }
}
