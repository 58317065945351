.videos-container {
  padding: 0 10%;
  width: 100%;
  &__top {
    display: flex;
    flex-direction: row;
    &--video-box {
      display: inline-block;
      width: 50%;
      border: none;
      background-color: transparent;
      padding: 0;
      position: relative;
      &:focus {
        outline: none;
      }
      &--description {
        color: $white;
        position: absolute;
        bottom: 1.5rem;
        left: 1rem;
        right: 1rem;
        text-align: left;
        font-size: 1rem;
      }
      &--line {
        position: absolute;
        height: 1px;
        background-color: $c--primary;
        width: 50%;
        bottom: 1rem;
        left: 1rem;
      }
      img {
        width: 100%;
      }
    }
  }
  &__bottom {
    display: flex;
    flex-direction: row;
    &--left {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 5rem;
      color: $c--secondary;
      font-size: 2.5rem;
      font-weight: bold;
      & sup {
        top: -2px;
        position: relative;
        font-size: 1.4rem;
      }
      &--button {
        font-size: 1rem;
        width: 260px;
        margin-top: 2.5rem;
      }
    }
    &--right {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      picture {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          top: 5rem;
          position: relative;
          width: 90%;
          height: auto;
        }
      }
    }
  }
}

@media only screen and (max-width: $screen-sm-min) {
  .videos-container {
    &__top {
      flex-direction: column;
      &--video-box {
        width: 100%;
      }
    }
    &__bottom {
      flex-direction: column;
      &--right {
        picture {
          img {
            height: 70vh;
            width: auto;
            top: 3rem;
          }
        }
      }
      &--left {
        text-align: center;
        font-size: 1.7rem;
        margin-top: 3rem;
        &--button {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: $screen-xsm-min) {
  .videos-container {
    &__top {
      flex-direction: column;
      &--video-box {
        &--description {
          color: $c--secondary;
          font-size: 1rem;
          position: relative;
          margin-bottom: 1rem;
          bottom: 0;
          left: 0;
          text-align: center;
        }
        &--line {
          display: none;
        }
      }
    }
    &__bottom {
      flex-direction: column;
      &--right {
        picture {
          img {
            height: 40vh;
            width: auto;
            top: 2rem;
          }
        }
      }
    }
  }
}
