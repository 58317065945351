.icon_title {
  display: flex;
  margin: 0 0 30px;
  align-items: flex-start;
  &--list-style {
    margin-top: 10px;
    margin-bottom: 10px;
    h4 {
      color: $c--secondary;
    }
    .picto-calendar {
      margin-left: -8px;
    }
  }
  &__text_wrapper {
    width: 100%;
    margin-left: 10px;
    margin-top: 5px;
  }
}
