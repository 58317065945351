.form-ruler {
  display: flex;
  flex-wrap: no-wrap;
  justify-content: space-between;
  list-style: none;
  margin-top: 16px;
  margin-bottom: 8px;
  padding: 0;
}

.form-ruler--item {
  color: $c--secondary;
  text-align: center;
  width: 32px;
}

.form-ruler--item--active {
  color: $c--primary;
  font-weight: bold;
}

.form-slider {
  height: 40px;
  margin-bottom: 0;
  margin-top: 16px;
  position: relative;
}

.form-slider--input {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  box-shadow: 0 0 0;
  height: 1px;
  margin: 0;
  margin-top: 15px;
  outline: none;
  padding: 0;
  transition: opacity 0.2s;
  width: 100%;
  position: relative;
  z-index: 1;
  &::-moz-range-track {
    background: transparent;
    border: 0;
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    appearance: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: $white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.14);
    cursor: pointer;
  }

  &::-moz-range-thumb {
    background: $white;
    border: none;
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.14);
    cursor: pointer;
    height: 40px;
    width: 40px;
  }
}

.form-slider--baseline {
  position: absolute;
  border: none;
  border-top: 1px solid $c--secondary;
  width: 100%;
  top: 7px;
}
.form-slider--progress {
  border: none;
  border-top: 1px solid $c--primary;
  margin: 0;
  position: absolute;
  left: 0;
  top: 15px;
  z-index: 0;
}

// Target EDGE
@supports (-ms-ime-align: auto) {
  .form-slider--baseline {
    display: none;
  }
  .form-slider--progress {
    display: none;
  }
}

// Target IE
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-slider--baseline {
    display: none;
  }
  .form-slider--progress {
    display: none;
  }
}
