.referer-cell {
  display: flex;
  flex-direction: row;
  color: $c--secondary;
  margin: 0.5rem 0;
  background-color: $c--secondary-light-05;
  font-size: 0.9rem;
  align-items: center;
  &__1 {
    padding: 0.3rem;
    flex: 1;
    &:last-of-type {
      text-align: right;
    }
  }
  &__2 {
    padding: 0.3rem;
    flex: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    &--left {
      margin-right: 0.5rem;
    }
  }
  &__3 {
    padding: 0.3rem;
    flex: 3;
  }
}

.referer-cell-mobile {
  border: solid 1px $c--secondary;
  box-shadow: none !important;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  &.expanded {
    border: solid 1px $c--primary;
  }
  &:first-child,
  &:last-child {
    border-radius: 0.5rem;
  }
  &__summary {
    color: $c--secondary;
    font-size: 1.2rem;
    font-weight: bold;
  }
  &__details {
    &--header {
      display: flex;
      flex-direction: row;
      color: $c--secondary;
      &--1 {
        flex: 1;
      }
    }
    &--data {
      &--label {
        color: $c--primary;
        font-size: 0.8rem;
      }
      &--value {
        display: flex;
        flex-direction: row;
        color: $c--secondary;
        align-items: center;
        &--icon {
          margin: 3px 3px 3px 0px;
        }
      }
    }
  }
}
