@mixin carousel-button-body {
  cursor: pointer;
  width: 15px;
}

.carousel-slider {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  &__left-button {
    @include carousel-button-body();
    transform: rotate(90deg);
    padding: 1rem 0rem 0 0rem;
  }
  &__right-button {
    @include carousel-button-body();
    transform: rotate(270deg);
    padding: 1rem 0rem 0 0rem;
  }
  &__container {
    width: calc(100% - 40px);
    overflow: hidden;
    display: flex;
    cursor: grab;
    &--element {
      float: left;
    }
  }
}
