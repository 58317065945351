.nurizones-video-container {
  padding: 0 10%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  &.brand-video {
    margin-top: 2rem;
  }
  &__video-box {
    flex: 1;
    display: inline-block;
    border: none;
    background-color: transparent;
    padding: 0;
    position: relative;
    &:focus {
      outline: none;
    }
    img {
      width: 100%;
      @media screen and (max-width: $screen-md-min) {
        width: 100%;
        height: auto;
      }
    }
  }
  &__text-content {
    flex: 1;
    .title {
      color: $c--secondary;
      font-weight: bold;
      font-size: 2rem;
      &.green {
        color: $c--primary;
      }
    }
    .description {
      color: $c--secondary;
      padding: 1rem 0;
    }
  }
}

@media only screen and (max-width: $screen-sm-min) {
  .nurizones-video-container {
    flex-direction: column;
  }
}

@media screen and (max-width: $screen-xsm-min) {
  .nurizones-video-container {
    &__text-content {
      .description {
        text-align: center;
      }
    }
  }
}
