.my-plans-table {
  width: 100%;
  margin-top: 1rem;
  &__header-desktop {
    color: $c--secondary;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    &--column--1 {
      flex: 1;
    }
    &--column--2 {
      flex: 2;
    }
    &--column--3 {
      flex: 3;
    }
    & .button-holder {
      text-align: right;
      &__button {
        border-radius: 1rem;
        text-transform: none;
      }
    }
  }
  &__header-mobile {
    display: none;
  }
}

@media only screen and (max-width: $screen-md-min) {
  .my-plans-table {
    &__header-desktop {
      display: none;
    }
    &__header-mobile {
      color: $c--secondary;
      font-size: 1rem;
      font-weight: bold;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.5rem;
    }
  }
}
