.campaign-container {
  margin: 0 5%;
  position: relative;
  top: -3rem;
  background-size: cover;
  background-position: center;
  &__content-box {
    padding: 2rem;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &--title {
      font-size: 2rem;
      font-weight: bold;
    }
    &--description {
      margin: 1rem 0;
    }
    &--button {
      width: 100%;
      text-align: center;
      bottom: 0;
      .button-element {
        border-color: $white;
        color: $white;
      }
    }
  }
}

@media only screen and (max-width: $screen-xsm-min) {
  .campaign-container {
    margin: 0;
    position: relative;
    top: 0;
    background-size: cover;
    background-position: center;
    &__image-box {
      padding-bottom: 75%;
    }
    &__content-box {
      &--title {
        font-size: 1.7rem;
        font-weight: bold;
      }
      &--button {
        & .button-element {
          width: 100%;
          height: 2.7rem;
        }
      }
    }
  }
}
