.xml-upload {
  display: inline-block;
  position: relative;
  margin-right: 20px;
  &__info {
    display: inline-block;
  }
}

@mixin infoButtonBody {
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid $c--primary;
  color: $c--primary;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.xml-upload-info-button__mobile {
  @include infoButtonBody();
  bottom: 0;
  font-size: 0.8rem;
  width: 1rem;
  height: 1rem;
  top: 7px;
  position: relative;
}

.xml-upload-info-button {
  @include infoButtonBody();
  position: absolute;
  bottom: 0;
  font-size: 0.8rem;
  right: -1.5rem;
  width: 1rem;
  height: 1rem;
  top: -7px;
  left: 100%;
  &__content {
    color: $c--secondary;
    text-decoration: none;
    font-size: 0.8rem;
    min-width: 250px;
    a {
      margin: 0;
    }
    &--link {
      text-transform: capitalize !important; // Necessary to overwrite more specific link selectors used in the header
    }
  }
}

@media screen and (max-width: $screen-xsm-min) {
  .xml-upload {
    width: calc(100% - 10px);
  }
}
