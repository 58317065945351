.search-field__wrapper {
  margin: 0 20px 30px 20px;
  position: relative;
  height: 50px;

  &.search-field--active {
    .search-field--input {
      border: 1px solid $c--secondary;
      outline: none;
      text-align: left;
    }

    .search-field--label {
      p {
        display: none;
      }
      transform: translate(0%, 0%);
      margin-left: 15px;
    }
  }
}

.search-field--input {
  background-color: $c--secondary-light;
  border: 1px solid transparent;
  border-radius: 4px;
  color: $c--secondary;
  display: inline-block;
  font-size: 1rem;
  position: absolute;
  padding-left: 45px;
  transition: border 0.4s;
  height: 100%;
  width: 100%;
  &:focus {
    border: 1px solid $c--secondary;
    outline: none;
    text-align: left;

    & + .search-field--label {
      color: $c--secondary-light-4;
      transform: translate(0%, 0%);
      margin-left: 20px;
    }
  }
}

.search-field--label {
  display: flex;
  align-items: center;
  color: $c--secondary;
  white-space: nowrap;
  position: absolute;
  height: 100%;
  margin-left: 50%;
  transform: translate(-50%, 0%);
  transition:
    margin-left 0.2s,
    transform 0.2s;
  p {
    font-size: 12pt;
  }
  &::before {
    background: url('assets/images/search.svg') no-repeat center;
    background-size: 100%;
    content: '';
    display: inline-block;
    height: 20px;
    margin-right: 6px;
    min-width: 20px;
  }
}

.highlight {
  font-weight: bold;
  color: $c--primary;
  background-color: transparent;
}

@media only screen and (min-width: $screen-lg-min) {
  .search-field__wrapper {
    margin-bottom: 60px;
    margin-top: 30px;
  }
}
