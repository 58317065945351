.table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  color: $c--secondary;
  &__row {
    border-top: 1px solid $c--secondary-light-4;
    &:nth-child(2),
    &:nth-child(1) {
      border-top: none;
    }
    &:last-of-type {
      background-color: $c--secondary-light-2;
      border-top: none;
      td {
        font-weight: bold;
      }
    }
  }
  &__td,
  &__th {
    text-align: center;
    padding-right: 10px;
    vertical-align: top;
    padding-left: 10px;
    &:first-of-type {
      text-align: left;
    }
  }
  &__td {
    padding-top: 15px;
    padding-bottom: 15px;
    &--orange {
      color: $c--tertiary;
    }
    &--green {
      color: $c--primary;
    }
    &--red {
      color: $c--error;
    }
  }
  &__th {
    color: $c--secondary-dark;
  }
}
