.plot-xml-cell-mobile {
  border: solid 1px $c--secondary;
  box-shadow: none !important;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  &.expanded {
    border: solid 1px $c--primary;
  }
  &:first-child,
  &:last-child {
    border-radius: 0.5rem;
  }
  &__summary {
    display: flex;
    flex-direction: row;
    align-items: center;
    &--check {
      width: 1.5rem;
      &--checkbox {
        padding: 0.2rem 0;
      }
    }
    &--title {
      color: $c--secondary;
      font-size: 1rem;
      font-weight: bold;
      margin-left: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  &__details {
    &--item {
      &.uneditable {
        min-height: 4rem;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
      }
      &--key {
        color: $c--primary;
      }
      &--value {
        margin-left: 1rem;
        color: $c--secondary;
      }
    }
  }
}
