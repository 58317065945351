.nutrizones-phone-mock-container {
  display: flex;
  flex-direction: row;
  &__left {
    display: inline-flex;
    flex-direction: column;
    width: 50%;
    padding-left: 10%;
    &--section {
      height: 100vh;
      min-height: 500px;
      display: flex;
      align-items: center;
      position: relative;
      &--box {
        display: flex;
        flex-direction: row;
        width: 100%;
        .nutrizones-phone-mock-box {
          &__left {
            flex: 1;
          }
          &__rigth {
            padding-left: 2rem;
            flex: 6;
            &--title {
              font-size: 2rem;
              font-weight: bold;
              .title1 {
                color: $c--secondary;
              }
              .title2 {
                color: $c--primary;
              }
              .title3 {
                color: $c--secondary;
                b {
                  color: $c--primary;
                }
              }
            }
            &--desc {
              color: $c--secondary;
            }
            &--desc-title {
              color: $c--secondary;
            }
            &--desc-item {
              color: $c--secondary;
              list-style-type: disc;
            }
          }
        }
        .nutrizones-phone-mock-container__right--phone-box {
          display: none;
        }
      }
      &--icon {
        width: 100%;
        background-color: $white;
        bottom: 0;
        left: 0;
        padding-bottom: 8rem;
        position: absolute;
        .nutrizones-phone-mock-left-bottom-text {
          color: $c--secondary;
          font-size: 0.8rem;
          font-weight: bold;
          margin-bottom: 0.5rem;
        }
        .tablet-desktop-mobile {
          width: 60px;
          height: auto;
        }
      }
    }
  }
  &__right {
    display: inline-flex;
    width: 50%;
    height: 100vh;
    position: relative;
    top: 0;
    right: 0;
    padding-right: 10%;
    &--phone-box {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      &--video {
        width: 270px;
        position: absolute;
        left: 50%;
        margin-left: -135px;
        top: 50%;
        margin-top: -286px;
      }
      img {
        width: 270px;
        position: absolute;
        left: 50%;
        margin-left: -135px;
        top: 50%;
        margin-top: -286px;
      }
    }
  }
}

@media screen and (max-width: $screen-md-mui-min) {
  .nutrizones-phone-mock-container {
    &__left {
      width: 100%;
      padding: 0 10%;
      &--section {
        height: auto;
        margin: 1rem 0 1rem;
        min-height: unset;
        &--box {
          flex-direction: column;
          .nutrizones-phone-mock-box {
            &__rigth {
              padding-left: 0;
              &--title {
                font-size: 1.5rem;
              }
              &--desc {
                margin-bottom: 1rem;
              }
              & button {
                width: 100%;
              }
            }
          }
          .nutrizones-phone-mock-container__right--phone-box {
            display: flex;
            position: relative;
            margin: 2rem 0;
            &--video {
              width: 270px;
              position: relative;
              left: unset;
              margin-left: unset;
              margin-top: unset;
              top: unset;
            }
            img {
              width: 270px;
              position: absolute;
              left: unset;
              margin-left: unset;
              margin-top: unset;
              top: unset;
            }
          }
        }
        &--icon {
          display: none;
        }
      }
    }
    &__right {
      display: none;
    }
  }
}

@media screen and (max-width: $screen-xs-min) {
  .nutrizones-phone-mock-container {
    &__left {
      &--section {
        &--box {
          .nutrizones-phone-mock-box {
            &__rigth {
              &--desc {
                font-size: 0.8rem;
              }
              &--desc-title {
                font-size: 0.8rem;
              }
              &--desc-item {
                font-size: 0.8rem;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-height: 500px) {
  .nutrizones-phone-mock-container {
    &__left {
      &--section {
        &--icon {
          padding-bottom: 2rem;
        }
      }
    }
  }
}
