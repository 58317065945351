.impact {
  &__wrapper {
    margin: auto;
    .circular_progress_with_title__wrapper {
      max-width: 500px;
      margin: auto;
    }
    .table {
      margin-top: 30px;
      margin-bottom: 60px;
      &__wrapper {
        max-width: 500px;
        margin: 0 auto;
        margin-top: 70px;
      }
      &__title {
        color: $c--secondary;
      }
      &__subtitle {
        color: $c--secondary;
        margin-bottom: -15px;
        margin-top: 30px;
      }
    }
  }
}
