.my-crop-rotation-filters {
  &__search-field {
    width: 100%;
    margin-bottom: 1rem;
  }
  &__filters {
    width: 100%;
    justify-content: left;
    &--top-text {
      color: $c--secondary;
      font-size: 0.8rem;
    }
    &--list {
      display: flex;
      flex-direction: row;
      align-items: center;
      &--text {
        color: $c--secondary;
        font-size: 1rem;
        font-weight: bold;
        margin-right: 0.5rem;
        &:not(:first-child) {
          margin-left: 0.5rem;
        }
        &.mark {
          margin: 0 1rem 0 -1rem;
          padding-top: 1rem;
        }
        &.category {
          margin-left: 1rem;
        }
      }
      &--select {
        min-width: 11.3rem;
        margin: 0 2rem 0 0;
        &.period {
          min-width: 6rem;
        }
      }
    }
  }
}

@media only screen and (max-width: $screen-md-min) {
  .my-crop-rotation-filters {
    &__filters {
      &--top-text {
        display: none;
      }
      &--list {
        flex-direction: column;
        &--text {
          display: none;
        }
        &--select {
          width: 100%;
          margin-top: 1rem;
          margin-right: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: $screen-lg-min) {
  .my-crop-rotation-filters {
    &__search-field {
      margin-top: 0.5rem;
    }
  }
}
