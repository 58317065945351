// LAYOUT
.full-width {
  width: 100%;
}

.half-width {
  width: 100%;
}

.wrapper--all-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 66px);
  margin: 0 auto;
  width: 100%;
}

.greyed-out {
  opacity: 0.3;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// MARGIN
.margin {
  &-h--auto {
    margin-left: auto;
    margin-right: auto;
  }

  &--zero {
    margin: 0;
  }

  &-l--auto {
    margin-left: auto;
  }

  &-top--zero {
    margin-top: 0;
  }

  &-top--56 {
    margin-top: 56px;
  }

  &-bottom--zero {
    margin-bottom: 0;
  }

  &-top--eight {
    margin-top: 8px !important;
  }

  &-top--sixteen {
    margin-top: 16px;
  }

  &-bottom--24 {
    margin-bottom: 24px;
  }

  &-top--sm {
    margin-top: 20px;
  }

  &--minus-24 {
    margin-left: -24px;
  }

  &-top--minus-22 {
    margin-top: -22px;
  }

  &-top--sixteen-important {
    margin-top: 16px !important;
  }
}

.tab-row-picto.margin-bottom--zero,
.margin-bottom--zero {
  margin-bottom: 0;
}

.tab-row-picto.margin-top--24,
.margin-top--24 {
  margin-top: 24px;
}

// PADDING
.padding {
  &--zero {
    padding: 0;
  }

  &-top--zero {
    padding-top: 0;
  }
}

// FLEX
.flex {
  &-column {
    display: flex;
    flex-direction: column;
  }

  &-row {
    display: flex;
    flex-direction: row;
  }

  &-v--center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
  }
}

.justify {
  &-content--end {
    justify-content: flex-end;
  }

  &-content--space-between {
    justify-content: space-between;
  }
}

.align-self--center {
  align-self: center;
}

// TEXT
.text-align {
  &--center {
    text-align: center;
  }
  &--left {
    text-align: left;
  }
}

.color-primary {
  color: $c--primary;
}

.color-secondary {
  color: $c--secondary;
}

.break-word {
  word-break: break-word;
  word-wrap: break-word;
}

.undecorated {
  text-decoration: none;
}

// IMAGES
.img-center {
  display: block;
  margin: 0 auto;
}

.max-width {
  &--400 {
    max-width: 400px;
  }
}

// OVERFLOWS

.overflow {
  &-hidden {
    overflow: hidden;
  }
}

@media only screen and (min-width: $screen-sm-min) {
  .half-width {
    width: 50%;
  }
}
