.body {
  &__wrapper {
    padding: 0 8px;
    padding-bottom: 30px;
    width: 100%;
  }

  &__full-h {
    min-height: calc(100vh - 96px);
  }

  &--fit-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    min-height: calc(100vh - 96px);
    min-height: calc((var(--vh, 1vh) * 100) - 96px);
    padding: 0 8px;
    width: 100%;
  }

  &__full-h__buttons {
    flex: 1 1 auto;
    align-items: flex-end;
    align-self: flex-end;
    display: flex;
    flex-basis: auto;
    justify-self: flex-end;
    margin: 0 auto;
    margin-top: auto;
    width: 100%;

    button,
    a {
      margin: 0 auto;
    }
    button {
      margin-top: 30px;
    }
  }
}

.content {
  &-wrapper {
    overflow-x: hidden;
    overflow-y: visible;

    &:only-child {
      flex-basis: 100%;
      max-width: 100vw;
      width: 100%;
    }
  }

  &-main {
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    position: relative;
    z-index: 1090;
    main {
      padding-top: 66px;
    }
  }

  &--loading-screen {
    z-index: 1090;
  }

  &-row {
    margin-top: 30px;
  }

  &-row__grid-wrapper {
    margin: 0 auto;
    width: 100%;
  }

  &-row--grid-wrapper {
    margin: 0 auto;

    .appbar &,
    .tab-accordion__children & {
      width: 100%;
    }
  }
}

.terms-conditions {
  &__wrapper {
    -webkit-overflow-scrolling: touch;
    border: 1px solid $c--secondary-light-4;
    border-radius: 2px;
    color: $c--secondary-dark;
    overflow: auto;
    padding: 10px 24px;
    position: relative;
    text-align: justify;

    h2 {
      font-size: 1.2rem;
      font-weight: 400;
      margin-bottom: 0.85rem;
      margin-top: 1.2rem;
    }

    p {
      color: $c--secondary-dark;
    }
  }

  &__wrapper--read {
    border: 0;
    height: initial;
    margin-top: 24px;
    padding: 0;
  }
}

.no-results {
  font-size: 2rem;
  font-weight: 200;
  margin-top: 90px;
}

.navigation {
  &-open + .content-wrapper {
    .content-main {
      animation: slideRAndScaleY 0.3s ease-in-out 0.05s;
      animation-fill-mode: forwards;
      height: 100vh;
      overflow: hidden;
      transform-origin: 50% 50%;
      z-index: 1090;

      main > div:not(.page-header),
      .header-content,
      .appbar__logo,
      .subheader {
        opacity: 0.3;
        transition: opacity 0.3s;
      }

      &::before {
        background-color: transparent;
        content: '';
        height: 100vh;
        left: 0;
        position: absolute;
        top: 0;
        width: 100vw;
        z-index: 1;
      }
    }
  }
  &-close + .content-wrapper {
    .content-main {
      animation: reverseSlideRAndScaleY 0.3s ease-in-out 0s;
      overflow-y: hidden;
      transform-origin: 50% 50%;
      z-index: 1090;
    }
  }
}

.terms__content {
  flex-basis: 100%;
  max-width: 100%;
  padding-bottom: 1rem;
  margin: 0 auto;
  width: 100%;
}

.terms__content--NG4 {
  &--text-fields {
    width: 100%;
  }
}

.dialog__terms {
  max-width: 980px;
  width: 94vw;

  .body__full-h__buttons {
    margin-top: 0;
  }
}

.separator {
  &__full-width {
    border: 0;
    border-bottom: 1px solid rgba(131, 156, 169, 0.4);
    margin: 0;
    width: calc(100% + 48px);
  }

  &__right-aligned {
    border: 0;
    border-bottom: 1px solid rgba(131, 156, 169, 0.4);
    margin: 0;
    width: calc(100% + 0px);
  }
}

.layout__buttons-wrapper {
  margin: 0 auto;
  padding: 40px 0;
  padding-top: 16px;
  width: calc(100% - 16px);

  button:first-of-type {
    margin-top: 36px;
  }
}

@media only screen and (min-width: $screen-sm-min) {
  .body {
    &__full-h__buttons {
      button,
      a {
        max-width: 320px;
      }
    }
  }
}

@media only screen and (min-width: $screen-lg-min) {
  .content-wrapper {
    height: 100%;
    overflow-y: auto;
    position: fixed;
    right: 0;
    width: 100%;
    max-width: calc(100% - 320px);
  }
  .content-wrapper--fullscreen {
    height: auto;
    width: auto;
    overflow-y: visible;
    position: static;
  }

  .body {
    &--fit-screen {
      min-height: calc(100vh - 118px);
      min-height: calc((var(--vh, 1vh) * 100) - 118px);
      padding-bottom: 30px;
    }

    &--fit-screen--no-header {
      min-height: 100vh;
    }
  }
  .navigation {
    &-open + .content-wrapper {
      .content-main {
        animation: none;

        &::before {
          display: none;
        }
      }
    }

    &-close + .content-wrapper {
      .content-main {
        animation: none;
      }
    }
  }
  .content-main main {
    padding-top: 0px;
  }
}
