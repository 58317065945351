.my-plot-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $c--secondary;
  padding: 0.5rem 0;
  border-radius: 0.2rem;
  margin: 0.5rem 0;
  font-weight: bold;
  font-size: 1rem;
  &__data {
    flex: 7;
    display: flex;
    flex-direction: row;
    align-items: center;
    &__checkbox {
      width: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      &--checked {
        background-color: $c--secondary-light-7;
        color: $white;
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
      }
    }
    &__name {
      flex: 3;
      padding-left: 0.5rem;
    }
    &__filter {
      flex: 3;
      &--element {
        min-width: 170px;
      }
    }
    &__area {
      flex: 1;
      text-align: right;
    }
  }
  &__actions {
    flex: 6;
    text-align: right;
  }
  &__mobile-actions {
    flex: 1;
    text-align: right;
    display: none;
  }
}
.my-plot-list {
  max-height: 45vh;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  &::-webkit-scrollbar {
    width: 0.2rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $c--primary;
  }
  .my-plot-cell__text-and-button {
    justify-content: space-between;
    padding: 0 1rem;
    button {
      text-transform: initial;
      border-radius: 15px;
      letter-spacing: 0px;
    }
  }
}

@media only screen and (max-width: $screen-md-min) {
  .my-plot-list {
    max-height: none;
  }
}

@media only screen and (max-width: $screen-sm-min) {
  .my-plot-header {
    &__name {
      flex: 3;
    }
    &__area {
      flex: 1;
    }
    &__actions {
      display: none;
    }
    &__mobile-actions {
      display: block;
    }
  }
}
