.supported-crops-container {
  display: flex;
  flex-direction: row;
  &__left {
    flex: 1;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
    }
  }
  &__right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &--title {
      color: $c--secondary;
      font-weight: bold;
      font-size: 2rem;
      margin: 1rem 20% 2rem 3rem;
      width: 50%;
    }
    &--crop-container {
      margin: 1rem 20% 1rem 3rem;
      .crop-element {
        flex-basis: 20%;
        position: relative;
        margin-bottom: 2rem;
        box-sizing: border-box;
        &__icon {
          height: 5.7rem;
          text-align: center;
          &--svg {
            width: 7rem;
          }
        }
        &__name {
          color: $c--secondary;
          text-align: center;
        }
      }
    }
    &--row {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
    }
    &--all-crops {
      border-top: 3px solid $c--primary;
      margin: 1rem 20% 1rem 3rem;
      padding-top: 2rem;
      img {
        width: 100%;
      }
    }
  }
}

.supported-crops-container {
  &__right {
    &--crop-container {
      .crop-element {
        @media (max-width: $screen-xl-min) {
          flex-basis: 25%;
        }
        @media (max-width: $screen-lg-min) {
          flex-basis: 33.33%;
        }
        @media (max-width: $screen-md-min) {
          margin: 2rem 0;
        }
        @media (max-width: $screen-xsm-min) {
          flex-basis: 50%;
          margin: 3rem 0;
        }
      }
    }
  }
}

@media only screen and (max-width: $screen-md-min) {
  .supported-crops-container {
    &__left {
      display: none;
    }
    &__right {
      padding: 0 2%;
      &--title {
        width: 96%;
        margin: 3rem 2% 0 2%;
        text-align: center;
        font-size: 1.7rem;
      }
      &--crop-container {
        margin: 0;
      }
      &--all-crops {
        margin: 0;
      }
    }
  }
}
