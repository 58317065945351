.plot-drawing-alert-dialogue-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    color: $c--primary;
    font-weight: bold;
    font-size: 1rem;
    margin: 1rem 0 0.5rem 0;
  }
  &__description {
    color: $c--secondary;
    font-size: 0.8rem;
    margin-bottom: 1rem;
  }
  &__image-description {
    color: $c--secondary;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
  }
}

.plot-drawing-alert-dialogue-actions {
  justify-content: center;
  padding: 1rem;
}
