// CUSTOM NOUN PROJECT
.picto-grass {
  flex-basis: 112px;
  flex-shrink: 0;
  height: 64px;

  svg {
    height: 64px;
  }
}

.picto-corn-cards {
  flex-basis: 124px;
  flex-shrink: 0;
  width: 124px;
}

.picto-target {
  flex-basis: 84px;
  flex-shrink: 0;
  width: 84px;
}

.picto-atomic-bottle {
  flex-basis: 75px;
  flex-shrink: 0;
  width: 75px;
}

.picto-chat-bubbles {
  flex-basis: 100px;
  flex-shrink: 0;
  width: 100px;
}

.picto-corn-winter {
  flex-basis: 68px;
  flex-shrink: 0;
  width: 68px;
}

.picto-plot {
  flex-basis: 141px;
  flex-shrink: 0;
  width: 141px;
}

.picto-product-big {
  width: 62px;
}

.picto-calendar {
  flex-basis: 34px;
  flex-shrink: 0;
  width: 34px;
  height: 34px;
}

.picto-product {
  flex-basis: 36px;
  flex-shrink: 0;
  width: 36px;
  height: 52px;
}

.picto-quantity {
  flex-basis: 36px;
  flex-shrink: 0;
  width: 36px;
}

.picto-farmer-select {
  flex-basis: 54px;
  flex-shrink: 0;
  width: 54px;
}

.picto-reseller-select {
  flex-basis: 54px;
  flex-shrink: 0;
  width: 54px;
}

.picto-account {
  width: 60px;
}

.picto-chevron--left {
  transform: rotate(90deg);
  width: 14px;
}

.react-bodymovin-container {
  margin: 24px auto 0;
  max-width: 70%;
  min-width: 240px;
}

// MATERIAL UI
svg[class*='MuiSelect'] {
  color: $c--secondary;
}
