.copy-crop-rotation-template {
  padding: 2rem 30% 2rem 30%;
  &__crop-rotation-name {
    width: 100%;
  }
  &__bottom {
    width: 100%;
    text-align: center;
    &--button {
      margin-top: 3rem;
      width: 80%;
    }
  }
}

@media only screen and (max-width: $screen-sm-min) {
  .copy-crop-rotation-template {
    padding: 2rem 5% 2rem 5%;
  }
}
