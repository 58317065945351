.soil-number-data {
  height: 48px;
  display: flex;
  justify-content: center;
}
.radio-selector-with-number-input-title {
  &__info {
    position: relative;
    display: inline-block;
    &--info-button--mobile {
      border: solid $c--secondary 1px;
      cursor: pointer;
      border-radius: 50%;
      color: $c--secondary;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 1rem;
      height: 1rem;
      margin-left: 5px;
      padding: 0.5rem;
    }
    &--info-button {
      position: relative;
      border: solid $c--secondary 1px;
      cursor: pointer;
      border-radius: 50%;
      color: $c--secondary;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      position: absolute;
      bottom: -5px;
      left: 5px;
      font-size: 0.8rem;
      width: 1rem;
      height: 1rem;
    }
    &--info-text {
      color: $c--secondary;
      font-size: 0.8rem;
    }
    &--tool-tip-body {
      width: 200px;
    }
  }
}
