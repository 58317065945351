.edit-organic-fertilizer-dialogue {
  &__title {
    color: $c--secondary;
    font-size: 1.2rem;
    text-align: center;
    font-weight: 700;
  }
  &__close {
    position: absolute;
    right: 6px;
    top: 6px;
    width: 40px;
    height: 40px;
  }
  &__dialog {
  }
  &__actions {
    &--delete-button {
      @media screen and (max-width: $screen-xsm-min) {
        margin-top: 1rem;
      }
    }
  }
}
