.macro_rutrients_recommendation {
  max-width: 450px;
  &__button {
    margin-top: 30px !important;
  }
  &__center {
    margin: 0 auto;
    margin-top: 20px;
  }
}
