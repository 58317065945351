.application-map-options {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 10px 0px 6px -6px rgba(0, 0, 0, 0.2);
  &__group {
    flex: 1;
    position: relative;
    &--disabled {
      background-color: $white--o7;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $c--secondary;
      font-size: 1.2rem;
      font-weight: bold;
      padding: 2rem;
      text-align: center;
    }
    .map-option-group-header {
      background-color: $c--primary--pale;
      text-align: center;
      padding: 1rem;
      color: $c--secondary;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      &.disabled {
        background-color: $c--secondary-light-4;
        color: $white;
      }
      &__title {
        font-size: 1.4rem;
        font-weight: bold;
      }
      &__description {
        font-size: 0.8rem;
      }
      &__info-button {
        border-radius: 50%;
        border: solid $c--secondary 1px;
        color: $c--secondary;
      }
      &__info-tooltip-container {
        width: 2px;
        text-align: center;
        &--info-button {
          border: solid $c--secondary 1px;
          cursor: pointer;
          color: $c--secondary;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          padding: 0.5rem;
          font-size: 0.8rem;
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
        }
        &--info-body {
          right: 30px;
          text-align: left;
        }
      }
    }
    .map-options-zoneing-mode-selector {
      background-color: $c--primary--pale;
      border-top: 1px solid $c--primary;
      padding: 0.5rem 1.5rem;
      position: relative;
    }
    .map-options-zones-selector {
      background-color: $c--primary--pale;
      border-top: 1px solid $c--primary;
      padding: 1rem 0;
      &__description {
        font-size: 0.8rem;
        text-align: center;
        color: $c--secondary;
        padding: 1rem 0;
      }
      &__box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 1rem;
        .element {
          position: relative;
          cursor: pointer;
          padding: 1rem;
          font-size: 1.4rem;
          font-weight: bold;
          color: $c--secondary;
          background-color: $white;
          border: 1px solid $c--secondary;
          display: flex;
          justify-content: center;
          align-items: center;
          &__disabled {
            position: absolute;
            background-color: $white--o7;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 500;
            cursor: default;
          }
          &.selected {
            color: $white;
            background-color: $c--primary;
            border: $c--primary;
          }
        }
      }
    }
    .map-option-group-container {
      padding: 1rem;
      &__element {
        color: $c--secondary;
        margin: 1rem 0;
        &.green {
          color: $c--primary;
        }
        .description-text {
          font-size: 0.75rem;
        }
        .data-text {
          font-size: 1.2rem;
          font-weight: bold;
          .product-name {
            color: $c--secondary;
            font-size: 1rem;
            font-weight: normal;
          }
        }
      }
    }
    .map-option-group-zones-header {
      display: flex;
      flex-direction: row;
      border-top: 1px solid $c--primary;
      &__element--left {
        background-color: $c--primary--pale-dark-5;
        width: 55px;
        &.isPresenting {
          width: 150px;
        }
      }
      &__element--rigth {
        background-color: $c--primary--pale;
        padding: 1rem;
        flex: 1;
        display: flex;
        flex-direction: row;
        gap: 0.8rem;
        color: $c--secondary;
        .header-description {
          flex: 1;
          text-align: center;
          font-size: 0.8rem;
          &__top {
            font-weight: bold;
          }
        }
      }
    }
    .map-options-humidity-selector {
      background-color: $c--primary--pale;
      padding: 0.5rem 2rem;
      color: $c--secondary;
      .description-text {
        font-size: 0.75rem;
        color: $c--secondary;
      }
      .humidity-selector-component {
        &--undefined {
          background-color: $white;
          padding: 0.2rem;
          border: solid 1px $c--primary--pale-dark-5;
          border-radius: 4px;
        }
        &--defined {
          background-color: unset;
          padding: 0.2rem;
          border: solid 1px transparent;
        }
      }
    }
    .map-option-group-zones {
      &__element {
        display: flex;
        flex-direction: row;
        position: relative;
        &--left {
          display: flex;
          font-size: 1.2rem;
          font-weight: bold;
          color: $white;
          padding: 1rem;
          width: 55px;
          min-height: 71px;
          &.isPresenting {
            width: 150px;
          }
          .zone-index {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .zone-details {
            flex: 2;
            font-size: 0.7rem;
            &__item {
              display: flex;
              flex-direction: row;
              gap: 0.5rem;
              &--left {
                flex: 1;
                text-align: right;
              }
              &--rigth {
                flex: 1;
                text-align: left;
              }
            }
          }
          &.zone-color-0 {
            background-color: #b95700;
          }
          &.zone-color-1 {
            background-color: #eb6909;
          }
          &.zone-color-2 {
            background-color: #c8d200;
          }
          &.zone-color-3 {
            background-color: #79b61d;
          }
          &.zone-color-4 {
            background-color: #5f9501;
          }
        }
        &--right {
          padding: 1rem;
          flex: 1;
          border-bottom: 1px solid $c--secondary-light-4;
          display: flex;
          flex-direction: row;
          gap: 0.8rem;
          justify-content: space-between;
        }
      }
    }
    .map-option-group-footer {
      padding: 0.5rem 1.5rem;
      &__title {
        text-align: center;
        color: $c--secondary;
        font-weight: bold;
        font-size: 1.4rem;
        padding-bottom: 0.5rem;
        padding-top: 1rem;
      }
      &__description {
        text-align: center;
        color: $c--secondary;
        font-size: 0.8rem;
        padding-bottom: 0.5rem;
      }
      .map-option-group-footer__button {
        text-transform: none;
      }
    }
  }
}

@media only screen and (max-width: $screen-lg-min) {
  .application-map-options {
    display: block;
    &__group {
      .map-option-group-header {
        background: none;
        color: $c--primary;
        &__description {
          display: none;
        }
      }
    }
  }
}
