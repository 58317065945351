.refer-farmer-list {
  margin-top: 1rem;
  &__header {
    display: flex;
    flex-direction: row;
    color: $c--secondary;
    font-weight: bold;
    align-items: center;
    &--1 {
      flex: 1;
      padding: 0.3rem;
    }
    &--2 {
      flex: 2;
      padding: 0.3rem;
    }
    &--3 {
      flex: 3;
      padding: 0.3rem;
    }
  }
  &__footer {
    border-top: 1px solid $c--secondary;
    margin-top: 1rem;
    &--text {
      margin: 1rem 0;
      color: $c--secondary;
    }
  }
}

@media only screen and (max-width: $screen-md-mui-min) {
  .refer-farmer-list {
    &__header {
      display: none;
    }
  }
}
