@import './Sections/TopCaroussel/TopCaroussel';
@import './Sections/FooterTop/FooterTop';
@import './Sections/NutrizonesFooterTop/NutrizonesFooterTop';
@import './Sections/Videos/Videos';
@import './Sections/YoutubePopUp/YoutubePopUp';
@import './Sections/SupportedCrops/SupportedCrops';
@import './Sections/PhoneMock/PhoneMock';
@import './Sections/NutrizonesPhoneMock/NutrizonesPhoneMock';
@import './Sections/NutrizonesList/NutrizonesList';
@import './Sections/Campaign/Campaign';
@import './Sections/TopVideo/TopVideo';
@import './Sections/Promotion/Promotion';
@import './Sections/NewsletterSubscription/NewsletterSubsciption';
@import './Sections/NewsletterSubscription/NewsletterSubscriptionDialogue';
@import './Sections/NewsletterSubscription/NewsletterSubscriptionSuccessDialogue';
@import './Sections/Banner/Banner';
@import './Sections/NutrizonesVideo/NutrizonesVideo';

.appbar--landing {
  .toolbar {
    box-shadow: none;
    display: block;
    .landing_page_content {
      display: flex;
      width: 100%;
      max-width: 1400px;
      margin: auto;
      height: 100%;
      align-items: center;
      justify-content: space-between;
      &__access_button_wrapper {
        display: flex;
        align-items: center;
        margin-right: -5px;
        .flag_wrapper {
          height: 40px;
          margin-right: 5px;
          button {
            padding: 0;
          }
          svg {
            width: 40px;
            height: 40px;
          }
          &__flag-icon {
            pointer-events: none;
          }
        }
      }
      &__button {
        margin: 5px;
        display: none;
      }
      &__logo {
        height: 45px;
        width: 13rem;
        flex-basis: 45px;
      }
    }
    h1 {
      display: none;
    }
  }
}

.landing-page-login-section {
  display: flex;
  &__button {
    border-radius: 0;
    margin: 0;
  }
}

.splash-screen-v3 {
  overflow: hidden;
  .extra_footer_landing {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 80%;
    padding: 1rem;
    margin: auto;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    &__logo {
      flex: 1;
      display: flex;
      flex-direction: row;
      height: 90px;
      &--nutriguide {
        width: 165px;
        padding-right: 2rem;
        display: flex;
        align-items: center;
        &:not(:only-child) {
          border-right: 1px solid $c--secondary;
        }
        & svg {
          width: 6rem;
        }
      }
      &--fachbeirat {
        &__wrapper {
          padding-left: 2rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          &.text {
            color: $c--brown;
            font-size: 0.9rem;
          }
          & svg {
            width: 9rem;
          }
        }
      }
    }
    &__links {
      flex: 2;
      text-align: center;
      &--bottom {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        &-link {
          display: flex;
          padding: 0 0.8rem;
          a {
            margin: auto;
            text-decoration: none;
          }
        }
      }
      &--cookie-button {
        display: inline;
        & img {
          max-width: 9rem;
          position: relative;
          top: 0.5rem;
        }
      }
    }
  }
}

@media only screen and (max-width: $screen-lg-min) {
  .splash-screen-v3 {
    .extra_footer_landing {
      &__links {
        &--bottom {
          &-link a {
            margin-left: 1rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $screen-md-mui-max) {
  .splash-screen-v3 {
    .extra_footer_landing {
      max-width: 100%;
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: $screen-xsm-min) {
  .splash-screen-v3 {
    .extra_footer_landing {
      &__logo {
        flex-direction: column;
        justify-content: flex-start;
        height: auto;
        &--nutriguide {
          border-right: none;
          display: block;
          align-items: center;
          padding-right: 0;
          width: 100%;
          text-align: center;
          & svg {
            width: 10rem;
          }
        }
        &--fachbeirat {
          &__wrapper {
            display: block;
            margin: 1.5rem 0 0 0;
            text-align: center;
            padding: 0;
          }
        }
      }
      &__links {
        text-align: center;
        &--bottom {
          flex-direction: column;
          padding-bottom: 2rem;
          &-link {
            padding-top: 0.5rem;
            & a {
              margin: 0;
            }
          }
        }
        &--cookie-button {
          & img {
            top: 0.6rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $screen-sm-min) {
  .splash-screen-v3 {
    &__buttons {
      &--mobile-only {
        display: none;
      }
      &--desktop-only {
        display: inline;
      }
    }
  }
  .appbar--landing {
    .toolbar {
      .landing_page_content {
        &__button {
          display: inline;
        }
      }
    }
  }
}

@media screen and (min-width: $screen-xs-min) {
  .appbar--landing {
    .toolbar {
      &__button {
        &__login {
          display: inline !important;
        }
      }
    }
  }
}

@media only screen and (min-width: $screen-lg-min) {
  .splash-screen-v3 {
    margin-top: 66px;
  }
}
