.plan-list-mobile-cell {
  border: solid 1px $c--secondary;
  box-shadow: none !important;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  &.expanded {
    border: solid 1px $c--primary;
  }
  &:first-child,
  &:last-child {
    border-radius: 0.5rem;
  }
  &__summary {
    color: $c--secondary;
    font-size: 1.2rem;
    font-weight: bold;
    &--checkbox {
      padding: 0.2rem 0;
      margin-right: 0.5rem;
      &--checked {
        background-color: $c--primary;
        font-size: 14px;
        border-radius: 2px;
        color: $white;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &__details {
    &--data {
      &--label {
        color: $c--primary;
        font-size: 0.8rem;
      }
      &--value {
        color: $c--secondary;
        font-weight: bold;
      }
    }
    &--actions {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
  &--button {
    text-transform: initial;
    border-radius: 15px;
  }
}
