.main-nav {
  display: flex;
  flex: 1;
  flex-direction: column;

  &__wrapper {
    background-color: $c--primary--pale;
    display: flex;
    flex-direction: column;
    height: 100vh;
    left: 0;
    overflow-y: auto;
    padding: 15px 85px 25px 24px;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__header {
    max-width: 60vw;
    text-align: center;
  }

  &__hello-user {
    margin-bottom: 0;
    margin-top: 12px;
  }

  &__title {
    color: $c--secondary;
    font-size: 0.75rem;
    margin-bottom: 15px;
    margin-top: 30px;
    max-width: 60vw;
    text-transform: uppercase;
  }

  &__avatar {
    align-items: center;
    background: $c--primary--pale-dark-5;
    border-radius: 50%;
    display: inline-flex;
    height: 28px;
    justify-content: center;
    margin-right: 5px;
    width: 28px;
  }

  &__avatar__picto {
    width: 16px;

    path {
      fill: $white;
    }
  }

  &__user-link {
    align-items: center;
    border-bottom: 1px solid transparent;
    border-radius: 3px;
    color: $c--secondary;
    display: inline-flex;
    max-width: 60vw;
    padding: 4px;
    position: relative;
    text-decoration: none;
    transition: border 0.3s;

    &:focus,
    &:hover {
      outline: none;
      background-color: $c--primary--pale-dark-2;
    }
  }

  &__item {
    align-items: center;
    border-radius: 3px;
    border-bottom-left-radius: 31px;
    border-top-left-radius: 31px;
    display: flex;
    margin-bottom: 8px;
    position: relative;
    text-decoration: none;
    transition: background-color 0.4s;
    width: 100%;
    min-height: 47px;

    &--inactive {
      pointer-events: none;
      cursor: default;
    }

    &:focus,
    &:hover {
      outline: none;
      background-color: $c--primary--pale-dark-2;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__item--secondary {
    align-self: flex-end;
    color: $c--secondary;
    font-weight: 200;
    margin: 0;
    margin-top: auto;
    padding: 4px;

    &::after {
      display: none;
    }

    &:hover,
    &:focus {
      background-color: $c--secondary-light-2;
    }

    .main-nav__item__title {
      color: $c--secondary;
      font-size: 1rem;
      text-transform: uppercase;
    }

    .main-nav__item__picto {
      background-color: transparent;
      margin-right: 8px;
      min-width: auto;
      width: 20px;

      img {
        max-height: auto;
        max-width: auto;
        width: 20px;
      }
    }
  }

  &__picto-svg {
    margin: auto;
    height: 70%;
    width: 80%;
  }

  &__label-wrapper {
    margin: 0;
  }

  &__link--active {
    background: $c--primary--pale-dark-5;

    &--add {
      pointer-events: none;
    }

    .main-nav__item__title,
    span {
      color: $white;
    }

    &:hover,
    &:focus {
      background: $c--primary--pale-dark-5;

      .main-nav__item__title,
      span {
        color: $white;
      }
    }

    &.main-nav__user-link {
      color: $white;
      pointer-events: none;
    }
  }

  &__list-links {
    &:last-of-type {
      margin-bottom: 20px;
    }
  }

  &__item__picto {
    align-items: center;
    background-color: $white;
    border-radius: 50%;
    display: flex;
    height: 47px;
    justify-content: center;
    min-width: 47px;
    position: relative;
    width: 47px;

    &--inactive {
      position: absolute;
      z-index: 2;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 50%;
      pointer-events: none;
    }

    img,
    svg {
      display: inline-block;
      max-height: 60%;
      max-width: 80%;
    }
  }

  &__label-wrapper {
    margin-left: 12px;
  }

  &__item__title {
    color: $c--primary;
    display: block;
    flex-basis: 100%;
    font-size: 1.2rem;
    &--inactive {
      color: $c--secondary-light-4;
    }
  }

  &__item-sub {
    color: $c--secondary;
    display: block;
    flex-basis: 100%;
    font-size: 0.85rem;
    margin-top: 10px;
  }
}

.logo {
  &__img {
    width: 116px;
    height: 52px;
  }
  &:focus,
  &:hover {
    outline: none;
    img {
      background-color: $c--primary--pale-dark-2;
    }
  }
}

.nav_application {
  margin: 0 0 0 8px;
}

#nav_application {
  margin-left: 16px;
}

@media only screen and (min-width: $screen-lg-min) {
  .main-nav {
    flex: 1 0 auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    &--header {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      width: 100%;
    }

    &__wrapper {
      width: 320px;
      background-color: $c--primary--pale;
      border-right: 1px solid $c--secondary-light-4;
      position: fixed;
      padding: 15px 40px 25px 35px;
    }

    &--item {
      &::after {
        display: none;
      }
    }
  }
}

@media print {
  .main-nav {
    &__wrapper {
      display: none;
    }
  }
}

@media only screen and (min-height: $screen-sm-min) {
  .main-nav {
    &__wrapper {
      padding-top: 25px;
      padding-bottom: 25px;
    }
    &__title {
      margin-top: 40px;
    }
    &__item {
      margin-bottom: 15px;
    }
  }
}

@media only screen and (min-height: 900px) {
  .main-nav {
    &__hello-user {
      margin-bottom: 0;
      margin-top: 15px;
    }
    &__item {
      &__picto {
        height: 55px;
        min-width: 55px;
        width: 55px;
      }
    }
  }
}

@media only screen and (min-height: 1000px) {
  .main-nav {
    &__title {
      margin-bottom: 20px;
    }
    &__item {
      margin-bottom: 20px;
      &__picto {
        height: 60px;
        min-width: 60px;
        width: 60px;
      }
    }
  }
}

@media only screen and (min-height: 1200px) {
  .main-nav {
    &__wrapper {
      padding-top: 35px;
      padding-bottom: 10px;
    }
    &__title {
      margin-top: 60px;
      margin-bottom: 30px;
    }
    &__item {
      margin-bottom: 20px;
      &__picto {
        left: -3px;
        height: 70px;
        min-width: 70px;
        width: 70px;
      }
    }
  }
}
