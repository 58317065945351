.add-crop-rotation-template {
  padding: 2rem 30% 2rem 30%;
  &__crop-rotation-name {
    width: 100%;
    padding: 0rem 0.5rem;
  }
  &__bottom {
    width: 100%;
    text-align: center;
    padding-right: 2rem;
    &--button {
      margin-top: 3rem;
      width: 50%;
    }
  }
}

@media only screen and (max-width: $screen-sm-min) {
  .add-crop-rotation-template {
    padding: 2rem 5% 2rem 5%;
  }
}
