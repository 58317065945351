@mixin tooltipBody {
  z-index: 9001;
  position: absolute;
  background: $white;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
  padding: 0.5rem;
}

@mixin arrowBody {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  background: white;
}

.info-tip-container {
  position: relative;
  &-button {
    min-width: 1px;
    min-height: 1px;
  }
  & .tooltip-body {
    @include tooltipBody();
    left: calc(100% + 30px);
    &::after {
      @include arrowBody();
      box-shadow: -1px 2px 1px 0px rgba(0, 0, 0, 0.3);
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      top: 50%;
      left: 0;
    }
  }
}
.info-tip-container-left {
  position: relative;
  & .tooltip-body {
    @include tooltipBody();
    right: calc(100% + 15px);
    &::after {
      @include arrowBody();
      box-shadow: 1px -1px 1px 0px rgba(0, 0, 0, 0.3);
      transform: translateX(50%) translateY(-50%) rotate(45deg);
      top: 50%;
      right: 0;
    }
  }
}
