.round-label {
  border: 2px solid $c--primary;
  border-radius: 50%;
  height: 180px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  width: 180px;

  &:hover {
    cursor: default;
  }

  &::before {
    background: $c--primary;
    border-radius: 50%;
    content: '';
    height: 28px;
    position: absolute;
    right: 18px;
    top: 6px;
    width: 28px;
  }
}

.round-label--text {
  margin: 0 auto;

  .round-label--fr & {
    padding: 0 10px;
  }
}

.round-label--text-normal {
  color: $c--secondary;
  display: inline-block;
  font-size: 0.875rem;
  text-transform: uppercase;
  width: 100%;
}

.round-label--text-big {
  color: $c--secondary;
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 900;
  text-transform: uppercase;

  .round-label--de & {
    font-size: 19px;
    margin: 2px 0;
    background-color: $white;
    margin-left: -14px;
  }
}

.round-label--balance {
  margin: 20px 0 10px;
  width: 40px;
}

.round-label--check {
  height: 28px;
  padding: 8px 6px;
  position: absolute;
  right: 18px;
  top: 6px;
  width: 28px;
}

@media only screen and (min-width: $screen-lg-min) {
  .round-label {
    margin-top: 30px;
  }
}
