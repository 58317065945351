.crop-rotation-cell-mobile {
  border: solid 1px $c--secondary;
  box-shadow: none !important;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  &.expanded {
    border: solid 1px $c--primary;
  }
  &:first-child,
  &:last-child {
    border-radius: 0.5rem;
  }
  &__summary {
    color: $c--secondary;
    font-size: 1.2rem;
    font-weight: bold;
  }
  &__details {
    &--header {
      display: flex;
      flex-direction: row;
      color: $c--secondary;
      &--1 {
        flex: 1;
      }
    }
    &--data {
      &--label {
        color: $c--primary;
        font-size: 0.8rem;
      }
      &--value {
        display: flex;
        flex-direction: row;
        color: $c--secondary;
        &.with-info {
          flex-direction: column;
        }
        &--1 {
          flex: 1;
        }
        &--info {
          font-size: 0.8rem;
        }
      }
    }
    &--actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      @media (max-width: $screen-xs-min) {
        flex-direction: column;
      }
    }
  }
  &--button {
    text-transform: initial;
    border-radius: 15px;
    margin-left: 1rem;
    &--delete {
      background-color: $c--orange;
      border-radius: 15px;
      text-transform: initial;
      margin-left: 1rem;
      &:hover {
        background-color: $c--orange;
      }
    }
  }
  &__tool-tip {
    width: 200%;
    &--title {
      color: $c--secondary;
      font-size: 1.2rem;
      font-weight: bold;
    }
    &--step-title {
      color: $c--secondary;
      margin-top: 0.8rem;
      padding-bottom: 0.4rem;
      margin-bottom: 0.4rem;
      border-bottom: solid 1px $c--primary;
      font-size: 1rem;
      font-weight: bold;
    }
    &--content {
      color: $c--secondary;
      font-weight: 400;
    }
  }
}
