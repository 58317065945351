.filter_products {
  &__wrapper {
    &--isolated {
      height: calc(100vh - 150px);
      height: calc((var(--vh, 1vh) * 100) - 150px);
      overflow: auto;
      .filter_products__group {
        margin-top: 10px;
        margin-bottom: 30px;
        &__label {
          font-size: 18pt;
        }
      }
      .filter_products__control-label {
        font-size: 20px;
        p {
          font-size: 16pt;
        }
        svg {
          font-size: 38px;
        }
      }
    }
  }
  &__control {
    display: block !important;
    margin-bottom: 15px !important;
  }
  &__group {
    margin-top: 2px;
    &__label {
      color: $c--primary !important;
    }
  }
  &__control-label {
    span {
      padding-bottom: 2px;
      padding-top: 2px;
    }
    p {
      margin: 0;
    }
  }
}
