.plan-selection-popup-body {
  border-top: solid 1px $c--secondary-light-4;
  border-bottom: solid 1px $c--secondary-light-4;
  width: 100%;
  margin-top: 1rem;
  padding: 0.5rem 0;
  position: relative;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  &__message {
    width: 100%;
    color: $c--secondary;
    text-align: center;
    margin: 1rem 0;
  }
  &__plan {
    cursor: pointer;
    padding: 0.5rem;
    border: 1px $c--secondary-light-7 solid;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: 1rem;
    &:focus {
      outline: none;
      border-color: $c--primary--pale-dark-5;
    }
    &.selected {
      border: solid 2px $c--primary;
    }
    &--icon {
      flex: 1;
      .crop-icon {
        height: 35px;
        width: 35px;
      }
    }
    &--data {
      flex: 4;
      margin-left: 0.5rem;
      &--element {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &:first-child {
          color: $c--primary;
        }
        &:last-child {
          color: $c--secondary;
        }
      }
    }
  }
}
