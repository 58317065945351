.edit-crop-rotation-template {
  padding: 2rem 5% 2rem 5%;
  &__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &--title {
      font-weight: bold;
      color: $c--secondary;
    }
  }
  &__save-button {
    width: 100%;
  }
}
