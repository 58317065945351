.newsletter-subscription-dialogue-container {
  color: $c--secondary;
  font-size: 1rem;
  &__title {
    color: $c--secondary;
    font-size: 1.2rem;
    text-align: center;
    font-weight: 700;
  }
  &__close {
    position: absolute;
    right: 6px;
    top: 6px;
  }
  &__normal-text {
    padding: 0.5rem 0;
    font-size: 1rem;
    color: #839ca9;
  }
  &__highlighted-text {
    font-weight: bold;
    padding: 0.5rem 0;
  }
  &__actions {
    justify-content: flex-start;
    padding: 1rem 2rem 1rem 2rem;
  }
  &__full-name {
    margin: 0 1rem 1.5rem 0;
    min-width: 200px;
  }
  &__email {
    min-width: 200px;
  }
  &__dialog {
    padding: 0 3rem;
  }
}

.newsletter-subscription-dialogue-container {
  &__title {
    @media screen and (max-width: $screen-xsm-min) {
      margin-top: 2rem;
    }
  }
  &__full-name {
    @media screen and (max-width: $screen-xsm-min) {
      width: 100%;
    }
  }
  &__email {
    @media screen and (max-width: $screen-xsm-min) {
      width: 100%;
      margin-bottom: 1.5rem;
    }
  }
  &__actions {
    @media screen and (max-width: $screen-xsm-min) {
      padding: 0 0.4rem 0.4rem;
    }
  }
  &__dialog {
    @media screen and (max-width: $screen-xsm-min) {
      padding: 0 1rem;
    }
  }
}
