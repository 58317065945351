@mixin shine($color, $light) {
  position: relative;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, $color, lighten($color, $light), $color);
    animation: progress 1.5s ease infinite;
  }
}

@mixin shine_c--secondary-light() {
  background-color: $c--secondary-light-absolute;
  @include shine($c--secondary-light-absolute, 3%);
}

@mixin shine_c--secondary() {
  background-color: $c--secondary;
  opacity: 0.5;
  @include shine($c--secondary, 15%);
}

@mixin shine_c--primary() {
  background-color: $c--primary;
  opacity: 0.3;
  @include shine($c--primary, 20%);
}

@keyframes open {
  0% {
    width: 0%;
  }
  50% {
    width: 80%;
  }
  0% {
    width: 0%;
  }
}

@keyframes progress {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes appear {
  5% {
    opacity: 1;
  }
  37.5% {
    opacity: 1;
  }
  42.5% {
    opacity: 0;
  }
}

@keyframes appear2 {
  5% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  32.5% {
    opacity: 1;
  }
  37.5% {
    opacity: 0;
  }
}

@keyframes appear3 {
  15% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  27.5% {
    opacity: 1;
  }
  32.5% {
    opacity: 0;
  }
}

.loading-skeleton {
  button {
    opacity: 0.5;
  }
  .hambutton {
    opacity: 1;
  }
  .subheader__title_skeleton {
    width: 300px;
    margin: auto;
    margin-top: 12px;
    @include shine_c--secondary();
  }
  #skeleton_tab_accordion_title {
    h2 {
      width: 200px;
      margin: auto;
      color: transparent;
      @include shine_c--secondary();
    }
  }
  .search-field__wrapper {
    border-radius: 4px;
    @include shine_c--secondary-light();
  }
  .timeline__item--label {
    span {
      height: 17px;
      width: 30px;
      display: block;
      margin: auto;
      @include shine_c--secondary();
    }
  }
  .timeline__step-box {
    animation-duration: 3.5s;
    opacity: 0;
    animation-iteration-count: infinite;
  }
  #timeline__step-box-1 {
    animation-name: appear;
  }
  #timeline__step-box-2 {
    animation-name: appear2;
  }
  #timeline__step-box-3 {
    animation-name: appear3;
  }
  .month-list {
    &__title {
      width: 100px;
      height: 20px;
      @include shine_c--secondary-light();
    }
    &__picto {
      height: 48px;
      width: 56px;
      position: relative;
      overflow: hidden;
      @include shine_c--primary();
    }
    &__plot-size {
      height: 19px;
      width: 40px;
      margin: 0;
      margin-top: 5px;
      @include shine_c--primary();
    }
    &__item__content {
      h4 {
        height: 19px;
        width: 200px;
        @include shine_c--primary();
      }
    }
    &__text {
      width: 200px;
      height: 19px;
      margin-top: 3px;
      @include shine_c--secondary-light();
    }
  }
  .timeline__my-plan {
    height: 180px;
    position: relative;
    hr {
      width: 0%;
      height: 0;
      position: absolute;
      border: none;
      border-top: 1px dashed $c--secondary;
      bottom: 27px;
      animation: open 10s ease infinite;
    }
  }
  .default_skeleton_text {
    width: 165px;
    height: 20px;
    @include shine_c--secondary-light();
    &__small {
      width: 100px;
    }
  }
  .default_skeleton_text__with-fit {
    width: 100%;
    height: 20px;
    margin-bottom: 0.5rem;
    @include shine_c--secondary-light();
  }
  .default_skeleton_text__with-margin-bottom {
    width: 150px;
    height: 20px;
    margin-bottom: 0.5rem;
    @include shine_c--secondary-light();
    &__small {
      width: 100px;
    }
  }
  .default_cell_skeleton {
    width: 100%;
    height: 2rem;
    margin-bottom: 1rem;
    @include shine_c--secondary-light();
  }
  .calendar_skeleton {
    width: 100%;
    height: 3rem;
    margin: 1rem 0;
    @include shine_c--secondary-light();
  }
  .calendar_skeleton_mobile {
    width: 100%;
    height: 6rem;
    margin: 1rem 0;
    @include shine_c--secondary-light();
  }
  .map_skeleton {
    width: 100%;
    height: 45vh;
    @include shine_c--secondary-light();
  }
  .periodic-row {
    span {
      width: 14px;
      height: 20px;
      @include shine_c--secondary-light();
    }
    h3 {
      width: 50px;
      height: 20px;
      @include shine_c--secondary-light();
    }
    .content-label {
      width: 70px;
      height: 19px;
      @include shine_c--primary();
    }
  }
  .timeline__card-title-wrapper {
    h2 {
      width: 200px;
      height: 22px;
      @include shine_c--primary();
    }
    h3 {
      width: 200px;
      height: 19px;
      margin-top: 2px;
      @include shine_c--primary();
    }
  }
  .nutrient_comparison__wrapper {
    opacity: 0.5;
  }
  .periodic_el_bars__bar__foreground {
    width: 0;
  }

  .section_title {
    p {
      margin: 0;
      width: 100px;
    }
  }
  .accordion__card-title {
    color: transparent;
    @include shine_c--secondary-light();
  }
  .content-label {
    color: transparent;
    margin-top: 5px;
    @include shine_c--secondary-light();
  }
}
