.fertilizer-needs-filter {
  padding: 0rem 5% 2rem 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &__select {
    min-width: 140px;
    &.period-selector {
      display: flex;
      align-items: flex-end;
    }
    &.print-button {
      border-radius: 2rem;
      max-width: 230px;
    }
  }
}

@media only screen and (max-width: $screen-sm-max) {
  .fertilizer-needs-filter {
    flex-direction: column;
    &__select {
      text-align: center;
      padding: 0.5rem 0;
      &.print-button {
        margin: 0 auto;
        padding-top: 3rem;
        text-align: center;
      }
      &.check-box {
        padding-top: 3rem;
        display: flex;
      }
    }
  }
}
