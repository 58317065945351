.choose_language {
  &__header {
    justify-content: space-between;
    display: flex;
    padding: 25px 17px 25px 40px;
    text-align: center;
    justify-content: space-between;
    font-size: 18px;
    color: $c--secondary;
    font-weight: bold;
  }
  &__header_title {
    padding: 8px 0 4px 30px;
    max-width: 250px;
  }
  &__line_container {
    justify-content: center;
    text-align: center;
    display: flex;
  }
  &__line_separator {
    border-top: 1px solid;
    width: 20%;
    color: $c--primary;
  }
  &__button {
    margin: 15px 0px;
  }
  &__button {
    background-color: transparent;
    border: none;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 25px 30px 40px;
    &:focus,
    &:hover {
      outline: none;
      background-color: $c--secondary-light-2;
    }
    &__flag_titles_wrapper {
      display: flex;
      align-items: center;
      width: 100%;
    }
    &__titles_wrapper {
      margin-left: 15px;
    }
    &__title {
      font-weight: bold;
      font-size: 16pt;
      color: $c--secondary;
    }
    &__subtitle {
      color: $c--secondary;
      font-weight: normal;
      font-size: 14pt;
      margin: 0;
      margin-top: 2px;
      text-align: left;
    }
    &__flag {
      width: 50px;
    }
    &__chevron {
      width: 30px;
      transform: rotate(270deg);
    }
  }
}

@media only screen and (max-width: $screen-md-min) {
  .choose_language {
    &__header {
      padding: 16px 11px 16px 20px;
    }
    &__button {
      padding: 15px 20px;
    }
  }
}
