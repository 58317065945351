.round_button {
  width: 30px;
  height: 30px;
  border: 1px solid $c--secondary;
  border-radius: 50%;
  background-color: transparent;
  padding: 0;
  position: relative;
  svg {
    width: 14px;
    flex-basis: 14px;
    height: 18px;
    position: absolute;
    margin-left: auto;
    left: 0;
    margin-right: auto;
    right: 0;
    margin-top: auto;
    top: 0;
    margin-bottom: auto;
    bottom: 0;
  }
  &:focus {
    outline: none;
  }
  &:focus,
  &:hover {
    background-color: $c--secondary-light-2;
  }
}
