body {
  background-color: $white;
  font-family: 'Roboto', sans-serif;
}

h2 {
  color: $c--primary;
}

h3 {
  color: $c--primary;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 30px;
}

h4 {
  color: $c--primary;
  font-weight: 400;
  margin: 0;
}

a:not([role='button']) {
  transition: color 0.4s;

  &:link,
  &:visited {
    color: $c--primary;
  }

  &:hover {
    color: $c--gradient-dark;
  }
}

.big-title {
  color: $c--primary;
  font-size: $fs-h1;
  font-weight: 300;
  margin: 0;
}

.content-label {
  color: $c--secondary;
}

// Form
.form__title {
  color: $c--primary;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
}

p {
  color: $c--secondary;
}

.text-small {
  color: $c--secondary;
  display: inline;
  font-size: $fs-small;
}
