.top-caroussel-silder-container {
  width: 100%;
  height: calc(100vh - 66px);
  background-color: rgb(231, 200, 178);
  position: relative;
  overflow: hidden;
  min-height: 505px;
  &__video {
    position: absolute;
    width: auto;
    min-width: 100%;
    min-height: 100%;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
  }
  &__element {
    padding: 0 10%;
    width: 100%;
    height: calc(100vh - 66px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    min-height: 505px;
    .double-text-content-slide-container {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      &__row {
        display: flex;
        flex-direction: row;
        gap: 1.5rem;
        &--cell {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          text-align: left;
          color: #fff;
          &.top {
            justify-content: flex-end;
          }
          &.title {
            font-size: 2.8rem;
            font-weight: bold;
          }
          &.green {
            margin-top: 2%;
            font-weight: bold;
            color: $c--primary;
            margin-bottom: 1rem;
          }
          &.message {
            margin-bottom: 1.5rem;
          }
          .nutriguide-logo {
            height: 95px;
          }
          .nutrizones-logo {
            height: 95px;
          }
        }
      }
    }
    .slide1-left {
      display: flex;
      justify-content: center;
      align-items: center;
      &__image {
        width: auto;
      }
    }
    .slide1-right {
      text-align: left;
      margin-top: 10%;
      margin-left: 10%;
      &__top-logo {
        border-bottom: 2px solid $c--primary;
        padding-bottom: 1rem;
        .nutriguide-logo {
          height: 95px;
        }
      }
      &__title {
        color: $white;
        font-size: 2.8rem;
        font-weight: bold;
        &.with-bottom-margin {
          margin-bottom: 2rem;
        }
      }
      &__subTitle {
        margin-top: 2%;
        font-weight: bold;
        color: $c--primary;
        margin-bottom: 1rem;
      }
      &__message {
        color: $white;
        margin-bottom: 1.5rem;
      }
    }
    .slide2-left {
      display: flex;
      justify-content: center;
      align-items: center;
      &__image {
        width: auto;
      }
    }
    .slide2-right {
      text-align: left;
      margin-top: 5%;
      &__top-logo {
        border-bottom: 2px solid $c--primary;
        padding-bottom: 1rem;
        .farmdok-logo {
          height: 3.5rem;
          width: 18rem;
        }
      }
      &__title {
        color: $white;
        font-size: 2.4rem;
        font-weight: bold;
      }
      &__subTitle {
        margin-top: 2%;
        font-weight: bold;
        color: $c--primary;
        margin-bottom: 1rem;
      }
      &__message {
        color: $white;
        margin-bottom: 1.5rem;
      }
    }
  }

  // This overwrites the default carousel styles
  .carousel .slider-wrapper.axis-horizontal .slider {
    .slide {
      background-color: $c--transparent;
    }
  }

  &__lines-holder {
    position: absolute;
    bottom: 15%;
    left: calc(50% - 50px);
    display: flex;
    flex-direction: row;
    &--element {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $c--transparent;
      border: none;
      padding: 20px 5px;
      &:focus {
        outline: none;
      }
      &--line {
        background-color: $c--secondary-light-7;
        width: 40px;
        height: 3px;
        transition: background-color 1s;
        &.active {
          background-color: $white;
        }
      }
    }
  }
  &__scroll-down {
    position: absolute;
    bottom: 3rem;
    left: calc(50% - 19px);
    display: block;
    cursor: pointer;
    background: none;
    border: none;
    &:focus {
      outline: none;
    }
  }
}

@media only screen and (max-width: $screen-lg-min) {
  .top-caroussel-silder-container {
    &__element {
      .double-text-content-slide-container {
        &__row {
          &--cell {
            &.title {
              font-size: 2rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $screen-md-mui-max) {
  .top-caroussel-silder-container {
    &__element {
      padding: 0 10%;
      .double-text-content-slide-container {
        &__row {
          &--cell {
            &.title {
              font-size: 1.5rem;
            }
          }
        }
      }
      .slide1-right {
        text-align: left;
        margin-top: 1.5rem;
        &__title {
          font-size: 2rem;
        }
        &__subTitle {
          margin-top: 1rem;
          font-size: 1.4rem;
        }
        &__button {
          text-align: center;
        }
        &__message {
          font-size: 1.2rem;
        }
      }
      .slide2-right {
        text-align: left;
        margin-top: 1.5rem;
        &__title {
          font-size: 2rem;
        }
        &__subTitle {
          margin-top: 1rem;
          font-size: 1.4rem;
        }
        &__button {
          text-align: center;
        }
        &__message {
          font-size: 1.2rem;
        }
      }
    }

    // This overwrites the default carousel styles
    .carousel .slider-wrapper.axis-horizontal .slider {
      img {
        display: none;
      }
    }

    &__lines-holder {
      bottom: 17%;
    }
  }
}

@media screen and (max-width: $screen-md-mui-max) and (orientation: landscape) {
  .top-caroussel-silder-container {
    &__element {
      align-items: flex-start;
    }
  }
}

@media only screen and (max-width: $screen-xs-max) {
  .top-caroussel-silder-container {
    &__element {
      padding: 0 10%;
      position: relative;
      .double-text-content-slide-container {
        position: relative;
        bottom: 3.5rem;
      }
      .slide1-right {
        text-align: left;
        margin-top: 1rem;
        &__top-logo {
          border-bottom: 2px solid $c--primary;
          padding-bottom: 1rem;
          .nutriguide-logo {
            height: 4rem;
          }
        }
        &__button {
          font-size: 0.9rem;
          & button {
            width: 100%;
          }
        }
        &__title {
          font-size: 1rem;
          margin-top: 0.5rem;
          width: 100%;
        }
        &__subTitle {
          margin-top: 0.6rem;
          font-size: 1rem;
        }
        &__message {
          font-size: 0.9rem;
        }
      }
      .slide2-right {
        text-align: left;
        margin-top: 1rem;
        &__top-logo {
          border-bottom: 2px solid $c--primary;
          padding-bottom: 1rem;
          .nutriguide-logo {
            height: 2rem;
          }
          .farmdok-logo {
            height: 2.5rem;
            width: 11rem;
          }
        }
        &__button {
          font-size: 0.9rem;
          & button {
            width: 100%;
            height: 2.7rem;
          }
        }
        &__title {
          font-size: 1rem;
          margin-top: 0.5rem;
        }
        &__subTitle {
          margin-top: 1.5rem;
          font-size: 1rem;
        }
        &__message {
          font-size: 1rem;
        }
      }
    }

    // This overwrites the default carousel styles
    .carousel .slider-wrapper.axis-horizontal .slider {
      img {
        display: none;
      }
    }
  }
}
