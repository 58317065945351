.bread_crumb {
  border: 2px solid $c--primary;
  border-radius: 30px;
  height: 45px;
  min-height: 45px;
  padding: 0px 30px 0px 40px;
  position: relative;
  margin-left: 5px;
  &__icon {
    flex-basis: 13px;
    flex-shrink: 0;
    width: 13px;
    position: absolute;
    left: 15px;
    margin: 0;
    color: $c--primary;
  }
}
