.flow-select--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 6vh;
  width: 100%;

  .form__title {
    align-self: flex-start;
  }
}

.flow-select--option-container {
  text-align: center;
  .form__field {
    justify-content: center;
  }
  .from_field_bavaria {
    text-align: left;
  }
}

.picto-welcome {
  flex-basis: 111px;
  width: 111px;
  margin-bottom: 30px;
}

.flow-select--separator {
  background-color: $c--secondary-light-4;
  width: 100%;
  height: 1px;
  margin: 0 auto 10px;
}

.flow-select--options-box {
  display: flex;
  flex-direction: column;
  width: auto;
  text-align: center;
}

.flow-select--option-subtitle {
  margin-left: 34px;
  margin-top: 10px;
}

.buttons_container {
  flex-direction: column;
  .add-plot-button {
    display: block;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    &__icon {
      height: 40px;
      width: 40px;
    }
    &__text {
      padding-left: 1rem;
    }
  }
}

.flow-select--welcome {
  color: $c--primary;
  font-size: 20px;
}

.flow-select--creating-plan {
  color: $c--secondary;
  text-align: center;
  font-size: 26px;
}

.flow-select--add-field {
  color: $c--secondary;
  text-align: center;
  font-size: 16px;
  max-width: 800px;
  margin-top: 1vh;
}

@media only screen and (min-width: $screen-xsm-min) {
  .flow-select--option-subtitle {
    margin-top: 16px;
    width: 220px;
    top: 0;
  }

  .flow-select__wrap_width {
    width: 146px; //To make text wrap the same as designs
  }

  .flow-select--options-box {
    flex-direction: row;
    margin: 3vh 0;
  }

  .flow-select--separator {
    width: 1px;
    height: 24vh;
    margin: 0 41px;
  }

  .picto-welcome {
    flex-basis: 170px;
    width: 170px;
  }

  .flow-select--option-container {
    margin-top: 2vh;
    width: 320px;
  }

  .flow-select--welcome {
    color: $c--primary;
  }

  .flow-select--creating-plan {
    color: $c--secondary;
    text-align: center;
    font-size: 45px;
  }

  .flow-select--add-field {
    color: $c--secondary;
    text-align: center;
    font-size: 15px;
    width: 510px;
    margin-top: 1vh;
  }
}
