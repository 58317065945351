.my-application-maps-table {
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $c--secondary;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.5rem;
    &--1 {
      flex: 1;
    }
    &--2 {
      flex: 2;
    }
    &--3 {
      flex: 3;
    }
  }
}
