.logo_item {
  &__list {
    width: 240px;
    padding: 0;
    margin: auto;
  }
  &__wrapper {
    display: inline-block;
    margin: auto 20px;
    margin-bottom: 40px;
    width: 200px;
    height: 250px;
    list-style-type: none;
    border: 1px solid $c--secondary;
    padding: 15px;
    padding-bottom: 5px;
    border-radius: 3px;
  }
  &__link {
    width: 100%;
    height: 100%;
    display: block;
    text-decoration: none;
  }
  &__image {
    max-width: 100%;
    max-height: 120px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    &__wrapper {
      height: calc(100% - 40px);
      display: block;
      position: relative;
    }
  }
  &__title {
    font-size: 16pt;
    font-weight: bold;
    height: 40px;
    text-align: center;
    color: $c--secondary;
  }
}

@mixin responsiveGridLogoItem($minWidthMedia, $width) {
  @media screen and (min-width: $minWidthMedia) {
    .logo_item__list {
      width: $width;
    }
  }
}

@include responsiveGridLogoItem($screen-xsm-min, calc(240px * 2));
@include responsiveGridLogoItem($screen-md-min, calc(240px * 3));
@include responsiveGridLogoItem(1800px, calc(240px * 6));
