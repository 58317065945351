$c--primary: #71bf44;
$c--primary--pale: rgba(113, 191, 68, 0.07);
$c--primary--pale-dark: rgba(113, 191, 68, 0.12);
$c--primary--pale-dark-2: rgba(113, 191, 68, 0.2);
$c--primary--pale-dark-5: rgba(113, 191, 68, 0.5);

$c--secondary: #839ca9;
$c--secondary-dark: #505f66;
$c--secondary-light: rgba(131, 156, 169, 0.1);
$c--secondary-light-05: rgba(131, 156, 169, 0.05);
$c--secondary-light-2: rgba(131, 156, 169, 0.2);
$c--secondary-light-4: rgba(131, 156, 169, 0.4);
$c--secondary-light-5: rgba(131, 156, 169, 0.5);
$c--secondary-light-7: rgba(131, 156, 169, 0.7);

$c--secondary-light-absolute: #f2f4f6;
$c--secondary-light-4-absolute: #cdd7dd;

$c--pattens-blue: #cdd7dc;

$c--tertiary: #ffcc00;

$c--gradient-light: #c8d200;
$c--gradient-main: #79b61d;
$c--gradient-dark: #5f9501;

$c--error: #d0021b;
$c--error-light-4: rgba(208, 2, 27, 0.4);
$c--brown: #826528;

$c--black-o5: rgba(0, 0, 0, 0.5);
$c--black-o3: rgba(0, 0, 0, 0.3);

$white: #fff;
$white--o5: rgba(255, 255, 255, 0.5);
$white--o7: rgba(255, 255, 255, 0.7);
$white--o9: rgba(255, 255, 255, 0.9);

$gray-light: #f9fafb;
$gray-dark: #ebebeb;

$c--chrome-select: rgb(77, 144, 254);

$c--orange: #eb6909;

$c--transparent: rgba(0, 0, 0, 0);
