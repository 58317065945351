.my-plots-template {
  border-top: $c--secondary-light-2 solid 1px;
  padding: 2rem 5% 2rem 5%;
  &__map--mobile {
    display: none;
  }
}

@media only screen and (max-width: $screen-md-min) {
  .my-plots-template {
    padding: 2rem 2% 2rem 2%;
    &__map--mobile {
      display: block;
      width: 100%;
      top: 0;
      z-index: 1000;
      background-color: #fff;
    }
  }
}

@media only screen and (max-width: $screen-md-min) {
  .my-plots-template {
    padding: 2rem 2% 2rem 2%;
    &__map--desktop {
      display: none;
    }
  }
}
