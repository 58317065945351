.filter_button {
  // border: 2px solid $c--primary;
  padding: 0px 80px;
  border-radius: 30px;
  position: relative;
  height: 45px;
  min-height: 45px;
  overflow: hidden;
  &__icon {
    flex-basis: 18px;
    flex-shrink: 0;
    width: 18px;
    &__wrapper {
      left: -2px;
      height: 45px;
      width: 45px;
      background-color: $c--primary;
      position: absolute;
      border-radius: 100%;
      top: -2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__wrapper {
    white-space: nowrap;
    overflow: auto;
  }
  &__small {
    font-size: 0;
    padding: 0;
    min-width: 45px;
  }
}

@media screen and (min-width: $screen-md-min) {
  .filter_button__wrapper {
    display: none;
  }
}
