.file-upload {
  width: 100%;
  min-height: 10vh;
  cursor: pointer;
  background-color: $gray-light;
  border: 1px solid $gray-dark;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4% 0;
  position: relative;
  &.drag-over {
    background-color: $c--primary--pale;
    border-color: $c--primary;
  }
  &__drop-zone {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
  }
  &__icon {
    width: 50px;
    height: auto;
    margin-bottom: 1rem;
  }
  &__text {
    font-weight: 400;
    font-size: 1rem;
    color: $c--secondary;
    &--mobile {
      display: none;
    }
  }
}

@media only screen and (max-width: $screen-md-min) {
  .file-upload {
    width: 100%;
    min-height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2% 0;
    &__icon {
      flex: 1;
      margin: 1rem;
      max-width: 40px;
    }
    &__text {
      flex: 4;
      font-size: 1.2rem;
      &--desktop {
        display: none;
      }
      &--mobile {
        display: block;
      }
    }
  }
}
