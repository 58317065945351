.footer {
  justify-self: flex-end;
  margin-top: 32px;
  position: relative;

  &__wrapper {
    align-items: center;
    background-color: $c--secondary-light;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 24px;
    width: 100%;
    gap: 0.5rem;
    text-align: center;
  }

  &__copyright {
    margin: 0;
    margin-top: 16px;
    position: relative;
    text-align: center;
    z-index: 1;
  }

  &__logo {
    height: 54px;
    margin-bottom: 16px;
  }

  &__link:not([role='button']):link,
  &__link:not([role='button']):visited {
    color: $c--secondary;
    margin: 16px;
    position: relative;
    text-decoration: none;
    transition: all 0.2s;
    z-index: 1;

    &:hover {
      color: $c--primary;
      text-decoration: underline;
    }
  }
}

@media only screen and (min-width: 825px) {
  .footer {
    &::after {
      bottom: 16px;
      height: 106px;
      top: initial;
      width: 102px;
    }

    &__logo {
      margin-right: auto;
    }

    &__wrapper {
      align-items: flex-end;
      flex-direction: row;
    }

    &__link {
      margin-left: 15px;
      margin-right: 0;
    }

    &__copyright {
      border-top: 1px dotted $c--secondary-light-4;
      text-align: center;
      padding-top: 15px;
      width: 100%;
    }
  }
}

@media only screen and (min-width: $screen-lg-min) {
  .footer {
    &::before {
      background-size: 100% 80px;
      height: 80px;
    }

    &::after {
      bottom: initial;
      height: 106px;
      top: 16px;
      width: 102px;
    }

    &__wrapper {
      align-items: initial;
      justify-content: flex-start;
    }

    &__logo {
      display: none;
    }

    &__copyright {
      border: 0;
      margin: 0 15px;
      margin-left: auto;
      text-align: right;
      width: initial;
    }

    &__link {
      margin: 0 15px;
    }
  }
}
