.tab-accordion-full-width {
  .tab-accordion {
    &__button {
      display: flex;
    }
    &__title {
      &-wrapper {
        width: 100%;
      }
    }
  }
}

.tab-accordion {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  &__panel {
    flex-basis: 100%;
    order: 1;
    padding: 30px 24px;
    width: 100%;

    .tab-accordion__children & {
      padding: 30px 0;
    }

    &-schrink {
      padding: 0;
      height: 0;
      overflow: hidden;
    }
  }

  &__header {
    padding-bottom: 30px;
    text-align: center;
    width: 100%;
  }

  &__title-wrapper {
    margin-right: auto;
    padding: 20px 16px;
    position: relative;
    top: 1px;
  }

  &__button {
    background: none;
    border: 0;
    color: $c--secondary;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    margin: 0;
    margin-top: 20px 0;
    min-height: auto;
    min-width: auto;
    padding: 0;
    order: 0;
    position: relative;
    text-align: center;
    transition: all 0.4s;
    width: 100%;
    z-index: 1;

    &:focus {
      outline: none;
      .tab-accordion__title-wrapper {
        background-color: $c--primary--pale-dark-2;
      }
    }
    &::-moz-focus-inner {
      border: none;
    }

    &:not(.tab-accordion__button--active):hover {
      cursor: pointer;

      &::after {
        background-color: $c--secondary-dark;
      }
    }

    &--active {
      border-bottom-color: $c--primary;

      .tab-accordion__title {
        color: $c--primary;
      }

      .tab-accordion__chevron {
        transform: rotate(0deg);
      }

      .timeline__step-num {
        background-color: $c--primary;
      }

      .tab-accordion__title-wrapper {
        &::after {
          background-color: $c--primary;
          bottom: 0;
          color: $c--primary;
          content: '';
          height: 3px;
          left: 0;
          position: absolute;
          transition: background-color 0.2s;
          width: calc(100% + 2px);
          margin-left: -1px;
        }
      }
    }
    &:last-of-type {
      & + .tab-accordion__underline {
        display: inline;
        width: 100%;
        margin: 0;
        z-index: 0;
        margin-top: 0px;
        border: none;
        border-top: 1px solid $c--secondary-light-4;
      }
    }
  }

  &__title {
    color: $c--secondary;
    font-size: 1.125rem;
    font-weight: 400;
    margin: 0;
    text-transform: uppercase;
  }

  &__info {
    position: absolute;
    background-color: $white;
    padding: 0.5rem;
    box-shadow: -1px 2px 3px -1px rgba(0, 0, 0, 0.3);
    width: 200px;
    top: -40%;
    &.left {
      right: calc(100% + 5px);
      &::after {
        @include arrowBody();
        box-shadow: 2px -1px 0px 0px rgba(0, 0, 0, 0.3);
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        top: 50%;
        left: 100%;
      }
    }
    &.right {
      left: calc(100% + 5px);
      &::after {
        @include arrowBody();
        box-shadow: -1px 2px 1px 0px rgba(0, 0, 0, 0.3);
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        top: 50%;
        left: 0;
      }
    }
  }

  &__info-button {
    position: absolute;
    right: 5%;
    border: 1px solid $c--primary;
    color: $c--primary;
    border-radius: 50%;
    display: flex;
    height: 30px;
    width: 30px;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    z-index: 1;
    &:focus {
      outline: none;
    }
  }

  &__subtitle {
    font-size: 0.875rem;
    color: $c--secondary;
    display: inline-block;
    margin: 8px 0 0;
  }

  &__chevron {
    display: none;
    min-width: 19px;
    transform: rotate(-90deg);
    transition: transform 0.2s;
    width: 19px;
  }

  &__header {
    &__title {
      color: $c--secondary;
      font-weight: normal;
      margin: 0;
      margin-top: 20px;

      svg + & {
        margin-top: 20px;
      }
    }

    &__subtitle {
      color: $c--secondary;
      font-weight: normal;
      margin: 0;
      margin-top: 4px;
    }
  }

  &__child__wrapper {
    width: 100%;
  }

  &__underline {
    display: none;
  }
}

.tab-column {
  padding: 30px 0;
  position: relative;

  &__wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  &::before {
    background-color: $c--secondary-light-4;
    content: '';
    height: 1px;
    right: -24px;
    position: absolute;
    top: 0;
    width: calc(100% + 24px);
  }

  &:last-of-type {
    border: 0;
  }

  .periodic-row {
    margin-top: 30px;
    max-width: initial;
  }
}

.tab-accordion__children {
  .content-row__grid-wrapper:last-of-type {
    .tab-accordion__button {
      margin-bottom: 0;
    }
  }

  & + .separator__full-width {
    display: none;
  }

  .tab-accordion__panel {
    order: initial;
    padding-left: 30px;
    padding-top: 0;
  }

  .tab-accordion__chevron {
    display: inline-block;
  }

  .tab-accordion__button {
    align-items: center;
    border: 0;
    display: flex;
    justify-content: center;
    margin-top: 0;
    order: initial;
    padding: 30px 0;
    padding-left: 30px;
    text-align: left;

    .tab-accordion__title {
      color: $c--secondary;
    }

    .tab-accordion__title-wrapper {
      border: 0;
      padding: 0;
      &::after {
        display: none;
      }
    }

    &.tab-accordion__button--active {
      pointer-events: initial;

      .tab-accordion__title {
        color: $c--primary;
      }

      &::after {
        display: none;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &::before {
      background-color: $c--secondary-light-4;
      content: '';
      height: 1px;
      left: -24px;
      position: absolute;
      top: 0;
      width: calc(100% + 48px);
    }

    &::after {
      left: -24px;
      width: calc(100% + 48px);
    }
  }
}

@media only screen and (min-width: $screen-xsm-min) {
  .tab-column {
    border-right: 1px solid rgba(131, 156, 169, 0.4);
    padding-right: 30px;

    &__wrapper {
      flex-direction: row;
    }

    &:first-child:nth-last-child(3) {
      border: none;
    }

    &:nth-last-child(2) {
      padding-left: 0;
    }

    &:first-child {
      padding-bottom: 30px;
    }

    &:last-child {
      padding-left: 30px;
      padding-right: 0;
    }
  }

  .tab-accordion__subtitle {
    display: none;
  }

  .tab-accordion__children {
    .tab-accordion__panel,
    .tab-accordion__panel.fade-out-schrink {
      animation-duration: 1s;
      animation-name: fadeInExpand;
      display: inline-block;
      height: auto;
      opacity: 1;
      padding: 0;
    }

    .tab-column {
      border-bottom: 0;
      margin-top: 30px;
      padding-top: 0;

      &::before {
        display: none;
      }

      &:first-child {
        padding-bottom: 30px;
      }

      &:last-child {
        padding-left: 30px;
        padding-right: 0;
      }

      &:first-child:nth-last-child(3) {
        &::before {
          background-color: $c--secondary-light-4;
          bottom: 0;
          content: '';
          height: 1px;
          left: 0;
          position: absolute;
          width: calc(100vw + 500px);
        }
      }
    }

    .tab-accordion__button {
      border: 0;
      display: flex;
      margin: 0;
      padding-left: 0;
      pointer-events: none;
      position: relative;
      width: 100%;

      &:first-of-type {
        border-top: 0;
        margin-top: 0;
      }

      &:hover {
        border-top: 0;
      }

      &.tab-accordion__button--active {
        &:last-of-type {
          margin-bottom: 0;
        }

        &::after {
          display: block;
        }
      }

      &::before {
        background-color: $c--secondary-light-4;
        top: 0;
        content: '';
        height: 1px;
        left: -250px;
        position: absolute;
        width: calc(100vw + 500px);
      }

      &::after {
        background-color: $c--secondary-light-4;
        bottom: 0;
        content: '';
        display: block;
        height: 1px;
        left: 0;
        position: absolute;
        width: 100vw;
      }

      &:not(:first-of-type):hover {
        border-bottom: 1px solid $c--secondary-light-4;
        border-top: 1px solid $c--secondary-light-4;
        color: $c--secondary;
        cursor: default;
      }
    }

    .tab-accordion__title {
      margin: 0;
      max-width: auto;
      padding: 0;
    }

    & + .separator__full-width {
      display: inherit;
    }

    .tab-accordion__title-wrapper {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
    }

    .tab-accordion__subtitle {
      display: inline-block;
    }
  }
}

@media only screen and (max-width: $screen-sm-min) {
  .tab-accordion {
    &__info-button {
      top: -20px;
      right: calc(50% - 15px);
    }
  }
}

@media only screen and (min-width: $screen-sm-min) {
  .tab-accordion {
    &--three-tabs {
      // HACKY SOLUTION, REPLACE LATER WITH BETTER SOLUTION
      .tab-accordion__button {
        display: inline;
        &:nth-of-type(1) {
          .tab-accordion__title-wrapper {
            float: right;
          }
        }
        &:nth-of-type(2) {
          flex-grow: 0;
        }
        &:nth-of-type(3) {
          .tab-accordion__title-wrapper {
            float: left;
          }
        }
      }
    }
    &__button {
      display: flex;

      &--active {
        .tab-accordion__title-wrapper {
          background: $white;
          border-left: 1px solid $c--secondary-light-4;
          border-right: 1px solid $c--secondary-light-4;
          position: relative;
        }
      }

      & {
        .tab-accordion__title-wrapper {
          margin: 0 auto;
        }
      }

      &:first-of-type {
        .tab-accordion__title-wrapper {
          margin-left: auto;
          margin-right: 0;
        }
      }

      &:last-of-type {
        .tab-accordion__title-wrapper {
          margin-left: 0;
          margin-right: auto;
        }
      }

      &::after {
        display: none;
      }
    }

    &__title {
      margin: 0 auto;
      max-width: 440px;
      padding: 0 10px;

      &-wrapper {
        display: inline-block;

        &::after {
          bottom: initial;
          top: 0;
        }
      }
    }
  }

  .tab-column {
    border-bottom: 0;
    padding: 0 30px;
    padding-bottom: 60px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border: 0;
      padding-right: 0;
    }
  }

  .tab-accordion__header {
    padding-top: 30px;
  }

  .tab-accordion__children {
    .tab-accordion__chevron {
      display: none;
    }
  }
}

@media only screen and (min-width: $screen-md-min) {
  .tab-column {
    &::before {
      display: none;
    }

    &:first-child:nth-last-child(3) {
      border-right: 1px solid rgba(131, 156, 169, 0.4);
    }

    &:first-child:nth-last-child(2) {
      padding-left: 30px;
    }

    &:nth-last-child(2) {
      padding-left: 30px;
    }
  }
}
