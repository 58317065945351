.plot-xml-cell {
  color: $c--secondary;
  background-color: $c--secondary-light-05;
  border-radius: 0.2rem;
  margin: 0.5rem;
  margin-left: 2rem;
  font-weight: 300;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-content: center;
  padding: 0.5rem;
  padding-left: 0px;
  line-height: 2rem;
  &__column {
    display: flex;
    &:first-child {
      margin-left: 0;
    }
    margin-left: 1rem;
    &--input {
      border: none;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
    }
    &--new-select-with-right-element {
      margin-top: 32px;
    }
    &--delete-button {
      color: $c--orange;
      font-size: 1rem;
      border: none;
      cursor: pointer;
      background-color: transparent;
      &:focus {
        outline: none;
      }
    }
  }
  & .plot-xml-cell-width__check {
    width: 1.5rem;
    &--checkbox {
      padding: 0.2rem 0;
    }
  }
  & .plot-xml-cell-width__1 {
    flex: 1;
    &.with-delete-button {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
    &.numbers {
      text-align: right;
      align-items: center;
    }
  }
  & .plot-xml-cell-width__2 {
    flex: 2;
  }
  & .plot-xml-cell-width__3 {
    flex: 3;
  }
  & .plot-xml-cell-width__4 {
    flex: 4;
  }
}
