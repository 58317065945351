@media only screen and (min-width: $screen-xsm-min) {
  .product_portfolio {
    .product_select__scroller {
      height: calc(100vh - 76px);
    }
  }
}

@media only screen and (min-width: $screen-lg-min) {
  .product_portfolio {
    .product_select__scroller {
      height: calc(100vh - 128px);
    }
  }
}
