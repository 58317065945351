.organic-fertilization {
  max-width: 500px;
  margin: 0 auto;
  &__title {
    width: 100%;
    color: $c--secondary;
    font-weight: bold;
    background-color: $c--primary--pale-dark-2;
    border-radius: 0.2rem;
    padding: 0.2rem 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
}
