.npks-bars {
  width: 100%;
  &__row {
    padding: 0.5rem 0;
    &--element {
      display: inline-flex;
      color: $c--secondary;
    }
    &--title {
      padding-bottom: 0.2rem;
    }
    &--value {
      margin-left: 0.5rem;
    }
  }
}
