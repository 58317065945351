.box-with-title-mobile {
  border: solid 1px $c--secondary;
  box-shadow: none !important;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  &.expanded {
    border: solid 1px $c--primary;
  }
  &:first-child,
  &:last-child {
    border-radius: 0.5rem;
  }
}
