.vertical_bar {
  &__wrapper {
    height: 200px;
    width: 100%;
    position: relative;
    &__super {
      width: 90px;
    }
  }
  &__container {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  &__background-bar {
    &--value {
      color: inherit;
      text-align: center;
      position: absolute;
      bottom: 100%;
      margin: 0;
      width: 100%;
    }
  }
  &__foreground-bar {
    &--value {
      color: inherit;
      text-align: center;
      position: absolute;
      bottom: 100%;
      margin: 0;
      width: 100%;
    }
  }
  &__title {
    margin: 0;
    margin-top: 5px;
    text-align: center;
    font-size: 12pt;
    color: $c--secondary;
    font-weight: normal;
  }
}
