.slick-slide {
  height: auto;

  & > div {
    height: 100%;
  }
}

.slick-track {
  display: flex;
}

.slider-container {
  padding: 0 48px;

  div:focus {
    outline: 0;
  }
}

.slide-with-picto {
  text-align: center;
}

.slide__title {
  color: $c--secondary;
  font-size: 1.375rem;
  font-weight: 900;
  margin: 0;
  text-transform: uppercase;
  min-height: 4rem;
}

.slide--subtitle {
  color: $c--secondary;
  font-size: 1rem;
  font-style: italic;
  font-weight: 200;
  margin: 0;
  margin-top: 12px;
}

.slick-arrow {
  height: 16px;
  top: calc(50% + 30px);
  width: 28px;

  &::before {
    background-size: 28px 16px;
    content: '';
    display: inline-block;
    height: 16px;
    opacity: 1;
    width: 28px;
  }
}

.slick-next {
  right: -40px;

  &::before {
    background-image: url('assets/images/arrow-right.svg');
    opacity: 1;
  }
  &:focus {
    background-color: $c--secondary-light-4;
  }
}

.slick-prev {
  left: -40px;

  &::before {
    background-image: url('assets/images/arrow-left.svg');
  }
  &:focus {
    background-color: $c--secondary-light-4;
  }
}

.slick-next.slick-disabled::before,
.slick-prev.slick-disabled::before {
  opacity: 0.3;
}

.slide-picto__wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 150px;
  width: 100%;

  svg {
    max-width: 100%;
  }
}

.slide-container {
  display: flex !important;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

.slide-subtext {
  color: $c--secondary;
  font-size: 0.75rem;
  text-align: center;
  margin: 32px 0 0 0;
}

.slide-subtext--picto {
  vertical-align: middle;
}

.slick-dots {
  position: relative;
  bottom: 0;
  margin: 24px 0 0 0;

  li {
    height: 8px;
    margin: 0;
    margin-left: 6px;
    width: 8px;

    &:first-of-type {
      margin-left: 0;
    }

    button {
      height: 8px;
      padding: 0;
      width: 8px;

      &::before {
        border: 1px solid $c--primary;
        border-radius: 50%;
        content: '';
        opacity: 1;
        height: 8px;
        line-height: inherit;
        width: 8px;
      }
    }

    &.slick-active button::before {
      opacity: 1;
      background-color: $c--primary;
    }
  }
}
