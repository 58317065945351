.plan_detail {
  &__seperator {
    margin: auto;
    margin-top: 60px;
    max-width: 1000px;
  }
  .step_change_explenation {
    max-width: 1000px;
    width: 100%;
    margin: auto;
    margin-top: 15px;
  }
}
