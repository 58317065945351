.nutrizones-footer-top {
  background-size: cover;
  background-position: center;
  min-height: 70vh;
  padding: 0 10%;
  &__box {
    background-color: $c--primary--pale-dark-5;
    width: 50%;
    color: $white;
    padding: 2rem 10% 2rem 2rem;
    &--logo {
      width: 40%;
      padding: 1.5rem 0.5rem 0.5rem 0.5rem;
    }
    &--text {
      font-size: 0.6rem;
      padding-bottom: 1.5rem;
    }
    &--title {
      font-size: 2.2rem;
    }
    &--content {
      font-size: 1rem;
      margin: 1rem 0;
    }
    &--content-large {
      font-size: 1.5rem;
      margin: 1rem 0;
    }
    &--actions {
      .title {
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: 1rem;
      }
      .buttons {
        display: flex;
        flex-direction: row;
        gap: 1rem;
      }
    }
  }
}

@media only screen and (max-width: $screen-md-max) {
  .nutrizones-footer-top {
    &__box {
      width: 70%;
    }
  }
}

@media only screen and (max-width: $screen-md-mui-min) {
  .nutrizones-footer-top {
    &__box {
      width: 100%;
    }
  }
}

@media only screen and (max-width: $screen-xsm-min) {
  .nutrizones-footer-top {
    padding: 0;
    &__box {
      &--logo {
        width: 60%;
      }
      &--title {
        font-size: 1.5rem;
      }
      &--actions {
        .buttons {
          flex-direction: column;
          svg {
            width: 300px;
            height: 50px;
          }
        }
      }
    }
  }
}
