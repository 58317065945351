.top-video-container {
  padding: 3rem 10%;
  width: 100%;
  display: flex;
  align-items: center;
  @media screen and (min-width: $screen-lg-min) {
    height: 34rem;
  }
  @media screen and (max-width: $screen-xl-min) {
    height: 27rem;
  }
  @media screen and (max-width: $screen-lg-min) {
    height: 24rem;
  }
  @media screen and (max-width: $screen-md-min) {
    height: auto;
  }

  &__video-box {
    display: inline-block;
    border: none;
    background-color: transparent;
    padding: 0;
    position: relative;
    &:focus {
      outline: none;
    }
    &--description {
      color: $white;
      position: absolute;
      bottom: 1.5rem;
      left: 1rem;
      right: 1rem;
      text-align: left;
      font-size: 0.8rem;
    }
    &--line {
      position: absolute;
      height: 1px;
      background-color: $c--primary;
      width: 50%;
      bottom: 1rem;
      left: 1rem;
    }
    img {
      width: auto;
      height: 27rem;
      @media screen and (max-width: $screen-xl-min) {
        height: 20rem;
      }
      @media screen and (max-width: $screen-lg-min) {
        height: 15rem;
      }
      @media screen and (max-width: $screen-md-min) {
        width: 100%;
        height: auto;
      }
    }
  }
  &__right {
    display: inline-block;
    &--icon {
      width: 100px;
      height: 100px;
    }
    &--new {
      color: $c--primary;
      text-transform: capitalize;
    }
    &--title {
      color: $c--secondary;
      font-size: 2rem;
      font-weight: bold;
      &.second {
        width: 90%;
      }
    }
    &--description {
      color: $c--secondary;
      font-size: 1rem;
      padding: 1rem 0;
    }
  }
  &__full {
    display: flex;
    width: 100%;
    flex-direction: row;
    &--left {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      &.icon {
        width: 150px;
        height: 150px;
      }
    }
    &--right {
      flex: 2;
      &.new {
        color: $c--primary;
      }
      &.title {
        color: $c--secondary;
        font-size: 2rem;
        font-weight: bold;
      }
      &.description {
        color: $c--secondary;
        font-size: 1rem;
        padding: 1rem 0;
      }
    }
  }
}

@media only screen and (max-width: $screen-sm-min) {
  .top-video-container {
    display: block;
    &__video-box {
      width: 100%;
      padding: 0 !important; // Needed to override inline styles
      &--description {
        color: $c--secondary;
        font-size: 1rem;
        position: relative;
        margin-bottom: 1rem;
        bottom: 0;
        left: 0;
        margin-top: 2rem;
        text-align: center;
      }
      &--line {
        display: none;
      }
    }
    &__right {
      width: 100%;
      padding-left: 0 !important; // Needed to override inline styles
      &--title {
        font-size: 1.7rem;
      }
      & button {
        width: 100%;
      }
      &--icon {
        display: block;
        margin: 2rem auto 0;
      }
    }
    &__full {
      flex-direction: column;
      &--left {
        &.icon {
          width: 150px;
          height: 150px;
        }
      }
    }
  }
}
