.periodic_el_quantity {
  &__wrapper {
    &:first-of-type {
      margin-top: 34px;
    }
    margin-top: 56px;
    &--single {
      &:first-of-type {
        margin-top: 24px;
      }
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      .periodic_el_quantity__field {
        width: calc(100% - 250px);
        min-width: 110px;
      }
    }
    p[class*='MuiFormHelperText']:not(.form__error-text) {
      position: absolute;
      top: 30px;
    }
  }
}
.periodic-element {
  margin-right: 8px;
}

.periodic_el_quantity__element_title_wrapper,
.picto-title {
  display: flex;
  align-items: center;
  padding-right: 15px;
  flex: 1;

  h3 {
    color: $c--secondary;
    margin: 0;
    font-weight: 400;
  }
}

.periodic-row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  .picto-title {
    max-width: 150px;
  }
}
