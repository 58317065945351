.new-select-with-right-element {
  position: relative;
  color: $c--primary;
  width: 100%;
  top: 5px;
  background-color: $white;
  &__container {
    position: absolute;
    top: 0;
    right: 1.5rem;
    min-width: 20px;
    max-width: calc(100% - 20px);
  }
}
