.import-plot-template {
  border-top: $c--secondary-light-2 solid 1px;
  padding: 2rem 5% 2rem 5%;
  &__duplicates-dialogue {
    max-height: 300px;
    overflow-y: auto;
    width: 100%;
    margin: 0.5rem 0;
    &::-webkit-scrollbar {
      width: 0.2rem;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $c--primary;
    }
    &--element {
      color: $c--secondary;
      padding: 0.2rem 0;
      &-left {
        display: inline-block;
        width: 50%;
        font-weight: bold;
        text-align: right;
        padding-right: 0.5rem;
      }
      &-right {
        display: inline-block;
        width: 50%;
      }
    }
    &--bottom-text {
      color: $c--secondary;
      text-align: center;
      margin-top: 1rem;
    }
  }
}

@media only screen and (max-width: $screen-md-min) {
  .import-plot-template {
    padding: 2rem 2% 2rem 2%;
  }
}
