.youtubepopup-container {
  widows: 100%;
  height: 100%;
  &__player {
    height: 50vw;
  }
  &__close {
    position: absolute;
    right: 6px;
    top: 6px;
  }
}
