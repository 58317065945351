.my-crop-rotations-template {
  padding: 0rem 5% 2rem 5%;
  &__top-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &--select {
      min-width: 220px;
      max-width: 300px;
      margin-bottom: 0.5rem;
    }
    &--button {
      text-transform: initial;
      border-radius: 15px;
    }
    &--search-field {
      width: 100%;
    }
  }
}

@media only screen and (max-width: $screen-lg-min) {
  .my-crop-rotations-template {
    &__top-area {
      &--search-field {
        margin-top: 0.5rem;
      }
    }
  }
}

@media only screen and (max-width: $screen-md-min) {
  .my-crop-rotations-template {
    padding: 2rem 5% 2rem 5%;
  }
}
