.n_format {
  max-width: 500px;
  margin: auto;
  margin-top: 50px;
  &__radio_wrapper {
    margin-top: 35px;
    margin-bottom: 40px;
  }
  &__legend_wrapper {
    margin-top: 25px;
  }
  &__key_value_wrapper {
    margin-top: 40px;
  }
  &__icon_title_wrapper {
    margin-top: 40px;
    margin-bottom: 20px;
  }
}
