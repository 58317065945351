.rail-outer {
  position: relative;
  width: 100%;
  height: 42px;
  transform: translate(0%, -50%);
  border-radius: 7px;
  cursor: pointer;
}

.rail-inner {
  position: absolute;
  width: 100%;
  height: 14px;
  transform: translate(0%, -50%);
  border-radius: 7px;
  pointer-events: 'none';
  background-color: rgba(131, 156, 169, 0.5);
}

.range-slider-handle {
  position: absolute;
  transform: translate(-50%, -50%);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 5;
  width: 28px;
  height: 42px;
  cursor: pointer;
  background-color: none;
}

.range-slider-handle-bottom {
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.3);
}

.range-slider-track {
  border-radius: 7px;
  cursor: pointer;
  position: absolute;
  transform: translate(0%, -50%);
  height: 14px;
  z-index: 1;
}

.range-slider-tick {
  position: absolute;
  margin-top: 14px;
  width: 1px;
  height: 5px;
  background-color: rgb(200, 200, 200);
}

.range-slider-tick-bottom {
  position: absolute;
  margin-top: 22px;
  font-size: 10px;
  text-align: center;
}
