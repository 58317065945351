.circular_progress {
  &__bar {
    // https://jsfiddle.net/ggj55sqo/
    position: relative;
    text-align: center;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    background-color: $c--primary;
    background-image: linear-gradient(95deg, transparent 50%, $c--secondary-light-4-absolute 50%),
      linear-gradient(90deg, $c--secondary-light-4-absolute 50%, transparent 50%);
    margin: auto;
    &__background {
      position: relative;
      top: 10px;
      left: 10px;
      text-align: center;
      width: 230px;
      height: 230px;
      border-radius: 50%;
      background-color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__content {
    &__h4 {
      font-size: 26pt;
    }
    &__h5 {
      margin: 0;
      color: $c--secondary;
      font-weight: normal;
      font-size: 13pt;
      max-width: 150px;
    }
  }
}
