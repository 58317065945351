.my-plot-cell {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  color: $c--secondary;
  background-color: $c--secondary-light-05;
  border-radius: 0.2rem;
  cursor: pointer;
  font-size: 0.9rem;
  &.selected {
    background-color: $c--primary--pale-dark-2;
  }
  &__data {
    flex: 7;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    &--checkbox {
      width: 48px;
    }
    &--icon-holder {
      width: 30px;
      .icon {
        height: 20px;
        width: 20px;
      }
    }
    &--name {
      padding: 0.5rem;
      flex: 3;
      word-break: break-all;
    }
    &--area {
      flex: 1;
      text-align: right;
    }
    &--action-layer {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  &__actions {
    flex: 6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    &--button {
      width: 44px;
      height: 44px;
      &--icon {
        width: 44px;
        height: 44px;
      }
    }
    &--edit {
      padding: 0 0.5rem;
      color: $c--primary;
      border-right: $c--secondary 1px solid;
      text-align: right;
      &:hover {
        text-decoration: underline;
      }
    }
    &--delete {
      padding: 0 0.5rem;
      color: $c--orange;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__modal-opener {
    display: none;
  }
}

@media only screen and (max-width: $screen-md-min) {
  .my-plot-cell {
    &__data {
      &--action-layer {
        right: 50px;
      }
    }
  }
}

@media only screen and (max-width: $screen-sm-min) {
  .my-plot-cell {
    &__data {
      flex: 7;
      &--name {
        flex: 5;
      }
      &--area {
        flex: 2;
      }
    }
    &__actions {
      display: none;
    }
    &__modal-opener {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      &--icon {
        width: 1rem;
        height: 1rem;
      }
    }
  }
}
