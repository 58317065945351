@keyframes questionMark {
  0% {
    top: -50px;
  }
  50% {
    top: -35px;
  }
}
@keyframes sunRising {
  0% {
    top: 50px;
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  10% {
    opacity: 1;
    top: -15px;
  }
  55% {
    opacity: 1;
    top: -15px;
  }
  60% {
    opacity: 1;
    top: 20px;
  }
  65% {
    opacity: 0;
    top: 50px;
  }
}

@keyframes apearSunRising {
  0% {
    fill: $c--secondary-dark;
  }
  5% {
    fill: $c--primary;
  }
  60% {
    fill: $c--primary;
  }
  65% {
    fill: $c--secondary-dark;
  }
}

.catch_error {
  &__content {
    &__wrapper {
      padding-left: 24px;
      padding-right: 24px;
      text-align: center;
    }
    &__visual {
      &__wrapper {
        position: relative;
        margin-bottom: 30px;
      }
      &__logo {
        position: relative;
        z-index: 1;
        width: 190px;
        height: 190px;
        &--field {
          margin-top: -30px;
          g {
            fill: $c--secondary-dark;
            animation: apearSunRising 12s ease infinite;
            animation-fill-mode: forwards;
            animation-delay: 0.4s;
          }
        }
        &--plan {
          path {
            fill: $c--primary;
          }
        }
        &--tractor {
          width: 190px;
          height: 120px;
          path {
            fill: $c--primary;
          }
        }
      }
      &__title {
        position: absolute;
        left: 0;
        margin-left: auto;
        margin-right: auto;
        right: 0;
        text-align: center;
        &--plan {
          top: -50px;
          left: 5px;
          font-size: 140px;
          animation: questionMark 1s ease-in-out infinite;
        }
        &--field {
          font-weight: bold;
          opacity: 0;
          font-size: 30pt;
          top: 50px;
          animation: sunRising 12s linear infinite;
          animation-delay: 0.4s;
        }
      }
    }
    &__explenation {
      max-width: 350px;
      margin: auto;
    }
  }
}

@media screen and (min-width: $screen-sm-min) {
  .catch_error__content {
    &__wrapper {
      display: flex;
      text-align: left;
      align-items: center;
      justify-content: center;
    }
    &__visual {
      &__wrapper {
        margin-bottom: 0px;
      }
      &__logo {
        &--field {
          margin-bottom: 30px;
        }
      }
    }
    &__explenation {
      margin: 0;
      margin-left: 40px;
    }
  }
}
