.btn-white--outline {
  border: 2px solid $white;
  color: $white;

  &[disabled] {
    color: $white;
    opacity: 0.6;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    border: 2px solid $white;
  }
}

.btn-white--text {
  color: $white;
  background-color: rgba(255, 255, 255, 0.3);

  &:hover {
    background-color: rgba(255, 255, 255, 0);
  }
}

.go_back,
.go_back__label {
  display: flex;
  text-decoration: none;
}

.go_back {
  &__label {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
  }
}

.go_back * {
  align-items: center;
}

#go_back {
  white-space: nowrap;
  text-transform: uppercase;
  cursor: pointer;
  color: #839ca9;
}

button.go_back {
  border: none;
  align-items: center;
  background-color: #ffffff;
}

.button_hidden {
  border: none;
  background-color: transparent;
}

.button-thick {
  &--green {
    border-color: $c--primary !important;
    border-width: 2px !important;
  }
}

button {
  cursor: pointer;
}

.btn {
  &-yellow {
    background-color: $c--tertiary;
    &:hover {
      background-color: $c--tertiary;
    }
  }
  &--border {
    border: 2px solid $white;
    color: black;
    &:hover {
      background-color: $c--tertiary;
      border: 2px solid $white;
      color: black;
    }
  }
  &--red {
    background-color: $white;
    border: 2px solid $c--error !important; //FIX IN MUITHEME
    color: $c--error !important;
    &:hover {
      border: 2px solid $white;
      color: black;
    }
  }
}

.button {
  &__center {
    margin: auto;
    display: block;
  }
  &__loading {
    position: relative;
    overflow: hidden;
    .linear_progress {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 5px;
    }
  }
}
