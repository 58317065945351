.accordion {
  &__card {
    width: 100%;
    max-width: 1000px;
    box-shadow: none !important;
    border: 1px solid $c--secondary;
    margin-top: -1px;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    &-list {
      margin-top: 30px;
    }
    &-detail {
      flex-direction: column;
      position: relative;
      padding: 24px 24px 24px !important;
      .seperator:first-of-type {
        width: 100%;
        left: 0;
        position: absolute;
        top: 0;
        background-color: $c--secondary;
      }
      .seperator:nth-of-type(2) {
        margin: 30px 0px;
        display: inline;
      }
      &__section {
        &--alone {
          width: 100% !important;
        }
      }
    }
    &-title {
      color: $c--secondary;
      font-size: 1.125rem;
      font-weight: 400;
      margin: 0;
      &-wrapper {
        width: calc(100% - 70px);
      }
    }
    &-header-wrapper {
      margin: 10px 0px;
      width: 100%;
      display: flex;
      align-items: center;
    }
    &--selected {
      border: 1px solid $c--primary;
      .accordion__card-title {
        color: $c--primary;
        font-weight: bold;
      }
      .content-label {
        color: $c--primary;
      }
      .timeline__step-num {
        background-color: $c--primary;
      }
    }
    &--single-section {
      max-width: 450px;
      width: calc(100% - 2px);
    }
  }
  &__icon {
    margin-right: 15px;
    &__radio {
      padding: 0px !important;
      margin-left: -5px !important;
      svg {
        font-size: 30px !important;
      }
    }
    &__product {
      height: 44px;
      flex-basis: 30px;
      flex-shrink: 0;
      width: 30px;
    }
  }
}

@media only screen and (min-width: $screen-xsm-min) {
  .accordion__card-detail {
    flex-direction: row;
    &__section {
      width: calc(50% - 30px);
      &--1 {
        margin-right: 30px;
      }
      &--2 {
        margin-left: 30px;
      }
    }
    .seperator:nth-of-type(2) {
      margin: 0px;
      left: 50%;
      display: block;
      height: calc(100% - 56px);
      width: 1px;
      flex: 1;
      position: absolute;
    }
  }
}
