.my_overview_entities {
  margin-top: 10px;
  .tab-accordion__panel-expand {
    padding: 10px; //
  }
  .filter_my_overview_entities {
    display: none;
  }
  .filter_button__wrapper {
    margin-left: 5px;
  }
  &__complex_list_item__container {
    min-height: calc(100vh - 340px);
    min-height: calc((var(--vh, 1vh) * 100) - 340px);
  }
  &__manage_button {
    display: block !important;
    margin: auto !important;
    margin-top: 10px !important;
  }
}

@media only screen and (min-width: $screen-xs-min) {
  .my_overview_entities {
    .filter_button__wrapper {
      margin-left: 25px;
    }
    .tab-accordion__panel-expand {
      padding: 30px 24px;
    }
  }
}

@media only screen and (min-width: $screen-sm-min) {
  .my_overview_entities {
    .filter_button__wrapper {
      margin-bottom: 10px;
    }
  }
}

@media only screen and (min-width: $screen-md-min) {
  .my_overview_entities {
    display: flex;
    .filter_my_overview_entities {
      display: block;
      margin-right: 50px;
      margin-left: 26px;
      margin-top: 57px;
    }
    &__tab_accordion_wrapper {
      width: calc(100% - 295px);
    }
    &__complex_list_item__container {
      margin-left: -30px;
      width: calc(100% + 60px);
    }
  }
}

@media only screen and (min-width: $screen-lg-min) {
  .my_overview_entities {
    .filter_my_overview_entities {
      margin-top: 207px;
    }
  }
}
